import React, { Component } from "react";
import ReactExport from "react-data-export";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar } from "@material-ui/core";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { getProducts, getProductsByParams } from "../logic/APIHandler";
import Spinner from "./Loading_Com";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const cellBorderStyle = {
  top: { style: "thin" },
  bottom: { style: "thin" },
  left: { style: "thin" },
  right: { style: "thin" },
}
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Excel_Com extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            excelData: [],
            openAlert: false,
            alertText: "",
        }
        this.excelRef = React.createRef();
        this.downloadStockOpnameExcel = this.downloadStockOpnameExcel.bind(this);
    }

    // Create formatted excel data & download it
    async downloadStockOpnameExcel() {
        this.setState({ isLoading: true });
        let resp = "";
        if ( localStorage.getItem("level") === "1" ) {
            resp = await getProductsByParams(
                JSON.parse(localStorage.getItem("currBranch")).value,
                false
            );            
        }
        else {
            resp = await getProducts();
        }
        if ( resp[0] && resp[0].status === 200 ) {
            const result = resp[0].data.sort((a,b) => (a.name > b.name? 1 : -1));
            // Create columns & header style
            let res = [
                {
                    columns: [
                        {title: "No", width: {wch: 4}, style: {
                            alignment: { horizontal: "center" },
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                            border: cellBorderStyle,
                        }}, 
                        {title: "Kode Produk", width: {wch: 14}, style:  {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                            border: cellBorderStyle,
                        }},
                        {title: "Nama Produk", width: {wch: 25}, style:  {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                            border: cellBorderStyle,
                        }},
                        {title: "Stok Sebelum", width: {wch: 15}, style: {
                            alignment: { horizontal: "center" },
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                            border: cellBorderStyle,
                        }},
                        {title: "Stok Sesudah", width: {wch: 15}, style: {
                            alignment: { horizontal: "center" },
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                            border: cellBorderStyle,
                        }},
                        {title: "Keterangan", width: {wch: 25}, style: {
                            alignment: { horizontal: "center" },
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                            border: cellBorderStyle,
                        }},
                    ],
                    data: [],
                }
            ]
            
            // Add row items to table
            for (let i=0; i < result.length; i++) {
                res[0].data.push([
                    {
                        value: (i+1),
                        style: {alignment: { horizontal: "center" }, border: cellBorderStyle}
                    },
                    {
                        value: result[i].product_code,
                        style: {border: cellBorderStyle},
                    },
                    {
                        value: result[i].name,
                        style: {border: cellBorderStyle},
                    },
                    {
                        value: this.props.type === "store"?
                            result[i].stock_store : result[i].stock_warehouse,
                        style: {alignment: { horizontal: "center" }, border: cellBorderStyle},
                    },
                    {
                        value: "",
                        style: {border: cellBorderStyle},
                    },
                    {
                        value: "",
                        style: {border: cellBorderStyle},
                    },
                ])
            }

            // Set table data
            this.setState({ excelData: res, isLoading: false }, () => {
                if ( this.excelRef.current !== null ) {
                    this.excelRef.current.click();
                }
            })
        }
        else if ( resp[1] && resp[1].status === 400 ) {
            this.setState({
                alertText: resp[1].data[0].msg,
                openAlert: true,
                isLoading: false,
            })
        }
        else {
            this.setState({
                alertText: "Gagal mengunduh excel! Silakan coba lagi.",
                openAlert: true,
                isLoading: false,
            })
        }
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12}>
                    
                    {this.state.isLoading? <Spinner /> : <></>}
                    
                    <Snackbar
                        open={this.state.openAlert}
                        autoHideDuration={1500}
                        onClose={() => this.setState({ openAlert: false })}
                    >
                        <Alert severity="error">
                            {this.state.alertText}
                        </Alert>
                    </Snackbar>

                    <Button
                        variant="contained"
                        className="excel-com-btn"
                        onClick={this.downloadStockOpnameExcel}
                    >
                        <CloudDownloadIcon style={{ marginRight: "10px" }}/>
                        {this.props.label}
                    </Button>
                    <div style={{ pointerEvents: "none" }}>
                        <ExcelFile 
                            filename={this.props.excelTitle}
                            element={
                                <Button innerRef={this.excelRef}></Button>
                            }
                        >
                            <ExcelSheet dataSet={this.state.excelData} name={this.props.sheetTitle}/>
                        </ExcelFile>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default Excel_Com;