import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar, Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { TextInput, NumberInput, PriceInput, DropdownInput } from "../../../logic/FormInput";
import { getProducts, postProductLoss } from "../../../logic/APIHandler";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddLoses() {

    const numberRegex = /^[0-9]*$/;

    // Form State
    const [stock, setStock] = useState("");
    const [products, setProducts] = useState([]);
    const [isSelling, setIsSelling] = useState(false);

    // Page State
    const history = useHistory();
    const { register, handleSubmit, control, errors } = useForm();
    const [openJWT, setOpenJWT] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
        getProductData();
    }, []);

    // Get list of products
    const getProductData = async () => {
        setIsLoading(true);
        let resp = await getProducts();
        if ( resp[0] && resp[0].status === 200 ) {
            let result = [];
            resp[0].data.sort((a,b) => (a.name > b.name? 1 : -1)).forEach(res => {
                result.push({
                    "label": res.product_code + " - " + res.name,
                    "value": res._id,
                })
            });
            setProducts(result);
        }
        else if ( resp[0] && resp[0].status === 204 ) {
            setErrorText("Data produk tidak ditemukan.");
            setOpenErrorAlert(true);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
        }
        setIsLoading(false);
    }

    // Handle onChange input number
    const onChangeInputNumber = (event) => {
        if ( numberRegex.test(event.target.value) ) {
            if ( event.target.name === "quantity" ) {
                setStock(event.target.value);
            }
        }
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send new data to server
    const onSubmit = async (data) => {
        setIsLoading(true);
        let resp = await postProductLoss(data);
        if (resp[0] && resp[0].status === 200) {
            setIsLoading(false);
            setOpenSuccessAlert(true);
            await sleep(1000);
            history.replace("/losses");
        }
        else if ( resp[1] && resp[1].status === 400 ) {
            setErrorText(resp[1].data[0].msg);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            setErrorText(resp[1].message);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="content" style={{ position: "relative" }}>
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""}
                    level={localStorage.getItem("level")} />
                
                <Modal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Menambah Produk Loss
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2.2rem" }}>
                                Tambah Produk Loss
                            </h1>

                            <Grid container>
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    <label className="selection-label">Produk *</label>
                                    <DropdownInput
                                        name="product_id"
                                        className="selection-field add-margin-bottom"
                                        control={control}
                                        options={products}
                                        rules={{ required: "*produk harus dipilih" }}
                                        errors={errors.product_id}
                                        errorClassName="text-required"
                                        isClearable={false}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    <label className="selection-label">Asal Produk *</label>
                                    <DropdownInput
                                        name="origin"
                                        className="selection-field add-margin-bottom"
                                        control={control}
                                        options={[
                                            { label: "Toko", value: "store" },
                                            { label: "Gudang", value: "warehouse" },
                                        ]}
                                        rules={{ required: "*asal produk harus dipilih" }}
                                        errors={errors.origin}
                                        errorClassName="text-required"
                                        isClearable={false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInput
                                        label="Kuantitas *"
                                        name="quantity"
                                        placeholder="x (piece)"
                                        className="full-width add-margin-bottom"
                                        value={stock}
                                        onChange={onChangeInputNumber}
                                        validation={register}
                                        requiredText="*kuantitas produk loss harus diisi"
                                        errors={errors.quantity}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        multiline={true}
                                        rows={2}
                                        label="Keterangan Loss"
                                        name="notes"
                                        placeholder="Keterangan"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                    />
                                </Grid>
                                <Grid item xs={12} className="add-margin-bottom">
                                    <span>Produk dapat dijual?</span>
                                    <FormGroup className="cb-inline">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isSelling}
                                                    onClick={(e) =>
                                                        setIsSelling(e.target.checked)
                                                    }
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                                {!isSelling? <></> :
                                    <>
                                    <Grid item xs={12}>
                                        <TextInput
                                            label="Kode Produk Loss *"
                                            name="product_code"
                                            placeholder="Kode Produk"
                                            className="full-width add-margin-bottom"
                                            validation={register({ required: "*kode produk loss harus diisi" })}
                                            errors={errors.product_code}
                                            errorClassName="text-required"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PriceInput
                                            label="Harga Jual *"
                                            name="selling_price"
                                            className="full-width add-margin-bottom price-field"
                                            control={control}
                                            rules={{ required: "*harga jual baru harus diisi" }}
                                            errors={errors.selling_price}
                                            errorClassName="text-required"
                                        />
                                    </Grid>
                                    </>
                                }
                            </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>

                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}