import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import NumberFormat from "react-number-format";
import { Grid, Button, } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import DeleteIcon from '@material-ui/icons/Delete';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Table from '../../../components/Table_Com';
import Modal from '../../../components/Modal_Com';
import logo from "../../../assets/img/logo.png";
import { getCashierSessions } from "../../../logic/APIHandler";
import moment from "moment";

class Session extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            open: false,
            openJWT: false,
            openDelete: false,
            sessionId: '00000000',
            cashierName: '',
            notes: '',
            isLoading: false,
            isSidebarOpen: 'slide-out',
        }
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    columns = [
        {
            title: "Tanggal",
            field: "created_at",
            tooltip: "Tanggal buka sesi kasir",
            render: rowData =>
                <p>
                    {moment(rowData.created_at).format("DD MMMM YYYY HH:mm:ss")}
                </p>
        },
        { 
            title: "Nama", 
            field: "cashier_id.name",
            tooltip: "Nama kasir",
        },
        { 
            title: "Nominal Awal", 
            field: "initial_nominal",
            tooltip: "Nominal awal kasir",
            render: rowData =>
                <p>
                    <NumberFormat
                        value={rowData.initial_nominal}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix={"Rp "}
                        suffix=""
                    />
                </p>
        },
        { 
            title: "Nominal Akhir", 
            field: "final_nominal",
            tooltip: "Nominal akhir kasir",
            render: rowData => rowData.final_nominal?
                <p>
                    <NumberFormat
                        value={rowData.final_nominal}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix={"Rp "}
                        suffix=""
                    />
                </p> : <p>-</p>
        },
        {
            title: "Status",
            field: "status",
            tooltip: "Status sesi",
            render: rowData => rowData.status === "open"?
                <span className="status-validated">BUKA</span>
                :<span className="status-pending">TUTUP</span>
        },
    ]

    actions = [
        {
            icon: () => <FindInPageIcon />,
            tooltip: "Lihat Detail Sesi",
            onClick: (event, rowData) => {
                this.props.history.push({
                    pathname: "/sesi-kasir/" + rowData._id,
                  })
            }
        },
        rowData => ({
            hidden: rowData.status === "close",
            icon: () => <CheckCircleOutlineIcon />,
            tooltip: "Tutup Sesi Kasir",
            onClick: (event, rowData) => {
                this.handleOpen(rowData, "close");
            }
        }),
        {
            icon: () => <DeleteIcon />,
            tooltip: "Hapus Sesi",
            onClick: (event, rowData) => {
                this.handleOpen(rowData, "delete");
            }
        },
    ]

    // Show delete modal
    handleOpen(data, action) {
        this.setState({
            open: action === "close"? true : false,
            openDelete: action === "delete"? true : false,
            sessionId: data._id,
            cashierName: data.cashier_id.name,
            notes: data.notes,
        })
    }

    // CLose delete modal
    handleClose() {
        this.setState({
            open: false,
            openDelete: false,
        })
    }

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }

    async getData() {
        this.setState({ isLoading: true });
        let resp = await getCashierSessions();
        if ( resp[0] && resp[0].status === 200 ) {
            this.setState({
                data: resp[0].data,
                isLoading: false,
            })
        }
        else if ( resp[1] && resp[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({
                isLoading: false,
            })
        }
    }

    componentDidMount() {
        if ( localStorage.getItem("path") !== this.props.location.pathname ) {
            localStorage.removeItem("path");
            localStorage.removeItem("search");
            localStorage.removeItem("tablePage");
        }
        this.getData();
    }

    render() {
        return (
            <div className="content">
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""} level="3" />

                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="close-session"
                    id={this.state.sessionId}
                    modalTitle="Tutup sesi kasir?"
                    dataName={this.state.cashierName}
                    notes={this.state.notes}
                    submitText="Submit"
                    cancelText="Cancel"
                />
                <Modal
                    open={this.state.openDelete}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="erase-session"
                    id={this.state.sessionId}
                    color="secondary"
                    modalTitle="Hapus sesi?"
                    dataName={this.state.cashierName}
                    submitText="Delete"
                    cancelText="Cancel"
                />
                <Modal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                { this.state.isLoading ? ( <Spinner /> ) : <Grid container className="grid-container">
                    <Grid item xs={12} className="material-table">
                        <Table
                            tableTitle="Tabel Sesi Kasir"
                            tableColumns={this.columns}
                            data={this.state.data}
                            tableActions={this.actions}
                        />
                    </Grid>
                    <Grid item xs={12} className="table-footer">
                        <NavLink to="/sesi-kasir-form" style={{ textDecoration: "none" }}>
                            <Button variant="contained" className="add-btn-table">
                                Buat Sesi Kasir
                            </Button>
                        </NavLink>
                    </Grid>
                </Grid> }
            </div>
        );
    }
}

export default Session;