import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import { Grid, Button, } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import DeleteIcon from '@material-ui/icons/Delete';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Table from '../../../components/Table_Com';
import Modal from '../../../components/Modal_Com';
import logo from "../../../assets/img/logo.png";
import { getTaxes } from "../../../logic/APIHandler";

class Taxes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            open: false,
            openJWT: false,
            taxId: '00000000',
            taxName: '',
            isLoading: false,
            isSidebarOpen: 'slide-out',
        }
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    columns = [
        { 
            title: "Nama", 
            field: "name",
            tooltip: "Nama pajak",
        },
        {
            title: "Nilai (%)",
            field: "value",
            tooltip: "Nilai pajak dalam persen(%)",
        },
    ]

    actions = [
        {
            icon: () => <FindInPageIcon />,
            tooltip: "Lihat Detail Pajak",
            onClick: (event, rowData) => {
                this.props.history.push({
                    pathname: "/pajak/" + rowData._id,
                  })
            }
        },
        // {
        //     icon: () => <DeleteIcon />,
        //     tooltip: "Hapus Pajak",
        //     onClick: (event, rowData) => {
        //         this.handleOpen(rowData);
        //     }
        // },
    ]

    // Show delete modal
    handleOpen(data) {
        this.setState({
            open: true,
            taxId: data._id,
            taxName: data.name,
        })
    }

    // CLose delete modal
    handleClose() {
        this.setState({
            open: false,
        })
    }

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }

    async getData() {
        this.setState({ isLoading: true });
        let resp = await getTaxes();
        if ( resp[0] && resp[0].status === 200 ) {
            this.setState({
                data: resp[0].data,
                isLoading: false,
            })
        }
        else if ( resp[1] && resp[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({
                isLoading: false,
            })
        }
    }

    componentDidMount() {
        if ( localStorage.getItem("path") !== this.props.location.pathname ) {
            localStorage.removeItem("path");
            localStorage.removeItem("search");
            localStorage.removeItem("tablePage");
        }
        this.getData();
    }

    render() {
        return (
            <div className="content">
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""} level="2" />

                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="delete-tax"
                    id={this.state.taxId}
                    color="secondary"
                    modalTitle="Hapus pajak?"
                    dataName={this.state.taxName}
                    submitText="Delete"
                    cancelText="Cancel"
                />
                <Modal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                { this.state.isLoading ? ( <Spinner /> ) : <Grid container className="grid-container">
                    <Grid item xs={12} className="material-table">
                        <Table
                            tableTitle="Tabel Pajak"
                            tableColumns={this.columns}
                            data={this.state.data}
                            tableActions={this.actions}
                        />
                    </Grid>
                    {this.state.data && this.state.data.length <= 0?
                        <Grid item xs={12} className="table-footer">
                            <NavLink to="/pajak-form" style={{ textDecoration: "none" }}>
                                <Button variant="contained" className="add-btn-table">
                                        Tambah Pajak
                                </Button>
                            </NavLink>
                        </Grid> : <></>
                    }
                </Grid> }
            </div>
        );
    }
}

export default Taxes;