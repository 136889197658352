import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import { Autocomplete } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar, TextField } from "@material-ui/core";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import DeleteIcon from '@material-ui/icons/Delete';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Table from '../../../components/Table_Com';
import Modal from '../../../components/Modal_Com';
import logo from "../../../assets/img/logo.png";
import { getProductLosses } from "../../../logic/APIHandler";
import { formatToPrice } from "../../../logic/Handler";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Loses extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            exportData: [],
            open: false,
            openExport: false,
            openJWT: false,
            openErrorAlert: false,
            errorText: "",
            currStock: 0,
            productId: '00000000',
            productName: '',
            qty: 0,
            currBranch:
                localStorage.getItem("level") !== "1"? null
                : localStorage.getItem("currBranch")? JSON.parse(localStorage.getItem("currBranch"))
                : null,
            isLoading: false,
            isSidebarOpen: 'slide-out',
        }
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseError = this.handleCloseError.bind(this);
        this.handleFilterBranch = this.handleFilterBranch.bind(this);
    }

    column = [
        { 
            title: "Kode", 
            field: "product_code",
            tooltip: "Kode produk loss",
            cellStyle: {
                width: "150px",
                maxWidth: "150px"
            },
            render: rowData => rowData.product_code?
                <p>{rowData.product_code}</p> : <p>-</p>
        },
        {
            title: "Nama",
            field: "product_id.name",
            tooltip: "Nama produk loss",
        },
        { 
            title: "Kuantitas", 
            field: "quantity",
            tooltip: "Kuantitas produk loss",
        },
        { 
            title: "Asal Produk", 
            field: "origin",
            tooltip: "Tempat asal produk loss",
            render: rowData => rowData.origin === "warehouse"?
                <p>Gudang</p> : <p>Toko</p>
        },
    ]

    actionsMaster = [
        {
            icon: () => <FindInPageIcon />,
            tooltip: "Lihat Detail Produk",
            onClick: (event, rowData) => {
                localStorage.removeItem("tablePageLog");
                localStorage.removeItem("tablePageLogWR");
                this.props.history.push({
                    pathname: "/losses/" + rowData._id,
                })
            }
        },
    ]
    actionsOps = [
        {
            icon: () => <FindInPageIcon />,
            tooltip: "Lihat Detail Produk",
            onClick: (event, rowData) => {
                localStorage.removeItem("tablePageLog");
                localStorage.removeItem("tablePageLogWR");
                this.props.history.push({
                    pathname: "/losses/" + rowData._id,
                })
            }
        },
        {
            icon: () => <DeleteIcon />,
            tooltip: "Hapus Produk Loss",
            onClick: (event, rowData) => {
                this.handleOpen(rowData);
            }
        },
    ]

    // Show delete modal
    handleOpen(data) {
        this.setState({
            open: true,
            productId: data._id,
            productName: data.product_id.name,
        })
    }
    // CLose modal
    handleClose() {
        this.setState({
            open: false,
            openExport: false,
        })
    }
    // Close error toast
    handleCloseError() {
        this.setState({
            openErrorAlert: false,
        });
    };

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }

    async handleFilterBranch(branch) {
        this.setState({
            isLoading: true,
            currBranch: branch,
        });
        localStorage.removeItem("tablePage");
        localStorage.removeItem("saved-so");
        localStorage.removeItem("saved-so-store");
        localStorage.setItem("currBranch", JSON.stringify(branch));
        let resp = await getProductLosses(branch.value);
        if ( resp[0] && resp[0].status === 200 ) {
            let exportDB = [];
            const salesProducts = resp[0].data.filter(res =>
                res.isSelling === true
            );
            if ( salesProducts.length > 0 ) {
                salesProducts.forEach(product => {
                    // Set data for export database
                    exportDB.push({
                        "label": product.product_code + " - " + product.product_id.name,
                        "value": product._id,
                        "price": formatToPrice(product.selling_price),
                    });
                });
            }
            this.setState({
                data: resp[0].data,
                exportData: exportDB,
            })
        }
        else if ( resp[0] && resp[0].status === 204 ) {
            this.setState({
                data: [],
                exportData: [],
                errorText: "Tidak ada produk yang ditemukan!",
                openErrorAlert: true,
            })
        }
        else {
            this.setState({
                data: [],
                exportData: [],
                errorText: "Gagal mem-filter berdasarkan cabang.",
                openErrorAlert: true,
            })
        }
        this.setState({ isLoading: false });
    }

    async getData() {
        this.setState({ isLoading: true });
        let resp = [];
        if ( localStorage.getItem("level") === "1" ) {
            if ( localStorage.getItem("currBranch") ) {
                const branch = JSON.parse(localStorage.getItem("currBranch"));
                resp = await getProductLosses(branch.value);
            }
        }
        else {
            resp = await getProductLosses();
        }
        if ( resp[0] && resp[0].status === 200 ) {
            let products = resp[0].data;
            const salesProducts = resp[0].data.filter(res =>
                res.isSelling === true
            );
            let exportDB = [];
            if ( salesProducts.length > 0 ) {
                salesProducts.forEach(product => {
                    // Set data for export database
                    exportDB.push({
                        "label": product.product_code + " - " + product.product_id.name,
                        "value": product._id,
                        "price": formatToPrice(product.selling_price),
                    });
                });
            }
            this.setState({
                data: products,
                exportData: exportDB,
                isLoading: false,
            })
        }
        else if ( resp[0] && resp[0].status === 204) {
            this.setState({
                data: [],
                errorText: "Data produk tidak ditemukan!",
                openErrorAlert: true,
                isLoading: false,
            })
        }
        else if ( resp[1] && resp[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({
                errorText: "Gagal memuat data. Cek kembali koneksi internet Anda.",
                openErrorAlert: true,
                isLoading: false,
            })
        }
    }

    componentDidMount() {
        if ( localStorage.getItem("path") !== this.props.location.pathname ) {
            localStorage.removeItem("path");
            localStorage.removeItem("search");
            localStorage.removeItem("tablePage");
        }
        this.getData();
    }

    render() {
        return (
            <div className="content">
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""}
                    level={localStorage.getItem("level")} />

                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="erase-losses"
                    id={this.state.productId}
                    color="secondary"
                    modalTitle="Hapus produk loss?"
                    dataName={this.state.productName}
                    submitText="Delete"
                    cancelText="Cancel"
                />
                <Modal
                    open={this.state.openExport}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="export-database"
                    modalClassName="export-db"
                    data={this.state.exportData}
                    color="primary"
                    modalTitle="Print Label"
                    submitText="Download Data Label"
                    cancelText="Cancel"
                />
                <Modal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    {localStorage.getItem("level") === "1"?
                        <Grid item xs={12} className="add-margin-bottom">
                            <Autocomplete
                                className="full-width tf-label"
                                onChange={(_, value) => this.handleFilterBranch(value)}
                                options={localStorage.getItem("branch")?
                                    JSON.parse(localStorage.getItem("branch")) : []
                                }
                                getOptionLabel={(option) => option.label}
                                disableClearable={true}
                                value={this.state.currBranch}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Cabang"
                                        placeholder="Pilih"
                                        variant="outlined"
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />}
                            />
                        </Grid> : <></>
                    }

                    {localStorage.getItem("level") !== "3"?
                        <NavLink
                            to={{
                                pathname: "/losses/export-database",
                                state: {
                                    data: this.state.exportData,
                                },
                            }}
                            className="export-btn"
                            style={{ textDecoration: "none" }}
                        >
                            <Button
                                variant="contained"
                            >
                                <CloudDownloadIcon style={{ marginRight: "10px" }} />
                                Export Database
                            </Button>
                        </NavLink> : <></>
                    }

                    <Snackbar open={this.state.openErrorAlert} autoHideDuration={3000} onClose={this.handleCloseError}>
                        <Alert severity="error">
                            {this.state.errorText}
                        </Alert>
                    </Snackbar>

                    { this.state.isLoading ? ( <Spinner /> ) : <>
                        <Grid item xs={12} className="material-table add-margin-top">
                            <Table
                                tableTitle="Tabel Produk Loss"
                                tableColumns={this.column}
                                data={this.state.data}
                                tableActions={localStorage.getItem("level") === "1"?
                                    this.actionsMaster : this.actionsOps}
                            />
                        </Grid>
                        <Grid item xs={12} className="table-footer">
                            {localStorage.getItem("level") !== "1"?
                                <NavLink to="/losses-form" style={{ textDecoration: "none" }}>
                                    <Button variant="contained" className="add-btn-table">
                                        Tambah Produk Loss
                                    </Button>
                                </NavLink> : <></>
                            }
                        </Grid></>
                    }
                </Grid>
            </div>
        );
    }
}

export default Loses;