import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar, } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { TextInput, PercentageInput, DropdownCurrentInput,
    DropdownInput, PriceInput, NumberInput } from "../../../logic/FormInput";
import { getProducts, postDiscount } from "../../../logic/APIHandler";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddDiscount() {

    const numberRegex = /^[0-9]*$/;

    // Form State
    const [x, setX] = useState("");
    const [y, setY] = useState("");
    const [currType, setCurrType] = useState(null);
    const [percentage, setPercentage] = useState("");
    const [products, setProducts] = useState([]);
    
    // Page State
    const history = useHistory();
    const { register, handleSubmit, control, errors } = useForm();
    const [openJWT, setOpenJWT] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    // Get list of products
    const getData = async () => {
        setIsLoading(true);
        let resp = await getProducts();
        if ( resp[0] && resp[0].status === 200 ) {
            let temp = [];
            resp[0].data.forEach(res => {
                temp.push({
                    "label": res.product_code + " - " + res.name,
                    "value": res._id,
                })
            });
            setProducts(temp);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp[1].message? resp[1].message : resp[1].error[0].msg);
        }
        setIsLoading(false);
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Handle DropdownCurrentInput onChange event
    const handleDropdownChange = (data) => {
        setCurrType(data);
        if ( data.value === "buyxgety" ) {
            getData();
        }
    }

    // Handle onChange input number
    const onChangeInputNumber = (event) => {
        if ( numberRegex.test(event.target.value) ) {
            if ( event.target.name === "valueX" ) {
                setX(event.target.value);
            }
            else if ( event.target.name === "valueY" ) {
                setY(event.target.value);
            }
        }
    }

    // Handle onChange percentage value
    const onChangeValue = (event) => {
        let value = event.target.value;
        value = value.charAt(value.length-1);
        if ( numberRegex.test(value) ) {
            if ( event.target.value.length > percentage.length ) {
                const intVal = parseInt(event.target.value.replace("%",""));
                if ( intVal >= 0 && intVal <= 100 ) {
                    if ( percentage.includes("%") ) setPercentage(percentage.replace("%", value) + "%");
                    else setPercentage(value + "%");
                }
            }
            else {
                if ( event.target.value.length <= 1 ) setPercentage("");
                else setPercentage(percentage.slice(0, percentage.length-2) + "%");
            }
        }
    }

    // Send new data to server
    const onSubmit = async (data) => {
        setIsLoading(true);
        let resp = await postDiscount(data);
        if (resp[0] && resp[0].status === 200) {
            setIsLoading(false);
            setOpenSuccessAlert(true);
            await sleep(1000);
            history.replace("/diskon");
        }
        else if ( resp[1] && resp[1].status === 400 ) {
            setErrorText(resp[1].data[0].msg);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            setErrorText(resp[1].message);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} level="2" />
                
                <Modal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Menambah Diskon
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2.2rem" }}>
                                Tambah Diskon Baru
                            </h1>

                            <Grid container>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Nama *"
                                        name="name"
                                        placeholder="Nama Diskon"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*nama diskon harus diisi" })}
                                        errors={errors.name}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DropdownCurrentInput
                                        label="Tipe Diskon"
                                        name="type_promo"
                                        className="full-width add-margin-bottom tf-label"
                                        keepData={handleDropdownChange}
                                        disableClearable={true}
                                        control={control}
                                        options={[
                                            { label: "Persen", value: "persen" },
                                            { label: "Flat", value: "rupiah" },
                                            { label: "Buy X Get Y", value: "buyxgety" },
                                        ]}
                                        getOptionLabel={(option) => option.label}
                                        value={currType}
                                        required={true}
                                        rules={{ required: "*tipe diskon harus diisi" }}
                                        errors={errors.type_promo}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {currType && currType.value === "persen"?
                                        <PercentageInput
                                            label="Nilai *"
                                            name="percentage"
                                            className="full-width add-margin-bottom"
                                            value={percentage}
                                            onChange={onChangeValue}
                                            validation={register}
                                            requiredText="*nilai diskon harus diisi"
                                            errors={errors.percentage}
                                            errorClassName="text-required"
                                        />
                                        : currType && currType.value === "rupiah"?
                                        <PriceInput
                                            label="Nilai *"
                                            name="flat"
                                            className="full-width add-margin-bottom price-field"
                                            control={control}
                                            rules={{ required: "*nilai diskon harus diisi" }}
                                            errors={errors.flat}
                                            errorClassName="text-required"
                                        />
                                        : currType && currType.value === "buyxgety"?
                                        <Grid container>
                                            <Grid item xs={8} style={{ position: "relative" }}>
                                                <label className="selection-label">Produk X *</label>
                                                <DropdownInput
                                                    name="productX"
                                                    className="selection-field add-margin-bottom"
                                                    control={control}
                                                    options={products}
                                                    rules={{ required: "*produk x harus diisi" }}
                                                    errors={errors.productX}
                                                    errorClassName="text-required"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <NumberInput
                                                    label="Total X *"
                                                    name="valueX"
                                                    placeholder="0"
                                                    className="quarter-field quarter-right add-margin-bottom"
                                                    value={x}
                                                    onChange={onChangeInputNumber}
                                                    validation={register}
                                                    requiredText="*total produk x harus diisi"
                                                    errors={errors.valueX}
                                                    errorClassName="text-required quarter"
                                                />
                                            </Grid>
                                            <Grid item xs={8} style={{ position: "relative" }}>
                                                <label className="selection-label">Produk Y *</label>
                                                <DropdownInput
                                                    name="productY"
                                                    className="selection-field add-margin-bottom"
                                                    control={control}
                                                    options={products}
                                                    rules={{ required: "*produk y harus diisi" }}
                                                    errors={errors.productY}
                                                    errorClassName="text-required"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <NumberInput
                                                    label="Total Y *"
                                                    name="valueY"
                                                    placeholder="0"
                                                    className="quarter-field quarter-right add-margin-bottom"
                                                    value={y}
                                                    onChange={onChangeInputNumber}
                                                    validation={register}
                                                    requiredText="*total produk y harus diisi"
                                                    errors={errors.valueY}
                                                    errorClassName="text-required quarter"
                                                />
                                            </Grid>
                                        </Grid>
                                        : <></>
                                    }
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>

                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}