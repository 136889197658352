import React, { Component } from "react";
import { Grid, TextField, Button, Card, CardHeader, CardContent } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ReactExport from "react-data-export";
import NumberFormat from "react-number-format";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Sidebar from "../../components/Sidebar_Com";
import Spinner from "../../components/Loading_Com";
import Modal from "../../components/Modal_Com";
import Filter from "../../components/Filter_Com";
import logo from "../../assets/img/logo.png";
import { getDashboardData } from "../../logic/APIHandler";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Dashboard extends Component {

    constructor(props) {
        super(props);
        // Set Indonesia as moment's location
        let idLocale = require('moment/locale/id'); 
        moment.locale('id', idLocale);
        this.state = {
            excelData: [],
            filenameRange: "",
            defaultData: [],
            // Cards State
            grossIncome: 0,
            netIncome: 0,
            totalPurchase: 0,
            totalExpense: 0,
            totalDiscount: 0,
            totalTaxes: 0,
            countSales: 0,
            countSalesWarehouse: 0,
            countPurchase: 0,
            countRefund: 0,
            // Page State
            open: false,
            openJWT: false,
            isLoading: false,
            isSidebarOpen: 'slide-out',
            filterState: localStorage.getItem("start")? {
                "start": moment(localStorage.getItem("start")).format("YYYY-MM-DD"),
                "end": moment(localStorage.getItem("end")).format("YYYY-MM-DD"),
            } : null,
            currBranch:
                localStorage.getItem("level") !== "1"? null
                : localStorage.getItem("currBranch")? JSON.parse(localStorage.getItem("currBranch"))
                : null,
        }
        this.excelRef = React.createRef();
        this.filterRef = React.createRef();
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.filterData = this.filterData.bind(this);
        this.downloadExcel = this.downloadExcel.bind(this);
        this.handleFilterBranch = this.handleFilterBranch.bind(this);
    }

    // Make the website stay still for __ ms
    sleep(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }

    // Filter data table
    async filterData() {
        this.setState({ isLoading: true });
        localStorage.removeItem("tablePage");
        const ref = this.filterRef.current.state;
        let resp = []; let start = ""; let end = "";
        let canFilter = true;
        // If use filter
        if ( ref.startDate || ref.endDate ) {
            if ( ref.startDate && ref.endDate ) { // Both is filled
                if ( moment(ref.endDate)-moment(ref.startDate) >= 0 ) {
                    start = moment(ref.startDate).format("YYYY-MM-DD");
                    end = moment(ref.endDate).format("YYYY-MM-DD");
                }
                else { // If start > end & vice versa
                    canFilter = false;
                    this.setState({
                        errorText: "Tanggal mulai harus lebih kecil dari tanggal akhir & sebaliknya!",
                        openErrorAlert: true,
                        isLoading: false,
                    })
                }
            }
            else if ( ref.startDate && !ref.endDate ) { // Only start filled
                start = moment(ref.startDate).format("YYYY-MM-DD");
                end = moment(ref.startDate).format("YYYY-MM-DD");
            }
            else if ( !ref.startDate && ref.endDate ) { // Only end filled
                start = moment(ref.endDate).format("YYYY-MM-DD");
                end = moment(ref.endDate).format("YYYY-MM-DD");
            }

            // If no error on input field filter
            if ( canFilter ) {
                resp = await getDashboardData(start, end);
                this.setState({
                    filterState: { "start": start, "end": end },
                    filenameRange: (start === end)?
                        " (" + start + ")" : " (" + start + " - " + end + ")",
                })
            }
        }
        // If filter is being cleared
        else {
            resp = await getDashboardData(
                moment().startOf('month').format('YYYY-MM-DD'),
                moment().endOf('month').format('YYYY-MM-DD')
            );
            this.setState({ filterState: null, filenameRange: "" });
        }

        // If no error on input field filter
        if ( canFilter ) {
            if ( resp[0] && resp[0].status === 200 ) {
                let result = resp[0].data.filter(res =>
                    res.branch_store_id === JSON.parse(localStorage.getItem("currBranch")).value
                );
                result = result[0]? result[0].details : [];
                this.setState({
                    defaultData: resp[0].data,
                    grossIncome: result.gross_income,
                    netIncome: result.net_income,
                    totalPurchase: result.total_transaction_buy,
                    totalExpense: result.total_extra_expenses,
                    totalDiscount: result.total_discount,
                    totalTaxes: result.total_taxes,
                    countSales: result.total_transaction[0].total,
                    countSalesWarehouse: result.total_transaction[2].total,
                    countPurchase: result.total_transaction[1].total,
                    countRefund: result.total_transaction[6].total,
                    isLoading: false,
                })
            }
            else {
                this.setState({
                    grossIncome: 0, netIncome: 0,
                    totalPurchase: 0, totalExpense: 0,
                    totalDiscount: 0, totalTaxes: 0,
                    countSales: 0, countSalesWarehouse: 0,
                    countPurchase: 0, countRefund: 0,
                    isLoading: false,
                });
            }
        }
    }

    // Filter data by branch
    async handleFilterBranch(branch) {
        this.setState({
            isLoading: true,
            currBranch: branch,
        });
        localStorage.removeItem("tablePage");
        localStorage.removeItem("saved-so");
        localStorage.removeItem("saved-so-store");
        localStorage.setItem("currBranch", JSON.stringify(branch));
        let result = this.state.defaultData.filter(res =>
            res.branch_store_id === branch.value
        );
        result = result[0]? result[0].details : [];
        await this.sleep(500);
        this.setState({
            grossIncome: result.gross_income,
            netIncome: result.net_income,
            totalPurchase: result.total_transaction_buy,
            totalExpense: result.total_extra_expenses,
            totalDiscount: result.total_discount,
            totalTaxes: result.total_taxes,
            countSales: result.total_transaction[0].total,
            countSalesWarehouse: result.total_transaction[2].total,
            countPurchase: result.total_transaction[1].total,
            countRefund: result.total_transaction[6].total,
            isLoading: false,
        });
    }

    async getData() {
        this.setState({ isLoading: true });
        let start = "", end = "";
        if ( localStorage.getItem("start") || localStorage.getItem("end") ) {
            start = moment(localStorage.getItem("start")).format("YYYY-MM-DD");
            end = moment(localStorage.getItem("end")).format("YYYY-MM-DD");
            if ( start === end ) {
                this.setState({ filenameRange: " (" + start + ")" });
            }
            else {
                this.setState({ filenameRange: " (" + start + " - " + end + ")" });
            }
        }
        else {
            start = moment().startOf('month').format('YYYY-MM-DD');
            end = moment().endOf('month').format('YYYY-MM-DD');
        }
        let resp = await getDashboardData(start, end);
        if ( resp[0] && resp[0].status === 200 ) {
            let result = resp[0].data.filter(res =>
                res.branch_store_id === JSON.parse(localStorage.getItem("currBranch")).value
            );
            result = result[0]? result[0].details : [];
            this.setState({
                defaultData: resp[0].data,
                grossIncome: result.gross_income? result.gross_income : "Rp 0",
                netIncome: result.net_income? result.net_income : "Rp 0",
                // totalPurchase: result.total_transaction_buy,
                totalExpense: result.total_extra_expenses? result.total_extra_expenses : "Rp 0",
                totalDiscount: result.total_discount? result.total_discount : "Rp 0",
                totalTaxes: result.total_taxes? result.total_taxes : "Rp 0",
                countSales: result.total_transaction? result.total_transaction[0].total : 0,
                countSalesWarehouse: result.total_transaction? result.total_transaction[2].total : 0,
                countPurchase: result.total_transaction? result.total_transaction[1].total : 0,
                countRefund: result.total_transaction? result.total_transaction[6].total : 0,
                isLoading: false,
            })
        }
        else if ( resp[1] && resp[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({
                isLoading: false,
            })
        }
    }

    // Create formatted excel data & download it
    async downloadExcel() {
        this.setState({ isLoading: true });
        let resp = [];
        let start = "", end = "";
        if ( localStorage.getItem("start") || localStorage.getItem("end") ) {
            start = moment(localStorage.getItem("start")).format("YYYY-MM-DD");
            end = moment(localStorage.getItem("end")).format("YYYY-MM-DD");
        }
        else {
            start = moment().startOf('month').format('YYYY-MM-DD');
            end = moment().endOf('month').format('YYYY-MM-DD');
            this.setState({ filenameRange: " (" + moment().format("MMMM YYYY") + ")" });
        }
        resp = await getDashboardData(start, end);
 
        if ( resp[0] && resp[0].status === 200 ) {
            let result = resp[0].data.filter(res =>
                res.branch_store_id === JSON.parse(localStorage.getItem("currBranch")).value
            );
            result = result[0]? result[0].details : [];
            // Create columns & header style
            let res = [
                {
                    columns: [
                        {title: "DATA PENDAPATAN & PENGELUARAN", width: {wch: 40}, style: {
                            font: { bold: true },
                        }},
                        {title: "", width: {wch: 15}, style: {
                            font: { bold: true },
                        }},
                    ],
                    data: [],
                }
            ]
            
            // Add row items to table
            let printGross = result.gross_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            printGross = "Rp " + printGross + ",-";
            res[0].data.push([
                { value: "Total Penjualan" },
                { value: printGross, style: { alignment: { horizontal: "right" }} },
            ])

            let printDiscounts = result.total_discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            printDiscounts = "Rp " + printDiscounts + ",-";
            res[0].data.push([
                { value: "Total Diskon" },
                { value: printDiscounts, style: { alignment: { horizontal: "right" }} },
            ])

            let printTaxes = result.total_taxes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            printTaxes = "Rp " + printTaxes + ",-";
            res[0].data.push([
                { value: "Total Pajak" },
                { value: printTaxes, style: { alignment: { horizontal: "right" }} },
            ])

            let printExpenses = result.total_extra_expenses.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            printExpenses = "Rp " + printExpenses + ",-";
            res[0].data.push([
                { value: "Total Pengeluaran Tambahan" },
                { value: printExpenses, style: { alignment: { horizontal: "right" }} },
            ])
            
            res[0].data.push([{ value: "" }, { value: "" }])
            
            res[0].data.push([
                { value: "DATA TRANSAKSI", style: { font: { bold: true } } },
                { value: "" },
            ])
            
            res[0].data.push([
                { value: "Total Transaksi Penjualan Kasir" },
                { value: result.total_transaction[0].total, style: { alignment: { horizontal: "right" }} },
            ])
            res[0].data.push([
                { value: "Total Transaksi Penjualan Gudang" },
                { value: result.total_transaction[2].total, style: { alignment: { horizontal: "right" }} },
            ])

            // Set table data
            this.setState({ excelData: res, isLoading: false }, () => {
                if ( this.excelRef.current !== null ) {
                    this.excelRef.current.click();
                }
            })

        }
        else {
            this.setState({
                errorText: "Gagal memuat data. Cek kembali koneksi internet Anda.",
                openErrorAlert: true,
                isLoading: false,
            })
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className="content">
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""} level="1" />

                <Modal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container" spacing={2} style={{ position: "relative" }}>
                    <Grid item xs={12} className="with-download add-margin-bottom">
                        <Autocomplete
                            className="full-width tf-label add-margin-bottom"
                            onChange={(_, value) => this.handleFilterBranch(value)}
                            options={localStorage.getItem("branch")?
                                JSON.parse(localStorage.getItem("branch")) : []
                            }
                            getOptionLabel={(option) => option.label}
                            disableClearable={true}
                            value={this.state.currBranch}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Cabang"
                                    placeholder="Pilih"
                                    variant="outlined"
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />}
                        />
                        <Filter ref={this.filterRef} handleFilter={this.filterData} type="filter-by-date" />
                    </Grid>
                    
                    <Button
                        variant="contained"
                        className="download-btn with-branch"
                        onClick={this.downloadExcel}
                    >
                        <CloudDownloadIcon style={{ marginRight: "10px" }} />
                        Download Excel
                    </Button>

                    { this.state.isLoading ? ( <Spinner /> ) : <>
                        <Grid item xs={12}>
                            <h1 style={{ marginTop: "0" }}>
                                {`Data Pendapatan & Pengeluaran ${this.state.filterState?
                                    "" : "(" + moment().format("MMMM YYYY") + ")"}`}
                            </h1>
                        </Grid>
                        <Grid item xs={12} md={6} className="income-state-card">
                            <Grid container>
                                <Grid item xs={1} className="colored-card"></Grid>
                                <Grid item xs={11} className="income-card-content">
                                    <h3>TOTAL PENJUALAN</h3>
                                    <h2>
                                        <NumberFormat
                                            value={this.state.grossIncome}
                                            displayType={"text"}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix={"Rp "}
                                            suffix=",00"
                                        />
                                    </h2>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} md={6} className="income-state-card">
                            <Grid container>
                                <Grid item xs={1} className="colored-card"></Grid>
                                <Grid item xs={11} className="income-card-content">
                                    <h3>TOTAL PENDAPATAN KESELURUHAN</h3>
                                    <h2>
                                        <NumberFormat
                                            value={this.state.netIncome}
                                            displayType={"text"}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix={"Rp "}
                                            suffix=",00"
                                        />
                                    </h2>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        {/* <Grid item xs={12} md={6} className="income-state-card">
                            <Grid container>
                                <Grid item xs={1} className="colored-card"></Grid>
                                <Grid item xs={11} className="income-card-content">
                                    <h3>TOTAL PEMBELIAN BARANG</h3>
                                    <h2>
                                        <NumberFormat
                                            value={this.state.totalPurchase}
                                            displayType={"text"}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix={"Rp "}
                                            suffix=",00"
                                        />
                                    </h2>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        <Grid item xs={12} md={6} className="income-state-card">
                            <Grid container>
                                <Grid item xs={1} className="colored-card"></Grid>
                                <Grid item xs={11} className="income-card-content">
                                    <h3>TOTAL DISKON</h3>
                                    <h2>
                                        <NumberFormat
                                            value={this.state.totalDiscount}
                                            displayType={"text"}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix={"Rp "}
                                            suffix=",00"
                                        />
                                    </h2>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} className="income-state-card">
                            <Grid container>
                                <Grid item xs={1} className="colored-card"></Grid>
                                <Grid item xs={11} className="income-card-content">
                                    <h3>TOTAL PENGELUARAN TAMBAHAN</h3>
                                    <h2>
                                        <NumberFormat
                                            value={this.state.totalExpense}
                                            displayType={"text"}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix={"Rp "}
                                            suffix=",00"
                                        />
                                    </h2>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} className="income-state-card">
                            <Grid container>
                                <Grid item xs={1} className="colored-card"></Grid>
                                <Grid item xs={11} className="income-card-content">
                                    <h3>TOTAL PAJAK</h3>
                                    <h2>
                                        <NumberFormat
                                            value={this.state.totalTaxes}
                                            displayType={"text"}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix={"Rp "}
                                            suffix=",00"
                                        />
                                    </h2>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <h1>
                                {`Data Transaksi ${this.state.filterState? "" : "(" + moment().format("MMMM YYYY") + ")"}`}
                            </h1>
                            {/* Card Section */}
                            <Grid container className="add-margin-top add-margin-bottom" spacing={2}>
                                <Grid item xs={12} sm={6} lg={6}>
                                    <Card className="card-layout">
                                        <CardHeader
                                            title="Total Transaksi Penjualan Kasir"
                                            titleTypographyProps={{ variant:'h6' }}
                                            className="card-header"
                                        />
                                        <CardContent className="card-content">
                                            <h1>{this.state.countSales}</h1>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={6}>
                                    <Card className="card-layout">
                                        <CardHeader
                                            title="Total Transaksi Penjualan Gudang"
                                            titleTypographyProps={{ variant:'h6' }}
                                            className="card-header"
                                        />
                                        <CardContent className="card-content">
                                            <h1>{this.state.countSalesWarehouse}</h1>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                {/* <Grid item xs={12} sm={6} lg={3}>
                                    <Card className="card-layout">
                                        <CardHeader
                                            title="Total Transaksi Pembelian Barang"
                                            titleTypographyProps={{ variant:'h6' }}
                                            className="card-header"
                                        />
                                        <CardContent className="card-content">
                                            <h1>{this.state.countPurchase}</h1>
                                        </CardContent>
                                    </Card>
                                </Grid> */}
                                {/* <Grid item xs={12} sm={6} lg={3}>
                                    <Card className="card-layout">
                                        <CardHeader
                                            title="Total Transaksi Refund"
                                            titleTypographyProps={{ variant:'h6' }}
                                            className="card-header"
                                        />
                                        <CardContent className="card-content">
                                            <h1>{this.state.countRefund}</h1>
                                        </CardContent>
                                    </Card>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ pointerEvents: "none" }}>
                            <ExcelFile
                                filename={`Laporan Keseluruhan${this.state.filenameRange?
                                    this.state.filenameRange : ""
                                }`}
                                element={
                                    <Button innerRef={this.excelRef}></Button>
                                }
                            >
                                <ExcelSheet dataSet={this.state.excelData} name="Laporan"/>
                            </ExcelFile>
                        </Grid>
                    </> }
                </Grid>
            </div>
        );
    }
}

export default Dashboard;