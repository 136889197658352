import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Grid, Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import NumberFormat from "react-number-format";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from '@material-ui/icons/Print';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { DateInput, PhoneInput, PriceInput, TextInput } from "../../../logic/FormInput";
import { formatDeliveryOrderAddress, downloadDeliveryOrderPDF  } from "../../../logic/Handler";
import { getTransaction, getBranch } from "../../../logic/APIHandler";
import { categs } from "../../../assets/data/Categories";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";
import moment from "moment";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailTransaction(props) {
    
    // Form State
    const [id, setId] = useState("");
    const [productName, setProductName] = useState("");
    const [categ, setCateg] = useState("");
    const [items, setItems] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [discount, setDiscount] = useState("");
    const [payment, setPayment] = useState("");
    const [subtotal, setSubtotal] = useState("-");
    const [orderFee, setOrderFee] = useState("-");
    const [defaultDate, setDefaultDate] = useState(moment());
    const [branchData, setBranchData] = useState("");

    // Snackbar State
    const [alertText, setAlertText] = useState("");
    const [openErrorAlert, setOpenErrorAlert] = useState(false);

    // Page State
    const { register, control, setValue } = useForm();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isPurchase, setIsPurchase] = useState(false);
    const [isSellWarehouse, setIsSellWarehouse] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openDecline, setOpenDecline] = useState(false);
    const [openJWT, setOpenJWT] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
        const idTransaction = props.match.params.id;
        setId(idTransaction);
        if ( idTransaction ) {
            getData(idTransaction);
            if ( localStorage.getItem("level") === "2"
                || localStorage.getItem("level") === "3"
            ) {
                getBranchData();
            }
        }
        else {
            console.log("ID not found");
        }     
    }, [props.match.params.id]);

    // Get initial data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = await getTransaction(id);
        if ( resp[0] && resp[0].status === 200 ) {
            const result = resp[0].data;
            setData(result);
            setProductName(result.items[0].product_id.name);
            setIsPurchase(result.category === "buy"? true : false);
            setIsSellWarehouse(result.category === "sell-warehouse"? true : false);
            if ( result.approval_manager_finance !== "preview" ) {
                setIsApproved(true);
            }
            if ( result.category === "buy" ) {
                setValue("no_invoice", result.receipt);
            }
            else {
                setValue("no_invoice", result.no_invoice);
            }
            setValue("category", categs[result.category]);
            setCateg(result.category);
            setDefaultDate(result.created_at.substring(0,19));
            setValue("cashier", result.user_id.name);
            setValue("notes", result.category === "sell"?
                result.type_payment.notes : result.notes);
            setItems(result.items);
            setTaxes(result.taxes);
            setDiscount(result.total_discount);
            setPayment(result.type_payment && result.type_payment.payment_id?
                result.type_payment.payment_id.name : "-");
            if ( result.approval_manager_finance !== "preview" || result.category.includes("sell") ) {
                setSubtotal(result.total_price);
            }

            if ( result.category === "sell-warehouse" ) {
                const deliveryOrder = result.details_transaction_warehouse;
                setValue("buyer_name", deliveryOrder.buyer_name);
                setValue("buyer_phone", deliveryOrder.buyer_phone);
                setValue("delivery_destination", deliveryOrder.delivery_destination);
                setValue("postal_fee", deliveryOrder.postal_fee);
                setValue("driver_name", deliveryOrder.driver_name);
                setValue("driver_phone", deliveryOrder.driver_phone);
                setOrderFee(deliveryOrder.postal_fee);
            }
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp[1].message);
        }
        setIsLoading(false);
    }
    const getBranchData = async () => {
        setIsLoading(true);
        const branch = JSON.parse(localStorage.getItem("branch"));
        let resp = await getBranch(branch._id);
        if ( resp[0] && resp[0].status === 200 ) {
            setBranchData(resp[0].data);
        }
        else {
            setAlertText(resp[1]? resp[1].message :
                "Gagal mendapatkan alamat cabang. Cek kembali koneksi Anda.");
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    }

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Handle close
    const handleClose = () => {
        setOpenModal(false);
        setOpenDecline(false);
    }
    const handleCloseAlert = () => {
        setOpenErrorAlert(false);
    }

    // Print devliery order in PDF
    const handlePrintPDF = async (type) => {
        data["address"] = formatDeliveryOrderAddress(branchData);
        if ( data.taxes && data.taxes[0] ) {
            const isAdded = data.items.filter(res => res.product_id.name.includes("%"));
            if ( isAdded.length <= 0 ) {
                data.items.push({
                    product_id: {
                        name: data.taxes[0].name + " (" + data.taxes[0].value + "%)"
                    },
                    quantity: "",
                    selling_price: "",
                    total_price: data.taxes[0].total,
                });
            }
        }
        if ( data.items.length <= 6 ) { // Print 1x
            data["page"] = "";
            downloadDeliveryOrderPDF(data, 0, data.items.length-1, true, type);
        }
        else { // Print >1x
            const totalPage = Math.ceil(data.items.length/6);
            for ( let i=0; i < totalPage; i++ ) {
                data["page"] = "(" + (i + 1) + "-" + totalPage + ")";
                downloadDeliveryOrderPDF(
                    data,
                    (i * 6),
                    ((i + 1) * 6) - 1,
                    i === (totalPage - 1)? true : false,
                    type
                );
            }
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""}
                level={localStorage.getItem("level")} />

            <Modal
                open={openModal}
                onClose={handleClose}
                onClickCancel={handleClose}
                modalType="approve-trx"
                id={id}
                modalTitle="Lengkapi Data Transaksi"
                submitText="Submit"
                cancelText="Cancel"
            />
            <Modal
                open={openDecline}
                onClose={handleClose}
                onClickCancel={handleClose}
                modalType="decline-trx"
                id={id}
                color="secondary"
                modalTitle="Tolak transaksi pembelian?"
                dataName={productName}
                submitText="Tolak"
                cancelText="Cancel"
            />
            <Modal
                open={openJWT}
                modalType="handle-jwt"
                modalTitle="Token Anda Sudah Expire"
            />

            <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                <Alert severity="error">
                    {alertText}
                </Alert>
            </Snackbar>
            
            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <img src={logo} alt="logo" className="header-logo" />
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Grid item xs={12}>
                    <form className="add-form">
                        <Grid container>
                            <Grid item xs={12} md={(isPurchase && !isApproved) || isSellWarehouse? 6 : 12}>
                                <h1 style={{ marginBottom: "2.2rem" }}>
                                    Detail Transaksi
                                </h1>
                            </Grid>
                            {isSellWarehouse && (localStorage.getItem("level") === "2"
                                || localStorage.getItem("level") === "3")?
                                <Grid item xs={12} md={6}>
                                    <Button
                                        variant="contained"
                                        className="edit-btn"
                                        onClick={() => handlePrintPDF(false)}
                                    >
                                        <CloudDownloadIcon style={{ marginRight: "10px" }}/>
                                        Download
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className="edit-btn print-btn"
                                        onClick={() => handlePrintPDF("preview")}
                                    >
                                        <PrintIcon style={{ marginRight: "10px" }}/>
                                        Print
                                    </Button>
                                </Grid> : <></>
                            }
                            {isPurchase && !isApproved?
                                <Grid item xs={12} md={6}>
                                    <Button
                                        variant="outlined"
                                        className="edit-btn left-margin"
                                        onClick={() => setOpenDecline(true)}
                                    >Tolak</Button>
                                    <Button
                                        variant="contained"
                                        className="edit-btn"
                                        onClick={() => setOpenModal(true)}
                                    >Approve</Button>
                                </Grid> : <></>
                            }
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label={isPurchase? "No. Nota Bon" : "No. Invoice"}
                                    name="no_invoice"
                                    placeholder="-"
                                    className="form-left-field add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label="Kategori"
                                    name="category"
                                    placeholder="-"
                                    className="form-right-field add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DateInput
                                    label="Tanggal Penjualan"
                                    name="date"
                                    value={defaultDate}
                                    className="form-left-field add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label={categ === "sell"? "Kasir" : "User"}
                                    name="cashier"
                                    placeholder="-"
                                    className="form-right-field add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    multiline={true}
                                    rows={2}
                                    label="Keterangan"
                                    name="notes"
                                    placeholder="-"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                        <Grid item xs={12} className="add-margin-top transaction-table"
                            style={{ overflowX: 'auto' }}>
                            <table className="manual-table transaction">
                                <thead>
                                    {categ === "buy"?
                                        <tr>
                                            <th style={{ width: "30px" }}>No</th>
                                            <th>Kode</th>
                                            <th>Nama Produk</th>
                                            <th>Supplier</th>
                                            <th>Kuantitas</th>
                                            <th>Total Harga Beli</th>
                                        </tr>
                                        : <tr>
                                            <th style={{ width: "30px" }}>No</th>
                                            <th>Kode</th>
                                            <th>Nama Produk</th>
                                            <th>Harga</th>
                                            <th>Kuantitas</th>
                                            <th>Subtotal</th>
                                        </tr>
                                    }
                                </thead>
                                <tbody>
                                    {items.map((product, idx) => ((idx % 2 !== 0)?
                                        <tr className="odd-cell" key={`trx-item-${idx}`}>
                                            <td className="center-cell">{idx+1}</td>
                                            <td>{product.product_id.product_code}</td>
                                            <td>{product.product_id.name}</td>
                                            {categ === "buy"?
                                                <td>{product.product_id.supplier_id.name}</td> :
                                                <td>
                                                    <NumberFormat
                                                        value={product.selling_price}
                                                        displayType={"text"}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        prefix={"Rp "}
                                                    />
                                                </td>
                                            }
                                            <td className="center-cell">{product.quantity}</td>
                                            <td className="right-cell">
                                                <NumberFormat
                                                    value={product.total_price}
                                                    displayType={"text"}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    prefix={"Rp "}
                                                />
                                            </td>
                                        </tr>
                                        : <tr className="even-cell" key={`trx-item-${idx}`}>
                                            <td className="center-cell">{idx+1}</td>
                                            <td>{product.product_id.product_code}</td>
                                            <td>{product.product_id.name}</td>
                                            {categ === "buy"?
                                                <td>{product.product_id.supplier_id.name}</td> :
                                                <td>
                                                    <NumberFormat
                                                        value={product.selling_price}
                                                        displayType={"text"}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        prefix={"Rp "}
                                                    />
                                                </td>
                                            }
                                            <td className="center-cell">{product.quantity}</td>
                                            <td className="right-cell">
                                                <NumberFormat
                                                    value={categ === "buy"?
                                                        subtotal : product.total_price}
                                                    displayType={"text"}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    prefix={"Rp "}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    {categ === "buy"? <></> :
                                        <tr className="no-border">
                                            <td colSpan="5" className="footer">Total Diskon :</td>
                                            <td className="footer">
                                                <NumberFormat
                                                    value={discount}
                                                    displayType={"text"}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    prefix={"- Rp "}
                                                />
                                            </td>
                                        </tr>
                                    }
                                    {taxes.length > 0 && taxes.map((tax, idx) =>
                                        <tr key={`tax-${idx}`} className="no-border">
                                            <td colSpan="5" className="footer">{`${tax.name} (${tax.value}%) :`}</td>
                                            <td className="footer">
                                                <NumberFormat
                                                    value={tax.total}
                                                    displayType={"text"}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    prefix={"Rp "}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    {categ !== "sell-warehouse"? <></> :
                                        <tr className="no-border">
                                            <td colSpan="5" className="footer">Total Ongkir :</td>
                                            <td className="footer">
                                                <NumberFormat
                                                    value={orderFee}
                                                    displayType={"text"}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    prefix={"Rp "}
                                                />
                                            </td>
                                        </tr>
                                    }
                                    <tr className="no-border">
                                        <td colSpan="5" className="footer" style={{ fontWeight: "bold" }}>
                                            {categ === "buy"? "Total Biaya Pembelian :" : "Total :"}
                                        </td>
                                        <td className="footer" style={{ fontWeight: "bold" }}>
                                            <NumberFormat
                                                value={subtotal}
                                                displayType={"text"}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                prefix={"Rp "}
                                            />
                                        </td>
                                    </tr>
                                    {categ === "buy" || categ === "sell-warehouse"? <></> :
                                        <tr className="no-border">
                                            <td colSpan="5" style={{ paddingBottom: "10px", paddingLeft: "10px" }}>
                                                {`Tipe Pembayaran : ${payment}`}
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </Grid>
                        </Grid>
                    </form>
                </Grid>
                { categ === "sell-warehouse"?
                    <Grid item xs={12} >
                        <hr className="add-margin-top add-margin-bottom" />
                    </Grid> : <></>
                }
                { categ === "sell-warehouse"? <Grid item xs={12}>
                    <form className="add-form">
                        <Grid container>
                            <h1 style={{ marginBottom: "2.2rem" }}>
                                Detail Surat Jalan
                            </h1>
                            {/* <Grid item xs={12} md={6}>
                            </Grid> */}
                            {/* <Grid item xs={12} md={6}>
                                <Button variant="contained" className={`edit-btn ${hide? "":"hide"}`}
                                    onClick={onClickEdit}>Edit</Button>
                            </Grid> */}
                        </Grid>

                        <Grid container className="add-margin-bottom">
                            <Grid item xs={12}>
                                <DateInput
                                    label="Tanggal"
                                    name="date"
                                    value={defaultDate}
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label="Nama Pembeli"
                                    name="buyer_name"
                                    placeholder="Nama Pembeli"
                                    className="form-left-field add-margin-bottom"
                                    validation={register}
                                    // errors={errors.name}
                                    // errorClassName="text-required"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PhoneInput
                                    label="No. Telpon Pembeli"
                                    name="buyer_phone"
                                    placeholder="Nomor Telpon"
                                    className="form-right-field add-margin-bottom"
                                    // value={phoneNumber}
                                    // onChange={onChangePhoneNumber}
                                    validation={register}
                                    // requiredText="*nomor HP admin harus diisi"
                                    // errors={errors.phone}
                                    // errorClassName="text-required"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    multiline={true}
                                    rows={2}
                                    label="Alamat Pengiriman"
                                    name="delivery_destination"
                                    placeholder="Alamat"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PriceInput
                                    label="Biaya Pengiriman"
                                    name="postal_fee"
                                    className="full-width add-margin-bottom price-field"
                                    control={control}
                                    // rules={{ required: "*total pengeluaran tambahan harus diisi" }}
                                    // errors={errors.total_price}
                                    // errorClassName="text-required"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label="Supir / Kurir"
                                    name="driver_name"
                                    placeholder="Supir / Kurir"
                                    className="form-left-field add-margin-bottom"
                                    validation={register}
                                    // errors={errors.name}
                                    // errorClassName="text-required"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PhoneInput
                                    label="No. Telpon Supir / Kurir"
                                    name="driver_phone"
                                    placeholder="Nomor Telpon"
                                    className="form-right-field add-margin-bottom"
                                    // value={phoneNumber}
                                    // onChange={onChangePhoneNumber}
                                    validation={register}
                                    // requiredText="*nomor HP admin harus diisi"
                                    // errors={errors.phone}
                                    // errorClassName="text-required"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Grid> : <></> }
            </Grid>
        </div>
    )
}