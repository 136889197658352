import React, { useState } from 'react';
import Select from "react-select";
import { Controller } from "react-hook-form";
import NumberFormat from 'react-number-format';
import { Autocomplete } from '@material-ui/lab';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    // KeyboardTimePicker,
  } from '@material-ui/pickers';
import { TextField } from "@material-ui/core";
// import { TextField, FormControl, FormGroup, Switch, FormLabel,
//     FormControlLabel } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

// Field for basic input text
export function TextInput(props) {
    return (
        <>
            <TextField
                variant="outlined"
                name={props.name}
                label={props.label}
                placeholder={props.placeholder}
                multiline={props.multiline}
                rows={props.rows}
                value={props.value}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                onBlur={props.onBlur}
                className={`${props.className} ${props.errors? "error-style" : ""}`}
                style={props.style}
                inputRef={props.validation}
                disabled={props.disabled}
                inputProps={props.inputProps}
                InputLabelProps={{
                  shrink: true,
                }}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for number only input
export function NumberInput(props) {
    return (
        <>
            <TextField
                autoComplete='off'
                variant="outlined"
                label={props.label}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                className={props.className}
                style={props.style}
                disabled={props.disabled}
                inputRef={props.validation({
                    required: (props.requiredText),
                    pattern: {
                        value: /^[+-]?\d+$/,
                        message: "*format tidak valid",
                    },
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ inputMode: 'numeric' }}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for phone number input
export function PhoneInput(props) {
    return (
        <>
            <TextField
                variant="outlined"
                label={props.label}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                className={`${props.className} ${props.errors? "error-style" : ""}`}
                disabled={props.disabled}
                inputRef={props.validation({
                    required: (props.requiredText),
                    pattern: {
                        value: /^[+-]?\d+$/,
                        message: "*format tidak valid",
                    },
                    minLength: {
                        value: 7,
                        message: "*nomor telepon terlalu pendek",
                    },
                    maxLength: {
                        value: 16,
                        message: "*nomor telepon terlalu panjang",
                    },
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ inputMode: 'numeric' }}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for email input
export function EmailInput(props) {
    return (
        <>
            <TextField
                type="email"
                variant="outlined"
                label={props.label}
                name={props.name}
                placeholder={props.placeholder}
                className={`${props.className} ${props.errors? "error-style" : ""}`}
                disabled={props.disabled}
                inputRef={props.validation({
                    required: props.required? "*email harus diisi" : false,
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "*alamat email tidak valid",
                    },
                })}
                InputLabelProps={{
                  shrink: true,
                }}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for price
export function PriceInput(props) {
    return (
        <>
            <label className="price-label">{props.label}</label>
            <Controller
                as={
                    <NumberFormat
                      variant={props.variant}
                      customInput={TextField}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={"Rp "}
                      className={`${props.className} ${props.errors? "error-style" : ""}`}
                      style={props.style}
                      placeholder={props.user === "master"? "-" : "Rp 0"}
                      allowNegative={false}
                      disabled={props.disabled}
                    />
                }
                name={props.name}
                defaultValue={props.type && props.type === "item"?
                props.defaultValue : ""}
                variant="outlined"
                control={props.control}
                rules={props.rules}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    );
}

// Price field with onBlur function
export function PriceOnBlurInput(props) {
    return (
        <>
            <label className="price-label">{props.label}</label>
            <NumberFormat
                variant="outlined"
                customInput={TextField}
                defaultValue={props.defaultValue}
                onBlur={props.onBlur}
                className={props.className}
                thousandSeparator="."
                decimalSeparator=","
                prefix={"Rp "}
                placeholder="Rp -"
                allowNegative={false}
            />
        </>
    );
}

// Field for date input
export function DateInput(props) {
    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    name={props.name}
                    label={props.label}
                    format="dd/MM/yyyy"
                    maxDate={props.maxDate}
                    minDate={props.minDate}
                    value={props.value}
                    onChange={props.onChange}
                    className={`${props.className} ${props.errors? "error-style" : ""}`}
                    style={props.style}
                    disabled={props.disabled}
                    inputRef={props.validation}
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    disableFuture={props.disableFuture}
                    disablePast={props.disablePast}
                />
            </MuiPickersUtilsProvider>
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for dropdown input (can be multiple choice too)
export function DropdownInput(props) {
    return (
        <>
            <Controller
                as={Select}
                name={props.name}
                className={props.className}
                options={props.options}
                getOptionLabel={props.getOptionLabel}
                control={props.control}
                rules={props.rules}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                isMulti={props.isMulti}
                isClearable={props.isClearable}
                placeholder={props.placeholder}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    );
}

// Field for dropdown input (can get current value)
export function DropdownCurrentInput(props) {
    return (
        <>
            <Controller
                name={props.name}
                as={ ({ onChange }) => 
                    (<Autocomplete
                        disableClearable={props.disableClearable}
                        options={props.options}
                        getOptionLabel={props.getOptionLabel}
                        // onChange={(_, data) => onChange(data)}
                        onChange={(_, data) => {
                            // UPDATE THE DROPDOWN VALUE
                            onChange(data);
                            // KEEP THE DATA WHEN OTHER EVENT HAPPEN
                            props.keepData(data)
                        }}
                        value={props.value}
                        defaultValue=""
                        className={`${props.className} ${props.errors? "error-style" : ""}`}
                        disabled={props.disabled}
                        style={props.style}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                inputRef={props.inputRef}
                                label={props.label}
                                placeholder="Pilih"
                                variant="outlined"
                                required={props.required}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                            />}
                    />)
                }
                control={props.control}
                rules={props.rules}
                defaultValue={props.defaultValue}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for percentage
export function PercentageInput(props) {
    return (
        <>
            <TextField
                variant="outlined"
                label={props.label}
                name={props.name}
                placeholder="0%"
                value={props.value}
                onChange={props.onChange}
                className={props.className}
                disabled={props.disabled}
                inputRef={props.validation({
                    required: (props.requiredText),
                })}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ inputMode: 'numeric' }}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for upload image and show the preview
export function ImageInput(props) {
    const [state, setState] = useState({
      image: null,
      imgPath: 'Pilih Gambar',
      canceledImage: false,
      deleteResponse: 0,
    });
    const max = 2097152;
    const defaultImage = props.defaultImg
      ? props.defaultImg
      : require("../assets/img/imageDefault.png").default;
  
    const validateImage = value => {
      return value[0] ? value[0].size <= max : props.isEdit === true;
    };
  
    const inputImage = event => {
      let obj = new FormData();
      obj.append("image", event.target.files[0]);
      setState({
        ...state,
        image: URL.createObjectURL(event.target.files[0]),
        formData: obj,
        imgPath: event.target.files[0].name,
        canceledImage: false,
        deleteResponse: 0,
      });
    };
  
    const clearImage = event => {
      if ( props.name === 'national_identification' || props.name === 'photo'
        || props.name === 'proof_of_payment' ) {
            alert('Foto tidak dapat dihapus! Hanya dapat melakukan penggantian foto.')
      }
      else {
          document.getElementById("selectedFile_" + props.name).value = null;
          setState({
            ...state,
            image: require("../assets/img/imageDefault.png").default,
            formData: null,
            imgPath: "Pilih Gambar",
            canceledImage: true,
            deleteResponse: 200,
          });
      }
    };
  
    return (
      <div className={props.overallClassName}>
          <div style={{ position: "relative", width: "min-content", marginLeft: "auto", marginRight: "auto" }}>
            <div className={`delete-img-btn ${props.disabled? "hide" : ""}
                ${(props.defaultImg? "" : state.canceledImage? "hide" : state.image? "" : "hide")}
                ${state.deleteResponse === 200? "hide":""} ${props.canDelete? "":"hide"}`}>
                <CloseIcon
                    onClick={() => clearImage()}
                    className="can-delete"
                />
            </div>
            <img
                width="100%"
                style={{ width:"10rem", height:"10rem", display:"block", marginLeft:"auto", marginRight:"auto" }}
                src={state.image ? state.image : defaultImage}
                alt="img"
            />
          </div>
          <div className={props.disabled? "" : "image-info"}>
            <p style={{
                marginBottom: "10px", paddingLeft: "2px", fontSize: "0.9rem",
                textAlign: (props.disabled? "center" : "left")
            }}>
                <b>{props.label}</b></p>
            <div>
                <label>
                    <input
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg"
                    type="file"
                    size="60"
                    name={props.name}
                    onChange={inputImage}
                    disabled={props.disabled}
                    id={"selectedFile_" + props.name}
                    ref={
                        !props.isEdit
                        ? props.validation({
                            required: props.error_label + " harus diisi",
                            validate: value =>
                                validateImage(value) ||
                                "Gambar yang anda masukkan melebihi batas maksimal (2MB)"
                            })
                        : props.validation({
                            required: false,
                            validate: value =>
                                validateImage(value) ||
                                "Gambar yang anda masukkan melebihi batas maksimal (2MB)"
                            })
                    }
                    />
                    <div className={(props.disabled? "hide" : "")}>
                        <span className="img-name">{state.imgPath}</span>
                        <span className="browse-btn">Browse</span>
                    </div>
                </label>
                {props.errors && (
                    <span style={{ color:"red", width:"100%", display:"inherit" }}>
                    <small>{props.errors.message}</small>
                    </span>
                )}
            </div>
          </div>
      </div>
    );
}