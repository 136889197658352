import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar, Checkbox } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { TextInput, NumberInput, PriceInput, DropdownInput } from "../../../logic/FormInput";
import { getProductLoss } from "../../../logic/APIHandler";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailLoses(props) {

    const numberRegex = /^[0-9]*$/;
    let Barcode = require('react-barcode');

    // Form State
    const [code, setCode] = useState("");
    const [name, setName] = useState("");
    const [stock, setStock] = useState("");
    const [isSell, setIsSell] = useState(false);
    const [updatedData, setUpdatedData] = useState("");
    
    // Page State
    const location = useLocation();
    const history = useHistory();
    const { register, handleSubmit, control, errors, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [idProduct, setIDProduct] = useState(null);
    const [hide, setHide] = useState('hide');
    const [pageTitle, setPageTitle] = useState('Detail Produk Loss');
    const [openPin, setOpenPin] = useState(false);
    const [openJWT, setOpenJWT] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
        const idProduct = props.match.params.id;
        setIDProduct(idProduct);
        if ( idProduct ) {
            getData(idProduct);
        }
        else {
            console.log("ID not found");
        }     
    }, [props.match.params.id]);

    // Get initial data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = await getProductLoss(id);
        if ( resp[0] && resp[0].status === 200 ) {
            const product = resp[0].data;
            setValue("name", product.product_id.product_code + " - " + product.product_id.name);
            setValue("product_code", product.product_code);
            setValue("selling_price", product.selling_price);
            setValue("notes", product.notes);
            setValue("origin", product.origin === "warehouse"?
                { label: "Gudang", value: "warehouse" } :
                { label: "Toko", value: "store" }
            );
            setStock(product.quantity);
            setIsSell(product.isSelling);
            setCode(product.product_code);
            setName(product.product_id.name);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
        }
        setIsLoading(false);
    }

    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Handle toggle edit button
    const onClickEdit = () => {
        if ( isDisabled ) {
            setPageTitle("Edit Produk Loss")
            setHide("");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // CLose pin modal
    const handleClose = () => {
        setOpenPin(false);
    }

    // Handle onChange input number
    const onChangeInputNumber = (event) => {
        if ( numberRegex.test(event.target.value) ) {
            if ( event.target.name === "quantity" ) {
                setStock(event.target.value);
            }
        }
    }

    // Download product's barcode as .png
    const downloadBarcode = () => {
        const image = document.querySelector('.barcode > img');
        let a = document.createElement("a"); //Create <a>
        a.href = image.src; //Image Base64 Goes here
        a.download = code + " - " + name + ".png"; //File name Here
        a.click(); //Downloaded file
    }

    // Send new data to server
    const onSubmit = async (data) => {
        data.isSelling = isSell;
        data._id = idProduct;
        setUpdatedData(data);
        setOpenPin(true);
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""}
                    level={localStorage.getItem("level")} />

                <Modal
                    open={openPin}
                    onClose={handleClose}
                    onClickCancel={handleClose}
                    modalType="update-losses"
                    id={idProduct}
                    color="primary"
                    modalTitle="Masukkan Pin Master"
                    updatedData={updatedData}
                    submitText="Submit"
                    cancelText="Cancel"
                />
                <Modal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Update Produk
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <h1 style={{ marginBottom: "2.2rem" }}>
                                        {pageTitle}
                                    </h1>
                                </Grid>
                                {localStorage.getItem("level") === "1"?
                                    <></>
                                // : localStorage.getItem("level") === "3"?
                                //     <Grid item xs={12} md={6}>
                                //         <Button
                                //             variant="contained"
                                //             className="edit-btn"
                                //             onClick={downloadBarcode}
                                //         >Download Barcode</Button>
                                //     </Grid>
                                : <Grid item xs={12} md={6}>
                                        <Button
                                            variant="contained"
                                            className={`edit-btn left-margin ${hide? "":"hide"}`}
                                            onClick={onClickEdit}
                                        >Edit</Button>
                                        <Button
                                            variant="contained"
                                            className={`edit-btn ${code && isSell? "" : "hide"}`}
                                            onClick={downloadBarcode}
                                        >Download Barcode</Button>
                                    </Grid>
                                }
                            </Grid>

                            <Grid container spacing={2} className="add-margin-bottom">
                                <Grid item xs={12} className="barcode hide">
                                    {code? 
                                        <Barcode
                                            value={code}
                                            renderer={"img"}
                                        /> : <></>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Nama Produk"
                                        name="name"
                                        placeholder="Nama"
                                        className="full-width"
                                        validation={register}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    <label className="selection-label">Asal Produk</label>
                                    <DropdownInput
                                        name="origin"
                                        className={`${isDisabled? "disabled-field" : ""}
                                            selection-field`}
                                        control={control}
                                        options={[
                                            { label: "Toko", value: "store" },
                                            { label: "Gudang", value: "warehouse" },
                                        ]}
                                        rules={{ required: "*asal produk harus dipilih" }}
                                        errors={errors.origin}
                                        errorClassName="text-required use-spacing"
                                        isClearable={false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <NumberInput
                                        label="Kuantitas"
                                        name="quantity"
                                        placeholder="x (piece)"
                                        className="full-width"
                                        value={stock}
                                        onChange={onChangeInputNumber}
                                        validation={register}
                                        requiredText="*kuantitas produk loss harus diisi"
                                        errors={errors.quantity}
                                        errorClassName="text-required use-spacing"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        multiline={true}
                                        rows={2}
                                        label="Keterangan Loss"
                                        name="notes"
                                        placeholder="Keterangan"
                                        className="full-width"
                                        validation={register}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <span>Produk dapat dijual?</span>
                                    <Checkbox
                                        checked={isSell}
                                        onClick={() => setIsSell(!isSell)}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12} className={isSell? "" : "hide"}>
                                    <TextInput
                                        label="Kode Produk Loss"
                                        name="product_code"
                                        placeholder="Kode Produk"
                                        className="full-width"
                                        validation={register({ required: isSell?
                                            "*kode produk loss harus diisi" : false })}
                                        errors={errors.product_code}
                                        errorClassName="text-required use-spacing"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12} className={isSell? "" : "hide"}>
                                    <PriceInput
                                        label="Harga Jual"
                                        name="selling_price"
                                        className="full-width price-field"
                                        control={control}
                                        rules={{ required: isSell?
                                            "*harga jual baru harus diisi" : false
                                        }}
                                        errors={errors.selling_price}
                                        errorClassName="text-required use-spacing"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={onClickEdit}
                                        className={`add-cancel-btn ${hide}`}
                                    >Cancel</Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        style={{ float: "right", marginRight: "15px" }}
                                        className={`add-new-btn responsive-submit-btn ${hide}`}
                                    >Submit</Button>
                                </Grid>
                            </Grid>

                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}