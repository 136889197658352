import React from 'react';
import MaterialTable from 'material-table';
import { useLocation } from 'react-router-dom';

import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

export default function Table(props) {

    const location = useLocation();

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    return (
        <MaterialTable
            icons={ tableIcons }
            columns={ props.tableColumns }
            data={ props.data }
            title={ props.tableTitle }
            actions={props.tableActions}
            options={{
                searchText: localStorage.getItem("search")? localStorage.getItem("search") : "", //search
                initialPage: localStorage.getItem("tablePage")? parseInt(localStorage.getItem("tablePage")) : 0, //currentPage
                pageSize: 10, //rowpage
                pageSizeOptions: [],
                actionsColumnIndex: -1,
                headerStyle: { position: 'sticky', top: 0 },
                // minBodyHeight: (props.minBodyHeight),
                // maxBodyHeight: (props.maxBodyHeight),
            }}
            onChangePage={(page) => {
                if ( !props.defaultPagination ) {
                    localStorage.setItem("path", location.pathname);
                    localStorage.setItem("tablePage", parseInt(page));
                }
            }}
            onSearchChange={(value) => {
                localStorage.setItem("path", location.pathname);
                localStorage.setItem("search", value);
            }}
            // onOrderChange={(el)=>{setTableState({...tableState, order: el})}}
            // onChangeRowsPerPage={(el)=>{setTableState({...tableState, rowPage: el})}}
            localization={{
                header: {
                    actions: 'Aksi',
                },
            }}
        />
    );

}