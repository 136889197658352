import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { TextInput, PercentageInput, PriceInput, DropdownInput,
    NumberInput } from "../../../logic/FormInput";
import { getDiscount, updateDiscount, getProducts } from "../../../logic/APIHandler";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailDiscount(props) {

    const numberRegex = /^[0-9]*$/;

    // Form State
    const [x, setX] = useState("");
    const [y, setY] = useState("");
    const [currType, setCurrType] = useState("");
    const [percentage, setPercentage] = useState("");
    const [products, setProducts] = useState([]);
    
    // Page State
    const history = useHistory();
    const { register, handleSubmit, control, errors, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [idDiscount, setIDDiscount] = useState(null);
    const [hide, setHide] = useState('hide');
    const [pageTitle, setPageTitle] = useState('Detail Diskon');
    const [openJWT, setOpenJWT] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        const idDiscount = props.match.params.id;
        setIDDiscount(idDiscount);
        if ( idDiscount ) {
            getData(idDiscount);
        }
        else {
            console.log("ID not found");
        }     
    }, [props.match.params.id]);

    // Get initial data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = await getDiscount(id);
        if ( resp[0] && resp[0].status === 200 ) {
            const discount = resp[0].data;
            setValue("name", discount.name);
            setCurrType(discount.type_promo);
            if ( discount.type_promo === "persen" ) {
                setValue("type_promo", "Persen");
                setPercentage(discount.value + "%");
            }
            else if ( discount.type_promo === "rupiah" ) {
                setValue("type_promo", "Flat");
                setValue("flat", discount.value);
            }
            else {
                getAllProducts();
                setValue("type_promo", "Buy X Get Y");
                setValue("productX", {
                    "label": discount.x.product_id.product_code
                        + " - " + discount.x.product_id.name,
                    "value": discount.x.product_id._id,
                });
                setValue("productY", {
                    "label": discount.y.product_id.product_code
                        + " - " + discount.y.product_id.name,
                    "value": discount.y.product_id._id,
                });
                setX(discount.x.value);
                setY(discount.y.value);
            }
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp[1].message);
        }
        setIsLoading(false);
    }

    // Get list of products
    const getAllProducts = async () => {
        setIsLoading(true);
        let resp = await getProducts();
        if ( resp[0] && resp[0].status === 200 ) {
            let temp = [];
            resp[0].data.forEach(res => {
                temp.push({
                    "label": res.product_code + " - " + res.name,
                    "value": res._id,
                })
            });
            setProducts(temp);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp[1].message? resp[1].message : resp[1].error[0].msg);
        }
        setIsLoading(false);
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Handle toggle edit button
    const onClickEdit = () => {
        if ( isDisabled ) {
            setPageTitle("Edit Diskon")
            setHide("");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Handle onChange input number
    const onChangeInputNumber = (event) => {
        if ( numberRegex.test(event.target.value) ) {
            if ( event.target.name === "valueX" ) {
                setX(event.target.value);
            }
            else if ( event.target.name === "valueY" ) {
                setY(event.target.value);
            }
        }
    }

    // Handle onChange percentage value
    const onChangeValue = (event) => {
        let value = event.target.value;
        value = value.charAt(value.length-1);
        if ( numberRegex.test(value) ) {
            if ( event.target.value.length > percentage.length ) {
                const intVal = parseInt(event.target.value.replace("%",""));
                if ( intVal >= 0 && intVal <= 100 ) {
                    if ( percentage.includes("%") ) setPercentage(percentage.replace("%", value) + "%");
                    else setPercentage(value + "%");
                }
            }
            else {
                if ( event.target.value.length <= 1 ) setPercentage("");
                else setPercentage(percentage.slice(0, percentage.length-2) + "%");
            }
        }
    }

    // Send new data to server
    const onSubmit = async (data) => {
        setIsLoading(true);
        let resp = await updateDiscount(idDiscount, data);
        if (resp[0] && resp[0].status === 200) {
            setIsLoading(false);
            setOpenSuccessAlert(true);
            await sleep(1000);
            history.go(0);
        }
        else if ( resp[1] && resp[1].status === 400 ) {
            setErrorText(resp[1].data[0].msg);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
        else {
            setErrorText(resp[1].message);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} level="2" />

                <Modal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Update Diskon
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <h1 style={{ marginBottom: "2.2rem" }}>
                                        {pageTitle}
                                    </h1>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button variant="contained" className={`edit-btn ${hide? "":"hide"}`}
                                        onClick={onClickEdit}>Edit</Button>
                                </Grid>
                            </Grid>

                            <Grid container className="add-margin-bottom">
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Nama *"
                                        name="name"
                                        placeholder="Nama Diskon"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*nama diskon harus diisi" })}
                                        errors={errors.name}
                                        errorClassName="text-required"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Tipe Diskon *"
                                        name="type_promo"
                                        placeholder="Tipe Diskon"
                                        className="full-width add-margin-bottom disabled-text-input"
                                        validation={register({ required: "*tipe diskon harus diisi" })}
                                        errors={errors.type_promo}
                                        errorClassName="text-required"
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {currType === "persen"?
                                        <PercentageInput
                                            label="Nilai *"
                                            name="percentage"
                                            className="full-width add-margin-bottom"
                                            value={percentage}
                                            onChange={onChangeValue}
                                            validation={register}
                                            requiredText="*nilai diskon harus diisi"
                                            errors={errors.percentage}
                                            errorClassName="text-required"
                                            disabled={isDisabled}
                                        />
                                    : currType === "rupiah"?
                                        <PriceInput
                                            label="Nilai *"
                                            name="flat"
                                            className="full-width add-margin-bottom price-field"
                                            control={control}
                                            rules={{ required: "*nilai diskon harus diisi" }}
                                            errors={errors.flat}
                                            errorClassName="text-required"
                                            disabled={isDisabled}
                                        />
                                    : currType === "buyxgety"?
                                    <Grid container>
                                        <Grid item xs={12} sm={8} style={{ position: "relative" }}>
                                            <label className="selection-label">Produk X *</label>
                                            <DropdownInput
                                                name="productX"
                                                className={`${isDisabled? "disabled-field" : ""}
                                                    selection-field add-margin-bottom`}
                                                control={control}
                                                options={products}
                                                rules={{ required: "*produk x harus diisi" }}
                                                errors={errors.productX}
                                                errorClassName="text-required"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <NumberInput
                                                label="Total X *"
                                                name="valueX"
                                                placeholder="0"
                                                className="quarter-field quarter-right add-margin-bottom"
                                                value={x}
                                                onChange={onChangeInputNumber}
                                                validation={register}
                                                requiredText="*total produk x harus diisi"
                                                errors={errors.valueX}
                                                errorClassName="text-required-half"
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={8} style={{ position: "relative" }}>
                                            <label className="selection-label">Produk Y *</label>
                                            <DropdownInput
                                                name="productY"
                                                className={`${isDisabled? "disabled-field" : ""}
                                                    selection-field add-margin-bottom`}
                                                control={control}
                                                options={products}
                                                rules={{ required: "*produk y harus diisi" }}
                                                errors={errors.productY}
                                                errorClassName="text-required"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <NumberInput
                                                label="Total Y *"
                                                name="valueY"
                                                placeholder="0"
                                                className="quarter-field quarter-right add-margin-bottom"
                                                value={y}
                                                onChange={onChangeInputNumber}
                                                validation={register}
                                                requiredText="*total produk y harus diisi"
                                                errors={errors.valueY}
                                                errorClassName="text-required-half"
                                                disabled={isDisabled}
                                            />
                                        </Grid>
                                    </Grid>
                                    : <></>
                                    }
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={onClickEdit}
                                        className={`add-cancel-btn ${hide}`}
                                    >Cancel</Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        style={{ float: "right", marginRight: "15px" }}
                                        className={`add-new-btn responsive-submit-btn ${hide}`}
                                    >Submit</Button>
                                </Grid>
                            </Grid>

                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}