import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar, } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { TextInput, PercentageInput } from "../../../logic/FormInput";
import { postTax } from "../../../logic/APIHandler";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddTax() {

    const numberRegex = /^[0-9]*$/;
    
    // Page State
    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    const [tax, setTax] = useState("");
    const [openJWT, setOpenJWT] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Handle onChange tax value
    const onChangeValue = (event) => {
        let value = event.target.value;
        value = value.charAt(value.length-1);
        if ( numberRegex.test(value) ) {
            if ( event.target.value.length > tax.length ) {
                const intVal = parseInt(event.target.value.replace("%",""));
                if ( intVal >= 0 && intVal <= 100 ) {
                    if ( tax.includes("%") ) setTax(tax.replace("%", value) + "%");
                    else setTax(value + "%");
                }
            }
            else {
                if ( event.target.value.length <= 1 ) setTax("");
                else setTax(tax.slice(0, tax.length-2) + "%");
            }
        }
    }

    // Send new data to server
    const onSubmit = async (data) => {
        setIsLoading(true);
        let resp = await postTax(data);
        if (resp[0] && resp[0].status === 200) {
            setIsLoading(false);
            setOpenSuccessAlert(true);
            await sleep(1000);
            history.replace("/pajak");
        }
        else if ( resp[1] && resp[1].status === 400 ) {
            setErrorText(resp[1].data[0].msg);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            setErrorText(resp[1].message);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} level="2" />
                
                <Modal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Menambah Jenis Pajak
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2.2rem" }}>
                                Tambah Pajak Baru
                            </h1>

                            <Grid container>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Nama *"
                                        name="name"
                                        placeholder="Nama Pajak"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*nama pajak harus diisi" })}
                                        errors={errors.name}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PercentageInput
                                        label="Nilai *"
                                        name="value"
                                        className="full-width add-margin-bottom"
                                        value={tax}
                                        onChange={onChangeValue}
                                        validation={register}
                                        requiredText="*nilai pajak harus diisi"
                                        errors={errors.value}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>

                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}