import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import {
  List, ListItem, ListItemText,
  ListSubheader, Collapse, ListItemIcon,
} from '@material-ui/core';
import '../styles/Sidebar_Styles.css';
import logo from '../assets/img/logo.png';
import HomeIcon from '@material-ui/icons/Home';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FiberPinIcon from '@material-ui/icons/FiberPin';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import StoreIcon from '@material-ui/icons/Store';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import CategoryIcon from '@material-ui/icons/Category';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import PersonIcon from '@material-ui/icons/Person';
import PaymentIcon from '@material-ui/icons/Payment';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
// import CachedIcon from '@material-ui/icons/Cached';
import TimerIcon from '@material-ui/icons/Timer';
import HouseIcon from '@material-ui/icons/House';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

class Sidebar extends Component {

  constructor(props) {
    super(props);
    
    const currLocation = props.location.pathname;
    const currID = props.match.params.id;

    if ( currLocation.includes('/data-penjualan-') ) {
      this.state = {
        isExpandSales: true,
        isExpandOther: false,
        isExpandStock: false,
        id: (currID? currID : ""),
        currLocation: currLocation,
      }
    }
    else if ( currLocation.includes('/supplier') || currLocation.includes('/cabang')
      || currLocation.includes('generate') || currLocation.includes('password') ) {
      this.state = {
        isExpandSales: false,
        isExpandOther: true,
        isExpandStock: false,
        id: (currID? currID : ""),
        currLocation: currLocation,
      }
    }
    else if ( currLocation.includes('/stok-keseluruhan') || currLocation.includes('opname') ) {
      this.state = {
        isExpandSales: false,
        isExpandOther: false,
        isExpandStock: true,
        id: (currID? currID : ""),
        currLocation: currLocation,
      }
    }
    else {
      this.state = {
        isExpandSales: false,
        isExpandOther: false,
        isExpandStock: false,
        id: (currID? currID : ""),
        currLocation: currLocation,
      }
    }
  }

  // Maintain sidebar active menu to stay active in different pages
  onPaths = (paths) => {
    return (match, location) => {
      return paths.includes(location.pathname);
    };
  };

  handleListItemClick = (event) => {
    if ( event.target.innerText === "Data Penjualan Produk" ) {
      this.setState({
        isExpandSales: !this.state.isExpandSales,
      })
    }
    else if ( event.target.innerText === "Lainnya" ) {
      this.setState({
        isExpandOther: !this.state.isExpandOther,
      })
    }
    else if ( event.target.innerText === "Stok Keseluruhan" ) {
      this.setState({
        isExpandStock: !this.state.isExpandStock,
      })
    }
  };

  clearLocalStorage = (level) => {
    localStorage.removeItem("search");
    localStorage.removeItem("tablePage");
    localStorage.removeItem("tablePageLog");
    localStorage.removeItem("tablePageLogWR");
    localStorage.removeItem("start");
    localStorage.removeItem("end");
    localStorage.removeItem("code");
    localStorage.removeItem("name");
    localStorage.removeItem("category");
    localStorage.removeItem("payMethod");
    if ( level === "3" ) {
      localStorage.removeItem("invoice");
      localStorage.removeItem("supplier");
    }
  };

  render() {
    return (
      <div className={`sidebar ${this.props.sidebarState}`}>
        <List
          dense
          subheader={
            <ListSubheader className="sidebar-header">
              <NavLink
                to="/"
                className="default-link-style"
              >
                <img src={logo} alt="logo" className="sidebar-logo" />
              </NavLink>
            </ListSubheader>
          }
        >
          {this.props.level === "1"? // Master
            <React.Fragment>

              {/* Dashboard Link */}
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/dashboard",
                ])}
                to="/dashboard"
                onClick={() => this.clearLocalStorage(this.props.level)}
                style={{ textDecoration: "none" }}>
                <ListItem
                  button
                  className="sidebar-item"
                >
                  <ListItemIcon className="sidebar-icon"><HomeIcon /></ListItemIcon>
                  <ListItemText className="sidebar-item-text">Dashboard</ListItemText>
                </ListItem>
              </NavLink>

              {/* Overall Sales Link */}
              <ListItem
                button
                className="sidebar-item"
                onClick={(event) => this.handleListItemClick(event)}
              >
                <ListItemIcon className="sidebar-icon">
                  <TrendingUpIcon />
                </ListItemIcon>
                <ListItemText className="sidebar-item-text">Data Penjualan Produk</ListItemText>
                {this.state.isExpandSales ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.isExpandSales} timeout="auto" unmountOnExit>
                <List>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={this.onPaths([
                      "/data-penjualan-kasir",
                    ])}
                    to="/data-penjualan-kasir"
                    onClick={() => this.clearLocalStorage(this.props.level)}
                    style={{ textDecoration: "none" }}
                  >
                      <ListItem button className="sidebar-subitem">
                        {/* <ListItemIcon className="sidebar-icon">
                          <TrendingUpIcon />
                        </ListItemIcon> */}
                        <ListItemText primary="Penjualan Kasir" />
                      </ListItem>
                  </NavLink>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={this.onPaths([
                      "/data-penjualan-gudang",
                    ])}
                    to="/data-penjualan-gudang"
                    onClick={() => this.clearLocalStorage(this.props.level)}
                    style={{ textDecoration: "none" }}
                  >
                      <ListItem button className="sidebar-subitem">
                        {/* <ListItemIcon className="sidebar-icon">
                          <TrendingUpIcon />
                        </ListItemIcon> */}
                        <ListItemText primary="Penjualan Gudang" />
                      </ListItem>
                  </NavLink>
                </List>
              </Collapse>

              {/* Overall Stock & Stock Opname Link */}
              <ListItem
                button
                className="sidebar-item"
                onClick={(event) => this.handleListItemClick(event)}
              >
                <ListItemIcon className="sidebar-icon">
                  <ShowChartIcon />
                </ListItemIcon>
                <ListItemText className="sidebar-item-text">Stok Keseluruhan</ListItemText>
                {this.state.isExpandStock ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.isExpandStock} timeout="auto" unmountOnExit>
                <List>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={this.onPaths([
                      "/stok-keseluruhan-toko",
                      "/stock-opname-toko", "/stock-opname-compare-toko",
                    ])}
                    to="/stok-keseluruhan-toko"
                    onClick={() => this.clearLocalStorage(this.props.level)}
                    style={{ textDecoration: "none" }}
                  >
                      <ListItem button className="sidebar-subitem">
                        <ListItemText primary="Stok Toko" />
                      </ListItem>
                  </NavLink>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={this.onPaths([
                      "/stok-keseluruhan-gudang",
                      "/stock-opname-gudang", "/stock-opname-compare-gudang",
                    ])}
                    to="/stok-keseluruhan-gudang"
                    onClick={() => this.clearLocalStorage(this.props.level)}
                    style={{ textDecoration: "none" }}
                  >
                      <ListItem button className="sidebar-subitem">
                        <ListItemText primary="Stok Gudang" />
                      </ListItem>
                  </NavLink>
                </List>
              </Collapse>

              {/* Product Link */}
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/produk", "/produk/export-database",
                  ("/produk/" + this.state.id),
                  ("/produk/transaksi/" + this.state.id),
                  ("/produk/riwayat-stok/" + this.state.id),
                  ("/produk/riwayat-stok/transaksi/" + this.state.id),
                ])}
                to="/produk"
                onClick={() => this.clearLocalStorage(this.props.level)}
                style={{ textDecoration: "none" }}>
                <ListItem
                  button
                  className="sidebar-item"
                >
                  <ListItemIcon className="sidebar-icon"><LocalMallIcon /></ListItemIcon>
                  <ListItemText className="sidebar-item-text">Produk</ListItemText>
                </ListItem>
              </NavLink>

              {/* Product Losses Link */}
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/losses", "/losses-form", "/losses/export-database",
                  ("/losses/" + this.state.id),
                ])}
                to="/losses"
                onClick={() => this.clearLocalStorage(this.props.level)}
                style={{ textDecoration: "none" }}>
                <ListItem
                  button
                  className="sidebar-item"
                >
                  <ListItemIcon className="sidebar-icon">
                    <LocalMallOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText className="sidebar-item-text">Produk Loss</ListItemText>
                </ListItem>
              </NavLink>

              {/* Request Link */}
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/request", "/request-form", ("/request/" + this.state.id),
                ])}
                to="/request"
                onClick={() => this.clearLocalStorage(this.props.level)}
                style={{ textDecoration: "none" }}>
                <ListItem
                  button
                  className="sidebar-item"
                >
                  <ListItemIcon className="sidebar-icon">
                    <AssignmentLateIcon />
                  </ListItemIcon>
                  <ListItemText className="sidebar-item-text">Request</ListItemText>
                </ListItem>
              </NavLink>

              {/* Transition Link */}
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/transisi", "/transisi-form", ("/transisi/" + this.state.id),
                ])}
                to="/transisi"
                onClick={() => this.clearLocalStorage(this.props.level)}
                style={{ textDecoration: "none" }}>
                <ListItem
                  button
                  className="sidebar-item"
                >
                  <ListItemIcon className="sidebar-icon">
                    <SyncAltIcon />
                  </ListItemIcon>
                  <ListItemText className="sidebar-item-text">Transisi</ListItemText>
                </ListItem>
              </NavLink>

              {/* User Link */}
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/admin", "/admin-form", ("/admin/" + this.state.id),
                ])}
                to="/admin"
                onClick={() => this.clearLocalStorage(this.props.level)}
                style={{ textDecoration: "none" }}>
                <ListItem
                  button
                  className="sidebar-item"
                >
                  <ListItemIcon className="sidebar-icon"><PersonIcon /></ListItemIcon>
                  <ListItemText className="sidebar-item-text">Admin</ListItemText>
                </ListItem>
              </NavLink>

              {/* Payment Method Link */}
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/metode-pembayaran", "/metode-pembayaran-form", ("/metode-pembayaran/" + this.state.id),
                ])}
                to="/metode-pembayaran"
                onClick={() => this.clearLocalStorage(this.props.level)}
                style={{ textDecoration: "none" }}>
                <ListItem
                  button
                  className="sidebar-item"
                >
                  <ListItemIcon className="sidebar-icon"><PaymentIcon /></ListItemIcon>
                  <ListItemText className="sidebar-item-text">Metode Pembayaran</ListItemText>
                </ListItem>
              </NavLink>

              {/* Other Links */}
              <ListItem
                button
                className="sidebar-item"
                onClick={(event) => this.handleListItemClick(event)}
              >
                <ListItemIcon className="sidebar-icon"><MoreHorizIcon /></ListItemIcon>
                <ListItemText className="sidebar-item-text">Lainnya</ListItemText>
                {this.state.isExpandOther ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.isExpandOther} timeout="auto" unmountOnExit>
                <List>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={this.onPaths([
                      "/supplier", "/supplier-form", ("/supplier/" + this.state.id),
                    ])}
                    to="/supplier"
                    onClick={() => this.clearLocalStorage(this.props.level)}
                    style={{ textDecoration: "none" }}
                  >
                      <ListItem button className="sidebar-subitem">
                        <ListItemIcon className="sidebar-icon">
                          <StoreIcon />
                        </ListItemIcon>
                        <ListItemText primary="Supplier" />
                      </ListItem>
                  </NavLink>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={this.onPaths([
                      "/cabang", "/cabang-form", ("/cabang/" + this.state.id),
                    ])}
                    to="/cabang"
                    onClick={() => this.clearLocalStorage(this.props.level)}
                    style={{ textDecoration: "none" }}
                  >
                      <ListItem button className="sidebar-subitem">
                        <ListItemIcon className="sidebar-icon">
                          <LocationOnIcon />
                        </ListItemIcon>
                        <ListItemText primary="Cabang" />
                      </ListItem>
                  </NavLink>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={this.onPaths([
                      "/generate-pin"
                    ])}
                    to="/generate-pin"
                    onClick={() => this.clearLocalStorage(this.props.level)}
                    style={{ textDecoration: "none" }}
                  >
                      <ListItem button className="sidebar-subitem">
                        <ListItemIcon className="sidebar-icon">
                          <FiberPinIcon />
                        </ListItemIcon>
                        <ListItemText primary="Generate Pin" />
                      </ListItem>
                  </NavLink>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={this.onPaths([
                      "/ganti-password"
                    ])}
                    to="/ganti-password"
                    onClick={() => this.clearLocalStorage(this.props.level)}
                    style={{ textDecoration: "none" }}
                  >
                      <ListItem button className="sidebar-subitem">
                        <ListItemIcon className="sidebar-icon">
                          <VpnKeyIcon />
                        </ListItemIcon>
                        <ListItemText primary="Ganti Password" />
                      </ListItem>
                  </NavLink>
                  <NavLink
                    to="/"
                    onClick={() => localStorage.clear()}
                    style={{ textDecoration: "none" }}
                  >
                      <ListItem button className="sidebar-subitem">
                        <ListItemIcon className="sidebar-icon">
                          <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                      </ListItem>
                  </NavLink>
                </List>
              </Collapse>
    
            </React.Fragment>
            : this.props.level === "2"? // Manajer Store
              <React.Fragment>

              {/* Overall Stock & Stock Opname Link */}
              <ListItem
                button
                className="sidebar-item"
                onClick={(event) => this.handleListItemClick(event)}
              >
                <ListItemIcon className="sidebar-icon">
                  <ShowChartIcon />
                </ListItemIcon>
                <ListItemText className="sidebar-item-text">Stok Keseluruhan</ListItemText>
                {this.state.isExpandStock ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.isExpandStock} timeout="auto" unmountOnExit>
                <List>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={this.onPaths([
                      "/stok-keseluruhan-toko",
                      "/stock-opname-toko", "/stock-opname-compare-toko",
                    ])}
                    to="/stok-keseluruhan-toko"
                    onClick={() => this.clearLocalStorage(this.props.level)}
                    style={{ textDecoration: "none" }}
                  >
                      <ListItem button className="sidebar-subitem">
                        <ListItemText primary="Stok Toko" />
                      </ListItem>
                  </NavLink>
                  <NavLink
                    activeClassName="active-link"
                    exact
                    isActive={this.onPaths([
                      "/stok-keseluruhan-gudang",
                      "/stock-opname-gudang", "/stock-opname-compare-gudang",
                    ])}
                    to="/stok-keseluruhan-gudang"
                    onClick={() => this.clearLocalStorage(this.props.level)}
                    style={{ textDecoration: "none" }}
                  >
                      <ListItem button className="sidebar-subitem">
                        <ListItemText primary="Stok Gudang" />
                      </ListItem>
                  </NavLink>
                </List>
              </Collapse>

                {/* Product Category Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/kategori-produk", "/kategori-produk-form",
                  ])}
                  to="/kategori-produk"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><CategoryIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Kategori Produk</ListItemText>
                  </ListItem>
                </NavLink>

                {/* Product Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/produk", "/produk-form", "/produk/export-database",
                    ("/produk/" + this.state.id),
                    ("/produk/transaksi/" + this.state.id),
                    ("/produk/riwayat-stok/" + this.state.id),
                    ("/produk/riwayat-stok/transaksi/" + this.state.id),
                  ])}
                  to="/produk"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><LocalMallIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Produk</ListItemText>
                  </ListItem>
                </NavLink>

                {/* Product Losses Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/losses", "/losses-form", "/losses/export-database",
                    ("/losses/" + this.state.id),
                  ])}
                  to="/losses"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon">
                      <LocalMallOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText className="sidebar-item-text">Produk Loss</ListItemText>
                  </ListItem>
                </NavLink>

              {/* Request Link */}
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/request", "/request-form", ("/request/" + this.state.id),
                ])}
                to="/request"
                onClick={() => this.clearLocalStorage(this.props.level)}
                style={{ textDecoration: "none" }}>
                <ListItem
                  button
                  className="sidebar-item"
                >
                  <ListItemIcon className="sidebar-icon">
                    <AssignmentLateIcon />
                  </ListItemIcon>
                  <ListItemText className="sidebar-item-text">Request</ListItemText>
                </ListItem>
              </NavLink>

                {/* Transition Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/transisi", "/transisi-form", ("/transisi/" + this.state.id),
                  ])}
                  to="/transisi"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon">
                      <SyncAltIcon />
                    </ListItemIcon>
                    <ListItemText className="sidebar-item-text">Transisi</ListItemText>
                  </ListItem>
                </NavLink>

                {/* Discount Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/diskon", "/diskon-form", ("/diskon/" + this.state.id),
                  ])}
                  to="/diskon"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><NewReleasesIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Diskon</ListItemText>
                  </ListItem>
                </NavLink>

                {/* Taxes Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/pajak", "/pajak-form", ("/pajak/" + this.state.id),
                  ])}
                  to="/pajak"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><MonetizationOnIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Pajak</ListItemText>
                  </ListItem>
                </NavLink>

                {/* User Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/admin", "/admin-form", ("/admin/" + this.state.id),
                  ])}
                  to="/admin"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><PersonIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Admin</ListItemText>
                  </ListItem>
                </NavLink>

                {/* Change Password Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/ganti-password"
                  ])}
                  to="/ganti-password"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><VpnKeyIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">
                      Ganti Password
                    </ListItemText>
                  </ListItem>
                </NavLink>
      
                {/* Logout Link */}
                <NavLink to="/"
                    style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                    onClick={() => localStorage.clear()}
                  >
                    <ListItemIcon className="sidebar-icon"><ExitToAppIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Logout</ListItemText>
                  </ListItem>
                </NavLink>
      
              </React.Fragment>
            : this.props.level === "3"? // Keuangan
              <React.Fragment>

                {/* Cashier Session Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/sesi-kasir",
                    "/sesi-kasir-form",
                    ("/sesi-kasir/" + this.state.id),
                  ])}
                  to="/sesi-kasir"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><TimerIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Sesi Kasir</ListItemText>
                  </ListItem>
                </NavLink>

                {/* Cashier Sales Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/penjualan-barang-kasir",
                    ("/penjualan-barang-kasir/transaksi/" + this.state.id),
                  ])}
                  to="/penjualan-barang-kasir"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><LocalAtmIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Penjualan Barang Kasir</ListItemText>
                  </ListItem>
                </NavLink>

                {/* Warehouse Sales Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/penjualan-barang-gudang",
                    ("/penjualan-barang-gudang/transaksi/" + this.state.id),
                    "/transaksi-gudang-form",
                  ])}
                  to="/penjualan-barang-gudang"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><HouseIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Penjualan Barang Gudang</ListItemText>
                  </ListItem>
                </NavLink>
                
                {/* Overall Product Purchase Link */}
                {/* <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/pembelian-barang",
                    ("/pembelian-barang/transaksi/" + this.state.id),
                  ])}
                  to="/pembelian-barang"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><ShoppingCartIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Pembelian Barang</ListItemText>
                  </ListItem>
                </NavLink> */}
                
                {/* Income Statement Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/laporan-laba-rugi",
                    ("/laporan-laba-rugi/transaksi/" + this.state.id),
                    ("/laporan-laba-rugi/pengeluaran-tambahan/" + this.state.id),
                  ])}
                  to="/laporan-laba-rugi"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><ImportExportIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Laporan Laba Rugi</ListItemText>
                  </ListItem>
                </NavLink>

                {/* Product Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/produk", "/produk/export-database",
                    ("/produk/" + this.state.id),
                    ("/produk/transaksi/" + this.state.id),
                    ("/produk/riwayat-stok/" + this.state.id),
                    ("/produk/riwayat-stok/transaksi/" + this.state.id),
                  ])}
                  to="/produk"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><LocalMallIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Produk</ListItemText>
                  </ListItem>
                </NavLink>

                {/* Product Losses Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/losses", "/losses-form", "/losses/export-database",
                    ("/losses/" + this.state.id),
                  ])}
                  to="/losses"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon">
                      <LocalMallOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText className="sidebar-item-text">Produk Loss</ListItemText>
                  </ListItem>
                </NavLink>

                {/* Request Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/request", "/request-form", ("/request/" + this.state.id),
                  ])}
                  to="/request"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon">
                      <AssignmentLateIcon />
                    </ListItemIcon>
                    <ListItemText className="sidebar-item-text">Request</ListItemText>
                  </ListItem>
                </NavLink>

                {/* Extra Expenses Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/pengeluaran-tambahan", "/pengeluaran-tambahan-form",
                    ("/pengeluaran-tambahan/" + this.state.id),
                  ])}
                  to="/pengeluaran-tambahan"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><PostAddIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Pengeluaran Tambahan</ListItemText>
                  </ListItem>
                </NavLink>

                {/* Change Password Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/ganti-password"
                  ])}
                  to="/ganti-password"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><VpnKeyIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">
                      Ganti Password
                    </ListItemText>
                  </ListItem>
                </NavLink>
      
                {/* Logout Link */}
                <NavLink to="/"
                    style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                    onClick={() => localStorage.clear()}
                  >
                    <ListItemIcon className="sidebar-icon"><ExitToAppIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Logout</ListItemText>
                  </ListItem>
                </NavLink>
      
              </React.Fragment>
            : this.props.level === "4"? // Inventaris
              <React.Fragment>

                {/* Overall Stock & Stock Opname Link */}
                <ListItem
                  button
                  className="sidebar-item"
                  onClick={(event) => this.handleListItemClick(event)}
                >
                  <ListItemIcon className="sidebar-icon">
                    <ShowChartIcon />
                  </ListItemIcon>
                  <ListItemText className="sidebar-item-text">Stok Keseluruhan</ListItemText>
                  {this.state.isExpandStock ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.isExpandStock} timeout="auto" unmountOnExit>
                  <List>
                    <NavLink
                      activeClassName="active-link"
                      exact
                      isActive={this.onPaths([
                        "/stok-keseluruhan-toko",
                        "/stock-opname-toko", "/stock-opname-compare-toko",
                      ])}
                      to="/stok-keseluruhan-toko"
                      onClick={() => this.clearLocalStorage(this.props.level)}
                      style={{ textDecoration: "none" }}
                    >
                        <ListItem button className="sidebar-subitem">
                          <ListItemText primary="Stok Toko" />
                        </ListItem>
                    </NavLink>
                    <NavLink
                      activeClassName="active-link"
                      exact
                      isActive={this.onPaths([
                        "/stok-keseluruhan-gudang",
                        "/stock-opname-gudang", "/stock-opname-compare-gudang",
                      ])}
                      to="/stok-keseluruhan-gudang"
                      onClick={() => this.clearLocalStorage(this.props.level)}
                      style={{ textDecoration: "none" }}
                    >
                        <ListItem button className="sidebar-subitem">
                          <ListItemText primary="Stok Gudang" />
                        </ListItem>
                    </NavLink>
                  </List>
                </Collapse>

                {/* Product Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/produk", "/produk-form", "/produk/export-database",
                    ("/produk/" + this.state.id),
                    ("/produk/transaksi/" + this.state.id),
                    ("/produk/riwayat-stok/" + this.state.id),
                    ("/produk/riwayat-stok/transaksi/" + this.state.id),
                  ])}
                  to="/produk"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><LocalMallIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Produk</ListItemText>
                  </ListItem>
                </NavLink>

                {/* Product Losses Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/losses", "/losses-form", "/losses/export-database",
                    ("/losses/" + this.state.id),
                  ])}
                  to="/losses"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon">
                      <LocalMallOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText className="sidebar-item-text">Produk Loss</ListItemText>
                  </ListItem>
                </NavLink>

                {/* Transition Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/transisi", "/transisi-form", ("/transisi/" + this.state.id),
                  ])}
                  to="/transisi"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon">
                      <SyncAltIcon />
                    </ListItemIcon>
                    <ListItemText className="sidebar-item-text">Transisi</ListItemText>
                  </ListItem>
                </NavLink>

                {/* Change Password Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/ganti-password"
                  ])}
                  to="/ganti-password"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><VpnKeyIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">
                      Ganti Password
                    </ListItemText>
                  </ListItem>
                </NavLink>

                {/* Logout Link */}
                <NavLink to="/"
                    style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                    onClick={() => localStorage.clear()}
                  >
                    <ListItemIcon className="sidebar-icon"><ExitToAppIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Logout</ListItemText>
                  </ListItem>
                </NavLink>
      
              </React.Fragment>
            : this.props.level === "5"? // Kasir
              <React.Fragment>

                {/* Change Password Link */}
                <NavLink
                  activeClassName="active-link"
                  exact
                  isActive={this.onPaths([
                    "/ganti-password"
                  ])}
                  to="/ganti-password"
                  onClick={() => this.clearLocalStorage(this.props.level)}
                  style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                  >
                    <ListItemIcon className="sidebar-icon"><VpnKeyIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">
                      Ganti Password
                    </ListItemText>
                  </ListItem>
                </NavLink>

                {/* Logout Link */}
                <NavLink to="/"
                    style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    className="sidebar-item"
                    onClick={() => localStorage.clear()}
                  >
                    <ListItemIcon className="sidebar-icon"><ExitToAppIcon /></ListItemIcon>
                    <ListItemText className="sidebar-item-text">Logout</ListItemText>
                  </ListItem>
                </NavLink>
      
              </React.Fragment>
            :
            <React.Fragment>
    
              {/* Logout Link */}
              <NavLink to="/"
                  style={{ textDecoration: "none" }}>
                <ListItem
                  button
                  className="sidebar-item"
                  onClick={() => localStorage.clear()}
                >
                  <ListItemIcon className="sidebar-icon"><ExitToAppIcon /></ListItemIcon>
                  <ListItemText className="sidebar-item-text">Logout</ListItemText>
                </ListItem>
              </NavLink>
    
            </React.Fragment>
          }
        </List>
      </div>
    );

  }
}

export default withRouter(Sidebar);