import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import { Autocomplete } from "@material-ui/lab";
import { Grid, Button, Snackbar, TextField } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DeleteIcon from '@material-ui/icons/Delete';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Table from '../../../components/Table_Com';
import Modal from '../../../components/Modal_Com';
import logo from "../../../assets/img/logo.png";
import { getAdmins } from "../../../logic/APIHandler";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Admin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            open: false,
            openJWT: false,
            openPassword: false,
            openErrorPassword: false,
            adminId: '00000000',
            adminName: '',
            currBranch:
                localStorage.getItem("level") !== "1"? null
                : localStorage.getItem("currBranch")? JSON.parse(localStorage.getItem("currBranch"))
                : null,
            isLoading: false,
            isSidebarOpen: 'slide-out',
        }
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleFilterBranch = this.handleFilterBranch.bind(this);
    }

    columns = [
        {
            title: "Jenis Pekerjaan",
            field: "jobdesk",
            tooltip: "Jenis pekerjaan admin",
            cellStyle: {
                width: "180px",
                maxWidth: "180px"
            },
            render: rowData =>
                rowData.jobdesk === "operasional"?
                    <p>Manajer Store</p>
                : rowData.jobdesk === "keuangan"?
                    <p>Manajer Keuangan</p>
                : rowData.jobdesk === "inventaris"?
                    <p>Inventaris</p>
                : rowData.jobdesk === "kasir"?
                    <p>Kasir</p>
                : <></>
        },
        { 
            title: "Nama", 
            field: "name",
            tooltip: "Nama admin",
        },
        {
            title: "Email",
            field: "email",
            tooltip: "Email admin",
            cellStyle: {
                width: "250px",
                maxWidth: "250px"
            },
            render: rowData => rowData.email?
                <p>{rowData.email}</p> : <p>-</p> 
        },
        {
            title: "Nomor HP",
            field: "phone",
            tooltip: "Nomor HP admin",
        },
    ]

    actions = [
        {
            icon: () => <FindInPageIcon />,
            tooltip: "Lihat Detail Admin",
            onClick: (event, rowData) => {
                this.props.history.push({
                    pathname: "/admin/" + rowData._id,
                  })
            }
        },
        rowData => ({
            disabled: localStorage.getItem("level") === "1" && rowData.jobdesk !== "operasional"
                && rowData.jobdesk !== "keuangan",
            icon: () => <VpnKeyIcon />,
            tooltip: "Ganti Password",
            onClick: (event, rowData) => {
                localStorage.getItem("level") === "2"?
                    this.handleOpenPassword(rowData)
                : rowData.jobdesk === "operasional" || rowData.jobdesk === "keuangan"?
                    this.handleOpenPassword(rowData)
                : this.setState({
                    openErrorPassword: true,
                });
            }
        }),
        rowData => ({
            disabled: localStorage.getItem("level") === "1" && rowData.jobdesk !== "operasional"
                && rowData.jobdesk !== "keuangan",
            icon: () => <DeleteIcon />,
            tooltip: "Hapus admin",
            onClick: (event, rowData) => {
                this.handleOpen(rowData);
            }
        }),
    ]

    // Show delete modal
    handleOpen(data) {
        this.setState({
            open: true,
            adminId: data._id,
            adminName: data.name,
        })
    }

    // Show change password modal
    handleOpenPassword(data) {
        this.setState({
            openPassword: true,
            adminId: data._id,
            adminName: data.name,
        })
    }

    // CLose modal & snackbar
    handleClose() {
        this.setState({
            open: false,
            openPassword: false,
            openErrorPassword: false,
        })
    }

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }
    
    async handleFilterBranch(branch) {
        this.setState({
            isLoading: true,
            currBranch: branch,
        });
        localStorage.removeItem("tablePage");
        localStorage.removeItem("saved-so");
        localStorage.removeItem("saved-so-store");
        localStorage.setItem("currBranch", JSON.stringify(branch));
        let resp = await getAdmins(branch.value);
        if ( resp[0] && resp[0].status === 200 ) {
            this.setState({
                data: resp[0].data,
            })
        }
        else if ( resp[0] && resp[0].status === 204 ) {
            this.setState({
                data: [],
                errorText: "Tidak ada admin yang ditemukan!",
                openErrorAlert: true,
            })
        }
        else {
            console.log(resp);
            this.setState({
                data: [],
                errorText: "Gagal mem-filter berdasarkan cabang.",
                openErrorAlert: true,
            })
        }
        this.setState({ isLoading: false });
    }

    async getData() {
        this.setState({ isLoading: true });
        let resp = "";
        if ( localStorage.getItem("level") === "1" ) {
            if ( localStorage.getItem("currBranch") ) {
                const branch = JSON.parse(localStorage.getItem("currBranch"));
                resp = await getAdmins(branch.value);
            }
        }
        else {
            resp = await getAdmins();
        }
        if ( resp[0] && resp[0].status === 200 ) {
            let filteredAdmin = resp[0].data.filter(res => res["jobdesk"] !== "master");
            if ( localStorage.getItem("level") === "2" ) {
                filteredAdmin = filteredAdmin.filter(res => res["jobdesk"] !== "operasional");
            }
            let sortedAdmin = filteredAdmin.sort((a,b) => (b.jobdesk > a.jobdesk? 1 : -1)); // sort by jobdesk
            sortedAdmin = sortedAdmin.sort((a,b) => (a.jobdesk === b.jobdesk) ?
                (a.name > b.name? 1 : -1) : b.jobdesk); //sort by name
            this.setState({
                // operasional - keuangan - kasir - inventaris
                data: sortedAdmin,
                isLoading: false,
            })
        }
        else if ( resp[1] && resp[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({
                isLoading: false,
            })
        }
    }

    componentDidMount() {
        if ( localStorage.getItem("path") !== this.props.location.pathname ) {
            localStorage.removeItem("path");
            localStorage.removeItem("search");
            localStorage.removeItem("tablePage");
        }
        this.getData();
    }

    render() {
        return (
            <div className="content">
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""}
                    level={localStorage.getItem("level")} />

                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="delete-admin"
                    id={this.state.adminId}
                    color="secondary"
                    modalTitle="Hapus admin?"
                    dataName={this.state.adminName}
                    submitText="Delete"
                    cancelText="Cancel"
                />
                <Modal
                    open={this.state.openPassword}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="change-password"
                    id={this.state.adminId}
                    modalTitle="Ganti password admin?"
                    dataName={this.state.adminName}
                    submitText="Submit"
                    cancelText="Cancel"
                />
                <Modal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />
                
                <Snackbar open={this.state.openErrorPassword} autoHideDuration={2000} onClose={this.handleClose}>
                    <Alert severity="error">
                        Master hanya dapat ganti password Manajer Store/Keuangan.
                    </Alert>
                </Snackbar>

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                { this.state.isLoading ? ( <Spinner /> ) : <Grid container className="grid-container">
                    {localStorage.getItem("level") === "1"?
                        <Grid item xs={12} className="add-margin-bottom">
                            <Autocomplete
                                className="full-width tf-label"
                                onChange={(_, value) => this.handleFilterBranch(value)}
                                options={localStorage.getItem("branch")?
                                    JSON.parse(localStorage.getItem("branch")) : []
                                }
                                getOptionLabel={(option) => option.label}
                                disableClearable={true}
                                value={this.state.currBranch}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Cabang"
                                        placeholder="Pilih"
                                        variant="outlined"
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />}
                            />
                        </Grid> : <></>
                    }
                    <Grid item xs={12} className="material-table">
                        <Table
                            tableTitle="Tabel Admin"
                            tableColumns={this.columns}
                            data={this.state.data}
                            tableActions={this.actions}
                        />
                    </Grid>
                    <Grid item xs={12} className="table-footer">
                        <NavLink to="/admin-form" style={{ textDecoration: "none" }}>
                            <Button variant="contained" className="add-btn-table">
                                Tambah Admin
                            </Button>
                        </NavLink>
                    </Grid>
                </Grid> }
            </div>
        );
    }
}

export default Admin;