import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { TextInput, DropdownCurrentInput } from "../../../logic/FormInput";
import { getProducts, postTransition } from "../../../logic/APIHandler";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddTransition() {
    
    const numberRegex = /^[0-9]*$/;
    
    // Form State
    const [cart, setCart] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [stockOrigin, setStockOrigin] = useState({ label: "Gudang", value: "warehouse" });

    // Page State
    const history = useHistory();
    const { register, handleSubmit, control, setValue } = useForm();
    const [openJWT, setOpenJWT] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadCalculation, setLoadCalculation] = useState(false);
    const [loadRemove, setLoadRemove] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    },[]);

    // Get list of products
    const getData = async () => {
        setIsLoading(true);
        setValue("origin", { label: "Gudang", value: "warehouse" });
        let resp = await getProducts();
        if ( resp[0] && resp[0].status === 200 ) {
            let temp = [];
            resp[0].data.forEach(res => {
                temp.push({
                    "label": res.product_code + " - " + res.name,
                    "value": res._id,
                    "stock_warehouse": res.stock_warehouse,
                    "stock_store": res.stock_store,
                })
            });
            setProducts(temp);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
        }
        setIsLoading(false);
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Add product to cart / selected products
    const addProductToCart = data => {
        setProducts(products.filter(res => res["value"] !== data.value));
        setCart([...cart, {
            id: data.value,
            name: data.label,
            stock_warehouse: data.stock_warehouse,
            stock_store: data.stock_store,
            qty: 1,
        }]);
    }
    // Remove product from cart / selected products
    const handleRemoveProduct = async (index) => {
        setLoadRemove(true);
        await sleep(10);
        setProducts([
            ...products,
            { 
                "label": cart[index].name,
                "value": cart[index].id,
                "stock_warehouse": cart[index].stock_warehouse,
                "stock_store": cart[index].stock_store,
            }
        ]);
        let updatedCart = [...cart];
        updatedCart.splice(index, 1);
        setCart(updatedCart);
        // Update values in text field
        for (let i=index; i < cart.length-1; i++) {
            setValue(("qty."+i), cart[i+1].qty);
        }
        setLoadRemove(false);
    };

    // Send new data to server
    const onSubmit = async (data) => {
        if ( cart.length > 0 ) {
            setIsLoading(true);
            let resp = await postTransition(data, cart);
            if (resp[0] && resp[0].status === 200) {
                setIsLoading(false);
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.replace("/transisi");
            }
            else if ( resp[1] && resp[1].status === 400 ) {
                setErrorText(resp[1].data[0].msg);
                setOpenErrorAlert(true);
                setIsLoading(false);
            }
            else if ( resp[1] && resp[1].status === 401 ) {
                setIsLoading(false);
                setOpenJWT(true);
            }
            else {
                setErrorText(resp[1].message);
                setOpenErrorAlert(true);
                setIsLoading(false);
            }
        }
        else {
            setErrorText("Pilih minimal 1 jenis produk!");
            setOpenErrorAlert(true);
        }
    }

    return (
        <>
            <div className="content">
                { loadCalculation? ( <Spinner isCalc={true} /> ) : isLoading ? ( <Spinner /> ) : "" }
                <Sidebar
                    sidebarState={(windowWidth < 768)? isSidebarOpen : ""}
                    level={localStorage.getItem("level")}
                />
                
                <Modal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Mentransisikan Stok Produk
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form
                            className="add-form"
                            onKeyPress={e => {
                                if (e.key === "Enter") e.preventDefault();
                            }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <h1 style={{ marginBottom: "2.2rem" }}>
                                Form Transisi
                            </h1>
                            <Grid item xs={12}>
                                <DropdownCurrentInput
                                    label="Asal Stok"
                                    name="origin"
                                    className="full-width add-margin-bottom tf-label"
                                    keepData={async data => {
                                        setLoadCalculation(true);
                                        await sleep(20);
                                        setStockOrigin(data);
                                        setLoadCalculation(false);
                                    }}
                                    disableClearable={true}
                                    control={control}
                                    options={[
                                        { label: "Gudang", value: "warehouse" },
                                        { label: "Toko", value: "store" },
                                    ]}
                                    getOptionLabel={(option) => option.label}
                                    value={stockOrigin}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DropdownCurrentInput
                                    label="Pilih Produk"
                                    name="products"
                                    className="full-width add-margin-bottom tf-label"
                                    keepData={addProductToCart}
                                    disableClearable={false}
                                    control={control}
                                    options={products}
                                    getOptionLabel={(option) => option.label}
                                    value={selectedProduct}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {!loadRemove && cart.length > 0 && cart.map((product, idx) => {
                                    return (
                                        <Grid container spacing={2} key={`product-${idx}`}>
                                            <Grid item xs={7} md={7}>
                                                <TextInput
                                                    label="Produk"
                                                    placeholder="Kode - Nama Produk"
                                                    value={product.name}
                                                    className="full-width add-margin-bottom small-font"
                                                    disabled={true}
                                                />
                                            </Grid>
                                            <Grid item xs={2} md={2}>
                                                <TextInput
                                                    label="Qty"
                                                    name={`qty.${idx}`}
                                                    placeholder="0"
                                                    defaultValue={product.qty}
                                                    onChange={async (event) => {
                                                        setLoadCalculation(true)
                                                        await sleep(20)
                                                        let res = cart;
                                                        let value = event.target.value;
                                                        if (value && numberRegex.test(value)) {
                                                            // Set per item
                                                            if ( value <= 0 ) setValue(("qty."+idx), 1);
                                                            res[idx].qty = value <= 0? 1 : value;
                                                            setCart(res);
                                                        }
                                                        else {
                                                            res[idx].qty = "";
                                                            setValue(("qty."+idx), cart[idx].qty);
                                                        }
                                                        setLoadCalculation(false)
                                                    }}
                                                    className="full-width add-margin-bottom small-font"
                                                    validation={register}
                                                />
                                            </Grid>
                                            <Grid item xs={2} md={2}>
                                                <TextInput
                                                    label={stockOrigin.label === "Gudang"?
                                                        "Stok Gudang" : "Stok Toko"
                                                    }
                                                    placeholder="-"
                                                    value={stockOrigin.label === "Gudang"?
                                                        product.stock_warehouse : product.stock_store
                                                    }
                                                    className="full-width add-margin-bottom small-font"
                                                    disabled={true}
                                                />
                                            </Grid>
                                            <Grid item xs={1} md={1}>
                                                <button
                                                    type="button"
                                                    className="remove-product-btn"
                                                    onClick={() => handleRemoveProduct(idx)}
                                                >X</button>
                                            </Grid>
                                        </Grid>
                                    )
                                })

                                }
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    multiline={true}
                                    rows={2}
                                    label="Keterangan"
                                    name="notes"
                                    placeholder="Keterangan"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                />
                            </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>

                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}