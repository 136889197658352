import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Grid, Button, TextField, Snackbar, Tooltip } from "@material-ui/core";
import ReactExport from "react-data-export";
import MuiAlert from "@material-ui/lab/Alert";
import NumberFormat from 'react-number-format';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import FirstPageOutlinedIcon from '@material-ui/icons/FirstPageOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import LastPageOutlinedIcon from '@material-ui/icons/LastPageOutlined';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import Filter from "../../../components/Filter_Com";
import logo from "../../../assets/img/logo.png";
import { 
    getTransactionsByCategory, getTransactionsByCategoryAndDate,
    getPrintTransactions, getPrintTransactionsByDate,
} from "../../../logic/APIHandler";
import { formatToPrice } from "../../../logic/Handler";
import "../../../styles/ManualTable_Styles.css";
import "../../../styles/Pagination_Styles.css";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class WarehouseSalesReport extends Component {

    constructor(props) {
        super(props);
        // Set Indonesia as moment's location
        let idLocale = require('moment/locale/id'); 
        moment.locale('id', idLocale);
        this.state = {
            data: [],
            excelData: [],
            filenameRange: "",
            openJWT: false,
            isLoading: false,
            isSidebarOpen: 'slide-out',
            openErrorAlert: false,
            errorText: "",
            monthTitle: "(" + moment().format("MMMM YYYY") + ")",
            // Pagination state
            counter: localStorage.getItem("tablePage")?
                parseInt(localStorage.getItem("tablePage")) : 1,
            page: localStorage.getItem("tablePage")?
                parseInt(localStorage.getItem("tablePage")) : 1,
            totalPage: 1,
            filterState: localStorage.getItem("start")? {
                "start": moment(localStorage.getItem("start")).format("YYYY-MM-DD"),
                "end": moment(localStorage.getItem("end")).format("YYYY-MM-DD"),
            } : null,
        }
        this.excelRef = React.createRef();
        this.filterRef = React.createRef();
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handlePagination = this.handlePagination.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCloseError = this.handleCloseError.bind(this);
        this.filterData = this.filterData.bind(this);
        this.downloadExcel = this.downloadExcel.bind(this);
    }

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }

    // Close error toast
    handleCloseError() {
        this.setState({
            openErrorAlert: false,
        });
    };

    async getData() {
        this.setState({ isLoading: true });
        let resp = [];
        if ( localStorage.getItem("start") || localStorage.getItem("end") ) {
            const start = moment(localStorage.getItem("start")).format("YYYY-MM-DD");
            const end = moment(localStorage.getItem("end")).format("YYYY-MM-DD");
            if ( start === end ) {
                this.setState({ filenameRange: " (" + start + ")" });
            }
            else {
                this.setState({ filenameRange: " (" + start + " - " + end + ")" });
            }
            resp = await getTransactionsByCategoryAndDate("sell-warehouse",
                localStorage.getItem("tablePage")? parseInt(localStorage.getItem("tablePage")) : 1, 10, start, end);
        }
        else {
            resp = await getTransactionsByCategory("sell-warehouse", localStorage.getItem("tablePage")?
                parseInt(localStorage.getItem("tablePage")) : 1, 10);
        }
        if ( resp[0] && resp[0].status === 200 ) {
            let totalPage = Math.ceil(resp[0].data.total / 10);
            this.setState({
                data: resp[0].data.transaction,
                totalPage: totalPage,
                isLoading: false,
            })
        }
        else if ( resp[1] && resp[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({
                isLoading: false,
            })
        }
    }

    async handlePagination(event, id) {
        this.setState({ isLoading: true });
        if ( id === "go-to-first" ) {
            let resp = [];
            if ( this.state.filterState ) { 
                resp = await getTransactionsByCategoryAndDate("sell-warehouse", 1, 10,
                    this.state.filterState.start, this.state.filterState.end);
            }
            else {
                resp = await getTransactionsByCategory("sell-warehouse", 1, 10);
            }
            if ( resp[0] && resp[0].status === 200 ) {
                localStorage.setItem("path", this.props.location.pathname);
                localStorage.setItem("tablePage", parseInt(1));
                this.setState({
                    data: resp[0].data.transaction,
                    page: 1,
                    counter: 1,
                    isLoading: false,
                })
            }
            else {
                this.setState({
                    errorText: "Gagal memuat data. Cek kembali koneksi internet Anda & reload page ini.",
                    openErrorAlert: true,
                    isLoading: false,
                })
            }
        }
        else if ( id === "go-to-last" ) {
            let resp = [];
            if ( this.state.filterState ) { 
                resp = await getTransactionsByCategoryAndDate("sell-warehouse", this.state.totalPage, 10,
                    this.state.filterState.start, this.state.filterState.end);
            }
            else {
                resp = await getTransactionsByCategory("sell-warehouse", this.state.totalPage, 10);
            }
            if ( resp[0] && resp[0].status === 200 ) {
                localStorage.setItem("path", this.props.location.pathname);
                localStorage.setItem("tablePage", parseInt(this.state.totalPage));
                this.setState({
                    data: resp[0].data.transaction,
                    page: this.state.totalPage,
                    counter: this.state.totalPage,
                    isLoading: false,
                })
            }
            else {
                this.setState({
                    errorText: "Gagal memuat data. Cek kembali koneksi internet Anda & reload page ini.",
                    openErrorAlert: true,
                    isLoading: false,
                })
            }
        }
        else if ( id === "prev-page" ) {
            let resp = [];
            if ( this.state.filterState ) { 
                resp = await getTransactionsByCategoryAndDate("sell-warehouse", this.state.page-1, 10,
                    this.state.filterState.start, this.state.filterState.end);
            }
            else {
                resp = await getTransactionsByCategory("sell-warehouse", this.state.page-1, 10);
            }
            if ( resp[0] && resp[0].status === 200 ) {
                localStorage.setItem("path", this.props.location.pathname);
                localStorage.setItem("tablePage", parseInt(this.state.page - 1));
                this.setState({
                    data: resp[0].data.transaction,
                    page: this.state.page - 1,
                    counter: this.state.page - 1,
                    isLoading: false,
                })
            }
            else {
                this.setState({
                    errorText: "Gagal memuat data. Cek kembali koneksi internet Anda & reload page ini.",
                    openErrorAlert: true,
                    isLoading: false,
                })
            }
        }
        else if ( id === "next-page" ) {
            let resp = [];
            if ( this.state.filterState ) { 
                resp = await getTransactionsByCategoryAndDate("sell-warehouse", this.state.page+1, 10,
                    this.state.filterState.start, this.state.filterState.end);
            }
            else {
                resp = await getTransactionsByCategory("sell-warehouse", this.state.page+1, 10);
            }
            if ( resp[0] && resp[0].status === 200 ) {
                localStorage.setItem("path", this.props.location.pathname);
                localStorage.setItem("tablePage", parseInt(this.state.page + 1));
                this.setState({
                    data: resp[0].data.transaction,
                    page: this.state.page + 1,
                    counter: this.state.page + 1,
                    isLoading: false,
                })
            }
            else {
                this.setState({
                    errorText: "Gagal memuat data. Cek kembali koneksi internet Anda & reload page ini.",
                    openErrorAlert: true,
                    isLoading: false,
                })
            }
        }
    }

    async handleSubmit(event) {
        this.setState({ isLoading: true });
        event.preventDefault();
        let resp = [];
        let currPage = false;
        if ( this.state.counter < 1 ) {
            if ( this.state.filterState ) resp = await getTransactionsByCategoryAndDate("sell-warehouse", 1, 10,
                this.state.filterState.start, this.state.filterState.end);
            else resp = await getTransactionsByCategory("sell-warehouse", 1, 10);
            currPage = 1;
        }
        else if ( this.state.counter >= this.state.totalPage ) {
            if ( this.state.filterState ) resp = await getTransactionsByCategoryAndDate("sell-warehouse",
                this.state.totalPage, 10, this.state.filterState.start, this.state.filterState.end);
            else resp = await getTransactionsByCategory("sell-warehouse", this.state.totalPage, 10);
            currPage = this.state.totalPage;
        }
        else if ( this.state.counter >= 1 && this.state.counter < this.state.totalPage ) {
            if ( this.state.filterState ) resp = await getTransactionsByCategoryAndDate("sell-warehouse",
                this.state.counter, 10, this.state.filterState.start, this.state.filterState.end);
            else resp = await getTransactionsByCategory("sell-warehouse", this.state.counter, 10);
        }
        if ( resp[0] && resp[0].status === 200 ) {
            localStorage.setItem("path", this.props.location.pathname);
            localStorage.setItem("tablePage", parseInt(currPage? currPage : this.state.counter));
            this.setState({
                data: resp[0].data.transaction,
                counter: currPage? currPage : this.state.counter,
                page: currPage? currPage : this.state.counter,
                isLoading: false,
            })
        }
        else {
            this.setState({
                data: [],
                counter: 1, page: 1,
                errorText: "Gagal memuat data. Cek kembali koneksi internet Anda & reload page ini.",
                openErrorAlert: true,
                isLoading: false,
            })
        }
    }

    // Filter data tabel
    async filterData() {
        this.setState({ isLoading: true });
        localStorage.removeItem("tablePage");
        const ref = this.filterRef.current.state;
        let resp = []; let start = ""; let end = "";
        let canFilter = true;
        // If use filter
        if ( ref.startDate || ref.endDate ) {
            if ( ref.startDate && ref.endDate ) { // Both is filled
                if ( moment(ref.endDate)-moment(ref.startDate) >= 0 ) {
                    start = moment(ref.startDate).format("YYYY-MM-DD");
                    end = moment(ref.endDate).format("YYYY-MM-DD");
                }
                else { // If start > end & vice versa
                    canFilter = false;
                    this.setState({
                        errorText: "Tanggal mulai harus lebih kecil dari tanggal akhir & sebaliknya!",
                        openErrorAlert: true,
                        isLoading: false,
                    })
                }
            }
            else if ( ref.startDate && !ref.endDate ) { // Only start filled
                start = moment(ref.startDate).format("YYYY-MM-DD");
                end = moment(ref.startDate).format("YYYY-MM-DD");
            }
            else if ( !ref.startDate && ref.endDate ) { // Only end filled
                start = moment(ref.endDate).format("YYYY-MM-DD");
                end = moment(ref.endDate).format("YYYY-MM-DD");
            }

            // If no error on input field filter
            if ( canFilter ) {
                resp = await getTransactionsByCategoryAndDate("sell-warehouse", 1, 10, start, end);
                this.setState({
                    filterState: { "start": start, "end": end },
                    filenameRange: (start === end)?
                        " (" + start + ")" : " (" + start + " - " + end + ")",
                })
            }
        }
        // If filter is being cleared
        else {
            resp = await getTransactionsByCategory("sell-warehouse", 1, 10);
            this.setState({ filterState: null });
        }

        // If no error on input field filter
        if ( canFilter ) {
            if ( resp[0] && resp[0].status === 200 ) {
                let totalPage = Math.ceil(resp[0].data.total / 10);
                this.setState({
                    data: resp[0].data.transaction,
                    totalPage: totalPage, counter: 1, page: 1,
                    isLoading: false,
                })
            }
            else {
                this.setState({
                    data: [],
                    totalPage: 1, counter: 1, page: 1,
                    isLoading: false,
                });
            }
        }
    }

    // Create formatted excel data & download it
    async downloadExcel() {
        this.setState({ isLoading: true });
        let resp = [];
        if ( localStorage.getItem("start") || localStorage.getItem("end") ) {
            const start = moment(localStorage.getItem("start")).format("YYYY-MM-DD");
            const end = moment(localStorage.getItem("end")).format("YYYY-MM-DD");
            resp = await getPrintTransactionsByDate("sell-warehouse", start, end);
        }
        else {
            this.setState({ filenameRange: " (" + moment().format("MMMM YYYY") + ")" });
            resp = await getPrintTransactions("sell-warehouse");
        }
        if ( resp[0] && resp[0].status === 200 ) {
            const result = resp[0].data;
            // Create columns & header style
            let res = [
                {
                    columns: [
                        {title: "No", width: {wch: 4}, style: {
                            alignment: { horizontal: "center" },
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                        }}, 
                        {title: "Tanggal", width: {wch: 17}, style: {
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                        }},
                        {title: "Waktu", width: {wch: 8}, style: {
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                        }},
                        {title: "No. Invoice", width: {wch: 28}, style: {
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                        }},
                        {title: "Nama Pembeli", width: {wch: 20}, style: {
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                        }},
                        {title: "Ongkos Kirim", width: {wch: 15}, style: {
                            alignment: { horizontal: "right" },
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                        }},
                        {title: "Pajak", width: {wch: 15}, style: {
                            alignment: { horizontal: "right" },
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                        }},
                        {title: "Total Harga", width: {wch: 15}, style: {
                            alignment: { horizontal: "right" },
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                        }},
                    ],
                    data: [],
                }
            ]
            
            // Add row items to table
            for (let i=0; i < result.length; i++) {
                res[0].data.push([
                    { value: (i+1), style: {alignment: { horizontal: "center" }}},
                    { value: moment(result[i].created_at).format("DD MMMM YYYY") },
                    { value: moment(result[i].created_at).format("HH:mm:ss") },
                    { value: result[i].no_invoice},
                    { value: result[i].details_transaction_warehouse.buyer_name},
                    { 
                        value: formatToPrice(result[i].details_transaction_warehouse.postal_fee),
                        style: { alignment: { horizontal: "right" }}
                    },
                    {
                        value: formatToPrice(result[i].taxes[0]? result[i].taxes[0].total : 0),
                        style: { alignment: { horizontal: "right" }}
                    },
                    {
                        value: formatToPrice(result[i].total_price),
                        style: { alignment: { horizontal: "right" }}
                    },
                ]);
            }

            // Set table data
            this.setState({ excelData: res, isLoading: false }, () => {
                if ( this.excelRef.current !== null ) {
                    this.excelRef.current.click();
                }
            })

        }
        else if ( resp[0] && resp[0].status === 204 ) {
            this.setState({
                errorText: "Tidak ada transaksi yang ditemukan!",
                openErrorAlert: true,
                isLoading: false,
            })
        }
        else {
            this.setState({
                errorText: "Gagal memuat data. Cek kembali koneksi internet Anda.",
                openErrorAlert: true,
                isLoading: false,
            })
        }
    }

    componentDidMount() {
        if ( localStorage.getItem("path") !== this.props.location.pathname ) {
            localStorage.removeItem("path");
            localStorage.removeItem("search");
            localStorage.removeItem("tablePage");
        }
        this.getData();
    }

    render() {
        return (
            <div className="content">
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""} level="3" />

                <Modal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container" style={{ position: "relative" }}>    
                    <Grid item xs={12} className="with-download">
                        <Filter ref={this.filterRef} handleFilter={this.filterData} type="filter-by-date" />
                    </Grid>
                    
                    <Button
                        variant="contained"
                        className="download-btn table"
                        onClick={this.downloadExcel}
                    >
                        <CloudDownloadIcon style={{ marginRight: "10px" }}/>
                        Download Excel
                    </Button>
                    
                    <Snackbar open={this.state.openErrorAlert} autoHideDuration={3000} onClose={this.handleCloseError}>
                        <Alert severity="error">
                            {this.state.errorText}
                        </Alert>
                    </Snackbar>

                    { this.state.isLoading ? ( <Spinner /> ) : <Grid item xs={12} className="add-margin-top">
                        <form onSubmit={this.handleSubmit} className="add-form">
                            <Grid container>
                                <Grid item xs={12} md={11}>
                                    <h1 style={{ marginBottom: "2.2rem" }}>
                                        {`Data Penjualan Barang Gudang ${this.state.filterState?
                                                "" : this.state.monthTitle}`}
                                    </h1>
                                </Grid>
                                <Grid item xs={12} md={1} style={{ pointerEvents: "none" }}>
                                    <ExcelFile 
                                        filename={`Laporan Penjualan Barang Gudang${this.state.filenameRange?
                                            this.state.filenameRange : ""
                                        }`}
                                        element={
                                            <Button innerRef={this.excelRef}></Button>
                                        }
                                    >
                                        <ExcelSheet dataSet={this.state.excelData} name="Laporan"/>
                                    </ExcelFile>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12} style={{ overflowX: 'auto' }}>
                                    <table className="manual-table">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "30px" }}>No</th>
                                                <th>Tanggal & Waktu</th>
                                                <th>No. Invoice</th>
                                                <th>Pembeli</th>
                                                <th>Total Penjualan</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.data.map((res, idx) => ((idx % 2 !== 0)?
                                                <tr className="odd-cell" key={`sell-transaction-${idx}`}>
                                                    <td className="center-cell">
                                                        {this.state.page === 1?
                                                            (idx+1) : ((idx+1)+((this.state.page-1)*10))
                                                        }
                                                    </td>
                                                    <td>{moment(res.created_at).format("DD MMMM YYYY HH:mm:ss")}</td>
                                                    <td>{res.no_invoice}</td>
                                                    <td>{res.details_transaction_warehouse.buyer_name}</td>
                                                    <td className="right-cell">
                                                        <NumberFormat
                                                            value={res.total_price}
                                                            displayType={"text"}
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            prefix={"Rp"}
                                                            suffix=",00"
                                                        />
                                                    </td>
                                                    <td className="center-cell">
                                                        <Tooltip 
                                                            title="Lihat Detil Transaksi"
                                                            placement="bottom" arrow
                                                        >
                                                            <NavLink
                                                                to={`penjualan-barang-gudang/transaksi/${res._id}`}
                                                                style={{ color: "black" }}>
                                                                <FindInPageIcon />
                                                            </NavLink>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                                : <tr className="even-cell" key={`sell-transaction-${idx}`}>
                                                    <td className="center-cell">
                                                        {this.state.page === 1?
                                                            (idx+1) : ((idx+1)+((this.state.page-1)*10))
                                                        }
                                                    </td>
                                                    <td>{moment(res.created_at).format("DD MMMM YYYY HH:mm:ss")}</td>
                                                    <td>{res.no_invoice}</td>
                                                    <td>{res.details_transaction_warehouse.buyer_name}</td>
                                                    <td className="right-cell">
                                                        <NumberFormat
                                                            value={res.total_price}
                                                            displayType={"text"}
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            prefix={"Rp"}
                                                            suffix=",00"
                                                        />
                                                    </td>
                                                    <td className="center-cell">
                                                        <Tooltip 
                                                            title="Lihat Detil Transaksi"
                                                            placement="bottom" arrow
                                                        >
                                                            <NavLink
                                                                to={`penjualan-barang-gudang/transaksi/${res._id}`}
                                                                style={{ color: "black" }}>
                                                                <FindInPageIcon />
                                                            </NavLink>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>

                            <Grid container style={{ marginTop: this.windowWidth < 768? "1rem" : "2.5rem" }}>
                                <Grid item xs={12} md={6} className="manual-table-bottom-btn">
                                    <NavLink to="/transaksi-gudang-form" style={{ textDecoration: "none" }}>
                                        <Button variant="contained" className="add-btn">
                                            Buat Transaksi
                                        </Button>
                                    </NavLink>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="manual-pagination">

                                        <FirstPageOutlinedIcon
                                            id="go-to-first"
                                            style={{
                                                color: this.state.page === 1?
                                                    "#B0B0B0" : "black",
                                                cursor: this.state.page === 1?
                                                    "default" : "pointer",
                                                pointerEvents: this.state.page === 1?
                                                    "none" : "initial",
                                            }}
                                            onClick={(e) => this.handlePagination(e, "go-to-first")}
                                        />
                                        <NavigateBeforeOutlinedIcon
                                            id="prev-page"
                                            style={{
                                                color: this.state.page === 1?
                                                    "#B0B0B0" : "black",
                                                cursor: this.state.page === 1?
                                                    "default" : "pointer",
                                                pointerEvents: this.state.page === 1?
                                                    "none" : "initial",
                                            }}
                                            onClick={(e) => this.handlePagination(e, "prev-page")}
                                        />

                                        <TextField
                                            variant="outlined"
                                            placeholder="-"
                                            value={this.state.counter}
                                            onChange={(e) => {
                                                this.setState({ counter: e.target.value })
                                            }}
                                        />
                                        <span style={{ marginRight: "5px" }}>/</span>
                                        <span className="last-idx">{this.state.totalPage}</span>

                                        <NavigateNextOutlinedIcon
                                            id="next-page"
                                            style={{
                                                color: this.state.page === this.state.totalPage?
                                                    "#B0B0B0" : "black",
                                                cursor: this.state.page === this.state.totalPage?
                                                    "default" : "pointer",
                                                pointerEvents: this.state.page === this.state.totalPage?
                                                    "none" : "initial",
                                            }}
                                            onClick={(e) => this.handlePagination(e, "next-page")}
                                        />
                                        <LastPageOutlinedIcon
                                            id="go-to-last"
                                            style={{
                                                color: this.state.page === this.state.totalPage?
                                                    "#B0B0B0" : "black",
                                                cursor: this.state.page === this.state.totalPage?
                                                    "default" : "pointer",
                                                pointerEvents: this.state.page === this.state.totalPage?
                                                    "none" : "initial",
                                            }}
                                            onClick={(e) => this.handlePagination(e, "go-to-last")}
                                        />

                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid> }
                </Grid>
            </div>
        );
    }
}

export default WarehouseSalesReport;