import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';

// Login Page
import LoginPage from './pages/Login_Page';

// Error Pages
import PageNotFoundPage from './pages/Error/PageNotFound_Page';
import UnauthorizedPage from './pages/Error/Unauthorized_Page';

// Master Pages
import DashboardPage from './pages/Master/Dashboard_Page';
import OverallCashierSalesPage from './pages/Master/Report/OverallCashierSales_Page';
import OverallWarehouseSalesPage from './pages/Master/Report/OverallWarehouseSales_Page';
import SupplierPage from './pages/Master/Supplier/Supplier_Page';
import AddSupplierPage from './pages/Master/Supplier/AddSupplier_Page';
import DetailSupplierPage from './pages/Master/Supplier/DetailSupplier_Page';
import PaymentMethodPage from './pages/Master/Payment/PaymentMethod_Page';
import AddPaymentMethodPage from './pages/Master/Payment/AddPaymentMethod_Page';
import DetailPaymentMethodPage from './pages/Master/Payment/DetailPaymentMethod_Page';
import BranchPage from './pages/Master/Branch/Branch_Page';
import AddBranchPage from './pages/Master/Branch/AddBranch_Page';
import DetailBranchPage from './pages/Master/Branch/DetailBranch_Page';
import GeneratePinPage from './pages/Master/GeneratePin_Page';

// Operasional Pages
import CategoryPage from './pages/Operasional/Category/Category_Page';
import AddCategoryPage from './pages/Operasional/Category/AddCategory_Page';
import StockOpnamePage from './pages/Operasional/StockOpname/StockOpname_Page';
import WarehouseStockOpnamePage from './pages/Operasional/StockOpname/WarehouseStockOpname_Page';
import StockOpnameComparisonPage from './pages/Operasional/StockOpname/StockOpnameComparison_Page';
import WarehouseStockOpnameComparisonPage from './pages/Operasional/StockOpname/WarehouseStockOpnameComparison_Page';
import TaxesPage from './pages/Operasional/Taxes/Taxes_Page';
import AddTaxesPage from './pages/Operasional/Taxes/AddTaxes_Page';
import DetailTaxesPage from './pages/Operasional/Taxes/DetailTaxes_Page';
import DiscountPage from './pages/Operasional/Discount/Discount_Page';
import AddDiscountPage from './pages/Operasional/Discount/AddDiscount_Page';
import DetailDiscountPage from './pages/Operasional/Discount/DetailDiscount_Page';
import TransitionPage from './pages/Operasional/Transition/Transition_Page';
import AddTransitionPage from './pages/Operasional/Transition/AddTransition_Page';
import DetailTransitionPage from './pages/Operasional/Transition/DetailTransition_Page';

// Keuangan Pages
import SessionPage from './pages/Keuangan/Session/Session_Page';
import AddSessionPage from './pages/Keuangan/Session/AddSession_Page';
import DetailSessionPage from './pages/Keuangan/Session/DetailSession_Page';
import CashierSalesReportPage from './pages/Keuangan/Report/CashierSalesReport_Page';
import WarehouseSalesReportPage from './pages/Keuangan/Report/WarehouseSalesReport_Page';
// import OverallPurchaseReportPage from './pages/Keuangan/Report/OverallPurchaseReport_Page';
import IncomeStatementPage from './pages/Keuangan/Report/IncomeStatement_Page';
import ExtraExpensePage from './pages/Keuangan/ExtraExpense/ExtraExpense_Page';
import AddExtraExpensePage from './pages/Keuangan/ExtraExpense/AddExtraExpense_Page';
import DetailExtraExpensePage from './pages/Keuangan/ExtraExpense/DetailExtraExpense_Page';
import AddWarehouseTransactionPage from './pages/Keuangan/Transaction/AddWarehouseTransaction_Page';

// Master & Operational Pages
import AdminPage from './pages/Master/Admin/Admin_Page';
import AddAdminPage from './pages/Master/Admin/AddAdmin_Page';
import DetailAdminPage from './pages/Master/Admin/DetailAdmin_Page';

// Operational & Inventaris Pages
import OverallStockPage from './pages/Operasional/StockOpname/OverallStock_Page';
import OverallStockWarehousePage from './pages/Operasional/StockOpname/OverallStockWarehouse_Page';
import DetailStockHistoryPage from './pages/Operasional/StockHistory/DetailStockHistory_Page';
import DetailTransactionPage from './pages/Keuangan/Transaction/DetailTransaction_Page';
// import StockHistoryPage from './pages/Operasional/StockHistory/StockHistory_Page';
// import ReturPage from './pages/Operasional/StockHistory/Retur_Page';

// Master & Operational & Inventaris Pages
import ExportDBPage from './pages/Master/ExportDB_Page';
import ProductPage from './pages/Master/Product/Product_Page';
import AddProductPage from './pages/Master/Product/AddProduct_Page';
import DetailProductPage from './pages/Master/Product/DetailProduct_Page';

// Master & Operational & Keuangan Pages
import RequestPage from './pages/Master/Request/Request_Page';
import AddRequestPage from './pages/Master/Request/AddRequest_Page';
import DetailRequestPage from './pages/Master/Request/DetailRequest_Page';

// All Users Pages
import LossesPage from './pages/Operasional/Losses/Losses_Page';
import AddLossesPage from './pages/Operasional/Losses/AddLosses_Page';
import DetailLossesPage from './pages/Operasional/Losses/DetailLosses_Page';
import ChangePasswordPage from './pages/ChangePassword_Page';

// CSS
import './styles/Colour_Styles.css';
import './styles/Default_Styles.css';
import './styles/Content_Styles.css';
import './styles/Table_Styles.css';

function App() {
  return (
    <Router>
      <Switch>

        {/* Base Route */}
        <Route exact path="/" component={LoginPage}/>
        
        {/* Master Route */}
        <PrivateRoute exact path="/dashboard" component={DashboardPage} access={["1"]} />
        <PrivateRoute exact path="/data-penjualan-kasir" component={OverallCashierSalesPage} access={["1"]} />
        <PrivateRoute exact path="/data-penjualan-gudang" component={OverallWarehouseSalesPage} access={["1"]} />
        <PrivateRoute exact path="/supplier" component={SupplierPage} access={["1"]} />
        <PrivateRoute exact path="/supplier-form" component={AddSupplierPage} access={["1"]} />
        <PrivateRoute exact path="/supplier/:id?" component={DetailSupplierPage} access={["1"]} />
        <PrivateRoute exact path="/metode-pembayaran" component={PaymentMethodPage} access={["1"]} />
        <PrivateRoute exact path="/metode-pembayaran-form" component={AddPaymentMethodPage} access={["1"]} />
        <PrivateRoute exact path="/metode-pembayaran/:id?" component={DetailPaymentMethodPage} access={["1"]} />
        <PrivateRoute exact path="/generate-pin" component={GeneratePinPage} access={["1"]} />
        <PrivateRoute exact path="/cabang" component={BranchPage} access={["1"]} />
        <PrivateRoute exact path="/cabang-form" component={AddBranchPage} access={["1"]} />
        <PrivateRoute exact path="/cabang/:id?" component={DetailBranchPage} access={["1"]} />

        {/* Operasional Route */}
        <PrivateRoute exact path="/pajak" component={TaxesPage} access={["2"]} />
        <PrivateRoute exact path="/pajak-form" component={AddTaxesPage} access={["2"]} />
        <PrivateRoute exact path="/pajak/:id?" component={DetailTaxesPage} access={["2"]} />
        <PrivateRoute exact path="/diskon" component={DiscountPage} access={["2"]} />
        <PrivateRoute exact path="/diskon-form" component={AddDiscountPage} access={["2"]} />
        <PrivateRoute exact path="/diskon/:id?" component={DetailDiscountPage} access={["2"]} />
        <PrivateRoute exact path="/kategori-produk" component={CategoryPage} access={["2"]} />
        <PrivateRoute exact path="/kategori-produk-form" component={AddCategoryPage} access={["2"]} />

        {/* Keuangan Route */}
        <PrivateRoute exact path="/sesi-kasir" component={SessionPage} access={["3"]} />
        <PrivateRoute exact path="/sesi-kasir-form" component={AddSessionPage} access={["3"]} />
        <PrivateRoute exact path="/sesi-kasir/:id?" component={DetailSessionPage} access={["3"]} />
        <PrivateRoute exact path="/penjualan-barang-kasir" component={CashierSalesReportPage} access={["3"]} />
        <PrivateRoute exact path="/penjualan-barang-kasir/transaksi/:id?" component={DetailTransactionPage} access={["3"]} />
        <PrivateRoute exact path="/penjualan-barang-gudang" component={WarehouseSalesReportPage} access={["3"]} />
        <PrivateRoute exact path="/penjualan-barang-gudang/transaksi/:id?" component={DetailTransactionPage} access={["3"]} />
        {/* <PrivateRoute exact path="/pembelian-barang" component={OverallPurchaseReportPage} access={["3"]} /> */}
        <PrivateRoute exact path="/pembelian-barang/transaksi/:id?" component={DetailTransactionPage} access={["3"]} />
        <PrivateRoute exact path="/laporan-laba-rugi" component={IncomeStatementPage} access={["3"]} />
        <PrivateRoute exact path="/laporan-laba-rugi/transaksi/:id?" component={DetailTransactionPage} access={["3"]} />
        <PrivateRoute exact path="/laporan-laba-rugi/pengeluaran-tambahan/:id?" component={DetailExtraExpensePage} access={["3"]} />
        <PrivateRoute exact path="/pengeluaran-tambahan" component={ExtraExpensePage} access={["3"]} />
        <PrivateRoute exact path="/pengeluaran-tambahan-form" component={AddExtraExpensePage} access={["3"]} />
        <PrivateRoute exact path="/pengeluaran-tambahan/:id?" component={DetailExtraExpensePage} access={["3"]} />
        <PrivateRoute exact path="/transaksi-gudang-form" component={AddWarehouseTransactionPage} access={["3"]} />

        {/* Master & Operasional Route */}
        <PrivateRoute exact path="/admin" component={AdminPage} access={["2","1"]} />
        <PrivateRoute exact path="/admin-form" component={AddAdminPage} access={["2","1"]} />
        <PrivateRoute exact path="/admin/:id?" component={DetailAdminPage} access={["2","1"]} />
        <PrivateRoute exact path="/stock-opname-toko" component={StockOpnamePage} access={["2","1"]} />
        <PrivateRoute exact path="/stock-opname-gudang" component={WarehouseStockOpnamePage} access={["2","1"]} />
        <PrivateRoute exact path="/stock-opname-compare-toko" component={StockOpnameComparisonPage} access={["2","1"]} />
        <PrivateRoute exact path="/stock-opname-compare-gudang" component={WarehouseStockOpnameComparisonPage} access={["2","1"]} />
        
        {/* Operasional & Inventaris Route */}
        <PrivateRoute exact path="/produk-form" component={AddProductPage} access={["4","2"]} />
        <PrivateRoute exact path="/transisi-form" component={AddTransitionPage} access={["4","2"]} />

        {/* Operasional & Inventaris & Keuangan Route */}
        {/* <PrivateRoute exact path="/retur" component={ReturPage} access={["4","3","2"]} />
        <PrivateRoute exact path="/barang-retur" component={StockHistoryPage} access={["4","3","2"]} />
        <PrivateRoute exact path="/barang-retur/:id?" component={DetailStockHistoryPage} access={["4","3","2"]} />
        <PrivateRoute exact path="/barang-retur/transaksi/:id?" component={DetailTransactionPage} access={["4","3","2"]} />
        <PrivateRoute exact path="/barang-retur/produk/:id?" component={DetailProductPage} access={["4","3","2"]} />
        <PrivateRoute exact path="/barang-retur/produk/transaksi/:id?" component={DetailTransactionPage} access={["4","3","2"]} /> */}

        {/* Master & Operasional & Inventaris Route */}
        <PrivateRoute exact path="/produk/export-database" component={ExportDBPage} access={["4","2","1"]} />
        <PrivateRoute exact path="/losses/export-database" component={ExportDBPage} access={["4","2","1"]} />
        <PrivateRoute exact path="/transisi" component={TransitionPage} access={["4","2","1"]} />
        <PrivateRoute exact path="/transisi/:id?" component={DetailTransitionPage} access={["4","2","1"]} />
        <PrivateRoute exact path="/stok-keseluruhan-toko" component={OverallStockPage} access={["4","2","1"]} />
        <PrivateRoute exact path="/stok-keseluruhan-gudang" component={OverallStockWarehousePage} access={["4","2","1"]} />

        {/* Master & Operasional & Keuangan Route */}
        <PrivateRoute exact path="/request" component={RequestPage} access={["3","2","1"]} />
        <PrivateRoute exact path="/request-form" component={AddRequestPage} access={["3","2","1"]} />
        <PrivateRoute exact path="/request/:id?" component={DetailRequestPage} access={["3","2","1"]} />

        {/* All Users Route */}
        <PrivateRoute exact path="/produk" component={ProductPage} access={["4","3","2","1"]} />
        <PrivateRoute exact path="/produk/:id?" component={DetailProductPage} access={["4","3","2","1"]} />
        <PrivateRoute exact path="/produk/transaksi/:id?" component={DetailTransactionPage} access={["4","3","2","1"]} />
        <PrivateRoute exact path="/produk/riwayat-stok/:id?" component={DetailStockHistoryPage} access={["4","3","2","1"]} />
        <PrivateRoute exact path="/produk/riwayat-stok/transaksi/:id?" component={DetailTransactionPage} access={["4","3","2","1"]} />
        <PrivateRoute exact path="/losses" component={LossesPage} access={["4","3","2","1"]} />
        <PrivateRoute exact path="/losses-form" component={AddLossesPage} access={["4","3","2","1"]} />
        <PrivateRoute exact path="/losses/:id?" component={DetailLossesPage} access={["4","3","2","1"]} />
        <PrivateRoute exact path="/ganti-password" component={ChangePasswordPage} access={["5","4","3","2","1"]} />

        {/* Unauthorized Route */}
        <Route exact path="/unauthorized" component={UnauthorizedPage} />
        
        {/* Non-exist Route */}
        <Route path="*" exact={true} component={PageNotFoundPage} />

      </Switch>
    </Router>
  );
}

export default App;
