import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import MuiAlert from "@material-ui/lab/Alert";
import { Autocomplete } from "@material-ui/lab";
import { Grid, Button, TextField, Snackbar } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Table from '../../../components/Table_Com';
import Modal from '../../../components/Modal_Com';
import ExcelCom from "../../../components/Excel_Com";
import logo from "../../../assets/img/logo.png";
import { getProducts, getProductsByParams } from "../../../logic/APIHandler";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class OverallStock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            open: false,
            openAdjust: false,
            openRestock: false,
            openJWT: false,
            openErrorAlert: false,
            errorText: "",
            currStock: 0,
            productId: '00000000',
            productName: '',
            currBranch:
                localStorage.getItem("level") !== "1"? null
                : localStorage.getItem("currBranch")? JSON.parse(localStorage.getItem("currBranch"))
                : null,
            isLoading: false,
            isSidebarOpen: 'slide-out',
        }
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseError = this.handleCloseError.bind(this);
        this.handleFilterBranch = this.handleFilterBranch.bind(this);
    }

    columns = [
        { 
            title: "Kode", 
            field: "product_code",
            tooltip: "Kode produk",
            cellStyle: {
                width: "150px",
                maxWidth: "150px"
            },
        },
        {
            title: "Nama",
            field: "name",
            tooltip: "Nama produk",
        },
        { 
            title: "Stok Toko", 
            field: "stock_store",
            tooltip: "Stok produk di toko",
        },
    ]
    
    actions = [
        {
            icon: () => <FindInPageIcon />,
            tooltip: "Lihat Detail Produk",
            onClick: (event, rowData) => {
                localStorage.removeItem("tablePageLog");
                localStorage.removeItem("tablePageLogWR");
                this.props.history.push({
                    pathname: "/produk/" + rowData._id,
                })
            }
        },
    ]

    // Show delete modal
    handleOpen(data) {
        this.setState({
            open: true,
            productId: data._id,
            productName: data.name,
        })
    }
    handleOpenAdjust(data) {
        this.setState({
            openAdjust: true,
            productId: data._id,
            productName: data.name,
            currStock: data.stock,
        })
    }
    handleOpenRestock(data) {
        this.setState({
            openRestock: true,
            productId: data._id,
            productName: data.name,
            currStock: data.stock,
        })
    }
    // CLose modal & snackbar
    handleClose() {
        this.setState({
            open: false,
            openAdjust: false,
            openRestock: false,
        })
    }
    // Close error toast
    handleCloseError() {
        this.setState({
            openErrorAlert: false,
        });
    };

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }

    async getData() {
        this.setState({ isLoading: true });
        let resp = "";
        if ( localStorage.getItem("level") === "1" ) {
            resp = await getProductsByParams(
                JSON.parse(localStorage.getItem("currBranch")).value,
                false
            );            
        }
        else {
            resp = await getProducts();
        }
        if ( resp[0] && resp[0].status === 200 ) {
            this.setState({
                data: resp[0].data,
                isLoading: false,
            })
        }
        else if ( resp[1] && resp[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({
                isLoading: false,
            })
        }
    }
    
    async handleFilterBranch(branch) {
        this.setState({
            isLoading: true,
            currBranch: branch,
        });
        localStorage.removeItem("tablePage");
        localStorage.removeItem("saved-so");
        localStorage.removeItem("saved-so-store");
        localStorage.setItem("currBranch", JSON.stringify(branch));
        let resp = await getProductsByParams(branch.value, false);
        if ( resp[0] && resp[0].status === 200 ) {
            this.setState({
                data: resp[0].data,
            })
        }
        else if ( resp[0] && resp[0].status === 204 ) {
            this.setState({
                data: [],
                errorText: "Tidak ada produk yang ditemukan!",
                openErrorAlert: true,
            })
        }
        else {
            console.log(resp);
            this.setState({
                data: [],
                errorText: "Gagal mem-filter berdasarkan cabang.",
                openErrorAlert: true,
            })
        }
        this.setState({ isLoading: false });
    }

    componentDidMount() {
        if ( localStorage.getItem("path") !== this.props.location.pathname ) {
            localStorage.removeItem("path");
            localStorage.removeItem("search");
            localStorage.removeItem("tablePage");
        }
        this.getData();
    }

    render() {
        return (
            <div className="content">
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""}
                    level={localStorage.getItem("level")} />

                <Modal
                    open={this.state.openAdjust}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="update-stock"
                    id={this.state.productId}
                    modalTitle="Penyesuaian Stok Produk"
                    dataName={this.state.productName}
                    currStock={this.state.currStock? this.state.currStock : "-"}
                    submitText="Submit"
                    cancelText="Cancel"
                />
                <Modal
                    open={this.state.openRestock}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="restock"
                    id={this.state.productId}
                    modalTitle="Restock Produk"
                    dataName={this.state.productName}
                    currStock={this.state.currStock? this.state.currStock : "-"}
                    submitText="Submit"
                    cancelText="Cancel"
                />
                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="delete-product"
                    id={this.state.productId}
                    color="secondary"
                    modalTitle="Hapus produk?"
                    dataName={this.state.productName}
                    submitText="Delete"
                    cancelText="Cancel"
                />
                <Modal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>
                
                <Snackbar open={this.state.openErrorAlert} autoHideDuration={1500} onClose={this.handleCloseError}>
                    <Alert severity="error">
                        {this.state.errorText}
                    </Alert>
                </Snackbar>

                { this.state.isLoading ? ( <Spinner /> ) : <Grid container className="grid-container">
                    {localStorage.getItem("level") === "4"?
                        <Grid item xs={12} style={{ marginTop: "-25px" }}>
                            <ExcelCom
                                type="store"
                                label="Download Seluruh Stok"
                                excelTitle={localStorage.getItem("level") !== "1"?
                                    "Stok Toko Keseluruhan" :
                                    `Stok Toko Keseluruhan - ${JSON.parse(localStorage.getItem("currBranch")).label}`
                                }
                                sheetTitle="Data Stok Produk"
                            />
                        </Grid> : <></>
                    }
                    {localStorage.getItem("level") === "1"?
                        <Grid item xs={12} className="add-margin-bottom">
                            <Autocomplete
                                className="full-width tf-label"
                                onChange={(_, value) => this.handleFilterBranch(value)}
                                options={localStorage.getItem("branch")?
                                    JSON.parse(localStorage.getItem("branch")) : []
                                }
                                getOptionLabel={(option) => option.label}
                                disableClearable={true}
                                value={this.state.currBranch}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Cabang"
                                        placeholder="Pilih"
                                        variant="outlined"
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />}
                            />
                        </Grid> : <></>
                    }
                    <Grid item xs={12} className="material-table add-margin-top">
                        <Table
                            tableTitle="Tabel Stok Toko"
                            tableColumns={this.columns}
                            data={this.state.data}
                            tableActions={this.actions}
                        />
                    </Grid>
                    {localStorage.getItem("level") === "1" || localStorage.getItem("level") === "2"?
                        <Grid item xs={12} className="table-footer">
                            <NavLink to="/stock-opname-toko" style={{ textDecoration: "none" }}>
                                <Button variant="contained" className="add-btn-table">
                                    Stock Opname
                                </Button>
                            </NavLink>
                        </Grid> : <></>
                    }
                </Grid> }
            </div>
        );
    }
}

export default OverallStock;