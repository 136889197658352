import React, { Component } from "react";
import { Grid, Button, } from "@material-ui/core";
import ReactExport from "react-data-export";
import NumberFormat from "react-number-format";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Table from '../../../components/Table_Com';
import Modal from '../../../components/Modal_Com';
import Filter from '../../../components/Filter_Com';
import logo from "../../../assets/img/logo.png";
import { getIncomeStatement, getIncomeStatementReport } from "../../../logic/APIHandler";
import {
    createCashierReportExcel,
    createExtraExpenseReportExcel,
    createOverallReportExcel,
    // createPurchaseReportExcel,
    createWarehouseReportExcel
} from "../../../logic/Handler";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class IncomeStatement extends Component {

    constructor(props) {
        super(props);
        // Set Indonesia as moment's location
        let idLocale = require('moment/locale/id'); 
        moment.locale('id', idLocale);
        this.state = {
            totalPrice: 0,
            expenseData: [],
            transactionData: [],
            // Cards State
            totalIncome: 0,
            totalSales: 0,
            totalPurchase: 0,
            totalDiscount: 0,
            totalTaxes: 0,
            totalExpense: 0,
            // Page State
            open: false,
            openJWT: false,
            isLoading: false,
            isSidebarOpen: 'slide-out',
            filterState: localStorage.getItem("start")? {
                "start": moment(localStorage.getItem("start")).format("YYYY-MM-DD"),
                "end": moment(localStorage.getItem("end")).format("YYYY-MM-DD"),
            } : null,
            // Excel State
            filenameRange: "",
            overallExcel: [],
        }
        this.excelRef = React.createRef();
        this.filterRef = React.createRef();
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.filterData = this.filterData.bind(this);
        this.downloadExcel = this.downloadExcel.bind(this);
    }

    columnsTrx = [
        { 
            title: "Kategori", 
            field: "category",
            tooltip: "Kategori transaksi",
            render: rowData =>
                rowData.category === "buy"? <p>Pembelian</p>
                : rowData.category === "sell"? <p>Penjualan</p>
                : <p>Penjualan Gudang</p>
        },
        {
            title: "Tanggal",
            field: "date",
            tooltip: "Tanggal transaksi",
            render: rowData =>
                <p>{moment(rowData.date).format("DD MMMM YYYY")}</p>
        },
        {
            title: "No. Invoice", 
            field: "no_invoice",
            tooltip: "Nomor invoice transaksi",
        },
        {
            title: "Total Diskon",
            field: "total_discount",
            tooltip: "Total diskon pada tiap transaksi",
            render: rowData =>
                <p>
                    <NumberFormat
                        value={rowData.total_discount}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix={"Rp "}
                        suffix=""
                    />
                </p>
        },
        {
            title: "Total Harga",
            field: "total_price",
            tooltip: "Total harga pada tiap transaksi",
            render: rowData =>
                <p>
                    <NumberFormat
                        value={rowData.total_price}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix={"Rp "}
                        suffix=""
                    />
                </p>
        },
    ]
    columnsExp = [
        {
            title: "Tanggal",
            field: "date",
            tooltip: "Tanggal pembuatan pengeluaran tambahan",
            render: rowData =>
                <p>{moment(rowData.created_at).format("DD MMMM YYYY")}</p>
        },
        {
            title: "Keterangan", 
            field: "notes",
            tooltip: "Keterangan pengeluaran tambahan",
        },
        {
            title: "Total Pengeluaran",
            field: "total_price",
            tooltip: "Total pengeluaran tambahan",
            render: rowData =>
                <p>
                    <NumberFormat
                        value={rowData.total_price}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix={"Rp "}
                        suffix=""
                    />
                </p>
        },
    ]

    actionsTrx = [
        {
            icon: () => <FindInPageIcon />,
            tooltip: "Lihat Detail Transaksi",
            onClick: (event, rowData) => {
                this.props.history.push({
                    pathname: "/laporan-laba-rugi/transaksi/" + rowData._id,
                })
            }
        },
    ]
    actionsExp = [
        {
            icon: () => <FindInPageIcon />,
            tooltip: "Lihat Detail Transaksi",
            onClick: (event, rowData) => {
                this.props.history.push({
                    pathname: "/laporan-laba-rugi/pengeluaran-tambahan/" + rowData._id,
                })
            }
        },
    ]

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }

    // Filter data table
    async filterData() {
        this.setState({ isLoading: true });
        localStorage.removeItem("tablePage");
        const ref = this.filterRef.current.state;
        let resp = []; let start = ""; let end = "";
        let canFilter = true;
        // If use filter
        if ( ref.startDate || ref.endDate ) {
            if ( ref.startDate && ref.endDate ) { // Both is filled
                if ( moment(ref.endDate)-moment(ref.startDate) >= 0 ) {
                    start = moment(ref.startDate).format("YYYY-MM-DD");
                    end = moment(ref.endDate).format("YYYY-MM-DD");
                }
                else { // If start > end & vice versa
                    canFilter = false;
                    this.setState({
                        errorText: "Tanggal mulai harus lebih kecil dari tanggal akhir & sebaliknya!",
                        openErrorAlert: true,
                        isLoading: false,
                    })
                }
            }
            else if ( ref.startDate && !ref.endDate ) { // Only start filled
                start = moment(ref.startDate).format("YYYY-MM-DD");
                end = moment(ref.startDate).format("YYYY-MM-DD");
            }
            else if ( !ref.startDate && ref.endDate ) { // Only end filled
                start = moment(ref.endDate).format("YYYY-MM-DD");
                end = moment(ref.endDate).format("YYYY-MM-DD");
            }

            // If no error on input field filter
            if ( canFilter ) {
                resp = await getIncomeStatement(start, end);
                this.setState({
                    filterState: { "start": start, "end": end },
                    filenameRange: (start === end)?
                        " (" + start + ")" : " (" + start + " - " + end + ")",
                })
            }
        }
        // If filter is being cleared
        else {
            resp = await getIncomeStatement(
                moment().startOf('month').format('YYYY-MM-DD'),
                moment().endOf('month').format('YYYY-MM-DD')
            );
            this.setState({ filterState: null });
        }

        // If no error on input field filter
        if ( canFilter ) {
            if ( resp[0] && resp[0].status === 200 ) {
                let sellTransactions = resp[0].data.sell_details.detail_transaction;
                let buyTransactions = resp[0].data.buy_details.detail_transaction;
                let transactions = [...sellTransactions, ...buyTransactions];
    
                this.setState({
                    totalIncome: resp[0].data.total_income,
                    totalSales: resp[0].data.sell_details.total_all_price,
                    totalPurchase: resp[0].data.buy_details.total_all_price,
                    totalDiscount: resp[0].data.sell_details.total_all_discount
                        + resp[0].data.buy_details.total_all_discount,
                    totalTaxes: resp[0].data.total_taxes,
                    totalExpense: resp[0].data.extra_expenses_details.total_all_price,
                    transactionData: transactions,
                    expenseData: resp[0].data.extra_expenses_details.detail_extra_expenses,
                    isLoading: false,
                })
            }
            else {
                this.setState({
                    totalIncome: 0,
                    totalSales: 0,
                    totalPurchase: 0,
                    totalDiscount: 0,
                    totalTaxes: 0,
                    totalExpense: 0,
                    transactionData: [],
                    expenseData: [],
                    isLoading: false,
                });
            }
        }
    }

    async getData() {
        this.setState({ isLoading: true });
        let start = "", end = "";
        if ( localStorage.getItem("start") || localStorage.getItem("end") ) {
            start = moment(localStorage.getItem("start")).format("YYYY-MM-DD");
            end = moment(localStorage.getItem("end")).format("YYYY-MM-DD");
            if ( start === end ) {
                this.setState({ filenameRange: " (" + start + ")" });
            }
            else {
                this.setState({ filenameRange: " (" + start + " - " + end + ")" });
            }
        }
        else {
            start = moment().startOf('month').format('YYYY-MM-DD');
            end = moment().endOf('month').format('YYYY-MM-DD');
        }
        let resp = await getIncomeStatement(start, end);
        if ( resp[0] && resp[0].status === 200 ) {
            let sellTransactions = resp[0].data.sell_details.detail_transaction;
            let buyTransactions = resp[0].data.buy_details.detail_transaction;
            let transactions = [...sellTransactions, ...buyTransactions];

            this.setState({
                totalIncome: resp[0].data.total_income,
                totalSales: resp[0].data.sell_details.total_all_price,
                totalPurchase: resp[0].data.buy_details.total_all_price,
                totalDiscount: resp[0].data.sell_details.total_all_discount
                    + resp[0].data.buy_details.total_all_discount,
                totalTaxes: resp[0].data.total_taxes,
                totalExpense: resp[0].data.extra_expenses_details.total_all_price,
                transactionData: transactions,
                expenseData: resp[0].data.extra_expenses_details.detail_extra_expenses,
                isLoading: false,
            })
        }
        else if ( resp[1] && resp[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({
                isLoading: false,
            })
        }
    }

    // Create formatted excel data & download it
    async downloadExcel() {
        this.setState({ isLoading: true });
        let resp = [];
        let start = "", end = "";
        if ( localStorage.getItem("start") || localStorage.getItem("end") ) {
            start = moment(localStorage.getItem("start")).format("YYYY-MM-DD");
            end = moment(localStorage.getItem("end")).format("YYYY-MM-DD");
        }
        else {
            start = moment().startOf('month').format('YYYY-MM-DD');
            end = moment().endOf('month').format('YYYY-MM-DD');
            this.setState({ filenameRange: " (" + moment().format("MMMM YYYY") + ")" });
        }
        resp = await getIncomeStatementReport(start, end);
 
        if ( resp[0] && resp[0].status === 200 ) {
            const result = resp[0].data;

            // Set excel base format
            let excelResult = [];
            excelResult.push({ dataSet: [], name: "Laporan Keseluruhan" });
            result.sell_cashier.forEach(cashier => {
                excelResult.push({ dataSet: [], name: cashier.cashier });
            });
            excelResult.push({ dataSet: [], name: "Penjualan Warehouse" });
            // excelResult.push({ dataSet: [], name: "Pembelian Barang" });
            excelResult.push({ dataSet: [], name: "Pengeluaran Tambahan" });
            
            let i = 0;
            excelResult.forEach(data => {
                if ( data.name.includes("Keseluruhan") ) {
                    data["dataSet"] = createOverallReportExcel(result);
                }
                else if ( data.name.includes("Warehouse") ) {
                    data["dataSet"] = createWarehouseReportExcel(result.sell_warehouse);
                }
                // else if ( data.name.includes("Pembelian") ) {
                //     data["dataSet"] = createPurchaseReportExcel(result.buy);
                // }
                else if ( data.name.includes("Pengeluaran") ) {
                    data["dataSet"] = createExtraExpenseReportExcel(result.extra_expenses);
                }
                else {
                    data["dataSet"] = createCashierReportExcel(result.sell_cashier[i].detail_transaction);
                    i++;
                }
            });

            this.setState({ overallExcel: excelResult, isLoading: false, }, () => {
                if ( this.excelRef.current !== null ) {
                    this.excelRef.current.click();
                }
            });
        }
        else {
            this.setState({
                errorText: "Gagal memuat data. Cek kembali koneksi internet Anda.",
                openErrorAlert: true,
                isLoading: false,
            })
        }
    }

    componentDidMount() {
        if ( localStorage.getItem("path") !== this.props.location.pathname ) {
            localStorage.removeItem("path");
            localStorage.removeItem("search");
            localStorage.removeItem("tablePage");
        }
        this.getData();
    }

    render() {
        return (
            <div className="content">
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""} level="3" />

                <Modal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container" style={{ position: "relative" }}>
                    <Grid item xs={12} className="with-download add-margin-bottom">
                        <Filter ref={this.filterRef} handleFilter={this.filterData} type="filter-by-date" />
                    </Grid>

                    <Button
                        variant="contained"
                        className="download-btn table"
                        onClick={this.downloadExcel}
                    >
                        <CloudDownloadIcon style={{ marginRight: "10px" }} />
                        Download Excel
                    </Button>

                    <Grid item xs={12}>
                    { this.state.isLoading ? ( <Spinner /> ) :
                        <Grid container spacing={2}>
                        <Grid item xs={12} md={6} className="income-state-card">
                            <Grid container>
                                <Grid item xs={1} className="colored-card"></Grid>
                                <Grid item xs={11} className="income-card-content">
                                    <h3>TOTAL PENJUALAN</h3>
                                    <h2>
                                        <NumberFormat
                                            value={this.state.totalSales}
                                            displayType={"text"}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix={"Rp "}
                                            suffix=",00"
                                        />
                                    </h2>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} md={6} className="income-state-card">
                            <Grid container>
                                <Grid item xs={1} className="colored-card"></Grid>
                                <Grid item xs={11} className="income-card-content">
                                    <h3>TOTAL PENDAPATAN</h3>
                                    <h2>
                                        <NumberFormat
                                            value={this.state.totalIncome}
                                            displayType={"text"}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix={"Rp "}
                                            suffix=",00"
                                        />
                                    </h2>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        {/* <Grid item xs={12} md={6} className="income-state-card">
                            <Grid container>
                                <Grid item xs={1} className="colored-card"></Grid>
                                <Grid item xs={11} className="income-card-content">
                                    <h3>TOTAL PEMBELIAN BARANG</h3>
                                    <h2>
                                        <NumberFormat
                                            value={this.state.totalPurchase}
                                            displayType={"text"}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix={"Rp "}
                                            suffix=",00"
                                        />
                                    </h2>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        <Grid item xs={12} md={6} className="income-state-card">
                            <Grid container>
                                <Grid item xs={1} className="colored-card"></Grid>
                                <Grid item xs={11} className="income-card-content">
                                    <h3>TOTAL DISKON</h3>
                                    <h2>
                                        <NumberFormat
                                            value={this.state.totalDiscount}
                                            displayType={"text"}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix={"Rp "}
                                            suffix=",00"
                                        />
                                    </h2>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} className="income-state-card">
                            <Grid container>
                                <Grid item xs={1} className="colored-card"></Grid>
                                <Grid item xs={11} className="income-card-content">
                                    <h3>TOTAL PENGELUARAN TAMBAHAN</h3>
                                    <h2>
                                        <NumberFormat
                                            value={this.state.totalExpense}
                                            displayType={"text"}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix={"Rp "}
                                            suffix=",00"
                                        />
                                    </h2>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} className="income-state-card">
                            <Grid container>
                                <Grid item xs={1} className="colored-card"></Grid>
                                <Grid item xs={11} className="income-card-content">
                                    <h3>TOTAL PAJAK</h3>
                                    <h2>
                                        <NumberFormat
                                            value={this.state.totalTaxes}
                                            displayType={"text"}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix={"Rp "}
                                            suffix=",00"
                                        />
                                    </h2>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className="material-table add-margin-top">
                            <Table
                                tableTitle={`Tabel transaksi ${this.state.filterState?
                                    "" : "(" + moment().format("MMMM YYYY") + ")"}`}
                                tableColumns={this.columnsTrx}
                                tableActions={this.actionsTrx}
                                data={this.state.transactionData}
                            />
                        </Grid>
                        <Grid item xs={12} className="material-table add-margin-top">
                            <Table
                                tableTitle={`Tabel Pengeluaran Tambahan ${this.state.filterState?
                                    "" : "(" + moment().format("MMMM YYYY") + ")"}`}
                                tableColumns={this.columnsExp}
                                tableActions={this.actionsExp}
                                data={this.state.expenseData}
                                defaultPagination={true}
                            />
                        </Grid>
                        <Grid item xs={12} className="table-footer">
                            <div style={{ display: "inline-block", width: "0", pointerEvents: "none" }}>
                                <ExcelFile
                                    filename={`Laporan Laba Rugi${this.state.filenameRange?
                                        this.state.filenameRange : ""
                                    }`}
                                    element={
                                        <Button innerRef={this.excelRef}></Button>
                                    }
                                >
                                    {
                                        this.state.overallExcel.map(res => (
                                            <ExcelSheet
                                                key={res.name}
                                                name={res.name}
                                                dataSet={res.dataSet}
                                            />
                                        ))
                                    }
                                </ExcelFile>
                            </div>
                        </Grid>
                        </Grid>
                        }
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default IncomeStatement;