import React, { Component } from "react";
import Select from "react-select";
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import "../styles/Filter_Styles.css";
import Spinner from "../components/Loading_Com";
import { TextInput } from "../logic/FormInput";
import { getCategories, getPaymentMethods } from "../logic/APIHandler";

const allCategories = [
    { label: "Retur Toko", value: "return-store" },
    { label: "Retur Supplier", value: "return-supplier" },
    { label: "Refund", value: "refund" },
];
const categoriesWithoutSupplier = [
    { label: "Retur Toko", value: "return-store" },
    { label: "Refund", value: "refund" },
];

class Filter extends Component {

    constructor(props) {
        super(props); 
        this.state = {
            isExpand: 
                localStorage.getItem("start") || localStorage.getItem("end") ||
                localStorage.getItem("category") || localStorage.getItem("payMethod") ||
                localStorage.getItem("invoice") || localStorage.getItem("supplier") ||
                localStorage.getItem("code") || localStorage.getItem("name")?
                true : false,
            isLoading: false,
            startDate: localStorage.getItem("start")? localStorage.getItem("start") : null,
            endDate: localStorage.getItem("end")? localStorage.getItem("end") : null,
            invoiceNumber: localStorage.getItem("invoice")? localStorage.getItem("invoice") : "",
            // Type = stock-history
            currCategory: localStorage.getItem("category")?
                allCategories.filter(res => res["value"] === localStorage.getItem("category"))[0] : null,
            paymentMethod: "",
            allPaymentMethods: "",
            // Type = product
            supplierName: localStorage.getItem("supplier")? localStorage.getItem("supplier") : "",
            productCode: localStorage.getItem("code")? localStorage.getItem("code") : "",
            productName: localStorage.getItem("name")? localStorage.getItem("name") : "",
            productInDate: null,
            productCategs: [],
            selectedCategs: [],
            // Type = overall-sales
            filterBy: { label: "Tahun", value: "year" },
            // selectedYear: { label: "2021", value: "2021"},
            // selectedMonth: months[parseInt(moment().format("MM"))-1],
            yearOpt: [],
        }
        this.handleFilter = this.handleFilter.bind(this);
        this.clearProductsFilter = this.clearProductsFilter.bind(this);
    }

    // Handle open/close filter
    async handleFilter() {
        if ( this.props.type === "stock-history" ) {
            if ( !this.state.isExpand ) {
                this.getPaymentMethodsOption();
                this.setState({ isExpand: !this.state.isExpand })
            }
            else {
                this.setState({
                    isExpand: !this.state.isExpand,
                })
            }
        }
        else if ( this.props.type === "product" && !this.state.isExpand ) {
            if ( !this.state.isExpand ) {
                this.getProductCategories();
                this.setState({ isExpand: !this.state.isExpand })
            }
            else {
                this.setState({
                    isExpand: !this.state.isExpand,
                })
            }
        }
        else if ( this.props.type === "overall-sales" && !this.state.isExpand ) {
            let years = [];
            for ( let i = parseInt(moment().format("YYYY")); i >= 2000; i-- ) {
                years.push({
                    label: i, value: i
                });
            }
            this.setState({
                yearOpt: years,
                isExpand: !this.state.isExpand,
            })
        }
        else {
            this.setState({ isExpand: !this.state.isExpand })
        }
    }

    // Clear all filter
    async clearProductsFilter() {
        localStorage.removeItem("category");
        localStorage.removeItem("code");
        localStorage.removeItem("name");
        this.setState({
            productCode: "",
            productName: "",
            selectedCategs: [],
            isExpand: false,
        });
    }

    // Get all product categories
    async getProductCategories() {
        this.setState({ isLoading: true });
        let result = [];
        let resp = "";
        if ( localStorage.getItem("level") === "1" ) {
            if ( localStorage.getItem("currBranch") ) {
                const branch = JSON.parse(localStorage.getItem("currBranch"));
                resp = await getCategories(branch.value);
            }
        }
        else {
            resp = await getCategories();
        }
        if ( resp[0] && resp[0].status === 200 ) {
            resp[0].data.forEach(res => {
                result.push({
                    label: res.name, value: res._id,
                });
            });
            if ( localStorage.getItem("category") ) {
                const currSelected = result.filter(res =>
                    res["label"] === localStorage.getItem("category")
                );
                this.setState({ selectedCategs: currSelected[0] });
            }
        }
        else {
            console.log("Cannot load product categories.")
        }
        this.setState({
            productCategs: result,
            isLoading: false,
        })
    }

    // Get all payment methods
    async getPaymentMethodsOption() {
        this.setState({ isLoading: true });
        let result = [];
        let resp = await getPaymentMethods();
        if ( resp[0] && resp[0].status === 200 ) {
            resp[0].data.forEach(res => {
                result.push({
                    label: res.name, value: res._id,
                });
            });
        }
        else {
            console.log("Cannot load payment methods.")
        }
        this.setState({
            allPaymentMethods: result,
            paymentMethod: localStorage.getItem("payMethod")?
                result.filter(res => res["value"] === localStorage.getItem("payMethod"))[0] : "",
            isLoading: false,
        })
    }

    componentDidMount() {
        if ( this.props.type === "product" ) {
            this.getProductCategories();
        }
        else if ( this.props.type === "stock-history" ) {
            this.getPaymentMethodsOption();
        }
    }

    render() {
        return (
            <Grid container className="filter-container">
                <Grid item xs={12} className="add-margin-bottom">
                {/* <Grid item xs={12} sm={this.props.type === "overall-sales"? 6 : 12}
                    className="add-margin-bottom"
                > */}
                    <Button
                        variant="contained"
                        onClick={this.handleFilter}
                        className="filter-btn"
                    >
                        <FilterListIcon style={{ marginRight:"5px" }}/>
                        Filter
                        {this.state.isExpand? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    </Button>
                </Grid>

                { this.state.isLoading ? ( <Spinner /> ) : <></> }
                
                {this.state.isExpand && this.props.type === "stock-history"?
                    <Grid container className="filter-form" spacing={1}>
                        <Grid item xs={12} sm={6} md={3}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    label="Tanggal Awal"
                                    format="dd/MM/yyyy"
                                    placeholder="dd/MM/yyyy"
                                    value={this.state.startDate}
                                    onChange={(date) => {
                                        this.setState({ 
                                            startDate: moment(date),
                                            endDate: this.state.endDate?
                                                this.state.endDate : moment(date)
                                        })
                                    }}
                                    className="date-input-field full-width add-margin-bottom"
                                    inputVariant="outlined"
                                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                    InputLabelProps={{ shrink: true }}
                                    disableFuture={true}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    label="Tanggal Akhir"
                                    format="dd/MM/yyyy"
                                    placeholder="dd/MM/yyyy"
                                    value={this.state.endDate}
                                    onChange={(date) => {
                                        this.setState({ 
                                            startDate: this.state.startDate?
                                                this.state.startDate : moment(date),
                                            endDate: moment(date),
                                        })
                                    }}
                                    className="date-input-field full-width add-margin-bottom"
                                    inputVariant="outlined"
                                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                    InputLabelProps={{ shrink: true }}
                                    disableFuture={true}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} className="filter-selection">
                            <label className="selection-label">Kategori</label>
                            <Select
                                className="selection-field add-margin-bottom tf-label"
                                onChange={(data) =>
                                    this.setState({ currCategory: data })
                                }
                                isClearable={true}
                                options={localStorage.getItem("level") !== "4"?
                                    allCategories : categoriesWithoutSupplier
                                }
                                getOptionLabel={(option) => option.label}
                                value={this.state.currCategory}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} className="filter-selection">
                            <label className="selection-label">Metode Pembayaran</label>
                            <Select
                                className="selection-field add-margin-bottom tf-label"
                                onChange={(data) => 
                                    this.setState({ paymentMethod: data })
                                }
                                isClearable={true}
                                options={this.state.allPaymentMethods}
                                getOptionLabel={(option) => option.label}
                                value={this.state.paymentMethod}
                            />
                        </Grid>

                        <Grid item xs={12}
                            style={{ display: this.state.isExpand? "inherit" : "none" }}>
                            <div className="button-wrap">
                                <Button
                                    variant="contained"
                                    onClick={this.props.handleFilter}
                                    className="filter-submit-btn"
                                >Submit</Button>
                                <Button
                                    onClick={() => {
                                        localStorage.removeItem("start");
                                        localStorage.removeItem("end");
                                        localStorage.removeItem("category");
                                        localStorage.removeItem("payMethod");
                                        this.setState({
                                            startDate: null,
                                            endDate: null,
                                            currCategory: "",
                                            paymentMethod: "",
                                        })
                                    }}
                                    variant="outlined"
                                    className="filter-clear-btn"
                                >Clear Filter</Button>
                            </div>
                        </Grid>
                    </Grid>
                    : this.state.isExpand && this.props.type === "product"?
                        <Grid container className="filter-form" spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    label="Kode Produk"
                                    placeholder="Kode Produk"
                                    className="full-width"
                                    value={this.state.productCode}
                                    onChange={(event) => 
                                        this.setState({ productCode: event.target.value })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput
                                    label="Nama Produk"
                                    placeholder="Nama Produk"
                                    className="full-width"
                                    value={this.state.productName}
                                    onChange={(event) => 
                                        this.setState({ productName: event.target.value })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} className="filter-selection">
                                <label className="selection-label" style={{ marginLeft: "15px" }}>
                                    Kategori Produk
                                </label>
                                <Select
                                    className="selection-field add-margin-bottom tf-label"
                                    styles={{ width: "100%" }}
                                    onChange={(data) => 
                                        this.setState({ selectedCategs: data })
                                    }
                                    isClearable={true}
                                    // isMulti={true}
                                    options={this.state.productCategs}
                                    getOptionLabel={(option) => option.label}
                                    value={this.state.selectedCategs}
                                />
                            </Grid>

                            <Grid item xs={12}
                                style={{ display: this.state.isExpand? "inherit" : "none" }}>
                                <div className="button-wrap">
                                    <Button
                                        variant="contained"
                                        onClick={this.props.handleFilter}
                                        className="filter-submit-btn"
                                    >Submit</Button>
                                    <Button
                                        onClick={() => {
                                            localStorage.removeItem("categories");
                                            localStorage.removeItem("category");
                                            localStorage.removeItem("code");
                                            localStorage.removeItem("name");
                                            this.setState({
                                                productCode: "",
                                                productName: "",
                                                selectedCategs: [],
                                            })
                                        }}
                                        variant="outlined"
                                        className="filter-clear-btn"
                                    >Clear Filter</Button>
                                </div>
                            </Grid>
                        </Grid>
                    : this.state.isExpand && this.props.type === "filter-by-date"?
                        <Grid container className="filter-form">
                            <Grid item xs={12} sm={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        label="Tanggal Mulai"
                                        format="dd/MM/yyyy"
                                        placeholder="dd/MM/yyyy"
                                        value={this.state.startDate}
                                        onChange={(date) => {
                                            localStorage.setItem("start", moment(date));
                                            localStorage.setItem("end", this.state.endDate?
                                                this.state.endDate : moment(date));
                                            this.setState({ 
                                                startDate: moment(date),
                                                endDate: this.state.endDate?
                                                    this.state.endDate : moment(date)
                                            })
                                        }}
                                        className="form-left-field add-margin-bottom"
                                        inputVariant="outlined"
                                        KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                        InputLabelProps={{ shrink: true }}
                                        disableFuture={true}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        label="Tanggal Akhir"
                                        format="dd/MM/yyyy"
                                        placeholder="dd/MM/yyyy"
                                        value={this.state.endDate}
                                        onChange={(date) => {
                                            localStorage.setItem("start", this.state.startDate?
                                                this.state.startDate : moment(date));
                                            localStorage.setItem("end", moment(date));
                                            this.setState({ 
                                                startDate: this.state.startDate?
                                                this.state.startDate : moment(date),
                                                endDate: moment(date)
                                            })
                                        }}
                                        className="form-right-field add-margin-bottom"
                                        inputVariant="outlined"
                                        KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                        InputLabelProps={{ shrink: true }}
                                        disableFuture={true}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={12}
                                style={{ display: this.state.isExpand? "inherit" : "none" }}>
                                <div className="button-wrap">
                                    <Button
                                        variant="contained"
                                        onClick={this.props.handleFilter}
                                        className="filter-submit-btn"
                                    >Submit</Button>
                                    <Button
                                        onClick={() => {
                                            localStorage.removeItem("start");
                                            localStorage.removeItem("end");
                                            this.setState({
                                                startDate: null,
                                                endDate: null,
                                            })
                                        }}
                                        variant="outlined"
                                        className="filter-clear-btn"
                                    >Clear Filter</Button>
                                </div>
                            </Grid>
                        </Grid>
                    : this.state.isExpand && this.props.type === "purchase-product-price"?
                        <Grid container className="filter-form" spacing={1}>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextInput
                                    label="No. Invoice"
                                    placeholder="No. Invoice"
                                    className="full-width add-margin-bottom"
                                    value={this.state.invoiceNumber}
                                    onChange={(event) => 
                                        this.setState({ invoiceNumber: event.target.value })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextInput
                                    label="Nama Supplier"
                                    placeholder="Nama Supplier"
                                    className="full-width add-margin-bottom"
                                    value={this.state.supplierName}
                                    onChange={(event) => 
                                        this.setState({ supplierName: event.target.value })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextInput
                                    label="Kode Produk"
                                    placeholder="Kode Produk"
                                    className="full-width add-margin-bottom"
                                    value={this.state.productCode}
                                    onChange={(event) => 
                                        this.setState({ productCode: event.target.value })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextInput
                                    label="Nama Produk"
                                    placeholder="Nama Produk"
                                    className="full-width add-margin-bottom"
                                    value={this.state.productName}
                                    onChange={(event) => 
                                        this.setState({ productName: event.target.value })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        label="Tanggal Mulai"
                                        format="dd/MM/yyyy"
                                        placeholder="dd/MM/yyyy"
                                        value={this.state.startDate}
                                        onChange={(date) => {
                                            localStorage.setItem("start", moment(date));
                                            localStorage.setItem("end", this.state.endDate?
                                                this.state.endDate : moment(date));
                                            this.setState({ 
                                                startDate: moment(date),
                                                endDate: this.state.endDate?
                                                    this.state.endDate : moment(date)
                                            })
                                        }}
                                        className="full-width add-margin-bottom"
                                        inputVariant="outlined"
                                        KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                        InputLabelProps={{ shrink: true }}
                                        disableFuture={true}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        label="Tanggal Akhir"
                                        format="dd/MM/yyyy"
                                        placeholder="dd/MM/yyyy"
                                        value={this.state.endDate}
                                        onChange={(date) => {
                                            localStorage.setItem("start", this.state.startDate?
                                                this.state.startDate : moment(date));
                                            localStorage.setItem("end", moment(date));
                                            this.setState({ 
                                                startDate: this.state.startDate?
                                                this.state.startDate : moment(date),
                                                endDate: moment(date)
                                            })
                                        }}
                                        className="full-width add-margin-bottom"
                                        inputVariant="outlined"
                                        KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                        InputLabelProps={{ shrink: true }}
                                        disableFuture={true}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={12}
                                style={{ display: this.state.isExpand? "inherit" : "none" }}>
                                <div className="button-wrap">
                                    <Button
                                        variant="contained"
                                        onClick={this.props.handleFilter}
                                        className="filter-submit-btn"
                                    >Submit</Button>
                                    <Button
                                        onClick={() => {
                                            localStorage.removeItem("invoice");
                                            localStorage.removeItem("supplier");
                                            localStorage.removeItem("code");
                                            localStorage.removeItem("name");
                                            localStorage.removeItem("start");
                                            localStorage.removeItem("end");
                                            this.setState({
                                                invoiceNumber: "",
                                                supplierName: "",
                                                productCode: "",
                                                productName: "",
                                                startDate: null,
                                                endDate: null,
                                            })
                                        }}
                                        variant="outlined"
                                        className="filter-clear-btn"
                                    >Clear Filter</Button>
                                </div>
                            </Grid>
                        </Grid>
                    : <></>
                }
            </Grid>
        )
    }
}

export default Filter;