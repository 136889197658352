import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { TextInput, PhoneInput, PriceOnBlurInput, DateInput,
    DropdownCurrentInput } from "../../../logic/FormInput";
import { getProducts, getTaxes } from "../../../logic/APIHandler";
import { formatPrice, formatToPrice } from "../../../logic/Handler";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";
import moment from "moment";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddWarehouseTransaction() {
    
    const numberRegex = /^[0-9]*$/;
    const selectedDate = moment();
    
    // Form State
    const [cart, setCart] = useState([]);
    const [products, setProducts] = useState([]);
    const [subtotal, setSubtotal] = useState("Rp 0");
    const [orderFee, setOrderFee] = useState("Rp 0");
    const [buyerPhone, setBuyerPhone] = useState("");
    const [driverPhone, setDriverPhone] = useState("");
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [tax, setTax] = useState({ name: "-", value: "-" });
    const [totalTax, setTotalTax] = useState("Rp 0");
    const [totalPrice, setTotalPrice] = useState("Rp 0");
    const [updatedData, setUpdatedData] = useState([]);

    // Page State
    const { register, handleSubmit, control, errors, setValue } = useForm();
    const [openPrompt, setOpenPrompt] = useState(false);
    const [openJWT, setOpenJWT] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadCalculation, setLoadCalculation] = useState(false);
    const [loadRemove, setLoadRemove] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
        setValue("date", moment());
        getData();
        getDataTax();
    },[]);

    // Get list of products
    const getData = async () => {
        setIsLoading(true);
        let resp = await getProducts();
        if ( resp[0] && resp[0].status === 200 ) {
            let temp = [];
            resp[0].data.forEach(res => {
                let price = formatToPrice(res.selling_price);
                temp.push({
                    "label": res.product_code + " - " + res.name,
                    "value": res._id,
                    "price": price,
                    "subtotal": price,
                })
            });
            setProducts(temp);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp[1].message? resp[1].message : resp[1].error[0].msg);
        }
        setIsLoading(false);
    }

    // Get tax
    const getDataTax = async () => {
        setIsLoading(true);
        let resp = await getTaxes();
        if ( resp[0] && resp[0].status === 200 ) {
            const firstTax = resp[0].data[0];
            setTax({
                name: firstTax.name,
                value: firstTax.value,
            });
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
        }
        setIsLoading(false);
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Handle onChange phone number & prevent user to input characters
    const onChangePhoneNumber = (event) => {
        const value = event.target.value;
        const name = event.target.name;
        if ( value ) {
            // allow + and number as first input
            if ( value.length === 1 ) {
                if ( value === '+' || numberRegex.test(value) ) {
                    if ( name === "buyer_phone" ) setBuyerPhone(value);
                    else setDriverPhone(value);
                }
            }
            // only allow numbers after first input
            else if ( value.length <= 16 ) {
                if ( numberRegex.test(value.substring(1)) ) {
                    if ( name === "buyer_phone" ) setBuyerPhone(value);
                    else setDriverPhone(value);
                }
            }
        }
        else {
            if ( name === "buyer_phone" ) setBuyerPhone(value);
            else setDriverPhone(value);
        }
    }

    // Add product to cart / selected products
    const addProductToCart = data => {
        let currSubtotal = parseInt(formatPrice(subtotal)) + parseInt(formatPrice(data.price));
        let currTax = 0;
        if ( tax.value !== "-" ) {
            currTax = currSubtotal * parseInt(tax.value) / 100;
        }
        setSubtotal(formatToPrice(currSubtotal));
        setTotalTax(formatToPrice(currTax));
        setTotalPrice(formatToPrice(currSubtotal + currTax + formatPrice(orderFee)));
        setProducts(products.filter(res => res["value"] !== data.value));
        setCart([...cart, {
            id: data.value,
            name: data.label,
            qty: 1,
            price: data.price,
            subtotal: data.price,
        }]);
    }
    // Remove product from cart / selected products
    const handleRemoveProduct = async (index) => {
        setLoadRemove(true);
        await sleep(10);
        setProducts([
            ...products,
            { 
                "label": cart[index].name,
                "value": cart[index].id,
                "price": cart[index].price,
                "subtotal": cart[index].subtotal,
            }
        ]);
        let updatedCart = [...cart];
        updatedCart.splice(index, 1);
        setCart(updatedCart);
        // Update values in text field
        for (let i=index; i < cart.length-1; i++) {
            setValue(("qty."+i), cart[i+1].qty);
        }
        // Calculate subtotal
        let currSubtotal = parseInt(formatPrice(subtotal)) - parseInt(formatPrice(cart[index].subtotal));
        let currTax = 0;
        if ( tax.value !== "-" ) {
            currTax = currSubtotal * parseInt(tax.value) / 100;
        }
        setSubtotal(formatToPrice(currSubtotal));
        setTotalTax(formatToPrice(currTax));
        setTotalPrice(formatToPrice(currSubtotal + currTax + formatPrice(orderFee)))
        setLoadRemove(false);
    };

    // Send new data to server
    const onSubmit = async (data) => {
        if ( cart.length > 0 ) {
            data["date"] = moment().format("DD/MM/YYYY");
            data["postal_fee"] = orderFee? orderFee : "Rp 0";
            data["total_price"] = totalPrice;
            data["total_tax"] = totalTax;
            data["tax"] = tax.value === "-"? false : tax;
            setUpdatedData(data);
            setOpenPrompt(true);
        }
        else {
            setErrorText("Pilih minimal 1 jenis produk!");
            setOpenErrorAlert(true);
        }
    }

    return (
        <div className="content">
            { loadCalculation? ( <Spinner isCalc={true} /> ) : isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} level="3" />
            
            <Modal
                open={openPrompt}
                onClose={() => setOpenPrompt(false)}
                onClickCancel={() => setOpenPrompt(false)}
                modalType="create-sell-warehouse"
                color="primary"
                modalTitle="Apakah Anda yakin data yang dimasukkan sudah benar?"
                updatedData={updatedData}
                cart={cart}
                submitText="Yakin"
                cancelText="Tidak"
            />
            <Modal
                open={openJWT}
                modalType="handle-jwt"
                modalTitle="Token Anda Sudah Expire"
            />

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <img src={logo} alt="logo" className="header-logo" />
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                    <Alert severity="success">
                        Berhasil Membuat Transaksi Baru
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <h1 style={{ marginBottom: "2.2rem" }}>
                            Buat Transaksi Penjualan Gudang
                        </h1>
                        <h2 style={{ marginBottom: "2.2rem" }}>
                            Data Surat Jalan
                        </h2>
                        <Grid container className="add-margin-bottom">
                            <Grid item xs={12}>
                                <DateInput
                                    label="Tanggal Penjualan"
                                    // name="date"
                                    value={selectedDate}
                                    // onChange={(date) => setSelectedDate(moment(date))}
                                    className="full-width add-margin-bottom"
                                    // validation={register}
                                    disableFuture={true}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label="Nama Pembeli *"
                                    name="buyer_name"
                                    placeholder="Nama Pembeli"
                                    className="form-left-field add-margin-bottom"
                                    validation={register({ required: "*nama pembeli harus diisi" })}
                                    errors={errors.buyer_name}
                                    errorClassName="text-required-half"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PhoneInput
                                    label="No. Telpon Pembeli *"
                                    name="buyer_phone"
                                    placeholder="Nomor Telpon"
                                    className="form-right-field add-margin-bottom"
                                    value={buyerPhone}
                                    onChange={onChangePhoneNumber}
                                    validation={register}
                                    requiredText="*no. telpon pembeli harus diisi"
                                    errors={errors.buyer_phone}
                                    errorClassName="text-required-half"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    multiline={true}
                                    rows={2}
                                    label="Alamat Pengiriman *"
                                    name="delivery_destination"
                                    placeholder="Alamat"
                                    className="full-width add-margin-bottom"
                                    validation={register({ required: "*alamat pengiriman harus diisi" })}
                                    errors={errors.delivery_destination}
                                    errorClassName="text-required"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PriceOnBlurInput
                                    label="Biaya Pengiriman"
                                    defaultValue=""
                                    className="full-width add-margin-bottom small-font"
                                    onBlur={(event) => {
                                        if ( event.target.value ) {
                                            if ( totalPrice === "Rp 0" ) {
                                                setTotalPrice(event.target.value);
                                            }
                                            else {
                                                const currTotal = (formatPrice(totalPrice))
                                                + formatPrice(event.target.value);
                                                setTotalPrice(formatToPrice(currTotal));
                                            }
                                        }
                                        else {
                                            setTotalPrice(formatToPrice(
                                                formatPrice(totalPrice) - formatPrice(orderFee)
                                            ));
                                        }
                                        setOrderFee(event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput
                                    label="Supir / Kurir *"
                                    name="driver_name"
                                    placeholder="Supir / Kurir"
                                    className="form-left-field add-margin-bottom"
                                    validation={register({ required: "*nama supir/kurir harus diisi" })}
                                    errors={errors.driver_name}
                                    errorClassName="text-required-half"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PhoneInput
                                    label="No. Telpon Supir / Kurir *"
                                    name="driver_phone"
                                    placeholder="Nomor Telpon"
                                    className="form-right-field add-margin-bottom"
                                    value={driverPhone}
                                    onChange={onChangePhoneNumber}
                                    validation={register}
                                    requiredText="*no. telpon supir/Kurir harus diisi"
                                    errors={errors.driver_phone}
                                    errorClassName="text-required-half"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    multiline={true}
                                    rows={2}
                                    label="Keterangan Tambahan"
                                    name="notes"
                                    placeholder="Keterangan"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                />
                            </Grid>
                        </Grid>
                        
                        <h2 style={{ marginBottom: "2.2rem" }}>
                            Data Produk
                        </h2>
                        <Grid item xs={12}>
                            <DropdownCurrentInput
                                label="Pilih Produk"
                                name="products"
                                className="full-width add-margin-bottom tf-label"
                                keepData={addProductToCart}
                                disableClearable={false}
                                control={control}
                                options={products}
                                getOptionLabel={(option) => option.label}
                                value={selectedProduct}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {!loadRemove && cart.length > 0 && cart.map((product, idx) => {
                                return (
                                    <Grid container spacing={2} key={`product-${idx}`}>
                                        <Grid item xs={12} md={5}>
                                            <TextInput
                                                label="Produk"
                                                placeholder="Kode - Nama Produk"
                                                value={product.name}
                                                className="full-width add-margin-bottom small-font"
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={8} md={2}>
                                            <PriceOnBlurInput
                                                label="Harga"
                                                defaultValue={product.price}
                                                className="full-width add-margin-bottom small-font"
                                                onBlur={async (event) => {
                                                    setLoadCalculation(true)
                                                    await sleep(20)
                                                    let res = cart;
                                                    let value = event.target.value;
                                                    let prevTotal = parseInt(formatPrice(res[idx].subtotal));
                                                    let itemSubtotal = 0;
                                                    // Set per item
                                                    if ( value ) {
                                                        res[idx].price = value;
                                                        if ( formatPrice(value) <= 0 ) {
                                                            itemSubtotal = 0;
                                                            res[idx].subtotal = "Rp 0";
                                                        }
                                                        else {
                                                            itemSubtotal = formatPrice(value) * res[idx].qty;
                                                            res[idx].subtotal = formatToPrice(itemSubtotal);
                                                        }
                                                    }
                                                    else {
                                                        res[idx].price = 0;
                                                        itemSubtotal = 0;
                                                        res[idx].subtotal = "Rp 0";
                                                    }
                                                    setCart(res);
                                                    // Set subtotal
                                                    const diff = itemSubtotal - prevTotal;
                                                    let currSubtotal = parseInt(formatPrice(subtotal)) + diff;
                                                    let currTax = 0;
                                                    if ( tax.value !== "-" ) {
                                                        currTax = currSubtotal * parseInt(tax.value) / 100;
                                                    }
                                                    setSubtotal(currSubtotal <= 0?
                                                        "Rp 0" : formatToPrice(currSubtotal));
                                                    setTotalTax(currSubtotal <= 0?
                                                        "Rp 0" : formatToPrice(currTax));
                                                    setTotalPrice(currSubtotal <= 0?
                                                        orderFee : formatToPrice(currSubtotal
                                                            + currTax + formatPrice(orderFee)));
                                                    setLoadCalculation(false)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={2}>
                                            <TextInput
                                                label="Qty"
                                                name={`qty.${idx}`}
                                                placeholder="0"
                                                defaultValue={product.qty}
                                                onChange={async (event) => {
                                                    setLoadCalculation(true)
                                                    await sleep(20)
                                                    let res = cart;
                                                    let value = event.target.value;
                                                    let prevTotal = parseInt(formatPrice(res[idx].subtotal));
                                                    if (value && numberRegex.test(value)) {
                                                        // Set per item
                                                        if ( value <= 0 ) setValue(("qty."+idx), 1);
                                                        res[idx].qty = value <= 0? 1 : value;
                                                        let total = formatPrice(res[idx].price) * res[idx].qty;
                                                        res[idx].subtotal = formatToPrice(total);
                                                        setCart(res);
                                                    }
                                                    else {
                                                        res[idx].qty = "";
                                                        res[idx].subtotal = "Rp 0";
                                                        setValue(("qty."+idx), cart[idx].qty);
                                                    }
                                                    // Set subtotal
                                                    const diff = parseInt(formatPrice(res[idx].subtotal)) - prevTotal;
                                                    let currSubtotal = parseInt(formatPrice(subtotal)) + diff;
                                                    let currTax = 0;
                                                    if ( tax.value !== "-" ) {
                                                        currTax = currSubtotal * parseInt(tax.value) / 100;
                                                    }
                                                    setSubtotal(currSubtotal <= 0?
                                                        "Rp 0" : formatToPrice(currSubtotal));
                                                    setTotalTax(currSubtotal <= 0?
                                                        "Rp 0" : formatToPrice(currTax));
                                                    setTotalPrice(currSubtotal <= 0?
                                                        orderFee : formatToPrice(currSubtotal
                                                            + currTax + formatPrice(orderFee)));
                                                    setLoadCalculation(false)
                                                }}
                                                className="full-width add-margin-bottom small-font"
                                                validation={register}
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm={10} md={2}>
                                            <TextInput
                                                label="Total"
                                                placeholder="Rp -"
                                                value={product.subtotal}
                                                className="full-width add-margin-bottom small-font"
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={2} md={1}>
                                            <button
                                                type="button"
                                                className="remove-product-btn"
                                                onClick={() => handleRemoveProduct(idx)}
                                            >X</button>
                                        </Grid>
                                    </Grid>
                                )
                            })

                            }
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={9}>
                                <h4 className="subtotal-info">Subtotal</h4>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextInput
                                    // label="Subtotal"
                                    placeholder="Rp -"
                                    value={subtotal}
                                    className="full-width small-font"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={9}>
                                <h4 className="subtotal-info">
                                    {`Pajak ${tax.name} (${tax.value}%)`}
                                </h4>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextInput
                                    placeholder="Rp -"
                                    value={totalTax}
                                    className="full-width small-font"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={9}>
                                <h4 className="subtotal-info">Biaya Pengiriman</h4>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextInput
                                    placeholder="Rp -"
                                    value={orderFee}
                                    className="full-width small-font"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={9}>
                                <h4 className="subtotal-info">Total Harga</h4>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <TextInput
                                    placeholder="Rp -"
                                    value={totalPrice}
                                    className="full-width small-font"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            variant="contained"
                            style={{ display: "block", marginLeft: "auto" }}
                            className="add-new-btn"
                        >Submit</Button>

                    </form>
                </Grid>
            </Grid>
        </div>
    );
}