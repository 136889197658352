export const categs = {
    "adjustment": "Penyesuaian",
    "buy": "Pembelian",
    "sell": "Penjualan",
    "sell-warehouse": "Penjualan Gudang",
    "return-store": "Retur Toko",
    "return-supplier": "Retur Supplier",
    "refund": "Refund",
    "opname": "Stock Opname",
    "request-product": "Request",
    "transition": "Transisi",
    "losses": "Losses",
};

export const typePaymentCategs = {
    "cash": "Cash",
    "debit": "Debit",
    "credit-card": "Kredit",
    "transfer": "Transfer",
};