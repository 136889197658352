import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import { Autocomplete } from "@material-ui/lab";
import NumberFormat from "react-number-format";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar, TextField } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CreateIcon from '@material-ui/icons/Create';
// import SyncAltIcon from '@material-ui/icons/SyncAlt';
import DeleteIcon from '@material-ui/icons/Delete';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Table from '../../../components/Table_Com';
import Modal from '../../../components/Modal_Com';
import Filter from '../../../components/Filter_Com';
import logo from "../../../assets/img/logo.png";
import {
    getProducts,
    getProductsByCategory,
    getProductsByParams,
} from "../../../logic/APIHandler";
import { formatToPrice } from "../../../logic/Handler";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Product extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            defaultData: [],
            exportData: [],
            open: false,
            openAdjust: false,
            openTransition: false,
            openJWT: false,
            openErrorAlert: false,
            errorText: "",
            currStock: 0,
            productId: '00000000',
            productName: '',
            qty: 0,
            currBranch:
                localStorage.getItem("level") !== "1"? null
                : localStorage.getItem("currBranch")? JSON.parse(localStorage.getItem("currBranch"))
                : null,
            isLoading: false,
            isSidebarOpen: 'slide-out',
        }
        this.filterRef = React.createRef();
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleCloseError = this.handleCloseError.bind(this);
        this.handleFilterBranch = this.handleFilterBranch.bind(this);
        this.filterData = this.filterData.bind(this);
    }

    columnsMaster = [
        { 
            title: "Kode", 
            field: "product_code",
            tooltip: "Kode produk",
            cellStyle: {
                width: "150px",
                maxWidth: "150px"
            },
        },
        {
            title: "Nama",
            field: "name",
            tooltip: "Nama produk",
        },
        {
            title: "Kategori",
            field: "formattedCategories",
            tooltip: "Kategori produk",
            cellStyle: {
                width: "250px",
                maxWidth: "250px"
            },
        },
        {
            title: "Harga Satuan",
            field: "selling_price",
            tooltip: "Harga per satuan produk",
            render: rowData =>
                <p>
                    <NumberFormat
                        value={rowData.selling_price}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix={"Rp"}
                        suffix=",00"
                    />
                </p>
        },
        { 
            title: "Stok Toko", 
            field: "stock_store",
            tooltip: "Stok produk di toko",
        },
        { 
            title: "Stok Gudang", 
            field: "stock_warehouse",
            tooltip: "Stok produk di gudang",
        },
    ]

    columnsOps = [
        { 
            title: "Kode", 
            field: "product_code",
            tooltip: "Kode produk",
            cellStyle: {
                width: "150px",
                maxWidth: "150px"
            },
        },
        {
            title: "Nama",
            field: "name",
            tooltip: "Nama produk",
        },
        {
            title: "Kategori",
            field: "formattedCategories",
            tooltip: "Kategori produk",
            cellStyle: {
                width: "250px",
                maxWidth: "250px"
            },
        },
        { 
            title: "Stok Toko", 
            field: "stock_store",
            tooltip: "Stok produk di toko",
        },
        { 
            title: "Stok Gudang", 
            field: "stock_warehouse",
            tooltip: "Stok produk di gudang",
        },
    ]

    actionsMaster = [
        {
            icon: () => <FindInPageIcon />,
            tooltip: "Lihat Detail Produk",
            onClick: (event, rowData) => {
                localStorage.removeItem("tablePageLog");
                localStorage.removeItem("tablePageLogWR");
                this.props.history.push({
                    pathname: "/produk/" + rowData._id,
                })
            }
        },
    ]
    actionsOps = [
        {
            icon: () => <FindInPageIcon />,
            tooltip: "Lihat Detail Produk",
            onClick: (event, rowData) => {
                localStorage.removeItem("tablePageLog");
                localStorage.removeItem("tablePageLogWR");
                this.props.history.push({
                    pathname: "/produk/" + rowData._id,
                })
            }
        },
        // {
        //     icon: () => <SyncAltIcon />,
        //     tooltip: "Transisi Produk",
        //     onClick: (event, rowData) => {
        //         this.handleOpenTransition(rowData);
        //     }
        // },
        {
            icon: () => <CreateIcon />,
            tooltip: "Penyesuaian Produk",
            onClick: (event, rowData) => {
                this.handleOpenAdjust(rowData);
            }
        },
        {
            icon: () => <DeleteIcon />,
            tooltip: "Hapus Produk",
            onClick: (event, rowData) => {
                this.handleOpen(rowData);
            }
        },
    ]

    // Show delete modal
    handleOpen(data) {
        this.setState({
            open: true,
            productId: data._id,
            productName: data.name,
        })
    }
    handleOpenAdjust(data) {
        this.setState({
            openAdjust: true,
            productId: data._id,
            productName: data.name,
            currStock: data.stock_store,
        })
    }
    handleOpenTransition(data) {
        this.setState({
            openTransition: true,
            productId: data._id,
            productName: data.name,
            currStock: data.stock_warehouse,
        })
    }
    // CLose modal
    handleClose() {
        this.setState({
            open: false,
            openAdjust: false,
            openTransition: false,
        })
    }
    // Close error toast
    handleCloseError() {
        this.setState({
            openErrorAlert: false,
        });
    };

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }
    
    // Make the website stay still for __ ms
    sleep(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    async getData() {
        this.setState({ isLoading: true });
        let resp = [], defaults = [];
        if ( localStorage.getItem("level") === "1" ) {
            if ( localStorage.getItem("currBranch") && localStorage.getItem("category") ) {
                resp = await getProductsByParams(
                    JSON.parse(localStorage.getItem("currBranch")).value,
                    localStorage.getItem("category")
                ); 
            }
            else if ( localStorage.getItem("currBranch") && !localStorage.getItem("category") ) {
                resp = await getProductsByParams(
                    JSON.parse(localStorage.getItem("currBranch")).value,
                    false
                ); 
            }
            else {
                resp = await getProductsByParams(false, false); 
            }
        }
        else {
            if ( localStorage.getItem("category") ) {
                resp = await getProductsByCategory(localStorage.getItem("category"));
                defaults = await getProducts();
                if (defaults[0] && defaults[0].status === 200) defaults = defaults[0].data;
            }
            else {
                resp = await getProducts();
                if (resp[0] && resp[0].status === 200) defaults = resp[0].data;
            }
        }
        if ( resp[0] && resp[0].status === 200 ) {
            let products = resp[0].data;
            if ( localStorage.getItem("level") === "1" ) {
                defaults = products;
            }
            if ( localStorage.getItem("code") ) {
                products = products.filter(res =>
                    res["product_code"].toLowerCase().includes(localStorage.getItem("code").toLowerCase())
                );
            }
            if ( localStorage.getItem("name") ) {
                products = products.filter(res =>
                    res["name"].toLowerCase().includes(localStorage.getItem("name").toLowerCase())
                );
            }
            let exportDB = [];
            if ( products.length > 0 ) {
                products.forEach(product => {
                    // Set data for export database
                    exportDB.push({
                        "label": product.product_code + " - " + product.name,
                        "value": product._id,
                        "price": formatToPrice(product.selling_price),
                    });
                    // Set data for table
                    let allCategories = "";
                    for (let i=0; i < product.categories.length; i++) {
                        if ( product.categories[i] && product.categories[i].category_id ) {
                            if ( i < product.categories.length - 1 ) {
                                allCategories += product.categories[i].category_id.name + ", ";
                            }
                            else {
                                allCategories += product.categories[i].category_id.name;
                            }
                        }
                    }
                    product["formattedCategories"] = allCategories;
                });
            }
            this.setState({
                data: products,
                defaultData: defaults,
                exportData: exportDB,
                isLoading: false,
            })
        }
        else if ( resp[0] && resp[0].status === 204) {
            this.setState({
                data: [],
                defaultData: [],
                // defaultData: defaults,
                errorText: "Data produk tidak ditemukan!",
                openErrorAlert: true,
                isLoading: false,
            })
        }
        else if ( resp[1] && resp[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({
                errorText: "Gagal memuat data. Cek kembali koneksi internet Anda.",
                openErrorAlert: true,
                isLoading: false,
            })
        }
    }

    // Filter data tabel
    async filterData() {
        this.setState({ isLoading: true });
        localStorage.removeItem("tablePage");
        const ref = this.filterRef.current.state;
        let result = [];
        let exportDB = this.state.exportData;
        
        // If filter by category
        if ( ref.selectedCategs && ref.selectedCategs.length !== 0 ) {
            localStorage.setItem("category", ref.selectedCategs.label);
            if ( localStorage.getItem("level") === "1" ) {
                result = await getProductsByParams(
                    JSON.parse(localStorage.getItem("currBranch")).value,
                    ref.selectedCategs.label
                );
            }
            else {
                result = await getProductsByCategory(ref.selectedCategs.label);
            }
            if ( result[0] && result[0].status === 200 ) {
                result = result[0].data;
                exportDB = [];
                result.forEach(product => {
                    // Set data for export database
                    exportDB.push({
                        "label": product.product_code + " - " + product.name,
                        "value": product._id,
                        "price": formatToPrice(product.selling_price),
                    });
                    // Set data for table
                    let allCategories = "";
                    for (let i=0; i < product.categories.length; i++) {
                        if ( i < product.categories.length - 1 ) {
                            allCategories += product.categories[i].category_id.name + ", ";
                        }
                        else {
                            allCategories += product.categories[i].category_id.name;
                        }
                    }
                    product["formattedCategories"] = allCategories;
                });
            }
            else if ( result[0] && result[0].status === 204 ) {
                result = [];
            }
            else {
                result = this.state.defaultData;
                this.setState({
                    errorText: "Gagal filter berdasarkan kategori. Cek kembali koneksi internet Anda.",
                    openErrorAlert: true,
                })
            }
        }
        else {
            await this.sleep(500);
            localStorage.removeItem("category")
            result = this.state.defaultData;
        }

        // If filter by code/name
        if ( ref.productCode ) {
            localStorage.setItem("code", ref.productCode);
            result = result.filter(res =>
                res["product_code"].toLowerCase().includes(ref.productCode.toLowerCase())
            );
        } else localStorage.removeItem("code");
        if ( ref.productName ) {
            localStorage.setItem("name", ref.productName);
            result = result.filter(res =>
                res["name"].toLowerCase().includes(ref.productName.toLowerCase())
            );
        } else localStorage.removeItem("name");

        this.setState({
            data: result,
            exportData: exportDB,
            isLoading: false,
        });
    }
    async handleFilterBranch(branch) {
        this.filterRef.current.clearProductsFilter();
        this.setState({
            isLoading: true,
            currBranch: branch,
        });
        localStorage.removeItem("tablePage");
        localStorage.removeItem("saved-so");
        localStorage.removeItem("saved-so-store");
        localStorage.setItem("currBranch", JSON.stringify(branch));
        let resp = "";
        if ( localStorage.getItem("category") ) {
            resp = await getProductsByParams(branch.value, localStorage.getItem("category"));
        }
        else {
            resp = await getProductsByParams(branch.value, false);
        }
        if ( resp[0] && resp[0].status === 200 ) {
            const defaults = resp[0].data;
            let products = resp[0].data;
            if ( localStorage.getItem("code") ) {
                products = products.filter(res =>
                    res["product_code"].toLowerCase().includes(localStorage.getItem("code").toLowerCase())
                );
            }
            if ( localStorage.getItem("name") ) {
                products = products.filter(res =>
                    res["name"].toLowerCase().includes(localStorage.getItem("name").toLowerCase())
                );
            }
            let exportDB = [];
            if ( products.length > 0 ) {
                products.forEach(product => {
                    // Set data for export database
                    exportDB.push({
                        "label": product.product_code + " - " + product.name,
                        "value": product._id,
                        "price": formatToPrice(product.selling_price),
                    });
                    // Set data for table
                    let allCategories = "";
                    for (let i=0; i < product.categories.length; i++) {
                        if ( product.categories[i] && product.categories[i].category_id ) {
                            if ( i < product.categories.length - 1 ) {
                                allCategories += product.categories[i].category_id.name + ", ";
                            }
                            else {
                                allCategories += product.categories[i].category_id.name;
                            }
                        }
                    }
                    product["formattedCategories"] = allCategories;
                });
            }
            this.setState({
                data: products,
                defaultData: defaults,
                exportData: exportDB,
            })
        }
        else if ( resp[0] && resp[0].status === 204 ) {
            this.setState({
                data: [],
                defaultData: [],
                exportData: [],
                errorText: "Tidak ada produk yang ditemukan!",
                openErrorAlert: true,
            })
        }
        else {
            this.setState({
                data: [],
                defaultData: [],
                exportData: [],
                errorText: "Gagal mem-filter berdasarkan cabang.",
                openErrorAlert: true,
            })
        }
        this.filterRef.current.getProductCategories();
        this.setState({ isLoading: false });
    }

    componentDidMount() {
        if ( localStorage.getItem("path") !== this.props.location.pathname ) {
            localStorage.removeItem("path");
            localStorage.removeItem("search");
            localStorage.removeItem("tablePage");
        }
        this.getData();
    }

    render() {
        return (
            <div className="content">
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""}
                    level={localStorage.getItem("level")} />

                <Modal
                    open={this.state.openAdjust}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="update-stock"
                    id={this.state.productId}
                    modalTitle="Penyesuaian Stok Produk"
                    dataName={this.state.productName}
                    currStock={this.state.currStock? this.state.currStock : "-"}
                    submitText="Submit"
                    cancelText="Cancel"
                />
                {/* <Modal
                    open={this.state.openTransition}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="stock-transition"
                    id={this.state.productId}
                    modalTitle="Transisi Produk"
                    dataName={this.state.productName}
                    currStock={this.state.currStock? this.state.currStock : "-"}
                    submitText="Submit"
                    cancelText="Cancel"
                /> */}
                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="erase-product"
                    id={this.state.productId}
                    color="secondary"
                    modalTitle="Hapus produk?"
                    dataName={this.state.productName}
                    submitText="Delete"
                    cancelText="Cancel"
                />
                <Modal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container" style={{ position: "relative" }}
                    spacing={localStorage.getItem("level") === "1"? 2 : 0}>
                    {localStorage.getItem("level") === "1"?
                        <Grid item xs={12}>
                            <Autocomplete
                                style={{ marginBottom: "5px" }}
                                className="full-width tf-label"
                                onChange={(_, value) => this.handleFilterBranch(value)}
                                options={localStorage.getItem("branch")?
                                    JSON.parse(localStorage.getItem("branch")) : []
                                }
                                getOptionLabel={(option) => option.label}
                                disableClearable={true}
                                value={this.state.currBranch}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Cabang"
                                        placeholder="Pilih"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />}
                            />
                        </Grid> : <></>
                    }
                    <Grid item xs={12} className="with-download">
                        <Filter ref={this.filterRef} handleFilter={this.filterData} type="product" />
                    </Grid>

                    {localStorage.getItem("level") !== "3"?
                        <NavLink
                            to={{
                                pathname: "/produk/export-database",
                                state: {
                                    data: this.state.exportData,
                                },
                            }}
                            style={{ textDecoration: "none" }}
                        >
                            <Button
                                variant="contained"
                                className={`download-btn table
                                    ${localStorage.getItem("level") === "1"? "with-branch" : ""}
                                `}
                            >
                                <CloudDownloadIcon style={{ marginRight: "10px" }} />
                                Export Database
                            </Button>
                        </NavLink> : <></>
                    }

                    <Snackbar open={this.state.openErrorAlert} autoHideDuration={1500} onClose={this.handleCloseError}>
                        <Alert severity="error">
                            {this.state.errorText}
                        </Alert>
                    </Snackbar>

                    { this.state.isLoading ? ( <Spinner /> ) : <>
                        <Grid item xs={12} className="material-table add-margin-top">
                            <Table
                                tableTitle="Tabel Produk"
                                tableColumns={localStorage.getItem("level") === "1"?
                                    this.columnsMaster : this.columnsOps
                                }
                                data={this.state.data}
                                tableActions={localStorage.getItem("level") === "1" ||
                                    localStorage.getItem("level") === "3"?
                                    this.actionsMaster : this.actionsOps
                                }
                            />
                        </Grid>
                        <Grid item xs={12} className="table-footer">
                            {localStorage.getItem("level") !== "1" && localStorage.getItem("level") !== "3"?
                                <NavLink to="/produk-form" style={{ textDecoration: "none" }}>
                                    <Button variant="contained" className="add-btn-table">
                                        Tambah Produk
                                    </Button>
                                </NavLink> : <></>
                            }
                        </Grid></>
                    }
                </Grid>
            </div>
        );
    }
}

export default Product;