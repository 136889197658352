import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Container, Grid, TextField, Button, InputAdornment,
    IconButton, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Spinner from '../components/Loading_Com';
import { hitLogin } from '../logic/APIHandler';
import '../styles/Login_Styles.css';
import logo from '../assets/img/logo.png';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function LoginPage(props) {

    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const { register, handleSubmit, errors } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');

    useEffect(() => {
        if ( localStorage.getItem("token") ) {
            if ( localStorage.getItem("level") === "1" ) { // master
                history.push("/dashboard");
            }
            else if ( localStorage.getItem("level") === "2" ||
            localStorage.getItem("level") === "4" ) { // operasional,inventaris
                history.push("/stok-keseluruhan-toko");
            }
            else if ( localStorage.getItem("level") === "3" ) { // keuangan
                history.push("/sesi-kasir");
            }
            else if ( localStorage.getItem("level") === "5" ) { // kasir
                history.push("/ganti-password");
            }
        }
    }, []);

    // Toggle show password
    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Handle log in
    const onSubmit = async data => {
        setIsLoading(true);
        let resp = await hitLogin(data);
        if ( resp[0] && resp[0].status === 200 ) {
            const user = resp[0].data;
            localStorage.setItem('token', user.token);
            if ( user.jobdesk === 'master' ) {
                let branches = [];
                if ( user.branch_store && user.branch_store.length > 0 ) {
                    user.branch_store.forEach(res => {
                        branches.push({
                            label: res.name,
                            value: res._id,
                        });
                    });
                }
                branches = branches.sort((a,b) => (a.label > b.label? 1 : -1));
                const defaultBranch = branches.filter(res =>
                    res.label.toLowerCase() === "pangkalan bun"
                );
                if ( defaultBranch && defaultBranch.length > 0 ) {
                    localStorage.setItem("currBranch", JSON.stringify(defaultBranch[0]));
                }
                localStorage.setItem("branch", JSON.stringify(branches));
            }
            else {
                localStorage.setItem("branch", JSON.stringify(user.branch_store));
            }
            if ( user.jobdesk === 'master' ) {
                localStorage.setItem('level', '1');
                history.push("/dashboard");
            }
            else if ( user.jobdesk === 'operasional' ) {
                localStorage.setItem('level', '2');
                history.push("/stok-keseluruhan-toko");
            }
            else if ( user.jobdesk === 'keuangan' ) {
                localStorage.setItem('level', '3');
                history.push("/sesi-kasir");
            }
            else if ( user.jobdesk === 'inventaris' ) {
                localStorage.setItem('level', '4');
                history.push("/stok-keseluruhan-toko");
            }
            else if ( user.jobdesk === 'kasir' ) {
                localStorage.setItem('level', '5');
                history.push("/ganti-password");
            }
        }
        else {
            setErrorText(resp[1].message);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    if ( localStorage.getItem("token") ) {
        return null;
    }
    else {
        return (
            <Container className="login-container">
                { isLoading ? ( <Spinner /> ) : "" }
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>
                <Grid container>
                    <Grid item xs={12}>
                        <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
                            <img src={logo} alt="logo" className="login-logo" />
                            <TextField
                                type="email"
                                label="Email"
                                name="email"
                                placeholder="Email"
                                className="login-input"
                                value={email}
                                onChange={e => {
                                    if ( !e.target.value.includes(" ") )
                                        setEmail(e.target.value)
                                }}
                                inputRef={register({ required: "*email harus diisi" })}
                                />
                            {errors.email && <p className="text-required">{errors.email.message}</p>}
                            <TextField
                                type={showPassword ? "text" : "password"}
                                label="Password"
                                name="password"
                                placeholder="Password"
                                className="login-input"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleShowPassword}
                                            >
                                                {showPassword? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                inputRef={register({ required: "*password harus diisi" })}
                            />
                            {errors.password && <p className="text-required">{errors.password.message}</p>}
    
                            <Button type="submit" variant="contained" id="login-btn">
                                LOGIN
                            </Button>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}