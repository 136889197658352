import React, { useState, useEffect } from 'react';
import moment from "moment";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar, TextField } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { stockOpname } from "../../../logic/APIHandler";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";
import "../../../styles/ManualTable_Styles.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function StockOpnameComparison() {

    // Form State
    const [data, setData] = useState([]);

    // Page State
    const history = useHistory();
    const { register, handleSubmit } = useForm();
    const [openJWT, setOpenJWT] = useState(false);
    const [openDiscard, setOpenDiscard] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    },[]);

    // Get list of products
    const getData = () => {
        setIsLoading(true);
        if ( localStorage.getItem("saved-so-store") ) {
            let result = JSON.parse(localStorage.getItem("saved-so-store"));
            result.forEach(res => {
                res["diff"] = parseInt(res.value) - parseInt(res.stock_store);
            });
            setData(result);
            setIsLoading(false);
        }
        else {
            history.replace("/stock-opname-toko");
        }
    }

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };
    // CLose modal
    const handleClose = () => {
        setOpenDiscard(false);
    }

    // Send new data to server
    const onSubmit = async (form) => {
        setIsLoading(true);
        let resp = await stockOpname("store", moment().format("DD/MM/YYYY"), data, form);
        if (resp[0] && resp[0].status === 200) {
            localStorage.removeItem("saved-so-store");
            setIsLoading(false);
            setOpenSuccessAlert(true);
            await sleep(1000);
            history.replace("/stock-opname-toko");
        }
        else if ( resp[1] && resp[1].status === 400 ) {
            setErrorText(resp[1].data[0].msg);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            setErrorText(resp[1].message);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar
                sidebarState={(windowWidth < 768)? isSidebarOpen : ""}
                level={localStorage.getItem("level")}
            />
            
            <Modal
                open={openDiscard}
                onClose={handleClose}
                onClickCancel={handleClose}
                modalType="discard-so-store"
                modalTitle="Batalkan stock opname?"
                color="secondary"
                submitText="Submit"
                cancelText="Cancel"
            />
            <Modal
                open={openJWT}
                modalType="handle-jwt"
                modalTitle="Token Anda Sudah Expire"
            />

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <img src={logo} alt="logo" className="header-logo" />
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            
            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={handleCloseSuccess}>
                    <Alert severity="success">
                        Berhasil Melakukan Stock Opname
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>

                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <h1 style={{ marginBottom: "2.2rem" }}>
                            {`${localStorage.getItem("level") !== "1"?
                                "Stock Opname Toko" : ("Stock Opname Toko - "
                                + JSON.parse(localStorage.getItem("currBranch")).label)
                            }`}
                        </h1>

                        <Grid container>
                            <Grid item xs={12} style={{ marginBottom: "2rem", overflowX: 'auto' }}>
                                <table className="manual-table">
                                    <thead>
                                        <tr>
                                            <th>Kode Produk</th>
                                            <th>Nama Produk</th>
                                            <th>Kuantitas Lama</th>
                                            <th>Kuantitas Baru</th>
                                            <th>Selisih</th>
                                            <th>Keterangan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((product, idx) => ((idx % 2 !== 0)?
                                            <tr className="odd-cell" key={`product-${idx}`}>
                                                <td>{product.product_code}</td>
                                                <td>{product.name}</td>
                                                <td className="center-cell">{product.stock_store}</td>
                                                <td className="center-cell">{product.value}</td>
                                                <td className="center-cell" style={{
                                                    color: product.diff === 0? "initial" : "red",
                                                }}>
                                                    {product.diff}
                                                </td>
                                                <td className="center-cell">
                                                    <TextField
                                                        multiline={true}
                                                        name={`reason.${idx}`}
                                                        placeholder="Keterangan"
                                                        className="input-stock-reason"
                                                        inputRef={register}
                                                    />
                                                </td>
                                            </tr>
                                            : <tr className="even-cell" key={`product-${idx}`}>
                                                <td>{product.product_code}</td>
                                                <td>{product.name}</td>
                                                <td className="center-cell">{product.stock_store}</td>
                                                <td className="center-cell">{product.value}</td>
                                                <td className="center-cell" style={{
                                                    color: product.diff === 0? "initial" : "red",
                                                }}>
                                                    {product.diff}
                                                </td>
                                                <td className="center-cell">
                                                    <TextField
                                                        multiline={true}
                                                        name={`reason.${idx}`}
                                                        placeholder="Keterangan"
                                                        className="input-stock-reason"
                                                        inputRef={register}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>

                        <Grid container className="so-btn-container">
                            <Grid item xs={12}>
                                <Button
                                    className="add-cancel-btn"
                                    onClick={() => history.goBack()}
                                >Back</Button>
                                <Button
                                    variant="outlined"
                                    onClick={() => setOpenDiscard(true)}
                                    style={{ float: "right", marginRight: "15px" }}
                                    className="add-cancel-btn"
                                >Discard Changes</Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ float: "right", marginRight: "15px" }}
                                    className="add-new-btn responsive-submit-btn"
                                >Submit</Button>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
            </Grid>
        </div>
    )
}