import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar, TextField, InputAdornment, Tooltip, makeStyles } from "@material-ui/core";
import { SpeedDial, SpeedDialIcon, SpeedDialAction, } from '@material-ui/lab';
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ForwardIcon from '@material-ui/icons/Forward';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import ExcelCom from "../../../components/Excel_Com";
import { TextInput } from '../../../logic/FormInput';
import { getProductByCode } from '../../../logic/APIHandler';
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";
import "../../../styles/ManualTable_Styles.css";

const useStyles = makeStyles({
    tooltip: {
      fontSize: 14
    }
});  
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function WarehouseStockOpname() {

    // Page State
    const classes = useStyles();
    const history = useHistory();
    const { register, handleSubmit, errors, setValue } = useForm();
    const [data, setData] = useState([]);
    const [defaultData, setDefaultData] = useState([]);
    const [openJWT, setOpenJWT] = useState(false);
    const [openDiscard, setOpenDiscard] = useState(false);
    const [openFAB, setOpenFAB] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadSearch, setLoadSearch] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
        if ( localStorage.getItem("saved-so") ) {
            setIsLoading(true);
            const savedData = JSON.parse(localStorage.getItem("saved-so"));
            setData(savedData);
            setDefaultData(savedData);
            setIsLoading(false);
        }
    },[]);
    
    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Close success toast
    const handleCloseAlert = (event, reason) => {
        setOpenSuccessAlert(false);
        setOpenErrorAlert(false);
    };
    // CLose modal
    const handleClose = () => {
        setOpenDiscard(false);
    }

    // Save current data on local storage
    const saveTemporary = () => {
        localStorage.setItem("saved-so", JSON.stringify(defaultData));
        setOpenSuccessAlert(true);
    }
    
    // Handle search by product name & code
    const handleSearch = async (event) => {
        setLoadSearch(true);
        await sleep(100)
        let val = event.target.value;
        let result = [];
        if ( val ) {
            result = defaultData.filter(res =>
                res["name"].toLowerCase().includes(val.toLowerCase()) ||
                res["product_code"].toLowerCase().includes(val.toLowerCase())
            );
        }
        else {
            result = defaultData;
        }
        setData(result);
        setLoadSearch(false);
    }

    // Remove product from table
    const removeItem = (code) => {
        setData(data.filter(
            res => res["product_code"] !== code
        ));
        setDefaultData(defaultData.filter(
            res => res["product_code"] !== code
        ));
    };

    // Submit stock opname data to next page
    const onSubmit = async (form) => {
        setIsLoading(true);
        const isDuplicate = defaultData.filter(res => res["product_code"] === form.product_code);
        if ( isDuplicate.length > 0 ) {
            setErrorText("Produk sudah berada dalam tabel!");
            setOpenErrorAlert(true);
        }
        else {
            let resp = await getProductByCode(form.product_code);
            if ( resp[0] && resp[0].status === 200 ) {
                let result = defaultData;
                let product = resp[0].data;
                product["value"] = form.quantity;
                result.push(product);
                setData(result); 
                setDefaultData(result); 
            }
            else if ( resp[0] && resp[0].status === 204 ) {
                setErrorText("Produk tidak ditemukan!");
                setOpenErrorAlert(true);
            }
            else if ( resp[1] && resp[1].status === 401) {
                setOpenJWT(true);
            }
            else {
                setErrorText(resp[1].message);
                setOpenErrorAlert(true);
            }
        }
        setValue("product_code", "");
        setValue("quantity", "");
        setValue("search", "");
        setIsLoading(false);
    }

    // Submit stock opname data to next page
    const compareStocks = () => {
        if ( defaultData.length > 0 ) {
            localStorage.setItem("saved-so", JSON.stringify(defaultData));
            history.push("/stock-opname-compare-gudang");
        }
        else {
            setErrorText("Mohon masukkan data terlebih dulu");
            setOpenErrorAlert(true);
        }
    }

    return (
        <div className="content">
            <Sidebar
                sidebarState={(windowWidth < 768)? isSidebarOpen : ""}
                level={localStorage.getItem("level")}
            />
            
            <Modal
                open={openDiscard}
                onClose={handleClose}
                onClickCancel={handleClose}
                modalType="discard-so"
                modalTitle="Hapus seluruh data yang sudah diisi?"
                color="secondary"
                submitText="Submit"
                cancelText="Cancel"
            />
            <Modal
                open={openJWT}
                modalType="handle-jwt"
                modalTitle="Token Anda Sudah Expire"
            />

            <div className="fba-so">
                <SpeedDial
                    ariaLabel="Stock Opname Options"
                    direction="down"
                    icon={<SpeedDialIcon />}
                    onClose={() => setOpenFAB(false)}
                    onOpen={() => setOpenFAB(true)}
                    open={openFAB}
                >
                    <SpeedDialAction
                        key="next"
                        icon={<ForwardIcon/>}
                        tooltipTitle="Apply data"
                        TooltipClasses={classes}
                        onClick={() => compareStocks()}
                    />
                    <SpeedDialAction
                        key="save"
                        icon={<SaveIcon/>}
                        tooltipTitle="Save sementara data yang sudah diisi"
                        TooltipClasses={classes}
                        onClick={saveTemporary}
                    />
                    <SpeedDialAction
                        key="delete"
                        icon={<DeleteIcon/>}
                        tooltipTitle="Hapus seluruh data yang sudah diisi"
                        TooltipClasses={classes}
                        onClick={() => setOpenDiscard(true)}
                    />
                </SpeedDial>
            </div>

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <img src={logo} alt="logo" className="header-logo" />
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            {isLoading? <Spinner /> : <></>}
            
            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
                    <Alert severity="success">
                        Berhasil Save Data Sementara
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={1500} onClose={handleCloseAlert}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>

                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <h1 style={{ marginBottom: "2.2rem" }}>
                                    {`${localStorage.getItem("level") !== "1"?
                                        "Stock Opname Gudang" : ("Stock Opname Gudang - "
                                        + JSON.parse(localStorage.getItem("currBranch")).label)
                                    }`}
                                </h1>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ExcelCom
                                    type="warehouse"
                                    label="Download Seluruh Stok"
                                    excelTitle={localStorage.getItem("level") !== "1"?
                                        "Stok Gudang Keseluruhan" :
                                        `Stok Gudang Keseluruhan - ${JSON.parse(localStorage.getItem("currBranch")).label}`
                                    }
                                    sheetTitle="Data Stok Produk"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="add-margin-bottom">
                            <Grid item xs={12} md={7}>
                                <TextInput
                                    label="Kode Produk"
                                    name="product_code"
                                    placeholder="Kode Produk"
                                    className="full-width"
                                    validation={register({ required: "*kode produk harus diisi" })}
                                    errors={errors.product_code}
                                    errorClassName="text-required-no-margin"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    type="number"
                                    autoComplete='off'
                                    label="Kuantitas"
                                    name="quantity"
                                    placeholder="0"
                                    variant="outlined"
                                    className="full-width"
                                    inputRef={register({
                                        required: "*kuantitas harus diisi",
                                        pattern: {
                                            value: /^[1-9]\d*(\d+)?$/i,
                                            message: 'Mohon masukan kuantitas berupa angka',
                                        },
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors.quantity &&
                                    <p className="text-required-no-margin">
                                        {errors.quantity.message}
                                    </p>
                                }
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="full-width opname-btn"
                                >Submit</Button>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} className="add-margin-bottom">
                                <TextField
                                    name="search"
                                    variant="outlined"
                                    className="full-width manual-search"
                                    placeholder="Search"
                                    onChange={handleSearch}
                                    inputRef={register}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ overflowX: 'auto' }}>
                                <table className="manual-table">
                                    <thead>
                                        <tr>
                                            <th>Kode Produk</th>
                                            <th>Nama Produk</th>
                                            <th>Kuantitas Lama</th>
                                            <th>Kuantitas Baru</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    {loadSearch? <tbody></tbody> :
                                        <tbody>
                                            {data.map((product, idx) => ((idx % 2 !== 0)?
                                                <tr className="odd-cell" key={`product-${idx}`}>
                                                    <td>{product.product_code}</td>
                                                    <td>{product.name}</td>
                                                    <td className="center-cell">{product.stock_warehouse}</td>
                                                    <td className="center-cell">{product.value}</td>
                                                    <td className="center-cell">
                                                        <Tooltip 
                                                            title="Hapus produk dari tabel"
                                                            placement="bottom" arrow
                                                        >
                                                            <HighlightOffIcon
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => removeItem(product.product_code)}
                                                            />
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                                : <tr className="even-cell" key={`product-${idx}`}>
                                                    <td>{product.product_code}</td>
                                                    <td>{product.name}</td>
                                                    <td className="center-cell">{product.stock_warehouse}</td>
                                                    <td className="center-cell">{product.value}</td>
                                                    <td className="center-cell">
                                                        <Tooltip 
                                                            title="Hapus produk dari tabel"
                                                            placement="bottom" arrow
                                                        >
                                                            <HighlightOffIcon
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => removeItem(product.product_code)}
                                                            />
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    }
                                </table>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </div>
    )
}