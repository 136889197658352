import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import NumberFormat from "react-number-format";
import { Grid, Button, } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import DeleteIcon from '@material-ui/icons/Delete';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Table from '../../../components/Table_Com';
import Modal from '../../../components/Modal_Com';
import logo from "../../../assets/img/logo.png";
import { getDiscounts } from "../../../logic/APIHandler";

class Discount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            open: false,
            openJWT: false,
            discountId: '00000000',
            discountName: '',
            isLoading: false,
            isSidebarOpen: 'slide-out',
        }
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    columns = [
        { 
            title: "Nama", 
            field: "name",
            tooltip: "Nama diskon",
        },
        {
            title: "Jenis Diskon",
            field: "type_promo",
            tooltip: "Jenis diskon",
            render: rowData =>
                rowData.type_promo === "persen"?
                    <p>Persen</p>
                : rowData.type_promo === "rupiah"?
                    <p>Flat</p>
                : <p>Buy X Get Y</p>
        },
        {
            title: "Nilai",
            field: "value",
            tooltip: "Nilai diskon",
            render: rowData =>
                rowData.type_promo === "persen"?
                    <p>{rowData.value}%</p>
                : rowData.type_promo === "rupiah"?
                    <p>
                        <NumberFormat
                            value={rowData.value}
                            displayType={"text"}
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix={"Rp"}
                            suffix=",00"
                        />
                    </p>
                : <NavLink to={`/diskon/${rowData._id}`}
                    style={{ color: "black" }}>Lihat Detail</NavLink>
        },
    ]

    actions = [
        {
            icon: () => <FindInPageIcon />,
            tooltip: "Lihat Detail Diskon",
            onClick: (event, rowData) => {
                this.props.history.push({
                    pathname: "/diskon/" + rowData._id,
                  })
            }
        },
        {
            icon: () => <DeleteIcon />,
            tooltip: "Hapus Diskon",
            onClick: (event, rowData) => {
                this.handleOpen(rowData);
            }
        },
    ]

    // Show delete modal
    handleOpen(data) {
        this.setState({
            open: true,
            discountId: data._id,
            discountName: data.name,
        })
    }

    // CLose delete modal
    handleClose() {
        this.setState({
            open: false,
        })
    }

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }

    async getData() {
        this.setState({ isLoading: true });
        let resp = await getDiscounts();
        if ( resp[0] && resp[0].status === 200 ) {
            this.setState({
                data: resp[0].data,
                isLoading: false,
            })
        }
        else if ( resp[1] && resp[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({
                isLoading: false,
            })
        }
    }

    componentDidMount() {
        if ( localStorage.getItem("path") !== this.props.location.pathname ) {
            localStorage.removeItem("path");
            localStorage.removeItem("search");
            localStorage.removeItem("tablePage");
        }
        this.getData();
    }

    render() {
        return (
            <div className="content">
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""} level="2" />

                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="delete-discount"
                    id={this.state.discountId}
                    color="secondary"
                    modalTitle="Hapus diskon?"
                    dataName={this.state.discountName}
                    submitText="Delete"
                    cancelText="Cancel"
                />
                <Modal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                { this.state.isLoading ? ( <Spinner /> ) : <Grid container className="grid-container">
                    <Grid item xs={12} className="material-table">
                        <Table
                            tableTitle="Tabel Diskon"
                            tableColumns={this.columns}
                            data={this.state.data}
                            tableActions={this.actions}
                        />
                    </Grid>
                    <Grid item xs={12} className="table-footer">
                        <NavLink to="/diskon-form" style={{ textDecoration: "none" }}>
                            <Button variant="contained" className="add-btn-table">
                                    Tambah Diskon
                            </Button>
                        </NavLink>
                    </Grid>
                </Grid> }
            </div>
        );
    }
}

export default Discount;