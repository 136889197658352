import React, { Component } from "react";
import { Grid, TextField, Button, Snackbar, } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import NumberFormat from "react-number-format";
import MuiAlert from "@material-ui/lab/Alert";
import ReactExport from "react-data-export";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Table from '../../../components/Table_Com';
import Modal from '../../../components/Modal_Com';
import Filter from '../../../components/Filter_Com';
import logo from "../../../assets/img/logo.png";
import { getOverallSales } from "../../../logic/APIHandler";
import { formatToPrice } from "../../../logic/Handler";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class OverallCashierSales extends Component {

    constructor(props) {
        super(props);
        // Set Indonesia as moment's location
        let idLocale = require('moment/locale/id'); 
        moment.locale('id', idLocale);
        this.state = {
            totalPrice: 0,
            data: [],
            excelData: [],
            filenameRange: " (" + moment().format('MMMM YYYY') + ")",
            open: false,
            openJWT: false,
            isLoading: false,
            isSidebarOpen: 'slide-out',
            openErrorAlert: false,
            errorText: "",
            monthTitle: "(" + moment().format("MMMM YYYY") + ")",
            filterState: localStorage.getItem("start")?
                {
                    "start": moment(localStorage.getItem("start")).format("YYYY-MM-DD"),
                    "end": moment(localStorage.getItem("end")).format("YYYY-MM-DD"),
                } : 
                {
                    "start": moment().startOf('month').format('YYYY-MM-DD'),
                    "end": moment().endOf('month').format('YYYY-MM-DD'),
                },
            currBranch:
                localStorage.getItem("level") !== "1"? null
                : localStorage.getItem("currBranch")? JSON.parse(localStorage.getItem("currBranch"))
                : null,
        }
        this.excelRef = React.createRef();
        this.filterRef = React.createRef();
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleCloseError = this.handleCloseError.bind(this);
        this.filterData = this.filterData.bind(this);
        this.downloadExcel = this.downloadExcel.bind(this);
        this.handleFilterBranch = this.handleFilterBranch.bind(this);
    }

    columns = [
        { 
            title: "Kode Produk", 
            field: "product_code",
            tooltip: "Kode produk",
            cellStyle: {
                width: "150px",
                maxWidth: "150px"
            },
        },
        { 
            title: "Nama", 
            field: "name",
            tooltip: "Nama produk",
        },
        {
            title: "Harga Produk",
            field: "selling_price",
            tooltip: "Harga satuan produk",
            render: rowData =>
                <p>
                    <NumberFormat
                        value={rowData.selling_price}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix={"Rp"}
                        suffix=",00"
                    />
                </p>
        },
        { 
            title: "Kuantitas", 
            field: "quantity",
            tooltip: "Kuantitas produk yang terjual",
            cellStyle: {
                width: "100px",
                maxWidth: "100px"
            },
        },
        {
            title: "Total Terjual",
            field: "total_price",
            tooltip: "Total pendapatan per barang",
            render: rowData =>
                <p>
                    <NumberFormat
                        value={rowData.total_price}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix={"Rp"}
                        suffix=",00"
                    />
                </p>
        },
    ]

    actions = [
        {
            icon: () => <FindInPageIcon />,
            tooltip: "Lihat Detail Produk",
            onClick: (event, rowData) => {
                localStorage.removeItem("tablePageLog");
                localStorage.removeItem("tablePageLogWR");
                this.props.history.push({
                    pathname: "/produk/" + rowData._id,
                })
            }
        },
    ]

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }
    
    // Close error toast
    handleCloseError() {
        this.setState({
            openErrorAlert: false,
        });
    };

    // Filter data table
    async filterData() {
        this.setState({ isLoading: true });
        localStorage.removeItem("tablePage");
        const ref = this.filterRef.current.state;
        const branch = JSON.parse(localStorage.getItem("currBranch"));
        let resp = []; let start = ""; let end = "";
        let canFilter = true;
        // If use filter
        if ( ref.startDate || ref.endDate ) {
            if ( ref.startDate && ref.endDate ) { // Both is filled
                if ( moment(ref.endDate)-moment(ref.startDate) >= 0 ) {
                    start = moment(ref.startDate).format("YYYY-MM-DD");
                    end = moment(ref.endDate).format("YYYY-MM-DD");
                }
                else { // If start > end & vice versa
                    canFilter = false;
                    this.setState({
                        errorText: "Tanggal mulai harus lebih kecil dari tanggal akhir & sebaliknya!",
                        openErrorAlert: true,
                        isLoading: false,
                    })
                }
            }
            else if ( ref.startDate && !ref.endDate ) { // Only start filled
                start = moment(ref.startDate).format("YYYY-MM-DD");
                end = moment(ref.startDate).format("YYYY-MM-DD");
            }
            else if ( !ref.startDate && ref.endDate ) { // Only end filled
                start = moment(ref.endDate).format("YYYY-MM-DD");
                end = moment(ref.endDate).format("YYYY-MM-DD");
            }

            // If no error on input field filter
            if ( canFilter ) {
                resp = await getOverallSales("sell", start, end, branch.value);
                this.setState({
                    filterState: { "start": start, "end": end }
                })
            }
        }
        // If filter is being cleared
        else {
            resp = await getOverallSales(
                "sell",
                moment().startOf('month').format('YYYY-MM-DD'),
                moment().endOf('month').format('YYYY-MM-DD'),
                branch.value
            );
            this.setState({ filterState: null });
        }

        // If no error on input field filter
        if ( canFilter ) {
            if ( resp[0] && resp[0].status === 200 ) {
                let price = resp[0].data.report_total_price;
                price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                price = "Rp " + price + ",-";
                this.setState({
                    data: resp[0].data.items.sort((a,b) => (a.quantity < b.quantity? 1 : -1)),
                    filenameRange: (start === end)?
                        " (" + start + ")" : " (" + start + " - " + end + ")",
                    totalPrice: price,
                    isLoading: false,
                })
            }
            else {
                this.setState({
                    data: [],
                    totalPrice: "-",
                    isLoading: false,
                });
            }
        }
    }

    // Filter data by branch
    async handleFilterBranch(branch) {
        this.setState({
            isLoading: true,
            currBranch: branch,
        });
        localStorage.removeItem("tablePage");
        localStorage.removeItem("saved-so");
        localStorage.removeItem("saved-so-store");
        localStorage.setItem("currBranch", JSON.stringify(branch));
        let start = "", end = "";
        if ( localStorage.getItem("start") || localStorage.getItem("end") ) {
            start = moment(localStorage.getItem("start")).format("YYYY-MM-DD");
            end = moment(localStorage.getItem("end")).format("YYYY-MM-DD");
            if ( start === end ) {
                this.setState({ filenameRange: " (" + start + ")" });
            }
            else {
                this.setState({ filenameRange: " (" + start + " - " + end + ")" });
            }
        }
        else {
            start = moment().startOf('month').format('YYYY-MM-DD');
            end = moment().endOf('month').format('YYYY-MM-DD');
        }
        let resp = await getOverallSales("sell", start, end, branch.value);
        let price = formatToPrice(resp[0].data.report_total_price);
        if ( resp[0] && resp[0].status === 200 ) {
            this.setState({
                data: resp[0].data.items.sort((a,b) => b.quantity - a.quantity),
                totalPrice: price,
                isLoading: false,
            })
        }
        else if ( resp[1] && resp[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({
                isLoading: false,
            })
        }
    }

    // Create formatted excel data & download it
    async downloadExcel() {
        this.setState({ isLoading: true });
        let resp = [];
        const branch = JSON.parse(localStorage.getItem("currBranch"));
        if ( localStorage.getItem("start") || localStorage.getItem("end") ) {
            const start = moment(localStorage.getItem("start")).format("YYYY-MM-DD");
            const end = moment(localStorage.getItem("end")).format("YYYY-MM-DD");
            resp = await getOverallSales("sell", start, end, branch.value);
        }
        else {
            this.setState({ filenameRange: " (" + moment().format('MMMM YYYY') + ")" });
            resp = await getOverallSales(
                "sell",
                moment().startOf('month').format('YYYY-MM-DD'),
                moment().endOf('month').format('YYYY-MM-DD'),
                branch.value
            );
        }

        if ( resp[0] && resp[0].status === 200 ) {
            const result = resp[0].data.items.sort((a,b) => (a.quantity < b.quantity? 1 : -1));
            // Create columns & header style
            let res = [
                {
                    columns: [
                        {title: "No", width: {wch: 4}, style: {
                            alignment: { horizontal: "center" },
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                        }}, 
                        {title: "Kode Produk", width: {wch: 14}, style: {
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                        }},
                        {title: "Nama Produk", width: {wch: 25}, style: {
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                        }},
                        {title: "Harga Beli", width: {wch: 15}, style: {
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                        }},
                        {title: "Harga Produk", width: {wch: 15}, style: {
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                        }},
                        {title: "Kuantitas", width: {wch: 10}, style: {
                            alignment: { horizontal: "center" },
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                        }},
                        {title: "Total Harga", width: {wch: 15}, style: {
                            alignment: { horizontal: "right" },
                            font: { bold: true, color: { rgb: 'FFFFFF'} },
                            fill: { fgColor: { rgb: '007F60' } },
                        }},
                    ],
                    data: [],
                }
            ]
            
            // Add row items to table
            if ( resp[0].data.items && resp[0].data.items.length > 0 ) {
                for (let i=0; i < result.length; i++) {
                    res[0].data.push([
                        { value: (i+1), style: {alignment: { horizontal: "center" }}},
                        { value: result[i].product_code},
                        { value: result[i].name},
                        { value: result[i].purchase_price? formatToPrice(result[i].purchase_price) : "-" },
                        { value: formatToPrice(result[i].selling_price) },
                        { value: result[i].quantity, style: {alignment: { horizontal: "center" }}},
                        { value: formatToPrice(result[i].total_price), style: { alignment: { horizontal: "right" }}},
                    ])
                }
            }
            else {
                res[0].data.push([
                    { value: "-", style: {alignment: { horizontal: "center" }}},
                    { value: "-" },
                    { value: "-" },
                    { value: "-" },
                    { value: "-", style: {alignment: { horizontal: "center" }}},
                    { value: "-", style: { alignment: { horizontal: "right" }}},
                ])
            }
    
            // Set table data
            this.setState({ excelData: res, isLoading: false }, () => {
                if ( this.excelRef.current !== null ) {
                    this.excelRef.current.click();
                }
            })
        }
        else {
            this.setState({
                errorText: "Gagal memuat data. Cek kembali koneksi internet Anda.",
                openErrorAlert: true,
                isLoading: false,
            })
        }
    }

    async getData() {
        this.setState({ isLoading: true });
        let start = "", end = "";
        if ( localStorage.getItem("start") || localStorage.getItem("end") ) {
            start = moment(localStorage.getItem("start")).format("YYYY-MM-DD");
            end = moment(localStorage.getItem("end")).format("YYYY-MM-DD");
            if ( start === end ) {
                this.setState({ filenameRange: " (" + start + ")" });
            }
            else {
                this.setState({ filenameRange: " (" + start + " - " + end + ")" });
            }
        }
        else {
            start = moment().startOf('month').format('YYYY-MM-DD');
            end = moment().endOf('month').format('YYYY-MM-DD');
        }
        const branch = JSON.parse(localStorage.getItem("currBranch"));
        let resp = await getOverallSales("sell", start, end, branch.value);
        let price = formatToPrice(resp[0].data.report_total_price);
        if ( resp[0] && resp[0].status === 200 ) {
            this.setState({
                data: resp[0].data.items.sort((a,b) => b.quantity - a.quantity),
                totalPrice: price,
                isLoading: false,
            })
        }
        else if ( resp[1] && resp[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({
                isLoading: false,
            })
        }
    }

    componentDidMount() {
        if ( localStorage.getItem("path") !== this.props.location.pathname ) {
            localStorage.removeItem("path");
            localStorage.removeItem("search");
            localStorage.removeItem("tablePage");
        }
        this.getData();
    }

    render() {
        return (
            <div className="content">
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""} level="1" />

                <Modal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container" spacing={2} style={{ position: "relative" }}>
                    
                    <Snackbar open={this.state.openErrorAlert} autoHideDuration={3000} onClose={this.handleCloseError}>
                        <Alert severity="error">
                            {this.state.errorText}
                        </Alert>
                    </Snackbar>

                    <Grid item xs={12} className="with-download">
                        <Autocomplete
                            className="full-width tf-label add-margin-bottom"
                            onChange={(_, value) => this.handleFilterBranch(value)}
                            options={localStorage.getItem("branch")?
                                JSON.parse(localStorage.getItem("branch")) : []
                            }
                            getOptionLabel={(option) => option.label}
                            disableClearable={true}
                            value={this.state.currBranch}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Cabang"
                                    placeholder="Pilih"
                                    variant="outlined"
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />}
                        />
                        <Filter ref={this.filterRef} handleFilter={this.filterData} type="filter-by-date" />
                    </Grid>
                    
                    <Button
                        variant="contained"
                        className="download-btn with-branch table"
                        onClick={this.downloadExcel}
                    >
                        <CloudDownloadIcon style={{ marginRight: "10px" }} />
                        Download Excel
                    </Button>

                    { this.state.isLoading ? ( <Spinner /> ) : <>
                        <Grid item xs={12} className="material-table">
                            <Table
                                tableTitle={`Penjualan Barang Kasir ${localStorage.getItem("start")
                                    || localStorage.getItem("end")? "" : this.state.monthTitle}`}
                                tableColumns={this.columns}
                                tableActions={this.actions}
                                data={this.state.data}
                            />
                        </Grid>
                        <Grid item xs={12} className="table-footer">
                            {/* <span className="total-income-text">
                                {`Total Pendapatan: ${this.state.totalPrice}`}
                            </span> */}
                            <div style={{ display: "inline-block", width: "0", pointerEvents: "none" }}>
                                <ExcelFile 
                                    filename={`Laporan Penjualan Kasir${this.state.filenameRange?
                                        this.state.filenameRange : ""
                                    }`}
                                    element={
                                        <Button innerRef={this.excelRef}></Button>
                                    }
                                >
                                    <ExcelSheet dataSet={this.state.excelData} name="Laporan"/>
                                </ExcelFile>
                            </div>
                        </Grid></> }
                </Grid>
            </div>
        );
    }
}

export default OverallCashierSales;