import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Grid, Button } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { DateInput, TextInput, PriceInput } from "../../../logic/FormInput";
import { getStockHistoryById } from "../../../logic/APIHandler";
import { categs } from "../../../assets/data/Categories";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";
import moment from "moment";

export default function DetailStockHistory(props) {
    
    // Form State
    const [idProduct, setIDProduct] = useState("");
    const [idTransaction, setIDTransaction] = useState("");
    const [defaultDate, setDefaultDate] = useState(moment());
    const [category, setCategory] = useState("");

    // Page State
    const location = useLocation();
    const history = useHistory();
    const { control, register, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openJWT, setOpenJWT] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
        const idStockHistory = props.match.params.id;
        if ( idStockHistory ) {
            getData(idStockHistory);
        }
        else {
            console.log("ID not found");
        }     
    }, [props.match.params.id]);

    // Get initial data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = await getStockHistoryById(id);
        if ( resp[0] && resp[0].status === 200 ) {
            const result = resp[0].data;
            setCategory(categs[result.category]);
            setValue("category", categs[result.category]);
            // if ( result.transaction_id ) setDefaultDate(result.transaction_id.date.substring(0,19));
            // else setDefaultDate(result.created_at.substring(0,19));
            setDefaultDate(result.created_at.substring(0,19));
            setValue("name", result.product_id.name);
            setValue("selling_price", result.product_id.selling_price);
            setValue("prev_stock", result.prev_stock);
            setValue("curr_stock", result.curr_stock);
            setValue("quantity", result.quantity);
            setValue("notes", result.notes);
            
            setIDProduct(result.product_id._id);
            if ( result.category.includes("return") || result.category === "refund" ) {
                setIDTransaction(result.transaction_id._id);
            }
            if ( result.category === "transition" ) {
                setValue("invoice", result.transition_id.invoice);
            }
            else if ( result.category === "request-product" ) {
                setValue("invoice", result.request_product_id.invoice);
            }
            else if ( result.category === "opname" ) {
                setValue("invoice", result.opname_id.no_invoice);
            }
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp[1].message);
        }
        setIsLoading(false);
    }

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""}
                    level={localStorage.getItem("level")} />

                <Modal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />
                
                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Grid item xs={12}>
                        <form className="add-form">
                            <h1 style={{ marginBottom: "2.2rem" }}>
                                {category === "Penyesuaian"?
                                    "Detail Penyesuaian"
                                : category === "Pembelian"?
                                    "Detail Pembelian"
                                : category === "Stock Opname"?
                                    "Detail Stock Opname"
                                : category === "Request"?
                                    "Detail Request"
                                : category === "Transisi"?
                                    "Detail Transisi"
                                : category === "Losses"?
                                    "Detail Losses"
                                : "Detail Riwayat Stok"
                                }
                            </h1>

                            <Grid container>
                                <Grid item xs={12}>
                                    {category !== "Losses" && category !== "Penyesuaian" && category !== "Pembelian"?
                                        <TextInput
                                            label="No. Invoice"
                                            name="invoice"
                                            placeholder="-"
                                            className="full-width add-margin-bottom"
                                            validation={register}
                                            disabled={true}
                                        /> : <></>
                                    }
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        label="Kategori"
                                        name="category"
                                        placeholder="-"
                                        className="form-left-field add-margin-bottom"
                                        validation={register}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DateInput
                                        label="Tanggal"
                                        name="date"
                                        value={defaultDate}
                                        className="form-right-field add-margin-bottom"
                                        validation={register}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Nama Produk"
                                        name="name"
                                        placeholder="-"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                        disabled={true}
                                    />
                                </Grid>
                                {category === "Penyesuaian" || category === "Pembelian"
                                    || category === "Stock Opname"?
                                    <></> :
                                    <Grid item xs={12}>
                                        <PriceInput
                                            label="Harga Penjualan"
                                            name="selling_price"
                                            className="full-width add-margin-bottom price-field"
                                            control={control}
                                            disabled={true}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        label="Stok Sebelum"
                                        name="prev_stock"
                                        placeholder="-"
                                        className="form-left-field add-margin-bottom"
                                        validation={register}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        label="Stok Sesudah"
                                        name="curr_stock"
                                        placeholder="-"
                                        className="form-right-field add-margin-bottom"
                                        validation={register}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Kuantitas"
                                        name="quantity"
                                        placeholder="-"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        multiline={true}
                                        rows={2}
                                        label="Keterangan"
                                        name="notes"
                                        placeholder="-"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                        disabled={true}
                                    />
                                </Grid>

                                {idTransaction? <>
                                    <Grid item xs={8} sm={9} md={4}>
                                        <h3>Lihat Detail Transaksi</h3>
                                    </Grid>
                                    <Grid item xs={4} sm={3} md={2}>
                                        <Button
                                            variant="contained"
                                            className="see-detail-btn"
                                            onClick={() => location.pathname.includes("barang-retur")?
                                                history.push("/barang-retur/transaksi/" + idTransaction)
                                                : history.push("transaksi/" + idTransaction)
                                            }
                                        >Lihat</Button>
                                    </Grid> 
                                    <Grid item xs={12} md={6}></Grid></>
                                    : <></>
                                }
                                
                                <Grid item xs={8} sm={9} md={4}>
                                    <h3>Lihat Detail Produk</h3>
                                </Grid>
                                <Grid item xs={4} sm={3} md={2}>
                                    <Button
                                        variant="contained"
                                        className="see-detail-btn"
                                        onClick={() => {
                                            localStorage.removeItem("tablePage");
                                            localStorage.removeItem("tablePageLog");
                                            localStorage.removeItem("tablePageLogWR");
                                            if ( location.pathname.includes("barang-retur") ) {
                                                history.push("/barang-retur/produk/" + idProduct);
                                            }
                                            else {
                                                history.push("/produk/" + idProduct)
                                            }
                                        }}
                                    >Lihat</Button>
                                </Grid>
                                <Grid item xs={12} md={6}></Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
        </div>
    )
}