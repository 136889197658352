import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { TextInput, PhoneInput, EmailInput, NumberInput, DropdownInput, ImageInput } from "../../../logic/FormInput";
import { postAdmin } from "../../../logic/APIHandler";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddAdminOperational() {

    const numberRegex = /^[0-9]*$/;

    // Form State
    const [nik, setNIK] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    // Modal State
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    
    // Page State
    const { register, handleSubmit, control, errors } = useForm();
    const [openJWT, setOpenJWT] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    // Handle onChange NIK & prevent user to input characters
    const onChangeNIK = (event) => {
        if ( numberRegex.test(event.target.value) ) {
            setNIK(event.target.value);
        }
    }

    // Handle onChange phone number & prevent user to input characters
    const onChangePhoneNumber = (event) => {
        const value = event.target.value;
        if ( value ) {
            // allow + and number as first input
            if ( value.length === 1 ) {
                if ( value === '+' || numberRegex.test(value) ) {
                    setPhoneNumber(event.target.value);
                }
            }
            // only allow numbers after first input
            else if ( value.length <= 16 ) {
                if ( numberRegex.test(value.substring(1)) ) {
                    setPhoneNumber(event.target.value);
                }
            }
        }
        else {
            setPhoneNumber(event.target.value);
        }
    }

    // Close success toast
    const handleCloseSuccess = () => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = () => {
        setOpenErrorAlert(false);
    };
    // Close modal
    const handleClose = () => {
        setOpen(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send new data to server
    const onSubmit = async (data) => {
        setIsLoading(true);
        let resp = await postAdmin(data);
        if (resp[0] && resp[0].status === 200) {
            localStorage.setItem("currBranch", JSON.stringify(data.branch));
            setEmail(resp[0].data.email);
            setPassword(resp[0].data.password);
            setIsLoading(false);
            setOpenSuccessAlert(true);
            setOpen(true);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            setErrorText(resp[1].message);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""}
                    level={localStorage.getItem("level")} />
                
                <Modal
                    open={open}
                    onClose={handleClose}
                    onClickCancel={handleClose}
                    modalType="create-admin-info"
                    color="primary"
                    modalTitle="Informasi Akun"
                    emailCallback={email}
                    passwordCallback={password}
                />
                <Modal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Membuat Admin Baru
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2.2rem" }}>
                                Tambah Admin Baru
                            </h1>

                            <Grid container>
                                {localStorage.getItem("level") === "1"?
                                    <Grid item xs={12} style={{ position: "relative" }}>
                                        <label className="selection-label">Cabang *</label>
                                        <DropdownInput
                                            name="branch"
                                            className="selection-field add-margin-bottom"
                                            control={control}
                                            options={localStorage.getItem("branch")?
                                                JSON.parse(localStorage.getItem("branch")) : []
                                            }
                                            defaultValue={
                                                JSON.parse(localStorage.getItem("currBranch"))
                                            }
                                            rules={{ required: "*cabang penempatan admin harus diisi" }}
                                            errors={errors.branch}
                                            errorClassName="text-required"
                                            isClearable={false}
                                        />
                                    </Grid> : <></>
                                }
                                <Grid item xs={6}>
                                    <NumberInput
                                        label="NIK *"
                                        name="nik"
                                        placeholder="NIK"
                                        className="form-left-field add-margin-bottom"
                                        value={nik}
                                        onChange={onChangeNIK}
                                        validation={register}
                                        requiredText="*NIK admin harus diisi"
                                        errors={errors.nik}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={6} style={{ position: "relative" }}>
                                    <label className="selection-label">Jenis Pekerjaan *</label>
                                    <DropdownInput
                                        name="jobdesk"
                                        className="selection-field add-margin-bottom"
                                        control={control}
                                        options={
                                            localStorage.getItem("level") === "1"?
                                            [
                                                { label: "Manajer Store", value: "operasional" },
                                                { label: "Manajer Keuangan", value: "keuangan" },
                                            ]
                                            : [
                                                { label: "Manajer Keuangan", value: "keuangan" },
                                                { label: "Inventaris", value: "inventaris" },
                                                { label: "Kasir", value: "kasir" },
                                            ]
                                        }
                                        defaultValue={null}
                                        rules={{ required: "*jenis pekerjaan admin harus diisi" }}
                                        errors={errors.jobdesk}
                                        errorClassName="text-required-half"
                                        isClearable={false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Nama *"
                                        name="name"
                                        placeholder="Nama Lengkap"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*nama lengkap admin harus diisi" })}
                                        errors={errors.name}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <EmailInput
                                        label="Email *"
                                        name="email"
                                        placeholder="Email Admin"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                        required={true}
                                        errors={errors.email}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12} className="add-margin-bottom">
                                    <PhoneInput
                                        label="Nomor HP *"
                                        name="phone"
                                        placeholder="Nomor HP"
                                        className="full-width add-margin-bottom"
                                        value={phoneNumber}
                                        onChange={onChangePhoneNumber}
                                        validation={register}
                                        requiredText="*nomor HP admin harus diisi"
                                        errors={errors.phone}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ImageInput
                                        name="ktp_image"
                                        label="Foto KTP"
                                        validation={register}
                                        isEdit={true}
                                        control={control}
                                        defaultImg={false}
                                        canDelete={true}
                                        overallClassName="half-input-image"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ImageInput
                                        name="npwp_image"
                                        label="Foto NPWP"
                                        validation={register}
                                        isEdit={true}
                                        control={control}
                                        defaultImg={false}
                                        canDelete={true}
                                        overallClassName="half-input-image"
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>

                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}