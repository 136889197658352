import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import { Grid, Button, } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import DeleteIcon from '@material-ui/icons/Delete';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Table from '../../../components/Table_Com';
import Modal from '../../../components/Modal_Com';
import logo from "../../../assets/img/logo.png";
import { getSuppliers } from "../../../logic/APIHandler";

class Supplier extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            open: false,
            openJWT: false,
            supplierId: '00000000',
            supplierName: '',
            isLoading: false,
            isSidebarOpen: 'slide-out',
        }
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    columns = [
        { 
            title: "Nama Perusahaan", 
            field: "name",
            tooltip: "Nama perusahaan",
            cellStyle: {
                width: "250px",
                maxWidth: "250px"
            },
        },
        {
            title: "Email",
            field: "email",
            tooltip: "Email supplier",
            render: rowData => rowData.email?
                <p>{rowData.email}</p> : <p>-</p> 
        },
        {
            title: "PIC",
            field: "pic",
            tooltip: "PIC (Person In Contact)",
            cellStyle: {
                width: "250px",
                maxWidth: "250px"
            },
        },
        {
            title: "Kontak PIC",
            field: "contact_pic",
            tooltip: "Nomor kontak PIC",
        },
    ]

    actions = [
        {
            icon: () => <FindInPageIcon />,
            tooltip: "Lihat Detail Supplier",
            onClick: (event, rowData) => {
                this.props.history.push({
                    pathname: "/supplier/" + rowData._id,
                  })
            }
        },
        {
            icon: () => <DeleteIcon />,
            tooltip: "Hapus Supplier",
            onClick: (event, rowData) => {
                this.handleOpen(rowData);
            }
        },
    ]

    // Show delete modal
    handleOpen(data) {
        this.setState({
            open: true,
            supplierId: data._id,
            supplierName: data.name,
        })
    }

    // CLose delete modal
    handleClose() {
        this.setState({
            open: false,
        })
    }

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }

    async getData() {
        this.setState({ isLoading: true });
        let resp = await getSuppliers();
        if ( resp[0] && resp[0].status === 200 ) {
            this.setState({
                data: resp[0].data,
                isLoading: false,
            })
        }
        else if ( resp[1] && resp[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({
                isLoading: false,
            })
        }
    }

    componentDidMount() {
        if ( localStorage.getItem("path") !== this.props.location.pathname ) {
            localStorage.removeItem("path");
            localStorage.removeItem("search");
            localStorage.removeItem("tablePage");
        }
        this.getData();
    }

    render() {
        return (
            <div className="content">
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""} level="1" />

                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="delete-supplier"
                    id={this.state.supplierId}
                    color="secondary"
                    modalTitle="Hapus supplier?"
                    dataName={this.state.supplierName}
                    submitText="Delete"
                    cancelText="Cancel"
                />
                <Modal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                { this.state.isLoading ? ( <Spinner /> ) : <Grid container className="grid-container">
                    <Grid item xs={12} className="material-table">
                        <Table
                            tableTitle="Tabel Supplier"
                            tableColumns={this.columns}
                            data={this.state.data}
                            tableActions={this.actions}
                        />
                    </Grid>
                    <Grid item xs={12} className="table-footer">
                        <NavLink to="/supplier-form" style={{ textDecoration: "none" }}>
                            <Button variant="contained" className="add-btn-table">
                                    Tambah Supplier
                            </Button>
                        </NavLink>
                    </Grid>
                </Grid> }
            </div>
        );
    }
}

export default Supplier;