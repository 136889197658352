import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import { Autocomplete } from "@material-ui/lab";
import { Grid, Button, Snackbar,TextField } from "@material-ui/core";
// import ReactExport from "react-data-export";
import MuiAlert from "@material-ui/lab/Alert";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import FindInPageIcon from '@material-ui/icons/FindInPage';
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Table from '../../../components/Table_Com';
import Modal from "../../../components/Modal_Com";
import logo from "../../../assets/img/logo.png";
import { getRequests } from "../../../logic/APIHandler";
import "../../../styles/ManualTable_Styles.css";
import "../../../styles/Pagination_Styles.css";
import moment from "moment";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class RequestPage extends Component {

    constructor(props) {
        super(props);
        // Set Indonesia as moment's location
        let idLocale = require('moment/locale/id'); 
        moment.locale('id', idLocale);
        this.state = {
            data: [],
            // excelData: [],
            // filenameRange: "",
            selectedId: "",
            openJWT: false,
            openModal: false,
            openDecline: false,
            isLoading: false,
            isSidebarOpen: 'slide-out',
            openErrorAlert: false,
            errorText: "",
            currBranch:
                localStorage.getItem("level") !== "1"? null
                : localStorage.getItem("currBranch")? JSON.parse(localStorage.getItem("currBranch"))
                : null,
        }
        // this.excelRef = React.createRef();
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
        // this.downloadExcel = this.downloadExcel.bind(this);
        this.handleFilterBranch = this.handleFilterBranch.bind(this);
    }

    columns = [
        {
            title: "Tanggal",
            field: "created_at",
            tooltip: "Tanggal pembuatan request produk",
            render: rowData =>
                <p>{moment(rowData.created_at).format("DD MMMM YYYY HH:mm:ss")}</p>
        },
        {
            title: "No. Invoice", 
            field: "invoice",
            tooltip: "Nomor invoice request",
            render: rowData => rowData.invoice?
                <p>{rowData.invoice}</p> : <p>-</p>
        },
        {
            title: "Status",
            field: "status",
            tooltip: "Status pembelian (sudah/belum di-approve)",
            render: rowData =>
                rowData.status === "approve"?
                    <span className="status-approved">APPROVED</span>
                : rowData.status === "validate"?
                    <span className="status-validated">VALIDATED</span>
                : rowData.status === "done"?
                    <span className="status-done">DONE</span>
                : rowData.status === "decline"?
                    <span className="status-declined">DECLINED</span>
                :<span className="status-pending">PENDING</span>
        },
    ]
    
    actions = [
        {
            icon: () => <FindInPageIcon />,
            tooltip: "Lihat Detail Request",
            onClick: (event, rowData) => {
                this.props.history.push({
                    pathname: "request/" + rowData._id,
                })
            }
        },
    ]

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }

    // Close toast or modal
    handleClose() {
        this.setState({
            openModal: false,
            openDecline: false,
            openErrorAlert: false,
        });
    };
    
    async handleFilterBranch(branch) {
        this.setState({
            isLoading: true,
            currBranch: branch,
        });
        localStorage.removeItem("tablePage");
        localStorage.removeItem("saved-so");
        localStorage.removeItem("saved-so-store");
        localStorage.setItem("currBranch", JSON.stringify(branch));
        let resp = await getRequests(branch.value);
        if ( resp[0] && resp[0].status === 200 ) {
            this.setState({
                data: resp[0].data,
            })
        }
        else if ( resp[0] && resp[0].status === 204 ) {
            this.setState({
                data: [],
            })
        }
        else {
            console.log(resp);
            this.setState({
                data: [],
                errorText: "Gagal mem-filter berdasarkan cabang.",
                openErrorAlert: true,
            })
        }
        this.setState({ isLoading: false });
    }

    // Get initial data
    async getData() {
        this.setState({ isLoading: true });
        let resp = "";
        if ( localStorage.getItem("level") === "1" ) {
            if ( localStorage.getItem("currBranch") ) {
                const branch = JSON.parse(localStorage.getItem("currBranch"));
                resp = await getRequests(branch.value);
            }
        }
        else {
            resp = await getRequests();
        }
        if ( resp[0] && resp[0].status === 200 ) {
            this.setState({
                data: resp[0].data,
                isLoading: false,
            })
        }
        else if ( resp[1] && resp[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({
                isLoading: false,
            })
        }
    }

    // Create formatted excel data & download it
    // async downloadExcel() {
    //     this.setState({ isLoading: true });
    //     const result = this.state.data.filter(res =>
    //         res["approval_manager_finance"] === "approve"
    //     );
    //     // Create columns & header style
    //     let res = [
    //         {
    //             columns: [
    //                 {title: "No", width: {wch: 4}, style: {
    //                     alignment: { horizontal: "center" },
    //                     font: { bold: true, color: { rgb: 'FFFFFF'} },
    //                     fill: { fgColor: { rgb: '007F60' } },
    //                 }}, 
    //                 {title: "Tanggal", width: {wch: 17}, style: {
    //                     font: { bold: true, color: { rgb: 'FFFFFF'} },
    //                     fill: { fgColor: { rgb: '007F60' } },
    //                 }},
    //                 {title: "Waktu", width: {wch: 8}, style: {
    //                     font: { bold: true, color: { rgb: 'FFFFFF'} },
    //                     fill: { fgColor: { rgb: '007F60' } },
    //                 }},
    //                 {title: "No. Invoice", width: {wch: 12}, style: {
    //                     font: { bold: true, color: { rgb: 'FFFFFF'} },
    //                     fill: { fgColor: { rgb: '007F60' } },
    //                 }},
    //                 {title: "Kode Produk", width: {wch: 12}, style: {
    //                     font: { bold: true, color: { rgb: 'FFFFFF'} },
    //                     fill: { fgColor: { rgb: '007F60' } },
    //                 }},
    //                 {title: "Nama Produk", width: {wch: 25}, style: {
    //                     font: { bold: true, color: { rgb: 'FFFFFF'} },
    //                     fill: { fgColor: { rgb: '007F60' } },
    //                 }},
    //                 {title: "Perusahaan Supplier", width: {wch: 25}, style: {
    //                     font: { bold: true, color: { rgb: 'FFFFFF'} },
    //                     fill: { fgColor: { rgb: '007F60' } },
    //                 }},
    //                 {title: "Keterangan", width: {wch: 40}, style: {
    //                     font: { bold: true, color: { rgb: 'FFFFFF'} },
    //                     fill: { fgColor: { rgb: '007F60' } },
    //                 }},
    //                 {title: "Kuantitas", width: {wch: 10}, style: {
    //                     alignment: { horizontal: "center" },
    //                     font: { bold: true, color: { rgb: 'FFFFFF'} },
    //                     fill: { fgColor: { rgb: '007F60' } },
    //                 }}, 
    //                 {title: "Total Harga", width: {wch: 15}, style: {
    //                     alignment: { horizontal: "right" },
    //                     font: { bold: true, color: { rgb: 'FFFFFF'} },
    //                     fill: { fgColor: { rgb: '007F60' } },
    //                 }},
    //             ],
    //             data: [],
    //         }
    //     ]
        
    //     // Add row items to table
    //     for (let i=0; i < result.length; i++) {
    //         // Format price
    //         let totalPrice = result[i].total_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    //         totalPrice = "Rp " + totalPrice + ",-";

    //         res[0].data.push([
    //             { value: (i+1), style: {alignment: { horizontal: "center" }}},
    //             { value: moment(result[i].date).format("DD MMMM YYYY") },
    //             { value: moment(result[i].date).format("HH:mm:ss") },
    //             { value: result[i].receipt? result[i].receipt : "-"},
    //             { value: result[i].items[0].product_id.product_code},
    //             { value: result[i].items[0].product_id.name},
    //             { value: result[i].items[0].product_id.supplier_id.name},
    //             { value: result[i].notes? result[i].notes : "-"},
    //             { value: result[i].items[0].quantity, style: {alignment: { horizontal: "center" }}},
    //             { value: totalPrice, style: { alignment: { horizontal: "right" }}},
    //         ])
    //     }

    //     // Set table data
    //     this.setState({ excelData: res, isLoading: false }, () => {
    //         if ( this.excelRef.current !== null ) {
    //             this.excelRef.current.click();
    //         }
    //     })
    // }

    componentDidMount() {
        if ( localStorage.getItem("path") !== this.props.location.pathname ) {
            localStorage.removeItem("path");
            localStorage.removeItem("search");
            localStorage.removeItem("tablePage");
        }
        this.getData();
    }

    render() {
        return (
            <div className="content">
                <Sidebar
                    sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""}
                    level={localStorage.getItem("level")}
                />

                <Modal
                    open={this.state.openModal}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="approve-trx"
                    id={this.state.selectedId}
                    modalTitle="Lengkapi Data Transaksi"
                    submitText="Submit"
                    cancelText="Cancel"
                />
                <Modal
                    open={this.state.openDecline}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="decline-trx"
                    id={this.state.selectedId}
                    color="secondary"
                    modalTitle="Tolak transaksi pembelian?"
                    dataName={this.state.productName}
                    submitText="Tolak"
                    cancelText="Cancel"
                />
                <Modal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container" style={{ position: "relative" }}>
                    {localStorage.getItem("level") === "1"?
                        <Grid item xs={12} className="add-margin-bottom">
                            <Autocomplete
                                className="full-width tf-label"
                                onChange={(_, value) => this.handleFilterBranch(value)}
                                options={localStorage.getItem("branch")?
                                    JSON.parse(localStorage.getItem("branch")) : []
                                }
                                getOptionLabel={(option) => option.label}
                                disableClearable={true}
                                value={this.state.currBranch}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Cabang"
                                        placeholder="Pilih"
                                        variant="outlined"
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />}
                            />
                        </Grid> : <></>
                    }
                    {/* <Button
                        variant="contained"
                        className="download-btn table"
                        onClick={this.downloadExcel}
                    >
                        <CloudDownloadIcon style={{ marginRight: "10px" }}/>
                        Download Excel
                    </Button> */}

                    <Snackbar open={this.state.openErrorAlert} autoHideDuration={3000} onClose={this.handleClose}>
                        <Alert severity="error">
                            {this.state.errorText}
                        </Alert>
                    </Snackbar>

                    { this.state.isLoading ? ( <Spinner /> ) : <>
                        <Grid item xs={12} className="material-table add-margin-top">
                            <Table
                                tableTitle="Tabel Request"
                                tableColumns={this.columns}
                                data={this.state.data}
                                tableActions={this.actions}
                            />
                        </Grid>
                        {localStorage.getItem("level") !== "1"?
                            <Grid item xs={12} className="table-footer">
                                <NavLink to="/request-form" style={{ textDecoration: "none" }}>
                                    <Button variant="contained" className="add-btn-table">
                                        Request Produk
                                    </Button>
                                </NavLink>
                                {/* <div style={{ display: "inline-block", width: "0", pointerEvents: "none" }}>
                                    <ExcelFile 
                                        filename={`Laporan Pembelian Barang${this.state.filenameRange?
                                            this.state.filenameRange : ""
                                        }`}
                                        element={
                                            <Button innerRef={this.excelRef}></Button>
                                        }
                                    >
                                        <ExcelSheet dataSet={this.state.excelData} name="Laporan"/>
                                    </ExcelFile>
                                </div> */}
                            </Grid> : <></>
                        }
                    </> }
                </Grid>
            </div>
        );
    }
}

export default RequestPage;