import jsPDF from "jspdf";
import 'jspdf-autotable';
import moment from "moment";
import iconSenwell from '../assets/img/icon-only.png';

/**
 * Format price input into number only
 * @param data: formatted price
 */
 export const formatPrice = (data) => { 
    let price = "";
    if ( data ) {
        if ( typeof data === "string" ) {
            for ( let i=0; i < data.length; i++ ) {
                const value = parseInt(data.charAt(i));
                if ( value >= 0 && value <= 9 ) {
                    price += value;
                }
            }
            return parseInt(price);
        }
        else {
            return data;
        }
    }
    else return "";
}

/**
 * Format number into Rupiah currency
 * @param data: unformatted price
 */
 export const formatToPrice = (data) => { 
    if ( data ) {
        return (
            "Rp " + data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        );
    }
    else if ( data === 0 ) {
      return "Rp 0";
    }
    else {
      return "";
    }
}

/**
 * Format date to match API's format
 * @param data: unformatted date
 */
 export const formatDate = (data) => {
    if ( data ) {
        let formattedDate = data.substring(6,10) + "-"
            + data.substring(3,5) + "-" + data.substring(0,2);
        return formattedDate;
    }
    else return "";
}

/**
 * Export string to text file
 * @param title: text file's title
 * @param content: text file's content in array
 */
 export const exportToFile = (title, content) => {
    if ( title && content ) {
      const element  = document.createElement('a');
      const file = new Blob(content, {type: 'text/plain;charset=utf-8'});
      element.href = URL.createObjectURL(file);
      element.download = (title + ".txt");
      document.body.appendChild(element);
      element.click();
    }
}

/**
 * Format branch address for PDF delivery order purpose
 * @param branch: branch's detail address
 */
 export const formatDeliveryOrderAddress = (branch) => {
    if ( branch ) {
      let result = [];
      // Check top address
      let topAddress = branch.address;
      if ( branch.kelurahan ) {
        topAddress += (", " + branch.kelurahan);
      }
      if ( branch.kecamatan || branch.kabupaten || branch.provinsi || branch.kodepos ) {
        topAddress += ",";
      }
      result.push(topAddress);
      //Check middle address
      let middleAddress = "";
      if ( branch.kecamatan && branch.kabupaten ) {
        middleAddress = branch.kecamatan + ", " + branch.kabupaten;
      }
      else if ( branch.kecamatan && !branch.kabupaten ) {
        middleAddress = branch.kecamatan;
      }
      else if ( !branch.kecamatan && branch.kabupaten ) {
        middleAddress = branch.kabupaten;
      }
      if ( middleAddress && (branch.provinsi || branch.kodepos) ) {
        middleAddress += ",";
        result.push(middleAddress);
      }
      else if ( middleAddress ) {
        result.push(middleAddress);
      }
      //Check bottom address
      if ( branch.provinsi && branch.kodepos ) {
        result.push(branch.provinsi + ", " + branch.kodepos);
      }
      else if ( branch.provinsi && !branch.kodepos ) {
        result.push(branch.provinsi);
      }
      else if ( !branch.provinsi && branch.kodepos ) {
        result.push(branch.kodepos);
      }
      return result;
    }
    else return [];
}

/**
 * Download delivery order in PDF format
 * @param data: sell-warehouse transaction data
 * @param firstIdx: first index of items
 * @param lastIdx: last index of items
 * @param isLastPage: flag if current PDF is last page
 * @param type: print / full
 */
export function downloadDeliveryOrderPDF(data, firstIdx, lastIdx, isLastPage, type) {
    let cellBodyData = [];

    let pdf = new jsPDF("landscape", "pt", "a5");
    // Senwell Icon
    pdf.addImage(iconSenwell, "JPEG", 24, 10, 95, 78);

    // Senwell name & location
    pdf.setFontSize(10);
    pdf.setFont(undefined, 'bold');
    pdf.text("SENWELL", 110, 30);
    pdf.text("PT. Trans International Sejahtera", 110, 42);
    pdf.setFontSize(8);
    pdf.setFont(undefined, 'normal');
    pdf.text(data.address[0], 110, 53);
    if ( data.address.length >= 2 ) {
      pdf.text(data.address[1], 110, 64);
    }
    if ( data.address.length >= 3 ) {
      pdf.text(data.address[2], 110, 75);
    }

    // Invoice Code
    pdf.setFontSize(10);
    pdf.setFont(undefined, 'bold');
    pdf.text(40, 93, "SURAT JALAN PENGIRIMAN BARANG");
    pdf.setLineWidth(1);
    pdf.line(40, 98, 220, 98);
    pdf.setFontSize(8);
    pdf.text(40, 108, "No. Nota - " + data.no_invoice);
    
    // Delivery Order Data
    pdf.setFont(undefined, 'normal');
    pdf.text(370, 30, "Tgl Penjualan");
    pdf.text(437, 30, ": " + moment(data.date).format("DD-MM-YYYY"));
    pdf.text(370, 42, "Pembeli");
    pdf.text(437, 42, ": " + data.details_transaction_warehouse.buyer_name);
    pdf.text(370, 54, "No HP Pembeli");
    pdf.text(437, 54, ": " + data.details_transaction_warehouse.buyer_phone);
    pdf.text(370, 66, "Supir/Kurir");
    pdf.text(437, 66, ": " + data.details_transaction_warehouse.driver_name);
    pdf.text(370, 78, "No HP Supir/Kurir");
    pdf.text(437, 78, ": " + data.details_transaction_warehouse.driver_phone);
    
    // Delivery Order Destination
    pdf.text(370, 90, "Tujuan");
    pdf.text(437, 90, ": ");
    let destination = pdf.splitTextToSize(
      data.details_transaction_warehouse.delivery_destination, 140
    );
    let yOrig;
    for (let index = 0; index < destination.length; index++) {
      yOrig = 90 + index * 12;
      pdf.text(441, yOrig, destination[index]);
    }

    let items = [];
    for ( let i = firstIdx; i <= lastIdx; i++ ) {
      if ( data.items[i] ) {
        items.push([
          data.items[i].product_id.name,
          data.items[i].quantity,
          formatToPrice(data.items[i].selling_price),
          formatToPrice(data.items[i].total_price),
        ]);
      }
    }

    pdf.autoTable({
      head: [[
        "NAMA BARANG",
        {content: "JUMLAH", styles: { halign: 'center'}},
        {content: "HARGA SATUAN", styles: { halign: 'right'}},
        {content: "HARGA TOTAL", styles: { halign: 'right'}},
      ]],
      body: items,
      startY: 118,
      theme: "grid",
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 1,
        fontSize: 8,
        minCellHeight: 20
      },
      headStyles: {
        fillColor: [0, 127, 96],
        textColor:[255,255,255],
        // halign: "center",
        valign: "middle",
        minCellHeight: 0,
      },
      bodyStyles: {
        // halign: "center",
        cellWidth: "wrap",
        textColor:[0,0,0]
      },
      columnStyles: {
        0: { cellWidth: 194 },
        1: { cellWidth: 66, halign: "center" },
        2: { cellWidth: 127, halign: "right" },
        3: { cellWidth: 127, halign: "right" }
      },
      didDrawCell: data => {
        data.section === "body" && cellBodyData.push(data.cell);
      }
    });

    // Print only on last page
    if ( isLastPage ) {
      // Total Price
      pdf.setLineWidth(1);
      pdf.setDrawColor('black');
      pdf.line(427, pdf.lastAutoTable.finalY, 427, pdf.lastAutoTable.finalY + 20);
      pdf.line(427, pdf.lastAutoTable.finalY + 20, 554, pdf.lastAutoTable.finalY + 20);
      pdf.line(554, pdf.lastAutoTable.finalY, 554, pdf.lastAutoTable.finalY + 20);
      pdf.setFontSize(8);
      let finalPrice = data.total_price - data.details_transaction_warehouse.postal_fee;
      finalPrice = formatToPrice(finalPrice);
      let startX = 387 + (554 - 427) - finalPrice.length;
      if ( finalPrice.length <= 9 ) startX += 7;
      else if ( finalPrice.length > 9 && finalPrice.length <= 11 ) startX += 4;
      else if ( finalPrice.length === 12 ) startX -= 1;
      else if ( finalPrice.length === 13 ) startX -= 4;
      else if ( finalPrice.length === 14 ) startX -= 7;
      else if ( finalPrice.length > 14 ) startX -= 10;
      pdf.text(startX, pdf.lastAutoTable.finalY + 13, finalPrice); 
    }
    
    // Notes
    let notes = data.notes? data.notes : "-";
    pdf.text(40, pdf.lastAutoTable.finalY + 35, "Keterangan Tambahan :");
    notes = pdf.splitTextToSize(notes, 514);
    let yOrigNotes = 0;
    for (let index = 0; index < notes.length; index++) {
      yOrigNotes = pdf.lastAutoTable.finalY + 50 + index * 12;
      pdf.text(40, yOrigNotes, notes[index]);
    }

    // Signature Section
    pdf.setFontSize(8);
    pdf.text(57, 330, "TTD Pembeli");
    pdf.text(40, 390, "(                                  )");
    pdf.text(500, 330, "Admin");
    pdf.text(470, 390, "(                                  )");
  
    if ( type === "preview" ) {
      window.open(pdf.output("bloburl"));
    }
    else if ( type === "print" ) {
      pdf.save('Surat Jalan ' + data.no_invoice + " " + data.page + '.pdf');
    }
    else {
      pdf.save('Surat Jalan ' + data.no_invoice + " " + data.page + '.pdf');
    }
}

/**
 * Download picking order in PDF format
 * @param data: transition data
 */
 export function downloadPickingOrderPDF(data) {
   let pdf = new jsPDF("potrait", "pt", "a5");

    // Title
    const xIcon = (pdf.internal.pageSize.width / 2) - 44;
    pdf.addImage(iconSenwell, "JPEG", xIcon, 0, 95, 78);
    pdf.setFontSize(12);
    pdf.setFont(undefined, 'bold');
    pdf.text("SENWELL", pdf.internal.pageSize.width / 2, 75, "center");

    // Subtitle
    pdf.setFontSize(8);
    pdf.setFont(undefined, 'normal');
    pdf.text("Cabang : " + data.branch, 40, 100);
    pdf.text("Invoice : " + data.invoice, 40, 115);
    pdf.text("Tanggal : " + data.date, 40, 130);


    let totalPage = 1;
    if ( data.items.length > 12 ) {
      totalPage = 1 + Math.ceil((data.items.length - 12) / 16);
    }
    for ( let page = 1; page <= totalPage; page++ ) {
      // Table Items
      let tableData = [];
      const startIdx = page === 1? 0 : page === 2? 12 : (12 + (page-2)*16);
      const maxTable = page === 1? 12 : (12 + ((page-1) * 16));
      for ( let i = startIdx; i < maxTable; i++ ) {
        let obj = ["","",""];
        if ( data.items[i] ) {
          obj = [
              i+1,
              data.items[i].product_id.product_code,
              data.items[i].quantity,
          ];
        }
        tableData.push(obj);
      }
  
      // Draw Table
      pdf.autoTable({
          head: [[
            "No.",
            "Produk", 
            {content: "Qty", styles: { halign: 'right'}},
          ]],
          body: tableData,
          startY: page === 1? 145 : (pdf.lastAutoTable.finalY + 300),
          theme: "grid",
          styles: {
              lineColor: [0, 0, 0],
              lineWidth: 1,
              fontSize: 8,
              minCellHeight: 25,
          },
          headStyles: {
              fillColor: [255, 255, 255],
              textColor:[0,0,0],
              halign: "left",
              valign: "middle",
              minCellHeight: 0,
          },
          bodyStyles: {
              halign: "left",
              valign: "middle",
              cellWidth: "wrap",
              fontSize: 8,
          },
          columnStyles: {
              0: { cellWidth: 35 },
              1: { cellWidth: 250 },
              2: { cellWidth: 50, halign: "right" },
          },
          didDrawCell: (data) => {
              data.section === "body" && tableData.push(data.cell);
          },
      });
    }

    // Notes Section
    if ( data.notes ) {
      pdf.text(40, pdf.internal.pageSize.height - 115, "Keterangan :");
      const notes = pdf.splitTextToSize(data.notes, 330);
      let yOrigNotes = 0;
      for (let index = 0; index < notes.length; index++) {
        yOrigNotes = pdf.internal.pageSize.height - 105 + index * 10;
        pdf.text(40, yOrigNotes, notes[index]);
      }
    }

    // Signature Section
    pdf.text(53, pdf.internal.pageSize.height - 75, "Pengirim");
    pdf.text(40, pdf.internal.pageSize.height - 30,
      "(                       )");
    pdf.text(200, pdf.internal.pageSize.height - 75, "Input");
    pdf.text(182, pdf.internal.pageSize.height - 30,
      "(                       )");
    pdf.text(325, pdf.internal.pageSize.height - 75, "Penerima");
    pdf.text(315, pdf.internal.pageSize.height - 30,
      "(                       )");

    pdf.save('Picking Order ' + data.invoice + '.pdf');
}

/**
 * [ Laporan Laba Rugi - Laporan Keseluruhan ]
 * Create content for Overall Report excel sheet
 * @param data: data needed to create excel content
 */
 export const createOverallReportExcel = (data) => {
  let res = [
    {
      columns: [
        {title: "DATA PENDAPATAN & PENGELUARAN", width: {wch: 40}, style: {
          font: { bold: true },
        }},
        {title: "", width: {wch: 15}, style: {
          font: { bold: true },
        }},
      ],
      data: [],
    }
  ]

  // Add row items to table
  let printSales = formatToPrice(data.gross_income);
  res[0].data.push([
    { value: "Total Penjualan" },
    { value: printSales, style: { alignment: { horizontal: "right" }} },
  ])

  let printSalesDisc = formatToPrice(data.total_discount);
  res[0].data.push([
    { value: "Total Diskon" },
    { value: printSalesDisc, style: { alignment: { horizontal: "right" }} },
  ])

  let printSalesTaxes = formatToPrice(data.total_taxes);
  res[0].data.push([
    { value: "Total Pajak" },
    { value: printSalesTaxes, style: { alignment: { horizontal: "right" }} },
  ])

  let printSalesExpenses = formatToPrice(data.total_extra_expenses);
  res[0].data.push([
    { value: "Total Pengeluaran Tambahan" },
    { value: printSalesExpenses, style: { alignment: { horizontal: "right" }} },
  ])

  res[0].data.push([
    { value: "DATA TRANSAKSI", style: { font: { bold: true } } },
    { value: "" },
  ])

  res[0].data.push([
    { value: "Total Transaksi Penjualan Kasir" },
    { value: data.total_cashier_transaction, style: { alignment: { horizontal: "right" }} },
  ])

  res[0].data.push([
    { value: "Total Transaksi Penjualan Gudang" },
    { value: data.total_warehouse_transaction, style: { alignment: { horizontal: "right" }} },
  ])

  return res;
}

/**
 * [ Laporan Laba Rugi - Kasir ]
 * Create content for Cashier Report excel sheet
 * @param data: overall cashier x transactions
 */
 export const createCashierReportExcel = (data) => {
  let res = [
    {
      columns: [
        {title: "No", width: {wch: 4}, style: {
          alignment: { horizontal: "center" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }}, 
        {title: "Tanggal", width: {wch: 17}, style: {
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Waktu", width: {wch: 8}, style: {
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "No. Invoice", width: {wch: 28}, style: {
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Jenis Pembayaran", width: {wch: 18}, style: {
            font: { bold: true, color: { rgb: 'FFFFFF'} },
            fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Nama Bank", width: {wch: 20}, style: {
            font: { bold: true, color: { rgb: 'FFFFFF'} },
            fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Diskon", width: {wch: 15}, style: {
          alignment: { horizontal: "right" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Pajak", width: {wch: 15}, style: {
          alignment: { horizontal: "right" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Total Harga", width: {wch: 15}, style: {
          alignment: { horizontal: "right" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Total Keseluruhan", width: {wch: 20}, style: {
          alignment: { horizontal: "right" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
      ],
      data: [],
    }
  ]
  
  // Add row items to table
  if ( data.length > 0 ) {
    let subtotal = 0;
    for (let i=0; i < data.length; i++) {
      subtotal += data[i].total_price;
      let paymentType = "-", bankName = "-";
      if ( data[i].type_payment && data[i].type_payment.payment_id ) {
          if ( data[i].type_payment.payment_id.category === "cash" ) {
              paymentType = "Tunai";
          }
          else {
              paymentType = "Non-Tunai";
              bankName = data[i].type_payment.payment_id.name;
          }
      }
      res[0].data.push([
        { value: (i+1), style: {alignment: { horizontal: "center" }}},
        { value: moment(data[i].created_at).format("DD MMMM YYYY") },
        { value: moment(data[i].created_at).format("HH:mm:ss") },
        { value: data[i].no_invoice},
        { value: paymentType },
        { value: bankName },
        { value: formatToPrice(data[i].total_discount), style: { alignment: { horizontal: "right" }}},
        {
          value: formatToPrice(data[i].taxes[0]? data[i].taxes[0].total : 0),
          style: { alignment: { horizontal: "right" }}
        },
        { value: formatToPrice(data[i].total_price), style: { alignment: { horizontal: "right" }}},
      ])
    }
    if ( res[0].data[0] ) {
      res[0].data[0].push({
        value: formatToPrice(subtotal),
        style: { alignment: { horizontal: "right" }}
      });
    }
  }
  else {
    res[0].data.push([
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
    ])
  }

  return res;
}

/**
 * [ Laporan Laba Rugi - Penjualan Gudang ]
 * Create content for Warehouse Report excel sheet
 * @param data: overall warehouse transactions
 */
 export const createWarehouseReportExcel = (data) => {
  let res = [
    {
      columns: [
        {title: "No", width: {wch: 4}, style: {
          alignment: { horizontal: "center" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }}, 
        {title: "Tanggal", width: {wch: 17}, style: {
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Waktu", width: {wch: 8}, style: {
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "No. Invoice", width: {wch: 28}, style: {
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Nama Pembeli", width: {wch: 20}, style: {
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Ongkos Kirim", width: {wch: 15}, style: {
          alignment: { horizontal: "right" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Pajak", width: {wch: 15}, style: {
          alignment: { horizontal: "right" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Total Harga", width: {wch: 15}, style: {
          alignment: { horizontal: "right" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Total Keseluruhan", width: {wch: 20}, style: {
          alignment: { horizontal: "right" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
      ],
      data: [],
    }
  ]
  
  // Add row items to table
  if ( data.length > 0 ) {
    let subtotal = 0;
    for (let i=0; i < data.length; i++) {
      subtotal += data[i].total_price;
      res[0].data.push([
        { value: (i+1), style: {alignment: { horizontal: "center" }}},
        { value: moment(data[i].created_at).format("DD MMMM YYYY") },
        { value: moment(data[i].created_at).format("HH:mm:ss") },
        { value: data[i].no_invoice},
        { value: data[i].details_transaction_warehouse.buyer_name},
        { 
          value: formatToPrice(data[i].details_transaction_warehouse.postal_fee),
          style: { alignment: { horizontal: "right" }}
        },
        {
          value: formatToPrice(data[i].taxes[0]? data[i].taxes[0].total : 0),
          style: { alignment: { horizontal: "right" }}
        },
        {
          value: formatToPrice(data[i].total_price),
          style: { alignment: { horizontal: "right" }}
        },
      ]);
    }
    if ( res[0].data[0] ) {
      res[0].data[0].push({
        value: formatToPrice(subtotal),
        style: { alignment: { horizontal: "right" }}
      });
    }
  }
  else {
    res[0].data.push([
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
    ])
  }

  return res;
}

/**
 * [ Laporan Laba Rugi - Pembelian Barang ]
 * Create content for Purchase Report excel sheet
 * @param data: overall purchase transactions
 */
 export const createPurchaseReportExcel = (data) => {
  let res = [
    {
      columns: [
        {title: "No", width: {wch: 4}, style: {
          alignment: { horizontal: "center" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }}, 
        {title: "Tanggal", width: {wch: 17}, style: {
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Waktu", width: {wch: 8}, style: {
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "No. Invoice", width: {wch: 28}, style: {
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Kode Produk", width: {wch: 12}, style: {
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Nama Produk", width: {wch: 25}, style: {
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Perusahaan Supplier", width: {wch: 22}, style: {
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Keterangan", width: {wch: 40}, style: {
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Kuantitas", width: {wch: 10}, style: {
          alignment: { horizontal: "center" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }}, 
        {title: "Total Harga", width: {wch: 15}, style: {
          alignment: { horizontal: "right" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Total Keseluruhan", width: {wch: 20}, style: {
          alignment: { horizontal: "right" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
      ],
      data: [],
    }
  ]
  
  // Add row items to table
  if ( data.length > 0 ) {
    let subtotal = 0;
    for (let i=0; i < data.length; i++) {
      if ( data[i].approval_manager_finance === "approve" ) {
        subtotal += data[i].total_price;
        res[0].data.push([
          { value: (i+1), style: {alignment: { horizontal: "center" }}},
          { value: moment(data[i].date).format("DD MMMM YYYY") },
          { value: moment(data[i].date).format("HH:mm:ss") },
          { value: data[i].receipt? data[i].receipt : "-"},
          { value: data[i].items[0].product_id.product_code},
          { value: data[i].items[0].product_id.name},
          { value: data[i].items[0].product_id.supplier_id?
              data[i].items[0].product_id.supplier_id.name : "-"
          },
          { value: data[i].notes? data[i].notes : "-"},
          { value: data[i].items[0].quantity, style: {alignment: { horizontal: "center" }}},
          { value: formatToPrice(data[i].total_price), style: { alignment: { horizontal: "right" }}},
        ])
      }
    }
    if ( res[0].data[0] ) {
      res[0].data[0].push({
        value: formatToPrice(subtotal),
        style: { alignment: { horizontal: "right" }}
      });
    }
    else {
      res[0].data.push([
        { value: "-" },
        { value: "-" },
        { value: "-" },
        { value: "-" },
        { value: "-" },
        { value: "-" },
        { value: "-" },
        { value: "-" },
        { value: "-" },
        { value: "-" },
      ])
    }
  }
  else {
    res[0].data.push([
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
    ])
  }

  return res;
}

/**
 * [ Laporan Laba Rugi - Pengeluaran Tambahan ]
 * Create content for Extra Expense Report excel sheet
 * @param data: overall extra expense transactions
 */
 export const createExtraExpenseReportExcel = (data) => {
  let res = [
    {
      columns: [
        {title: "No", width: {wch: 4}, style: {
          alignment: { horizontal: "center" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }}, 
        {title: "Tanggal", width: {wch: 17}, style: {
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Keterangan", width: {wch: 50}, style: {
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Total Harga", width: {wch: 15}, style: {
          alignment: { horizontal: "right" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
        {title: "Total Keseluruhan", width: {wch: 20}, style: {
          alignment: { horizontal: "right" },
          font: { bold: true, color: { rgb: 'FFFFFF'} },
          fill: { fgColor: { rgb: '007F60' } },
        }},
      ],
      data: [],
    }
  ]
  
  // Add row items to table
  if ( data.detail_extra_expenses.length > 0) {
    let subtotal = 0;
    for (let i=0; i < data.detail_extra_expenses.length; i++) {
      subtotal += data.total_all_price;
      res[0].data.push([
        { value: (i+1), style: {alignment: { horizontal: "center" }}},
        { value: moment(data.detail_extra_expenses[i].created_at).format("DD MMMM YYYY") },
        { value: data.detail_extra_expenses[i].notes},
        { value: formatToPrice(data.total_all_price), style: { alignment: { horizontal: "right" }}},
      ])
    }
    if ( res[0].data[0] ) {
      res[0].data[0].push({
        value: formatToPrice(subtotal),
        style: { alignment: { horizontal: "right" }}
      });
    }
  }
  else {
    res[0].data.push([
      { value: "-" },
      { value: "-" },
      { value: "-" },
      { value: "-" },
    ])
  }

  return res;
}