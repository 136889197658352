import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar, } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { TextInput, NumberInput, PriceInput, ImageInput, DropdownInput } from "../../../logic/FormInput";
import { getSuppliers, getCategories } from "../../../logic/APIHandler";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddProduct() {

    const numberRegex = /^[0-9]*$/;

    // Form State
    const [stock, setStock] = useState("");
    const [suppliers, setSuppliers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [updatedData, setUpdatedData] = useState("");

    // Page State
    const { register, handleSubmit, control, errors } = useForm();
    const [openPin, setOpenPin] = useState(false);
    const [openJWT, setOpenJWT] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
        getSupplierData();
        getCategoryData();
    }, []);

    // Get list of suppliers
    const getSupplierData = async () => {
        setIsLoading(true);
        let resp = await getSuppliers();
        if ( resp[0] && resp[0].status === 200 ) {
            let temp = [];
            resp[0].data.sort((a,b) => (a.name > b.name? 1 : -1)).forEach(res => {
                temp.push({
                    "label": res.name,
                    "value": res._id,
                })
            });
            setSuppliers(temp);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
        }
        setIsLoading(false);
    }
    // Get list of categories
    const getCategoryData = async () => {
        setIsLoading(true);
        let resp = await getCategories();
        if ( resp[0] && resp[0].status === 200 ) {
            let temp = [];
            resp[0].data.sort((a,b) => (a.name > b.name? 1 : -1)).forEach(res => {
                temp.push({
                    "label": res.name,
                    "value": res._id,
                })
            });
            setCategories(temp);
        }
        else if ( resp[0] && resp[0].status === 204 ) {
            setErrorText("Data kategori produk masih kosong. Mohon isi terlebih dulu.");
            setOpenErrorAlert(true);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
        }
        setIsLoading(false);
    }

    // Handle onChange input number
    const onChangeInputNumber = (event) => {
        if ( numberRegex.test(event.target.value) ) {
            if ( event.target.name === "stock_warehouse" ) {
                setStock(event.target.value);
            }
        }
    }

    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send new data to server
    const onSubmit = async (data) => {
        if ( data.product_code.length >= 4 && data.product_code.length <= 20 ) {
            setUpdatedData(data);
            setOpenPin(true);
        }
        else if ( data.product_code.length < 4 ) {
            setErrorText("Panjang kode produk minimal 4 digit.");
            setOpenErrorAlert(true);
        }
        else if ( data.product_code.length > 20 ) {
            setErrorText("Panjang kode produk maksimal 20 digit.");
            setOpenErrorAlert(true);
        }
    }

    return (
        <>
            <div className="content" style={{ position: "relative" }}>
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""}
                    level={localStorage.getItem("level")} />
                
                <Modal
                    open={openPin}
                    onClose={() => setOpenPin(false)}
                    onClickCancel={() => setOpenPin(false)}
                    modalType="add-product"
                    color="primary"
                    modalTitle="Apakah Anda yakin data yang dimasukkan sudah benar?"
                    updatedData={updatedData}
                    submitText="Yakin"
                    cancelText="Tidak"
                />
                <Modal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2.2rem" }}>
                                Tambah Produk Baru
                            </h1>

                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        label="Kode *"
                                        name="product_code"
                                        placeholder="Kode Produk"
                                        className="form-left-field add-margin-bottom"
                                        validation={register({ required: "*kode produk harus diisi" })}
                                        errors={errors.product_code}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        label="Nama *"
                                        name="name"
                                        placeholder="Nama Produk"
                                        className="form-right-field add-margin-bottom"
                                        validation={register({ required: "*nama produk harus diisi" })}
                                        errors={errors.name}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    <label className="selection-label">Supplier *</label>
                                    <DropdownInput
                                        name="supplier_id"
                                        className="selection-field add-margin-bottom"
                                        control={control}
                                        options={suppliers}
                                        rules={{ required: "*supplier harus diisi" }}
                                        errors={errors.supplier_id}
                                        errorClassName="text-required"
                                        isClearable={true}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    <label className="selection-label">Kategori Produk *</label>
                                    <DropdownInput
                                        name="category_id"
                                        className="selection-field add-margin-bottom"
                                        control={control}
                                        options={categories}
                                        rules={{ required: "*pilih minimal 1 kategori" }}
                                        errors={errors.category_id}
                                        errorClassName="text-required"
                                        isClearable={true}
                                        isMulti={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PriceInput
                                        label="Harga Jual *"
                                        name="selling_price"
                                        className="full-width add-margin-bottom price-field"
                                        control={control}
                                        rules={{ required: "*harga jual produk harus diisi" }}
                                        errors={errors.selling_price}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextInput
                                        label="Letak Rak"
                                        name="shelf_location"
                                        placeholder="Lokasi"
                                        className="form-left-field add-margin-bottom"
                                        validation={register}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className="add-margin-bottom">
                                    <NumberInput
                                        label="Stok Gudang"
                                        name="stock_warehouse"
                                        placeholder="x (piece)"
                                        className="form-right-field"
                                        value={stock}
                                        onChange={onChangeInputNumber}
                                        validation={register}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ImageInput
                                        name="product_image"
                                        label="Foto Produk"
                                        validation={register}
                                        isEdit={true}
                                        control={control}
                                        defaultImg={false}
                                        canDelete={true}
                                        overallClassName="full-input-image"
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>

                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}