import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Grid, Button, Snackbar, TextField, InputAdornment, IconButton } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import MenuIcon from '@material-ui/icons/Menu';
import Sidebar from "../components/Sidebar_Com";
import Spinner from "../components/Loading_Com";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import logo from "../assets/img/logo.png";
import { changeCurrentPassword } from "../logic/APIHandler";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ChangePasswordPage() {

    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const windowWidth = window.innerWidth;

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Toggle show password
    const handleShowOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    }
    const handleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    }

    // Toggle sidebar menu in responsive
    const toggleSidebar = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setIsSidebarOpen(open);
    }

    // Send new data to server
    const onSubmit = async (data) => {
        setIsLoading(true);
        let resp = await changeCurrentPassword(data);
        if (resp[0] && resp[0].status === 200) {
            setIsLoading(false);
            setOpenSuccessAlert(true);
            await sleep(1000);
            history.go(0);
        }
        else {
            if ( resp[1] && resp[1].status === 400 ) {
                setAlertMessage("Panjang password minimal 8 karakter!");
            }
            else if ( resp[1] && resp[1].status === 401 ) {
                setAlertMessage(resp[1].message);
            }
            else {
                setAlertMessage("Gagal mengganti password!");
            }
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} level={localStorage.getItem("level")} />

            <Grid container className="responsive-header">
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar("left", true)}>
                        <MenuIcon />
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <img src={logo} alt="logo" className="header-logo" />
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                    <Alert severity="success">
                        Berhasil Mengubah Password
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                    <Alert severity="error">
                        {alertMessage}
                    </Alert>
                </Snackbar>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <h1 style={{ marginBottom: "2rem" }}>Ganti Password</h1>

                        <Grid container>
                            <Grid item xs={12}>
                                {/* Input for old password */}
                                <TextField
                                    autoComplete="off"
                                    type={showOldPassword ? "text" : "password"}
                                    variant="outlined"
                                    label="Password Lama"
                                    name="old_password"
                                    placeholder="Password Lama"
                                    className="full-width add-margin-bottom"
                                    inputRef={register({ required: "*password lama harus diisi" })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleShowOldPassword}
                                                >
                                                    {showOldPassword? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors.old_password && <p className="text-required">{errors.old_password.message}</p>}
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for new password */}
                                <TextField
                                    autoComplete="off"
                                    type={showNewPassword ? "text" : "password"}
                                    variant="outlined"
                                    label="Password Baru"
                                    name="new_password"
                                    placeholder="Password Baru"
                                    className="full-width add-margin-bottom"
                                    inputRef={register({ required: "*password baru harus diisi" })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleShowNewPassword}
                                                >
                                                    {showNewPassword? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors.new_password && <p className="text-required">{errors.new_password.message}</p>}
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            variant="contained"
                            style={{ display: "block", marginLeft: "auto" }}
                            className="add-new-btn"
                        >Submit</Button>

                    </form>
                </Grid>
            </Grid>
        </div>
    );
}