import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar, } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Sidebar from "../../components/Sidebar_Com";
import Modal from "../../components/Modal_Com";
import { exportToFile } from "../../logic/Handler";
import { TextInput, DropdownCurrentInput } from "../../logic/FormInput";
import logo from "../../assets/img/logo.png";
import "../../styles/Form_Styles.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ExportDB() {

    // Export Database State
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [cart, setCart] = useState([]);
    const [loadRemove, setLoadRemove] = useState(false);

    // Page State
    const history = useHistory();
    const { register, handleSubmit, control, setValue, watch } = useForm();
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [modalContent, setModalContent] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
        const state = history.location.state;
        if ( state ) {
            if ( state.data.length > 0 ) {
                setData(state.data);
            }
            else {
                setErrorText("Tidak ada produk yang ditemukan!");
                setOpenErrorAlert(true);
            }
        }
        else {
            history.replace("/produk");
        }
    },[])
    
    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close success toast
    const handleCloseSuccess = () => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = () => {
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Export database to text file
    const onSubmit = async (products, isDownloadAll) => {
        let content = isDownloadAll? ["kode,nama,harga\n"] : ["kode,nama,harga,jumlah\n"];
        let formattedData = [];
        let canDownload = true, isTooMany = false;
        if ( isDownloadAll ) {
            for ( let i=0; i < data.length; i++ ) {
                const val = data[i].label.split(" - ");
                formattedData.push({
                    product_code: val[0],
                    name: val[1],
                    selling_price: data[i].price,
                })
            }
            if ( formattedData && formattedData.length > 0 ) {
                formattedData.forEach(res => {
                    let temp = res.product_code + "," + res.name + ","
                        + res.selling_price + "\n";
                    content.push(temp);
                });
            }
        }
        else {
            if ( cart.length > 0 ) {
                for ( let i=0; i < cart.length; i++ ) {
                    const val = cart[i].label.split(" - ");
                    formattedData.push({
                        product_code: val[0],
                        name: val[1],
                        selling_price: cart[i].price,
                        qty: products.qty[i],
                    })
                    if ( products.qty[i] > 100 ) isTooMany = true; 
                }
                if ( formattedData && formattedData.length > 0 ) {
                    formattedData.forEach(res => {
                        let temp = res.product_code + "," + res.name + ","
                            + res.selling_price + "," + res.qty + "\n";
                        content.push(temp);
                    });
                }
            }
            else {
                canDownload = false;
                setErrorText("Pilih produk terlebih dulu!");
                setOpenErrorAlert(true);
            }
        }
        if ( canDownload ) {
            if ( !isTooMany ) {
                if ( localStorage.getItem("level") === "1" ) {
                    const branchName = JSON.parse(localStorage.getItem("currBranch")).label;
                    exportToFile(("Database Produk - " + branchName), content);
                }
                else {
                    exportToFile("Database Produk", content);
                }
            }
            else {
                setModalContent(content);
                setOpen(true);
            }
        }
    }

    return (
        <>
            <div className="content">
                <Sidebar
                    sidebarState={(windowWidth < 768)? isSidebarOpen : ""}
                    level={localStorage.getItem("level")}
                />

                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    onClickCancel={() => setOpen(false)}
                    modalType="export-database"
                    data={modalContent}
                    color="primary"
                    modalTitle="Perhatian!"
                    submitText="Download"
                    cancelText="Back"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Menambah Cabang
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form className="add-form">
                            <h1 style={{ marginBottom: "2.2rem" }}>
                                Export Database
                            </h1>

                            <Grid container>
                                <Grid item xs={12}>
                                    <DropdownCurrentInput
                                        label="Pilih Produk"
                                        name="products"
                                        className="full-width add-margin-bottom tf-label"
                                        keepData={async (data) => {
                                            setCart([...cart, data]);
                                            await sleep(100);
                                            let itemListSection = document.getElementById("preview-items");
                                            itemListSection.scrollTop = itemListSection.scrollHeight;
                                        }}
                                        disableClearable={false}
                                        control={control}
                                        options={data}
                                        getOptionLabel={(option) => option.label}
                                        value={null}
                                    />
                                </Grid>
                                <Grid item xs={12} id="preview-items" className="product-list">
                                    {!loadRemove && cart.length > 0 && cart.map((product, idx) => {
                                        return (
                                            <Grid container spacing={1} key={`product-${idx}`}>
                                                <Grid item xs={12} sm={5} md={6} lg={8}>
                                                    <TextInput
                                                        label="Produk"
                                                        placeholder="Produk"
                                                        value={product.label}
                                                        className="full-width add-margin-bottom small-font"
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} sm={3} md={3} lg={2}>
                                                    <TextInput
                                                        label="Harga"
                                                        placeholder="-"
                                                        value={product.price}
                                                        className="full-width add-margin-bottom small-font"
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={2} md={2} lg={1}>
                                                    <TextInput
                                                        label="Jumlah"
                                                        name={`qty.${idx}`}
                                                        placeholder="0"
                                                        defaultValue={1}
                                                        className="full-width add-margin-bottom small-font"
                                                        validation={register}
                                                    />
                                                </Grid>
                                                <Grid item xs={2} sm={2} md={1} lg={1}>
                                                    <button
                                                        type="button"
                                                        className="remove-product-btn"
                                                        onClick={async () => {
                                                            // setLoadRemove(true);
                                                            // await sleep(10);
                                                            let updatedCart = [...cart];
                                                            updatedCart.splice(idx, 1);
                                                            setCart(updatedCart);
                                                            // Update values in text field
                                                            for (let i=0; i <= updatedCart.length; i++) {
                                                                if ( i !== idx ) {
                                                                    setValue(("qty."+i), watch("qty."+i));
                                                                }
                                                                else {
                                                                    i++;
                                                                    setValue(("qty."+(i-1)), watch("qty."+i));
                                                                }
                                                            }
                                                            // setLoadRemove(false);
                                                        }}
                                                    >X</button>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                            <Grid container className="so-btn-container">
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        className="add-cancel-btn"
                                        onClick={() => history.goBack()}
                                    >Back</Button>
                                    <Button
                                        type="submit"
                                        onClick={handleSubmit((d) => onSubmit(d, true))}
                                        variant="contained"
                                        style={{ float: "right", marginRight: "15px" }}
                                        className="add-cancel-btn download-all"
                                    >Download Seluruh Data</Button>
                                    <Button
                                        type="submit"
                                        onClick={handleSubmit((d) => onSubmit(d, false))}
                                        variant="contained"
                                        style={{ float: "right", marginRight: "15px" }}
                                        className="add-new-btn responsive-submit-btn"
                                    >Download Data Label</Button>
                                </Grid>
                            </Grid>

                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}