import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar, } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { PriceInput, DropdownInput, TextInput } from "../../../logic/FormInput";
import { getAdmins, postCashierSession } from "../../../logic/APIHandler";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddSession() {
    
    // Page State
    const history = useHistory();
    const { register, handleSubmit, errors, control } = useForm();
    const [cashiers, setCashiers] = useState([]);
    const [openJWT, setOpenJWT] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    },[]);

    const getData = async() => {
        setIsLoading(true);
        let resp = await getAdmins();
        if ( resp[0] && resp[0].status === 200 ) {
            let result = [];
            const cashierList = resp[0].data.filter(res => res.jobdesk === "kasir");
            cashierList.forEach(res => {
                result.push({
                    label: res.name,
                    value: res._id,
                })
            });
            setCashiers(result);
        }
        else {
            setErrorText("Gagal mendapatkan data kasir");
            setOpenErrorAlert(true);
        }
        setIsLoading(false);
    };

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send new data to server
    const onSubmit = async (data) => {
        setIsLoading(true);
        let resp = await postCashierSession(data);
        if (resp[0] && resp[0].status === 200) {
            setIsLoading(false);
            setOpenSuccessAlert(true);
            await sleep(1000);
            history.replace("/sesi-kasir");
        }
        else if ( resp[1] && resp[1].status === 400 ) {
            setErrorText(resp[1].data[0].msg);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            setErrorText(resp[1].message);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} level="3" />
                
                <Modal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Membuka Sesi Kasir Baru
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2.2rem" }}>
                                Buka Sesi Kasir Baru
                            </h1>

                            <Grid container>
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    <label className="selection-label">Nama Kasir *</label>
                                    <DropdownInput
                                        name="cashier"
                                        className="selection-field add-margin-bottom"
                                        control={control}
                                        options={cashiers}
                                        defaultValue={null}
                                        rules={{ required: "*nama kasir harus diisi" }}
                                        errors={errors.cashier}
                                        errorClassName="text-required"
                                        isClearable={false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PriceInput
                                        label="Nominal Awal *"
                                        name="initial_nominal"
                                        className="full-width add-margin-bottom price-field"
                                        control={control}
                                        rules={{ required: "*nominal awal kasir harus diisi" }}
                                        errors={errors.initial_nominal}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        multiline={true}
                                        rows={2}
                                        label="Keterangan"
                                        name="notes"
                                        placeholder="Keterangan"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>

                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}