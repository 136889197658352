import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory, NavLink } from "react-router-dom";
import { Grid, Button, Snackbar, Modal, TextField } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Spinner from "../components/Loading_Com";
import "../styles/Modal_Styles.css";
import { TextInput, NumberInput, DateInput, PriceInput, DropdownInput } from '../logic/FormInput';
import {
    deleteSupplier, deleteCategory, deleteAdmin, deleteTax, deleteBranch,
    deleteDiscount, deleteProduct, deletePaymentMethod, deleteExtraExpense,
    deleteProductLoss, deleteCashierSession,
    changePassword, adjustProductStock, updateProduct, restockProduct,
    approveTransaction, returnToSupplier, closeCashierSession, postProduct,
    updateProductLoss, updateCashierSession, updateRequestStatus, postTransaction,
    getBranch,
} from "../logic/APIHandler";
import { formatDeliveryOrderAddress, downloadDeliveryOrderPDF, exportToFile } from "../logic/Handler";
import moment from "moment";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomModal(props) {

    const numberRegex = /^[0-9]*$/;

    // Data state
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [newStock, setNewStock] = useState("");
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [selectedDate, setSelectedDate] = useState(moment());

    // Page state
    const history = useHistory();
    const { handleSubmit, register, control, errors, watch, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Handle onChange number
    const onChangeInputNumber = (event) => {
        if ( numberRegex.test(event.target.value) ) {
            setNewStock(event.target.value);
        }
    }

    // Handle onSubmit modal
    const onSubmit = async data => {
        setIsLoading(true);
        let resp = "";
        
        if ( props.modalType === 'delete-supplier' ) {
            resp = await deleteSupplier(props.id);
            setSuccessText("Berhasil menghapus supplier!")
        }
        else if ( props.modalType === 'delete-branch' ) {
            resp = await deleteBranch(props.id);
            setSuccessText("Berhasil menghapus cabang!")
            if ( resp[0] && resp[0].status === 200 ) {
                // Update branches in localstorage
                let result = JSON.parse(localStorage.getItem("branch"));
                result = result.filter(res => res.value !== props.id);
                localStorage.setItem("branch", JSON.stringify(result));
            }
        }
        else if ( props.modalType === 'delete-category' ) {
            resp = await deleteCategory(props.id);
            setSuccessText("Berhasil menghapus kategori produk!")
        }
        else if ( props.modalType === 'delete-admin' ) {
            resp = await deleteAdmin(props.id);
            setSuccessText("Berhasil menghapus admin!")
        }
        else if ( props.modalType === 'delete-payment-method' ) {
            resp = await deletePaymentMethod(props.id);
            setSuccessText("Berhasil menghapus metode pembayaran!")
        }
        else if ( props.modalType === 'delete-tax' ) {
            resp = await deleteTax(props.id);
            setSuccessText("Berhasil menghapus pajak!")
        }
        else if ( props.modalType === 'erase-product' ) {
            resp = await deleteProduct(props.id, data.pin);
            setSuccessText("Berhasil menghapus produk!")
        }
        else if ( props.modalType === 'erase-losses' ) {
            resp = await deleteProductLoss(props.id, data.pin);
            setSuccessText("Berhasil menghapus produk loss!")
        }
        else if ( props.modalType === 'delete-discount' ) {
            resp = await deleteDiscount(props.id);
            setSuccessText("Berhasil menghapus diskon!")
        }
        else if ( props.modalType === 'delete-extra-expense' ) {
            resp = await deleteExtraExpense(props.id);
            setSuccessText("Berhasil menghapus pengeluaran tambahan!")
        }
        else if ( props.modalType === 'erase-session' ) {
            resp = await deleteCashierSession(props.id, data.pin);
            setSuccessText("Berhasil menghapus sesi kasir!")
        }
        else if ( props.modalType === 'discard-so-store' ) {
            resp = [{ "status": 200 }];
            localStorage.removeItem("saved-so-store");
            setSuccessText("Berhasil menghapus data sementara stock opname toko!")
        }
        else if ( props.modalType === 'discard-so' ) {
            resp = [{ "status": 200 }];
            localStorage.removeItem("saved-so");
            setSuccessText("Berhasil menghapus data sementara stock opname gudang!")
        }
        else if ( props.modalType === 'restock' ) {
            resp = await restockProduct(props.id, data);
            setSuccessText("Berhasil melakukan restock produk!")
        }
        // else if ( props.modalType === 'stock-transition' ) {
        //     resp = await transitionProduct(props.id, data.newStock);
        //     setSuccessText("Berhasil mentransisikan stok produk dari gudang!")
        // }
        else if ( props.modalType === 'update-stock' ) {
            resp = await adjustProductStock(props.id, data);
            setSuccessText("Berhasil update stok produk!")
        }
        else if ( props.modalType === 'update-session' ) {
            resp = await updateCashierSession(props.id, props.updatedData, data.pin);
            setSuccessText("Berhasil update sesi kasir!")
        }
        else if ( props.modalType === 'add-product' ) {
            resp = await postProduct(props.updatedData);
            setSuccessText("Berhasil membuat produk baru!")
        }
        else if ( props.modalType === 'update-product' ) {
            resp = await updateProduct(props.id, props.updatedData, data.pin);
            setSuccessText("Berhasil update produk!")
        }
        else if ( props.modalType === 'update-losses' ) {
            resp = await updateProductLoss(props.id, props.updatedData, data.pin);
            setSuccessText("Berhasil update produk loss!")
        }
        else if ( props.modalType === 'approve-trx' ) {
            data["status"] = "approve";
            resp = await approveTransaction(props.id, data);
            setSuccessText("Berhasil approve transaksi!")
        }
        else if ( props.modalType === 'decline-trx' ) {
            data["status"] = "decline";
            resp = await approveTransaction(props.id, data);
            setSuccessText("Berhasil tolak transaksi!")
        }
        else if ( props.modalType === 'decline-restock' ) {
            resp = await updateRequestStatus(props.id, data, "decline");
            setSuccessText("Berhasil tolak request produk!")
        }
        else if ( props.modalType === 'approve-restock' ) {
            resp = await updateRequestStatus(props.id, props.data, "approve");
            setSuccessText("Berhasil approve request produk!")
        }
        else if ( props.modalType === 'validate-restock' ) {
            resp = await updateRequestStatus(props.id, props.data, "validate");
            setSuccessText("Berhasil memvalidasi request produk!")
        }
        else if ( props.modalType === 'done-restock' ) {
            resp = await updateRequestStatus(props.id, props.data, "done");
            setSuccessText("Berhasil menyelesaikan request produk!")
        }
        else if ( props.modalType === 'return-to-supplier' ) {
            resp = await returnToSupplier(props.id);
            setSuccessText("Berhasil mengembalikan barang ke supplier!")
        }
        else if ( props.modalType === 'close-session' ) {
            resp = await closeCashierSession(props.id, data);
            setSuccessText("Berhasil menutup sesi kasir.")
        }
        else if ( props.modalType === 'create-sell-warehouse' ) {
            resp = await postTransaction(props.updatedData, props.cart);
            setSuccessText("Berhasil membuat transaksi baru!")
        }
        else if ( props.modalType === 'change-password' ) {
            resp = await changePassword(props.id);
        }
        else if ( props.modalType === 'export-database' ) {
            if ( localStorage.getItem("level") === "1" ) {
                const branchName = JSON.parse(localStorage.getItem("currBranch")).label;
                exportToFile(("Database Produk - " + branchName), props.data);
            }
            else {
                exportToFile("Database Produk", props.data);
            }
        }

        if ( resp[0] && resp[0].status === 200 ) {
            setIsLoading(false);
            setOpenSuccessAlert(true);

            if ( props.modalType === 'change-password' ) {
                setSuccessText("Berhasil mengganti password!")
                setIsChangePassword(true);
                setEmail(resp[0].data.email);
                setNewPassword(resp[0].data.password)
            }
            else if ( props.modalType === 'add-product' ) {
                await sleep(1000);
                history.replace("/produk");
            }
            else if ( props.modalType === 'create-sell-warehouse' ) {
                setIsLoading(true);
                let data = resp[0].data;
                let branch = await getBranchData();
                data["address"] = formatDeliveryOrderAddress(branch);
                if ( data.taxes && data.taxes[0] ) {
                    data["items"].push({
                        product_id: {
                            name: data.taxes[0].name + " (" + data.taxes[0].value + "%)"
                        },
                        quantity: "",
                        selling_price: "",
                        total_price: data.taxes[0].total,
                    });
                }
                if ( data.items.length <= 6 ) { // Print 1x
                    data["page"] = "";
                    downloadDeliveryOrderPDF(data, 0, data.items.length-1, true, "preview");
                }
                else { // Print >1x
                    const totalPage = Math.ceil(data.items.length/6);
                    for ( let i=0; i < totalPage; i++ ) {
                        data["page"] = "(" + (i + 1) + "-" + totalPage + ")";
                        downloadDeliveryOrderPDF(
                            data,
                            (i * 6),
                            ((i + 1) * 6) - 1,
                            i === (totalPage - 1)? true : false,
                            "preview"
                        );
                    }
                }
                await sleep(3000);
                history.replace("/penjualan-barang-gudang/transaksi/" + resp[0].data._id);
            }
            else {
                await sleep(1000);
                history.go(0);
            }
        }
        else if ( props.modalType === 'export-database' ) {
            setIsLoading(false);
        }
        else {
            setIsLoading(false);
            if ( props.modalType === 'change-password' ) {
                setErrorText("Gagal mengganti password!")
            }
            else if ( props.modalType === 'update-stock' ) {
                setErrorText("Gagal update stok produk! Cek kembali pin admin atau internet Anda.")
            }
            else if ( props.modalType === 'restock' ) {
                setErrorText("Gagal restock produk!")
            }
            else if ( props.modalType === 'stock-transition' ) {
                if ( resp[0] && resp[0].status === 204 ) {
                    setErrorText(resp[0].message);
                }
                else {
                    setErrorText("Gagal mentransisikan stok produk dari gudang!")
                }
            }
            else if ( props.modalType === 'close-session' ) {
                setErrorText("Gagal menutup sesi kasir.")
            }
            else if ( props.modalType === 'update-session' ) {
                setErrorText("Gagal update sesi kasir!")
            }
            else if ( props.modalType === 'decline-restock' ) {
                setErrorText("Gagal menolak request produk!")
            }
            else if ( props.modalType === 'approve-restock' ) {
                setErrorText("Gagal approve request produk!")
            }
            else if ( props.modalType === 'validate-restock' ) {
                setErrorText("Gagal memvalidasi request produk!")
            }
            else if ( props.modalType === 'done-restock' ) {
                setErrorText("Gagal menyelesaikan request produk!")
            }
            else if ( props.modalType === 'add-product' ) {
                if ( resp[1] && resp[1].status === 400 ) {
                    if ( resp[1].data && resp[1].data[0] && resp[1].data[0].msg ) {
                        setErrorText(resp[1].data[0].msg)
                    }
                    else {
                        setErrorText("Gagal membuat produk baru!")
                    }
                }
                else {
                    setErrorText("Gagal membuat produk baru!")
                }
            }
            else if ( props.modalType === 'update-product' ) {
                if ( resp[1] && resp[1].status === 400 ) {
                    if ( resp[1].data ) setErrorText(resp[1].data[0].msg);
                    else setErrorText(resp[1].message);
                }
                else {
                    setErrorText(resp[1].message);
                }
            }
            else if ( props.modalType === 'update-losses' ) {
                setErrorText("Gagal update produk loss.");
            }
            else if ( props.modalType.includes('erase') ) {
                console.log(resp);
                setErrorText("Gagal menghapus data. Cek kembali pin master.");
            }
            else if ( props.modalType === "create-sell-warehouse" ) {
                console.log(resp);
                setErrorText("Gagal membuat transaksi penjualan gudang.");
            }
            else {
                setErrorText("Gagal menghapus data!")
            }
            setOpenErrorAlert(true);
        }
    }

    const getBranchData = async() => {
        const branch = JSON.parse(localStorage.getItem("branch"));
        let resp = await getBranch(branch._id);
        if ( resp[0] && resp[0].status === 200 ) {
            return resp[0].data;
        }
        else {
            setErrorText(resp[1]? resp[1].message :
                "Gagal mendapatkan alamat cabang. Cek kembali koneksi Anda.");
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    };

    // HTML for Modal
    const modalBody = (
        <div className={`modal-body ${props.modalClassName}`}>
            <h2 id="simple-modal-title" className={props.modalType === "handle-jwt"? "jwt-title"
                : props.modalType.includes("stock")? "stock-title" : ""} 
                style={{
                    marginTop: "10px",
                    textAlign: props.modalType === "approve-trx"? "center":"left",
                    marginBottom: props.modalType === "discard-so"
                        || props.modalType === "approve-trx"
                        || props.modalType.includes("stock")?
                        "40px" : "initial",
                }}
            >
                {isChangePassword? "Berhasil Mengganti Password!" : props.modalTitle}
            </h2>
            <form onSubmit={handleSubmit(onSubmit)} className="custom-form">
                {(props.modalType.includes('delete') || props.modalType === "change-password")?
                    <>
                        {isChangePassword?
                            <>
                                <p><b>Email:</b> {email}</p>
                                <p><b>Password Baru:</b> {newPassword}</p>
                            </>
                            : <p style={{ marginBottom: "30px" }}><b>Nama:</b> {props.dataName}</p>
                        }
                    </>
                : (props.modalType === "create-admin-info")?
                    <>
                        <p><b>Email:</b> {props.emailCallback}</p>
                        <p><b>Password:</b> {props.passwordCallback}</p>
                    </>
                : (props.modalType === "return-to-supplier")?
                    <>
                        <p><b>Nama:</b> {props.dataName}</p>
                        <p style={{ marginBottom: "30px" }}><b>Kuantitas:</b> {props.quantity}</p>
                    </>
                : (props.modalType === "update-stock")?
                    <Grid container>
                        <Grid item xs={12}>
                            <TextInput
                                label="Nama Produk"
                                placeholder="Nama"
                                defaultValue={props.dataName}
                                className="full-width add-margin-bottom"
                                style={{ display: "flex" }}
                                disabled={true}
                                />
                        </Grid>
                        <Grid item xs={12} style={{ position: "relative" }}>
                            <label className="selection-label">Asal Produk *</label>
                            <DropdownInput
                                name="origin"
                                className="selection-field add-margin-bottom"
                                control={control}
                                options={[
                                    { label: "Toko", value: "store" },
                                    { label: "Gudang", value: "warehouse" },
                                ]}
                                rules={{ required: "*asal produk harus dipilih" }}
                                errors={errors.origin}
                                errorClassName="text-required"
                                isClearable={false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <NumberInput
                                label="Stok Terbaru *"
                                name="newStock"
                                placeholder="x (piece)"
                                className="full-width add-margin-bottom"
                                style={{ display: "flex" }}
                                value={newStock}
                                onChange={onChangeInputNumber}
                                validation={register}
                                requiredText="*jumlah stok baru harus diisi"
                                errors={errors.newStock}
                                errorClassName="text-required-half"
                            />
                        </Grid>
                        <Grid item xs={12} className="add-margin-bottom">
                            <TextField
                                type="password"
                                autoComplete="new-password"
                                variant="outlined"
                                label="Pin *"
                                name="pin"
                                placeholder="Pin Master"
                                className="pin-input"
                                inputRef={register({ required: "*pin master harus diisi" })}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                            />
                            {errors.pin && <p className="text-required">{errors.pin.message}</p>}
                        </Grid>
                    </Grid>
                : (props.modalType === "stock-transition")?
                    <Grid container className="add-margin-top">
                        <Grid item xs={12}>
                            <TextInput
                                label="Nama Produk"
                                placeholder="Nama"
                                defaultValue={props.dataName}
                                className="full-width add-margin-bottom"
                                style={{ display: "flex" }}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DateInput
                                label="Tanggal Transisi"
                                name="date"
                                minDate={moment().subtract(2, 'days')}
                                value={selectedDate}
                                onChange={(date) => setSelectedDate(moment(date))}
                                className="full-width add-margin-bottom"
                                style={{ display: "flex" }}
                                validation={register({
                                    required: "*tanggal transisi harus diisi"
                                })}
                                errors={errors.date}
                                errorClassName="text-required"
                                disableFuture={true}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                label="Sisa Stok Gudang"
                                placeholder="x (piece)"
                                defaultValue={props.currStock}
                                className="full-width add-margin-bottom"
                                style={{ display: "flex" }}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} className="add-margin-bottom">
                            <NumberInput
                                label="Kuantitas Transisi *"
                                name="newStock"
                                placeholder="x (piece)"
                                className="full-width add-margin-bottom"
                                style={{ display: "flex" }}
                                value={newStock}
                                onChange={onChangeInputNumber}
                                validation={register}
                                requiredText="*kuantitas transisi harus diisi"
                                errors={errors.newStock}
                                errorClassName="text-required"
                            />
                        </Grid>
                    </Grid>
                : (props.modalType === "update-product") || (props.modalType === "erase-product")
                    || (props.modalType === "update-losses") || (props.modalType === "erase-losses")
                    || (props.modalType === "update-session") || (props.modalType === "erase-session") ?
                    <Grid container>
                        <Grid item xs={12} className="add-margin-bottom">
                            <TextField
                                type="password"
                                autoComplete="new-password"
                                variant="outlined"
                                label="Pin *"
                                name="pin"
                                placeholder="Pin Master"
                                className="pin-input add-margin-top"
                                inputRef={register({ required: "*pin master harus diisi" })}
                                InputLabelProps={{
                                shrink: true,
                                }}
                            />
                            {errors.pin && <p className="text-required">{errors.pin.message}</p>}
                        </Grid>
                    </Grid>
                : (props.modalType === "approve-trx")?
                    <Grid container className="add-margin-top">
                        <Grid item xs={12}>
                            <TextInput
                                label="No. Nota Bon"
                                name="receipt"
                                placeholder="No. Nota Bon"
                                className="full-width add-margin-bottom"
                                style={{ display: "flex" }}
                                validation={register}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                multiline={true}
                                rows={2}
                                label="Notes"
                                name="notes"
                                placeholder="Notes"
                                className="full-width add-margin-bottom"
                                style={{ display: "flex" }}
                                validation={register}
                            />
                        </Grid>
                        <Grid item xs={12} className="add-margin-bottom">
                            <PriceInput
                                label="Harga Beli"
                                name="total_price"
                                className="full-width add-margin-bottom price-field"
                                style={{ display: "flex" }}
                                control={control}
                                // rules={{ required: "*harga pembelian harus diisi" }}
                                // errors={errors.total_price}
                                // errorClassName="text-required"
                            />
                        </Grid>
                    </Grid>
                : (props.modalType === "decline-trx")?
                    <Grid container className="add-margin-top">
                        <Grid item xs={12}>
                            <TextInput
                                label="Nama Produk"
                                placeholder="Nama Produk"
                                value={props.dataName}
                                className="full-width add-margin-bottom"
                                style={{ display: "flex" }}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                multiline={true}
                                rows={2}
                                label="Notes"
                                name="notes"
                                placeholder="Notes"
                                className="full-width add-margin-bottom"
                                style={{ display: "flex" }}
                                validation={register}
                            />
                        </Grid>
                    </Grid>
                : (props.modalType === "close-session")?
                    <Grid container className="add-margin-top">
                        <Grid item xs={12}>
                            <PriceInput
                                label="Nominal Akhir *"
                                name="final_nominal"
                                className="full-width price-field"
                                style={{ display: "flex" }}
                                control={control}
                                rules={{ required: "*nominal akhir harus diisi" }}
                                errors={errors.final_nominal}
                                errorClassName="text-required"
                            />
                        </Grid>
                        <Grid item xs={12} className="add-margin-bottom">
                            <TextInput
                                multiline={true}
                                rows={2}
                                label="Keterangan"
                                name="notes"
                                defaultValue={props.notes}
                                placeholder="Keterangan"
                                className="full-width add-margin-bottom"
                                style={{ display: "flex" }}
                                validation={register}
                            />
                        </Grid>
                    </Grid>
                : (props.modalType === "decline-restock")?
                    <Grid container>
                        <Grid item xs={12} className="add-margin-bottom">
                            <TextInput
                                multiline={true}
                                rows={2}
                                label="Alasan"
                                name="notes"
                                placeholder="Alasan"
                                className="full-width add-margin-bottom"
                                style={{ display: "flex" }}
                                validation={register}
                            />
                        </Grid>
                    </Grid>
                : (props.modalType === "export-database")?
                    <p>Terdapat kuantitas yang melebihi angka 100. Anda yakin dengan inputan Anda?</p>
                : <Grid container className="add-margin-bottom">
                    <Grid item xs={12}><p></p></Grid>
                  </Grid>
                }
                {props.modalType === "handle-jwt"?
                    <NavLink to="/" style={{ textDecoration: "none" }}>
                        <Button onClick={() => localStorage.clear()} variant="contained"
                            className="jwt-btn">Login Kembali</Button>
                    </NavLink>
                : props.modalType === "create-admin-info"?
                    <NavLink to="/admin" style={{ textDecoration: "none" }}>
                        <Button color={props.color} variant="contained" className="jwt-btn">
                            OK
                        </Button>
                    </NavLink>
                : props.modalType === "change-password" && isChangePassword?
                    <Button color={props.color} variant="contained" className="jwt-btn"
                        onClick={() => history.go(0)}>
                        OK
                    </Button>
                :
                <>
                    <Button
                        variant="outlined"
                        className="modal-cancel-btn"
                        onClick={props.onClickCancel}
                    >
                        {props.cancelText}
                    </Button>
                    <Button type="submit" variant="contained" color={props.color} className="modal-submit-btn">
                        {props.submitText}
                    </Button>
                </>
                }
            </form>
        </div>
    );

    return (
        <>
            { isLoading ? ( <Spinner /> ) : "" }
            <Modal
                open={props.open}
                onClose={props.onClose}
                className="custom-modal"
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {modalBody}
            </Modal>
            <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                <Alert severity="success">
                    {successText}
                </Alert>
            </Snackbar>
            <Snackbar open={openErrorAlert} autoHideDuration={2500} onClose={handleCloseError}>
                <Alert severity="error">
                    {errorText}
                </Alert>
            </Snackbar>
        </>
    );
}