import Axios from "axios";
import moment from "moment";
import { formatPrice, formatDate } from "./Handler";
const BASE_URL = "https://api.roemahku.id/";  // main API
// const BASE_URL = "https://dev.roemahku.id/";  // dev API

/**
 * Translate error code to message
 * @param error: error code (ex: 404)
 */
 const handleError = error => {
    let errorResponse = null;
    if (error.code === "ECONNABORTED") {
        errorResponse = {
            status: 408,
            error: [{ msg: error.message }],
            message: "Request Time Out",
        };
    }
    else if (error.response) {
        errorResponse = error.response.data;
    }
    else {
        errorResponse = {
            status: 501,
            error: [{ msg: "Server Implementation Error" }],
        };
    }
    return errorResponse;
};

/**
 * Handle user login
 * @param data: data required to login
 */
 export const hitLogin = async data => {
    var errorResponse = null;
    var tokenData;
    var status;
    let object = [];
  
    object = {
      "email": data.email,
      "password": data.password,
    }
  
    await Axios({
      method: "post",
      url: "api/v1/auth/login",
      baseURL: BASE_URL,
      data: object,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    })
      .then(function(response) {
        tokenData = response.data;
        status = response.status;
      })
      .catch(function(error) {
        errorResponse = handleError(error);
        status = error;
      });
    return [tokenData, errorResponse, status];
};

/**
 * Generate master pin (only exist for 5 minutes) 
 */
 export const generatePin = async () => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "post",
    url: "api/v1/pin",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get overall data for dashboard master
 * @param start: start date
 * @param end: end date
 */
 export const getDashboardData = async (start, end) => {
  var errorResponse = null;
  var tokenData;

  await Axios({
      method: "get",
      url: "api/v1/reporting/count-total/" + start + "/" + end,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get overall sales for user master
 * @param category: transaction's category
 * @param start: start date
 * @param end: end date
 * @param branchId: branch store's ID
 */
 export const getOverallSales = async (category, start, end, branchId) => {
  var errorResponse = null;
  var tokenData;
  let url = "?start_date=" + start + "&end_date=" + end;
  if ( branchId ) {
    url += ("&branch_store_id=" + branchId);
  }

  await Axios({
      method: "get",
      url: "api/v1/reporting/filter/" + category + url,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

// -------------------------------------------------------------------------------------------- //

/**
 * Get list of branch store
 */
export const getBranches = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "api/v1/branch-store",
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get specific branch store
 * @param id: branch store's ID
 */
export const getBranch = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/branch-store/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new branch store
 * @param data: data required to create new branch store 
 */
export const postBranch = async data => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  object = {
    name: data.name,
    code: data.code,
    npwp: data.npwp,
    address: data.address,
    kelurahan: data.kelurahan,
    kecamatan: data.kecamatan,
    kabupaten: data.kabupaten,
    provinsi: data.provinsi,
    kodepos: data.kodepos,
    pic: data.pic,
    contact_pic: data.contact_pic,
    email: data.email,
  };

  await Axios({
    method: "post",
    url: "api/v1/branch-store",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update branch store's data
 * @param idBranch: branch store's ID which want to be updated
 * @param data: updated data 
 */
export const updateBranch = async (idBranch, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  object = {
    name: data.name,
    code: data.code,
    npwp: data.npwp,
    address: data.address,
    kelurahan: data.kelurahan,
    kecamatan: data.kecamatan,
    kabupaten: data.kabupaten,
    provinsi: data.provinsi,
    kodepos: data.kodepos,
    pic: data.pic,
    contact_pic: data.contact_pic,
    email: data.email,
  };

  await Axios({
    method: "put",
    url: "api/v1/branch-store/update/" + idBranch,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete branch store
 * @param idBranch: branch store's ID that want to be deleted 
 */
export const deleteBranch = async idBranch => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
      method: "delete",
      url: "api/v1/branch-store/delete/" + idBranch,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
      .then(function(response) {
      tokenData = response.data;
      status = response.status;
      })
      .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
      });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get list of suppliers
 */
export const getSuppliers = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "api/v1/suppliers",
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get specific supplier
 * @param id: supplier's ID
 */
export const getSupplier = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/suppliers/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new supplier
 * @param data: data required to create new supplier 
 */
export const postSupplier = async data => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  form.append("name", data.name);
  form.append("email", data.email);
  form.append("address", data.address);
  form.append("pic", data.pic);
  form.append("contact_pic", data.contact_pic);
  if ( data.ktp_image[0] ) form.append("ktp_image", data.ktp_image[0]);
  if ( data.npwp_image[0] ) form.append("npwp_image", data.npwp_image[0]);

  await Axios({
    method: "post",
    url: "api/v1/suppliers",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: form,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update supplier's data
 * @param idSupplier: supplier's ID which want to be updated
 * @param data: updated data 
 */
export const updateSupplier = async (idSupplier, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  form.append("name", data.name);
  form.append("email", data.email);
  form.append("address", data.address);
  form.append("pic", data.pic);
  form.append("contact_pic", data.contact_pic);
  if ( data.ktp_image[0] ) form.append("ktp_image", data.ktp_image[0]);
  if ( data.npwp_image[0] ) form.append("npwp_image", data.npwp_image[0]);

  await Axios({
    method: "put",
    url: "api/v1/suppliers/update/" + idSupplier,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: form,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete supplier
 * @param idSupplier: supplier's ID that want to be deleted 
 */
export const deleteSupplier = async idSupplier => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
      method: "delete",
      url: "api/v1/suppliers/delete/" + idSupplier,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
      .then(function(response) {
      tokenData = response.data;
      status = response.status;
      })
      .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
      });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get list of product categories
 * @param branchId: product's branch store
 */
export const getCategories = async (branchId) => {
  var errorResponse = null;
  var tokenData;
  let url = "";

  if ( branchId ) {
    url += ("?branch_store_id=" + branchId);
  }

  await Axios({
      method: "get",
      url: "api/v1/categories" + url,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get specific product category
 * @param id: product category's ID
 */
export const getCategory = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/categories/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new product category
 * @param data: data required to create new product category 
 */
export const postCategory = async data => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "post",
    url: "api/v1/categories",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: { "name": data.name },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update product category's data
 * @param idCategory: product category's ID which want to be updated
 * @param data: updated data 
 */
export const updateCategory = async (idCategory, data) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "put",
    url: "api/v1/categories/update/" + idCategory,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: { "name": data.name },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete product category
 * @param idCategory: product category's ID that want to be deleted 
 */
export const deleteCategory = async idCategory => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
      method: "delete",
      url: "api/v1/categories/delete/" + idCategory,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
      .then(function(response) {
      tokenData = response.data;
      status = response.status;
      })
      .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
      });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get list of products
 */
 export const getProducts = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "api/v1/products",
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 300000,
      timeoutErrorMessage: "Request telah melebihi 5 menit. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get list of products by category
 * @param category: product's category
 */
 export const getProductsByCategory = async (category) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "api/v1/products?categories=" + category,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get list of products by branch store and/or product category
 * @param branchId: product's branch store
 * @param category: product's category
 */
 export const getProductsByParams = async (branchId, category) => {
  var errorResponse = null;
  var tokenData;
  let url = "";
  if ( branchId && category ) {
    url += ("?branch_store_id=" + branchId + "&categories=" + category);
  }
  else if ( branchId && !category ) {
    url += ("?branch_store_id=" + branchId);
  }
  else if ( !branchId && category ) {
    url += ("?categories=" + category);
  }
  await Axios({
      method: "get",
      url: "api/v1/products" + url,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get list of products by its code
 * @param code: product's code
 */
 export const getProductByCode = async (code) => {
  var errorResponse = null;
  var tokenData;
  let url = "";

  if ( localStorage.getItem("level") === "1" ) {
    const branch = JSON.parse(localStorage.getItem("currBranch"));
    url += ("?branch_store_id=" + branch.value);
  }

  await Axios({
      method: "get",
      url: "api/v1/products/inquiry/" + code + url,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get specific product
 * @param id: product's ID
 */
 export const getProduct = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/products/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get specific product's log
 * @param id: product's ID
 * @param start: first index of pagination
 * @param end: last index of pagination
 */
 export const getProductsLog = async (id, origin, start, end) => {
  var errorResponse = null;
  var tokenData;
  let url = "";
  if ( origin ) {
    url = "?origin=" + origin;
  }
  await Axios({
      method: "get",
      url: "api/v1/products/history/" + id + "/" + start + "/" + end + url,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Create new product
 * @param data: data required to create new product 
 */
export const postProduct = async data => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();
  let price = formatPrice(data.selling_price);

  form.append("product_code", data.product_code);
  form.append("name", data.name);
  form.append("supplier_id", data.supplier_id.value);
  data.category_id.forEach(res => {
    form.append("category_id", res.value);
  });
  form.append("selling_price", price);
  form.append("shelf_location", data.shelf_location);
  form.append("stock_warehouse", data.stock_warehouse? data.stock_warehouse : 0);
  if ( data.product_image[0] ) form.append("product_image", data.product_image[0]);

  await Axios({
    method: "post",
    url: "api/v1/products",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: form,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update product's data
 * @param idProduct: product's ID which want to be updated
 * @param data: updated data 
 */
export const updateProduct = async (idProduct, data, pin) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();
  
  const numberRegex = /^[0-9]*$/;
  let price = "";
  if ( !numberRegex.test(data.selling_price) ) {
      for ( let i=0; i < data.selling_price.length; i++ ) {
          const value = parseInt(data.selling_price.charAt(i));
          if ( value >= 0 && value <= 9 ) {
              price += value;
          }
      }
  }

  form.append("pin", pin);
  form.append("product_code", data.product_code);
  form.append("name", data.name);
  form.append("supplier_id", data.supplier_id? data.supplier_id.value : "");
  data.category_id.forEach(res => {
    form.append("category_id", res.value);
  });
  form.append("selling_price", price? price : data.selling_price);
  form.append("shelf_location", data.shelf_location);
  // form.append("stock", data.stock);
  if ( data.promo_id ) form.append("promo_id", data.promo_id.value);
  if ( data.product_image[0] ) form.append("product_image", data.product_image[0]);
  if ( localStorage.getItem("level") === "1" )
    form.append("purchase_price", formatPrice(data.purchase_price));

  await Axios({
    method: "put",
    url: "api/v1/products/update/" + idProduct,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: form,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update product stock's
 * @param idProduct: product's ID which want to be updated
 * @param data: updated data 
 */
export const adjustProductStock = async (idProduct, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  object = {
    "origin": data.origin.value,
    "stock": data.newStock,
    "notes": "test",
    "pin": data.pin,
  }

  await Axios({
    method: "put",
    url: "api/v1/products/update/stock/" + idProduct,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// /**
//  * Update product stock's
//  * @param idProduct: product's ID which want to be updated
//  * @param newStock: stock to be transitioned from warehouse
//  */
// export const transitionProduct = async (idProduct, newStock) => {
//   var errorResponse = null;
//   var tokenData;
//   var status;
//   let object = [];

//   object = {
//     "stock_store": newStock,
//   }

//   await Axios({
//     method: "put",
//     url: "api/v1/products/transition/stock_store/" + idProduct,
//     baseURL: BASE_URL,
//     headers: {
//         "Authorization": "Bearer " + localStorage.getItem('token'),
//     },
//     data: object,
//     timeout: 30000,
//     timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
//   })
//     .then(function(response) {
//       tokenData = response.data;
//       status = response.status;
//     })
//     .catch(function(error) {
//       errorResponse = handleError(error);
//       status = error;
//     });
//   return [tokenData, errorResponse, status];
// };

/**
 * Restock product
 * @param idProduct: product's ID which want to be restocked
 * @param data: updated data
 */
export const restockProduct = async (idProduct, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  let formattedDate = formatDate(data.date);
  formattedDate += (" " + moment().subtract(moment.duration("07:00:00")).format("HH:mm:ss"));
  object = {
    "category": "buy",
    "date": formattedDate,
    "items": [
      {
        "product_id": idProduct,
        "quantity": data.newStock,
      }
    ],
  }

  await Axios({
    method: "post",
    url: "api/v1/transactions",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete product
 * @param idProduct: product's ID that want to be deleted
 * @param pin: master pin
 */
export const deleteProduct = async (idProduct, pin) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = {
    "pin": pin,
  }

  await Axios({
      method: "delete",
      url: "api/v1/products/delete/" + idProduct,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      data: object,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
      .then(function(response) {
      tokenData = response.data;
      status = response.status;
      })
      .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
      });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get list of product losses
 * @param branchId: product's branch store
 */
 export const getProductLosses = async (branchId) => {
  var errorResponse = null;
  var tokenData;
  let url = "";

  if ( branchId ) {
    url += ("?branch_store_id=" + branchId);
  }

  await Axios({
      method: "get",
      url: "api/v1/losses-product" + url,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 300000,
      timeoutErrorMessage: "Request telah melebihi 5 menit. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get specific product loss
 * @param id: product loss' ID
 */
 export const getProductLoss = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/losses-product/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new product loss
 * @param data: data required to create new product loss 
 */
export const postProductLoss = async data => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  object = {
    // "branch_store_id": data.branch? data.branch.value : "",
    "product_id": data.product_id.value,
    "product_code": data.product_code?
      data.product_code : "",
    "quantity": data.quantity,
    "selling_price": data.selling_price?
      formatPrice(data.selling_price) : "",
    "notes": data.notes,
    "origin": data.origin.value,
    "isSelling": data.isSelling,
  };

  await Axios({
    method: "post",
    url: "api/v1/losses-product",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update product loss' data
 * @param idProduct: product loss' ID which want to be updated
 * @param data: updated data 
 */
export const updateProductLoss = async (idProduct, data, pin) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  object = {
    // "product_id": data._id,
    "product_code": data.product_code,
    "quantity": data.quantity,
    "selling_price": data.selling_price?
      formatPrice(data.selling_price) : "",
    "notes": data.notes,
    "origin": data.origin.value,
    "isSelling": data.isSelling,
    "pin": pin,
  };

  await Axios({
    method: "put",
    url: "api/v1/losses-product/update/" + idProduct,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete product loss
 * @param idProduct: product loss' ID that want to be deleted
 * @param pin: master pin
 */
export const deleteProductLoss = async (idProduct, pin) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = {
    "pin": pin,
  }

  await Axios({
      method: "delete",
      url: "api/v1/losses-product/delete/" + idProduct,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      data: object,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
      .then(function(response) {
      tokenData = response.data;
      status = response.status;
      })
      .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
      });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get list of admin
 * @param branchId: branch store's ID
 */
export const getAdmins = async (branchId) => {
  var errorResponse = null;
  var tokenData;
  let url = "";

  if ( branchId ) {
    url += ("?branch_store_id=" + branchId);
  }

  await Axios({
      method: "get",
      url: "api/v1/users" + url,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get specific admin
 * @param id: admin's ID
 */
export const getAdmin = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/users/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new admin
 * @param data: data required to create new admin 
 */
export const postAdmin = async data => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  form.append("nik", data.nik);
  form.append("name", data.name);
  form.append("email", data.email);
  form.append("phone", data.phone);
  form.append("jobdesk", data.jobdesk.value);
  if ( data.branch ) {
    form.append("branch_store_id", data.branch.value);
  }
  if ( data.ktp_image[0] ) form.append("ktp_image", data.ktp_image[0]);
  if ( data.npwp_image[0] ) form.append("npwp_image", data.npwp_image[0]);

  await Axios({
    method: "post",
    url: "api/v1/users",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: form,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update admin's data
 * @param idAdmin: admin's ID which want to be updated
 * @param data: updated data 
 */
export const updateAdmin = async (idAdmin, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  form.append("nik", data.nik);
  form.append("name", data.name);
  form.append("email", data.email);
  form.append("phone", data.phone);
  form.append("jobdesk", data.jobdesk.value);
  if ( data.branch ) {
    form.append("branch_store_id", data.branch.value);
  }
  if ( data.ktp_image[0] ) form.append("ktp_image", data.ktp_image[0]);
  if ( data.npwp_image[0] ) form.append("npwp_image", data.npwp_image[0]);

  await Axios({
    method: "put",
    url: "api/v1/users/update/" + idAdmin,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: form,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update current user's password with user's input
 * @param data: previous and new password
 */
 export const changeCurrentPassword = async (data) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "put",
    url: "api/v1/auth/change_password",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: {
      "old_password": data.old_password,
      "new_password": data.new_password,
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update user's password with new random password
 * @param idAdmin: admin's ID which want to be updated
 */
 export const changePassword = async (idAdmin) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "put",
    url: "api/v1/users/update/password/" + idAdmin,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete admin
 * @param idAdmin: admin's ID that want to be deleted 
 */
export const deleteAdmin = async idAdmin => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
      method: "delete",
      url: "api/v1/users/delete/" + idAdmin,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
      .then(function(response) {
      tokenData = response.data;
      status = response.status;
      })
      .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
      });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get list of payment methods
 * @param branchId: branch store's ID
 */
 export const getPaymentMethods = async (branchId) => {
  var errorResponse = null;
  var tokenData;
  let url = "";

  if ( branchId ) {
    url += ("?branch_store_id=" + branchId);
  }

  await Axios({
      method: "get",
      url: "api/v1/type-payment" + url,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get specific payment method
 * @param id: payment method's ID
 */
export const getPaymentMethod = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/type-payment/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new payment method
 * @param data: data required to create new payment method 
 */
export const postPaymentMethod = async data => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  form.append("branch_store_id", data.branch.value);
  form.append("name", data.name);
  form.append("category", data.category.value);
  if ( data.payment_icon[0] ) form.append("payment_icon", data.payment_icon[0]);

  await Axios({
    method: "post",
    url: "api/v1/type-payment",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: form,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update payment method's data
 * @param idMethod: payment method's ID which want to be updated
 * @param data: updated data 
 */
export const updatePaymentMethod = async (idMethod, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  form.append("branch_store_id", data.branch.value);
  form.append("name", data.name);
  form.append("category", data.category.value);
  if ( data.payment_icon[0] ) form.append("payment_icon", data.payment_icon[0]);

  await Axios({
    method: "put",
    url: "api/v1/type-payment/update/" + idMethod,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: form,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete payment method
 * @param idMethod: payment method's ID that want to be deleted 
 */
export const deletePaymentMethod = async idMethod => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
      method: "delete",
      url: "api/v1/type-payment/delete/" + idMethod,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
      .then(function(response) {
      tokenData = response.data;
      status = response.status;
      })
      .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
      });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get list of taxes
 */
 export const getTaxes = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "api/v1/taxes",
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get specific tax
 * @param id: tax's ID
 */
export const getTax = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/taxes/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new tax
 * @param data: data required to create new tax 
 */
export const postTax = async data => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  object = {
    "name": data.name,
    "value": data.value.replace("%",""),
  }

  await Axios({
    method: "post",
    url: "api/v1/taxes",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update tax's data
 * @param idTax: tax's ID which want to be updated
 * @param data: updated data 
 */
export const updateTax = async (idTax, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  object = {
    "name": data.name,
    "value": data.value.replace("%",""),
  }

  await Axios({
    method: "put",
    url: "api/v1/taxes/update/" + idTax,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete tax
 * @param idTax: tax's ID that want to be deleted 
 */
export const deleteTax = async idTax => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
      method: "delete",
      url: "api/v1/taxes/delete/" + idTax,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
      .then(function(response) {
      tokenData = response.data;
      status = response.status;
      })
      .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
      });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get list of discounts
 */
 export const getDiscounts = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "api/v1/promos",
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get specific discount
 * @param id: discount's ID
 */
export const getDiscount = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/promos/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new discount
 * @param data: data required to create new discount 
 */
export const postDiscount = async data => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  if ( data.type_promo.value === "persen" ) {
    object = {
      "name": data.name,
      "type_promo": data.type_promo.value,
      "value": data.percentage.replace("%",""),
    }
  }
  else if ( data.type_promo.value === "rupiah" ) {
    object = {
      "name": data.name,
      "type_promo": data.type_promo.value,
      "value": formatPrice(data.flat),
    }
  }
  else if ( data.type_promo.value === "buyxgety" ) {
    object = {
      "name": data.name,
      "type_promo": data.type_promo.value,
      "x": {
        "product_id": data.productX.value,
        "value": data.valueX,
      },
      "y": {
        "product_id": data.productY.value,
        "value": data.valueY,
      },
    }
  }

  await Axios({
    method: "post",
    url: "api/v1/promos",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update discount's data
 * @param idDiscount: discount's ID which want to be updated
 * @param data: updated data 
 */
export const updateDiscount = async (idDiscount, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  if ( data.type_promo === "Persen" ) {
    object = {
      "name": data.name,
      "value": data.percentage.replace("%",""),
    }
  }
  else if ( data.type_promo === "Flat" ) {
    object = {
      "name": data.name,
      "value": formatPrice(data.flat),
    }
  }
  else if ( data.type_promo === "Buy X Get Y" ) {
    object = {
      "name": data.name,
      "x": {
        "product_id": data.productX.value,
        "value": data.valueX,
      },
      "y": {
        "product_id": data.productY.value,
        "value": data.valueY,
      },
    }
  }

  await Axios({
    method: "put",
    url: "api/v1/promos/update/" + idDiscount,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete discount
 * @param idDiscount: discount's ID that want to be deleted 
 */
export const deleteDiscount = async idDiscount => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
      method: "delete",
      url: "api/v1/promos/delete/" + idDiscount,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
      .then(function(response) {
      tokenData = response.data;
      status = response.status;
      })
      .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
      });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Do stock opname
 * @param date: stock opname implementation date 
 * @param products: list of products
 * @param newStocks: list of new stock/quantity
 * @param notes: notes for every product
 */
 export const stockOpname = async (origin, date, products, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  let formattedDate = formatDate(date);
  let opnameStocks = [];
  for (let i=0; i < products.length; i++) {
    opnameStocks.push({
      "product_id": products[i]._id,
      "quantity": products[i].value,
      "notes": data.reason[i],
    });
  }

  object = {
    "date": formattedDate,
    "opname_stocks": opnameStocks,
    "origin": origin,
    "branch_store_id": localStorage.getItem("level") === "1"?
      JSON.parse(localStorage.getItem("currBranch")).value : "",
  }

  await Axios({
    method: "post",
    url: "api/v1/stock-opname",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get stock history
 */
 export const getStockHistory = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "api/v1/stock-history",
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get specific stock history
 * @param id: stock history's ID
 */
 export const getStockHistoryById = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/stock-history/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Filter stock history by transaction type/category
 * @param category: stock history's category
 */
 export const getStockHistoryByCategory = async category => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/stock-history/filter/" + category,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Filter stock history by payment method
 * @param id: payment method's ID
 */
 export const getStockHistoryByPaymentMethod = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/stock-history/filter/payment-method/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Filter stock history by date
 * @param start: start date
 * @param end: end date
 */
 export const getStockHistoryByDate = async (start, end) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/stock-history/filter/date/" + start + "/" + end,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Return product from customer to store
 * @param idTransaction: transaction's ID 
 * @param data: data required to return the products 
 */
 export const returnToStore = async (idTransaction, products, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];
  let items = [];

  for(let i=0; i < products.length; i++) {
    if ( data.checked[i] ) {
      items.push({
        "product_id": products[i].product_id._id,
        "quantity": data.quantity[i]? parseInt(data.quantity[i]) : 0,
      });
    }
  }
  
  object = {
    "items": items,
    "notes": data.notes,
  }

  await Axios({
    method: "post",
    url: "api/v1/transactions/" + data.type.value + "/" + idTransaction,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get list of transactions
 */
 export const getTransactions = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "api/v1/transactions",
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get specific transaction by id
 * @param id: transaction's ID
 */
 export const getTransaction = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/transactions/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get all transactions by category
 * @param category: transaction's category
 */
 export const getPrintTransactions = async category => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/transactions/print/" + category,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get all transactions by start-end date
 * @param category: transaction's category
 * @param start: start date
 * @param end: end date
 */
 export const getPrintTransactionsByDate = async (category, start, end) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/transactions/print/" + start + "/" + end
      + "?category=" + category,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get specific transaction by code
 * @param code: transaction's code/invoice number
 */
 export const getTransactionByCode = async code => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/transactions/paging/1/10?search=" + code,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new transaction with category="sell-warehouse"
 * @param data: data required to create new transaction 
 */
 export const postTransaction = async (data, products) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];
  let productPrice = formatPrice(data.total_price);
  let orderFee = formatPrice(data.postal_fee);
  let formattedDate = formatDate(data.date);

  let resItems = [];
  products.forEach(res => {
    resItems.push({
      "product_id": res.id,
      "quantity": res.qty,
      "selling_price": formatPrice(res.price),
      "total_price": formatPrice(res.subtotal),
    });
  });

  object = {
    "category": "sell-warehouse",
    "total_price": parseInt(productPrice),
    "date": formattedDate,
    "taxes": data.tax? [
      {
        "name": data.tax.name,
        "value": data.tax.value,
        "total": formatPrice(data.total_tax),
      }
    ] : [],
    "details_transaction_warehouse": {
        "buyer_name": data.buyer_name,
        "buyer_phone": data.buyer_phone,
        "delivery_destination": data.delivery_destination,
        "postal_fee": parseInt(orderFee),
        "driver_name": data.driver_name,
        "driver_phone": data.driver_phone
    },
    "items": resItems,
    "notes": data.notes,
  }

  await Axios({
    method: "post",
    url: "api/v1/transactions",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Approve purchase transaction
 * @param id: transaction's ID which want to be approved
 * @param data: data needed to approve transaction
 */
 export const approveTransaction = async (id, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  if ( data.status === "approve" ) {
    object = {
      "receipt": data.receipt,
      "notes": data.notes,
      "total_price": formatPrice(data.total_price),
      "approval_manager_finance": "approve",
    }
  }
  else if ( data.status === "decline" ) {
    object = {
      "receipt": "",
      "notes": data.notes,
      "total_price": 0,
      "approval_manager_finance": "decline",
    }
  }

  await Axios({
    method: "put",
    url: "api/v1/transactions/approve/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get transactions with x category
 * @param category: transaction's category
 * @param start: first index of pagination
 * @param end: last index of pagination
 */
 export const getTransactionsByCategory = async (category, start, end) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/transactions/paging/" + category + "/" + start + "/" + end,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Get transactions with x category on certain date range
 * @param category: transaction's category
 * @param startIdx: first index of pagination
 * @param endIdx: last index of pagination
 * @param start: start date
 * @param end: end date
 */
 export const getTransactionsByCategoryAndDate = async (category, startIdx, endIdx, start, end) => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/transactions/paging/" + category + "/" + startIdx + "/"
      + endIdx + "/" + start + "/" + end,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get list of extra expenses
 */
 export const getExtraExpenses = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "api/v1/extra-expenses",
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get specific extra expense
 * @param id: extra expense's ID
 */
export const getExtraExpense = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/extra-expenses/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new extra expense
 * @param data: data required to create new extra expense 
 */
export const postExtraExpense = async data => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];
  let price = formatPrice(data.total_price);

  object = {
    "notes": data.notes,
    "total_price": price,
  }

  await Axios({
    method: "post",
    url: "api/v1/extra-expenses",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update extra expense's data
 * @param idExpense: extra expense's ID which want to be updated
 * @param data: updated data 
 */
export const updateExtraExpense = async (idExpense, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];
  let price = formatPrice(data.total_price);

  object = {
    "notes": data.notes,
    "total_price": price,
  }

  await Axios({
    method: "put",
    url: "api/v1/extra-expenses/update/" + idExpense,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete extra expense
 * @param idExpense: extra expense's ID that want to be deleted 
 */
export const deleteExtraExpense = async idExpense => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
      method: "delete",
      url: "api/v1/extra-expenses/delete/" + idExpense,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
      .then(function(response) {
      tokenData = response.data;
      status = response.status;
      })
      .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
      });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get income statement at certain date range
 * @param start: start date
 * @param end: end date
 */
 export const getIncomeStatement = async (start, end) => {
  var errorResponse = null;
  var tokenData;

  await Axios({
      method: "get",
      url: "api/v1/reporting/profit-and-loss/" + start + "/" + end,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get income statement for excel report at certain date range
 * @param start: start date
 * @param end: end date
 */
 export const getIncomeStatementReport = async (start, end) => {
  var errorResponse = null;
  var tokenData;

  await Axios({
      method: "get",
      url: "api/v1/reporting/export-excel/" + start + "/" + end,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

// -------------------------------------------------------------------------------------------- //

/**
 * Return products to supplier
 * @param id: product's ID
 */
export const returnToSupplier = async (id) => {
  var errorResponse = null;
  var tokenData;
  
  await Axios({
    method: "put",
      url: "api/v1/stock-history/return-to-supplier/" + id,
      baseURL: BASE_URL,
      headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
    })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
    return [tokenData, errorResponse];
  }

// -------------------------------------------------------------------------------------------- //

/**
 * Get list of cashier session
 */
 export const getCashierSessions = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "api/v1/open-cashier",
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get specific cashier session
 * @param id: cashier session's ID
 */
export const getCashierSession = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/open-cashier/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new cashier session
 * @param data: data required to create new cashier session 
 */
export const postCashierSession = async data => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  object = {
    "cashier_id": data.cashier? data.cashier.value : "",
    "initial_nominal": formatPrice(data.initial_nominal),
    "notes": data.notes,
  }

  await Axios({
    method: "post",
    url: "api/v1/open-cashier",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update cashier session's data
 * @param idSession: cashier session's ID which want to be updated
 * @param data: updated data 
 */
export const updateCashierSession = async (idSession, data, pin) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  object = {
    // "cashier_id": data.cashier? data.cashier.value : "",
    "initial_nominal": formatPrice(data.initial_nominal),
    "final_nominal": data.final_nominal?
      formatPrice(data.final_nominal) : "",
    "notes": data.notes,
    "pin": pin,
  }

  await Axios({
    method: "put",
    url: "api/v1/open-cashier/update/" + idSession,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Close cashier session
 * @param idSession: cashier session's ID which want to be closed
 * @param data: updated data
 */
export const closeCashierSession = async (idSession, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  object = {
    // "cashier_id": data.cashier? data.cashier.value : "",
    "final_nominal": formatPrice(data.final_nominal),
    "notes": data.notes,
  }

  await Axios({
    method: "put",
    url: "api/v1/open-cashier/update/" + idSession + "/close",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete cashier session
 * @param idSession: cashier session's ID that want to be deleted 
 */
export const deleteCashierSession = async (idSession, pin) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
      method: "delete",
      url: "api/v1/open-cashier/delete/" + idSession,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      data: { "pin": pin },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
      .then(function(response) {
      tokenData = response.data;
      status = response.status;
      })
      .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
      });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get list of request/restock products
 * @param branchId: branch store's ID
 */
 export const getRequests = async (branchId) => {
  var errorResponse = null;
  var tokenData;
  let url = "";

  if ( branchId ) {
    url += ("?branch_store_id=" + branchId);
  }

  await Axios({
      method: "get",
      url: "api/v1/request-product" + url,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get specific request/restock
 * @param id: request/restock's ID
 */
export const getRequest = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/request-product/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new request/restock
 * @param data: data required to create new request/restock
 * @param products: requested products 
 */
export const postRequest = async (data, products) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  let resItems = [];
  products.forEach(res => {
    resItems.push({
      "product_id": res.id,
      "quantity": res.qty,
      "selling_price": "",
    });
  });
  
  object = {
    "supplier_id": data.supplier_id? data.supplier_id.value : "",
    "total_price": "",
    "notes": data.notes,
    "items": resItems,
  }

  await Axios({
    method: "post",
    url: "api/v1/request-product",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update request/restock's status
 * @param idRequest: request/restock's ID which want to be updated
 * @param newStatus: new status for product request
 */
export const updateRequestStatus = async (idRequest, data, newStatus) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  if ( newStatus === "decline" ) {
    object = {
      "notes": data.notes,
    }
  }
  else if ( newStatus === "approve" || newStatus === "validate" ) {
    let resItems = [];
    data.items.forEach(res => {
      resItems.push({
        "product_id": res.product_id._id,
        "quantity": res.quantity,
        "selling_price": newStatus === "approve"?
          "" : formatPrice(res.selling_price),
      });
    });
    object = {
      "supplier_id": data.supplier_id? data.supplier_id.value : "",
      "total_price": "",
      "notes": data.notes,
      "items": resItems,
    }
  }
  else if ( newStatus === "done" ) {
    let resItems = [];
    data.items.forEach(res => {
      resItems.push({
        "product_id": res.product_id._id,
        "quantity": res.quantity,
        "selling_price": res.selling_price,
        "purchase_price": res.purchase_price,
      });
    });
    object = {
      "supplier_id": data.supplier_id? data.supplier_id.value : "",
      "total_price": data.total_price,
      "notes": data.notes,
      "items": resItems,
    }
  }

  await Axios({
    method: "put",
    url: "api/v1/request-product/update/" + idRequest + "/" + newStatus,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete request/restock
 * @param idRequest: request/restock's ID that want to be deleted 
 */
export const deleteRequest = async idRequest => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
      method: "delete",
      url: "api/v1/request-product/delete/" + idRequest,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
      .then(function(response) {
      tokenData = response.data;
      status = response.status;
      })
      .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
      });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get list of product transitions
 * @param branchId: branch store's ID
 */
 export const getTransitions = async (branchId) => {
  var errorResponse = null;
  var tokenData;
  let url = "";

  if ( branchId ) {
    url += ("?branch_store_id=" + branchId);
  }

  await Axios({
      method: "get",
      url: "api/v1/transition" + url,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get specific transition
 * @param id: transition's ID
 */
export const getTransition = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "api/v1/transition/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new transition
 * @param data: data required to create new transition
 * @param products: products to be transitioned
 */
export const postTransition = async (data, products) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let object = [];

  let resItems = [];
  products.forEach(res => {
    resItems.push({
      "product_id": res.id,
      "quantity": res.qty,
    });
  });

  object = {
    "origin": data.origin.value,
    "notes": data.notes,
    "items": resItems,
  }

  await Axios({
    method: "post",
    url: "api/v1/transition",
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: object,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete transition
 * @param id: transition's ID that want to be deleted 
 */
export const deleteTransition = async id => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
      method: "delete",
      url: "api/v1/transition/delete/" + id,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
      .then(function(response) {
      tokenData = response.data;
      status = response.status;
      })
      .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
      });
  return [tokenData, errorResponse, status];
};