import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import ReactExport from "react-data-export";
import NumberFormat from "react-number-format";
import { Grid, Button, } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Table from '../../../components/Table_Com';
import Modal from '../../../components/Modal_Com';
import Filter from "../../../components/Filter_Com";
import logo from "../../../assets/img/logo.png";
import { getExtraExpenses } from "../../../logic/APIHandler";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class ExtraExpense extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            defaultData: [],
            filenameRange: "",
            open: false,
            openJWT: false,
            expenseId: '00000000',
            expenseNotes: '',
            isLoading: false,
            isSidebarOpen: 'slide-out',
        }
        this.excelRef = React.createRef();
        this.filterRef = React.createRef();
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.filterData = this.filterData.bind(this);
        this.downloadExcel = this.downloadExcel.bind(this);
    }

    columns = [
        {
            title: "Tanggal",
            field: "created_at",
            tooltip: "Tanggal pembuatan pengeluaran tambahan",
            render: rowData =>
                <p>{moment(rowData.created_at).format("DD MMMM YYYY")}</p>
        },
        { 
            title: "Keterangan", 
            field: "notes",
            tooltip: "Keterangan pengeluaran tambahan",
        },
        {
            title: "Total Pengeluaran",
            field: "total_price",
            tooltip: "Total pengeluaran tambahan",
            render: rowData =>
                <p>
                    <NumberFormat
                        value={rowData.total_price}
                        displayType={"text"}
                        thousandSeparator="."
                        decimalSeparator=","
                        prefix={"Rp"}
                        suffix=",00"
                    />
                </p>
        },
    ]

    actions = [
        {
            icon: () => <FindInPageIcon />,
            tooltip: "Lihat Detail Pengeluaran Tambahan",
            onClick: (event, rowData) => {
                this.props.history.push({
                    pathname: "/pengeluaran-tambahan/" + rowData._id,
                  })
            }
        },
        {
            icon: () => <DeleteIcon />,
            tooltip: "Hapus Pengeluaran Tambahan",
            onClick: (event, rowData) => {
                this.handleOpen(rowData);
            }
        },
    ]

    // Make the website stay still for __ ms
    sleep(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Show delete modal
    handleOpen(data) {
        this.setState({
            open: true,
            expenseId: data._id,
            expenseNotes: data.notes,
        })
    }

    // CLose delete modal
    handleClose() {
        this.setState({
            open: false,
        })
    }

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }

    // Filter data tabel
    async filterData() {
        this.setState({ isLoading: true });
        localStorage.removeItem("tablePage");
        let result = [];
        let start = "", end = "";
        const ref = this.filterRef.current.state;
        if ( ref.startDate || ref.endDate ) {
            result = this.state.defaultData.filter(res => (
                (moment(res['created_at']).startOf("day") - moment(ref.endDate).startOf("day")) <= 0
            ))
            result = result.filter(res => (
                (moment(res['created_at']).startOf("day") - moment(ref.startDate).startOf("day")) >= 0
            ));
            start = moment(ref.startDate).format("YYYY-MM-DD");
            end = moment(ref.endDate).format("YYYY-MM-DD");
            this.setState({
                filenameRange: (start === end)?
                    " (" + start + ")" : " (" + start + " - " + end + ")",
            })
        }
        else {
            result = this.state.defaultData;
        }
        await this.sleep(250);
        this.setState({
            data: result,
            isLoading: false,
        });
    }

    // Create formatted excel data & download it
    async downloadExcel() {
        this.setState({ isLoading: true });
        // Create columns & header style
        let res = [
            {
                columns: [
                    {title: "No", width: {wch: 4}, style: {
                        alignment: { horizontal: "center" },
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: '007F60' } },
                    }}, 
                    {title: "Tanggal", width: {wch: 17}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: '007F60' } },
                    }},
                    {title: "Keterangan", width: {wch: 50}, style: {
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: '007F60' } },
                    }},
                    {title: "Total Harga", width: {wch: 15}, style: {
                        alignment: { horizontal: "right" },
                        font: { bold: true, color: { rgb: 'FFFFFF'} },
                        fill: { fgColor: { rgb: '007F60' } },
                    }},
                ],
                data: [],
            }
        ]

        if ( !localStorage.getItem("start") && !localStorage.getItem("end") ) {
            this.setState({ filenameRange: "" });
        }
        
        // Add row items to table
        if ( this.state.data && this.state.data.length > 0 ) {
            for (let i=0; i < this.state.data.length; i++) {
                let totalPrice = this.state.data[i].total_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                totalPrice = "Rp " + totalPrice + ",-";
                res[0].data.push([
                    { value: (i+1), style: {alignment: { horizontal: "center" }}},
                    { value: moment(this.state.data[i].created_at).format("DD MMMM YYYY") },
                    { value: this.state.data[i].notes},
                    { value: totalPrice, style: { alignment: { horizontal: "right" }}},
                ])
            }
        }
        else {
            res[0].data.push([
                { value: "-", style: {alignment: { horizontal: "center" }}},
                { value: "-" },
                { value: "-" },
                { value: "-", style: { alignment: { horizontal: "right" }}},
            ])
        }

        // Set table data
        this.setState({ excelData: res, isLoading: false }, () => {
            if ( this.excelRef.current !== null ) {
                this.excelRef.current.click();
            }
        })
    }

    async getData() {
        this.setState({ isLoading: true });
        let result = [];
        let resp = await getExtraExpenses();
        if ( resp[0] && resp[0].status === 200 ) {
            if ( localStorage.getItem("start") || localStorage.getItem("end") ) {
                result = resp[0].data.filter(res => (
                    (moment(res['created_at']).startOf("day")
                        - moment(localStorage.getItem("end")).startOf("day")) <= 0
                ))
                result = result.filter(res => (
                    (moment(res['created_at']).startOf("day")
                        - moment(localStorage.getItem("start")).startOf("day")) >= 0
                ));
                // Set excel name date range
                const start = moment(localStorage.getItem("start")).format("YYYY-MM-DD");
                const end = moment(localStorage.getItem("end")).format("YYYY-MM-DD");
                if ( start === end ) {
                    this.setState({ filenameRange: " (" + start + ")" });
                }
                else {
                    this.setState({ filenameRange: " (" + start + " - " + end + ")" });
                }
            }
            else {
                result = resp[0].data;
            }

            this.setState({
                data: result,
                defaultData: resp[0].data,
                isLoading: false,
            })
        }
        else if ( resp[1] && resp[1].status === 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({
                isLoading: false,
            })
        }
    }

    componentDidMount() {
        // Set Indonesia as moment's location
        let idLocale = require('moment/locale/id'); 
        moment.locale('id', idLocale);

        if ( localStorage.getItem("path") !== this.props.location.pathname ) {
            localStorage.removeItem("path");
            localStorage.removeItem("search");
            localStorage.removeItem("tablePage");
        }
        this.getData();
    }

    render() {
        return (
            <div className="content">
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""} level="3" />

                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="delete-extra-expense"
                    id={this.state.expenseId}
                    color="secondary"
                    modalTitle="Hapus pengeluaran tambahan?"
                    dataName={this.state.expenseNotes}
                    submitText="Delete"
                    cancelText="Cancel"
                />
                <Modal
                    open={this.state.openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container" style={{ position: "relative" }}>
                    <Grid item xs={12} className="with-download">
                        <Filter ref={this.filterRef} handleFilter={this.filterData} type="filter-by-date" />
                    </Grid>
                    
                    <Button
                        variant="contained"
                        className="download-btn table"
                        onClick={this.downloadExcel}
                    >
                        <CloudDownloadIcon style={{ marginRight: "10px" }} />
                        Download Excel
                    </Button>

                    { this.state.isLoading ? ( <Spinner /> ) : <>
                    <Grid item xs={12} className="material-table add-margin-top">
                        <Table
                            tableTitle="Tabel Pengeluaran Tambahan"
                            tableColumns={this.columns}
                            data={this.state.data}
                            tableActions={this.actions}
                        />
                    </Grid>
                    <Grid item xs={12} className="table-footer">
                        <NavLink to="/pengeluaran-tambahan-form" style={{ textDecoration: "none" }}>
                            <Button variant="contained" className="add-btn-table">
                                Buat Pengeluaran Tambahan
                            </Button>
                        </NavLink>
                        <div style={{ display: "inline-block", width: "0", pointerEvents: "none" }}>
                            <ExcelFile
                                filename={`Laporan Pengeluaran Tambahan${this.state.filenameRange?
                                    this.state.filenameRange : ""
                                }`}
                                element={
                                    <Button innerRef={this.excelRef}></Button>
                                }
                            >
                                <ExcelSheet dataSet={this.state.excelData} name="Laporan"/>
                            </ExcelFile>
                        </div>
                    </Grid></> }
                </Grid>
            </div>
        );
    }
}

export default ExtraExpense;