import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Grid, Button, Snackbar, TextField } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import NumberFormat from "react-number-format";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { DateInput, TextInput, DropdownInput } from "../../../logic/FormInput";
import { getSuppliers, getRequest } from "../../../logic/APIHandler";
import { formatPrice } from "../../../logic/Handler";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";
import moment from "moment";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailRequest(props) {
    
    // Form State
    const [id, setId] = useState("");
    const [status, setStatus] = useState("pending");
    const [items, setItems] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [defaultDate, setDefaultDate] = useState(moment());

    // Page State
    const { register, handleSubmit, control, setValue } = useForm();
    const [updatedData, setUpdatedData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadCalculation, setLoadCalculation] = useState(false);
    const [openApprove, setOpenApprove] = useState(false);
    const [openValidate, setOpenValidate] = useState(false);
    const [openDone, setOpenDone] = useState(false);
    const [openDecline, setOpenDecline] = useState(false);
    const [openJWT, setOpenJWT] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
        const idRequest = props.match.params.id;
        setId(idRequest);
        if ( idRequest ) {
            getData(idRequest);
        }
        else {
            console.log("ID not found");
        }     
    }, [props.match.params.id]);

    // Get initial data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = await getRequest(id);
        if ( resp[0] && resp[0].status === 200 ) {
            const result = resp[0].data;
            setStatus(result.status);
            setDefaultDate(result.created_at.substring(0,19));
            setValue("notes", result.notes);
            setValue("invoice", result.invoice);
            setTotalPrice(result.total_price);
            setItems(result.items);
            result.items.forEach((res, idx) => {
                setValue(("quantity." + idx), res.quantity);
                if ( result.status === "approve" ) {
                    setValue(("selling_price." + idx), res.product_id.selling_price);
                }
                else {
                    setValue(("selling_price." + idx), res.selling_price);
                }
                if ( localStorage.getItem("level") === "1" ) {
                    if ( result.status === "validate" ) {
                        res.purchase_price = "";
                        res.subtotal = 0;
                    }
                    else if ( result.status === "done" ) {
                        setValue(("subtotal." + idx), (res.purchase_price * res.quantity));
                    }
                }
            });
            getDataSupplier(result.supplier_id._id);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp[1].message);
            setIsLoading(false);
        }
    }
    // Get list of supplier
    const getDataSupplier = async (id) => {
        let resp = await getSuppliers();
        if ( resp[0] && resp[0].status === 200 ) {
            let temp = [];
            resp[0].data.sort((a,b) => (a.name > b.name? 1 : -1)).forEach(res => {
                temp.push({
                    "label": res.name,
                    "value": res._id,
                })
                // Set current supplier
                if ( res._id === id ) {
                    setValue("supplier_id", {
                        "label": res.name,
                        "value": res._id,
                    });
                }
            });
            setSuppliers(temp);
        }
        else if ( resp[0] && resp[0].status === 204 ) {
            setErrorText("Supplier tidak ditemukan!");
            setOpenErrorAlert(true);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
        }
        setIsLoading(false);
    }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Handle close
    const handleClose = () => {
        setOpenDone(false);
        setOpenValidate(false);
        setOpenApprove(false);
        setOpenDecline(false);
    }
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Send new data to server
    const onSubmit = async (data) => {
        let isValid = true;
        items.forEach((res, idx) => {
            if ( status !== "validate" && data.quantity[idx] && data.selling_price
                && data.selling_price[idx] ) {
                res.quantity = data.quantity[idx]
                res.selling_price = data.selling_price[idx]
            }
            else if ( status === "validate" ) {
                data.total_price = formatPrice(totalPrice);
                if ( !res.purchase_price ) {
                    isValid = false;
                }
            }
            else if ( status === "pending" && data.quantity[idx] ) {
                res.quantity = data.quantity[idx]
            }
            else {
                isValid = false;
            }
        });
        data.items = items;
        setUpdatedData(data);
        if ( isValid ) {
            if ( status === "pending" ) {
                setOpenApprove(true);
            }
            else if ( status === "approve" ) {
                setOpenValidate(true);
            }
            else if ( status === "validate" ) {
                setOpenDone(true);
            }
        }
        else {
            if ( status === "validate" ) {
                setErrorText("Seluruh harga beli produk wajib diisi!");
                setOpenErrorAlert(true);
            }
            else {
                setErrorText("Seluruh harga jual & kuantitas produk wajib diisi!");
                setOpenErrorAlert(true);
            }
        }
    }

    return (
        <div className="content">
            { loadCalculation? ( <Spinner isCalc={true} /> ) : isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""}
                level={localStorage.getItem("level")} />

            <Modal
                open={openDone}
                onClose={handleClose}
                onClickCancel={handleClose}
                modalType="done-restock"
                id={id}
                data={updatedData}
                modalTitle="Selesaikan Request Produk?"
                submitText="Submit"
                cancelText="Cancel"
            />
            <Modal
                open={openValidate}
                onClose={handleClose}
                onClickCancel={handleClose}
                modalType="validate-restock"
                id={id}
                data={updatedData}
                modalTitle="Validasi Request Produk?"
                submitText="Submit"
                cancelText="Cancel"
            />
            <Modal
                open={openApprove}
                onClose={handleClose}
                onClickCancel={handleClose}
                modalType="approve-restock"
                id={id}
                data={updatedData}
                modalTitle="Approve Request Produk?"
                submitText="Submit"
                cancelText="Cancel"
            />
            <Modal
                open={openDecline}
                onClose={handleClose}
                onClickCancel={handleClose}
                modalType="decline-restock"
                id={id}
                color="secondary"
                modalTitle="Tolak Request Produk?"
                submitText="Tolak"
                cancelText="Cancel"
            />
            <Modal
                open={openJWT}
                modalType="handle-jwt"
                modalTitle="Token Anda Sudah Expire"
            />
            
            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <img src={logo} alt="logo" className="header-logo" />
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openErrorAlert} autoHideDuration={3000} onClose={handleCloseError}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <h1 style={{ marginBottom: "2.2rem" }}>
                                    Detail Request
                                </h1>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                <DateInput
                                    label="Tanggal Request"
                                    name="date"
                                    value={defaultDate}
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    label="No. Invoice"
                                    name="invoice"
                                    placeholder="-"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ position: "relative" }}>
                                <label className="selection-label">Supplier</label>
                                <DropdownInput
                                    name="supplier_id"
                                    className={`${status !== "pending"? "disabled-field" : ""}
                                        selection-field add-margin-bottom`}
                                    control={control}
                                    options={suppliers}
                                    isClearable={false}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    multiline={true}
                                    rows={2}
                                    label="Keterangan"
                                    name="notes"
                                    placeholder="-"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={
                                        status === "decline" || status === "done"?
                                            true
                                        : status === "validate" && localStorage.getItem("level") !== "1"?
                                            true
                                        : status === "validate" && localStorage.getItem("level") === "1"?
                                            false
                                        : false
                                    }
                                />
                            </Grid>
                        <Grid item xs={12} className="add-margin-top transaction-table"
                            style={{ overflowX: 'auto' }}>
                            <table className="manual-table transaction restock">
                                <thead>
                                    {status === "pending" || status === "decline"?
                                        <tr>
                                            <th style={{ width: "30px" }}>No</th>
                                            <th style={{ textAlign: "left" }}>Kode</th>
                                            <th style={{ textAlign: "left" }}>Nama Produk</th>
                                            <th>Kuantitas</th>
                                        </tr>
                                    : (status === "validate" || status === "done") && localStorage.getItem("level") === "1" ?
                                        <tr>
                                            <th style={{ width: "30px" }}>No</th>
                                            <th style={{ textAlign: "left" }}>Kode</th>
                                            <th style={{ textAlign: "left" }}>Nama Produk</th>
                                            <th style={{ textAlign: "left" }}>Harga Jual</th>
                                            <th style={{ textAlign: "left" }}>Harga Beli</th>
                                            <th>Kuantitas</th>
                                            <th style={{ textAlign: "right" }}>Subtotal</th>
                                        </tr>
                                    : 
                                        <tr>
                                            <th style={{ width: "30px" }}>No</th>
                                            <th style={{ textAlign: "left" }}>Kode</th>
                                            <th style={{ textAlign: "left" }}>Nama Produk</th>
                                            <th style={{ textAlign: "left" }}>Harga Jual</th>
                                            <th>Kuantitas</th>
                                        </tr>
                                    }
                                </thead>
                                <tbody>
                                    {items.map((product, idx) => ((idx % 2 !== 0)?
                                        <tr className="odd-cell" key={`trx-item-${idx}`}>
                                            <td className="center-cell">{idx+1}</td>
                                            <td>{product.product_id.product_code}</td>
                                            <td>{product.product_id.name}</td>
                                            {/* Harga Jual 1 */}
                                            {status === "pending" || status === "decline"?
                                                <></> :
                                                <td>
                                                    <Controller
                                                        as={
                                                            <NumberFormat
                                                                customInput={TextField}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix={"Rp "}
                                                                placeholder="Rp 0"
                                                                allowNegative={false}
                                                                className="restock-price"
                                                                disabled={status === "validate" || status === "done"?
                                                                    true : false}
                                                            />
                                                        }
                                                        name={`selling_price.${idx}`}
                                                        control={control}
                                                    />
                                                </td>
                                            }
                                            {/* Harga Beli Master 1 */}
                                            {(status === "validate" || status === "done") && localStorage.getItem("level") === "1"?
                                                <td>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        defaultValue={product.purchase_price === 0?
                                                            "" : product.purchase_price}
                                                        onBlur={async (event) => {
                                                            setLoadCalculation(true)
                                                            let res = items;
                                                            let value = event.target.value;
                                                            let prevTotal = res[idx].subtotal;
                                                            let itemSubtotal = 0;
                                                            // Set per item
                                                            if ( value ) {
                                                                value = formatPrice(value);
                                                                itemSubtotal = value * res[idx].quantity;
                                                                res[idx].purchase_price = value;
                                                                res[idx].subtotal = value * res[idx].quantity;
                                                                setValue(("subtotal." + idx), itemSubtotal);
                                                            }
                                                            else {
                                                                res[idx].subtotal = 0;
                                                            }
                                                            setItems(res);
                                                            const currTotal = totalPrice? totalPrice : 0;
                                                            setTotalPrice(currTotal - prevTotal + itemSubtotal);
                                                            await sleep(200)
                                                            setLoadCalculation(false)
                                                        }}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        prefix={"Rp "}
                                                        placeholder="Rp -"
                                                        allowNegative={false}
                                                        disabled={status === "done"? true : false}
                                                    />
                                                </td> : <></>
                                            }
                                            <td className="center-cell">
                                                <TextField
                                                    type={status === "decline" || status === "validate"
                                                        || status === "done"? "text" : "number"
                                                    }
                                                    autoComplete='off'
                                                    name={`quantity.${idx}`}
                                                    className="input-stock"
                                                    placeholder="-"
                                                    inputRef={register}
                                                    disabled={status === "decline" || status === "validate"
                                                        || status === "done"? true : false
                                                    }
                                                />
                                            </td>
                                            {/* Subtotal Master 1 */}
                                            {(status === "validate" || status === "done") && localStorage.getItem("level") === "1"?
                                                <td>
                                                    <Controller
                                                        as={
                                                            <NumberFormat
                                                                customInput={TextField}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix={"Rp "}
                                                                placeholder="Rp 0"
                                                                allowNegative={false}
                                                                className="restock-price right"
                                                                disabled={true}
                                                            />
                                                        }
                                                        name={`subtotal.${idx}`}
                                                        control={control}
                                                    />
                                                </td> : <></>
                                            }
                                        </tr>
                                        : <tr className="even-cell" key={`trx-item-${idx}`}>
                                            <td className="center-cell">{idx+1}</td>
                                            <td>{product.product_id.product_code}</td>
                                            <td>{product.product_id.name}</td>
                                            {/* Harga Jual 2 */}
                                            {status === "pending" || status === "decline"?
                                                <></> :
                                                <td>
                                                    <Controller
                                                        as={
                                                            <NumberFormat
                                                                customInput={TextField}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix={"Rp "}
                                                                placeholder="Rp 0"
                                                                allowNegative={false}
                                                                className="restock-price"
                                                                disabled={status === "validate"|| status === "done"?
                                                                    true : false}
                                                            />
                                                        }
                                                        name={`selling_price.${idx}`}
                                                        control={control}
                                                    />
                                                </td>
                                            }
                                            {/* Harga Beli Master 2 */}
                                            {(status === "validate" || status === "done") && localStorage.getItem("level") === "1"?
                                                <td>
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        defaultValue={product.purchase_price === 0?
                                                            "" : product.purchase_price}
                                                        onBlur={async (event) => {
                                                            setLoadCalculation(true)
                                                            let res = items;
                                                            let value = event.target.value;
                                                            let prevTotal = res[idx].subtotal;
                                                            let itemSubtotal = 0;
                                                            // Set per item
                                                            if ( value ) {
                                                                value = formatPrice(value);
                                                                itemSubtotal = value * res[idx].quantity;
                                                                res[idx].purchase_price = value;
                                                                res[idx].subtotal = value * res[idx].quantity;
                                                                setValue(("subtotal." + idx), itemSubtotal);
                                                            }
                                                            else {
                                                                res[idx].subtotal = 0;
                                                            }
                                                            setItems(res);
                                                            const currTotal = totalPrice? totalPrice : 0;
                                                            setTotalPrice(currTotal - prevTotal + itemSubtotal);
                                                            await sleep(200)
                                                            setLoadCalculation(false)
                                                        }}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        prefix={"Rp "}
                                                        placeholder="Rp -"
                                                        allowNegative={false}
                                                        disabled={status === "done"? true : false}
                                                    />
                                                </td> : <></>
                                            }
                                            <td className="center-cell">
                                                <TextField
                                                    type={status === "decline" || status === "validate"
                                                        || status === "done"? "text" : "number"
                                                    }
                                                    autoComplete='off'
                                                    name={`quantity.${idx}`}
                                                    className="input-stock"
                                                    placeholder="-"
                                                    inputRef={register}
                                                    disabled={status === "decline" || status === "validate"
                                                        || status === "done"? true : false
                                                    }
                                                />
                                            </td>
                                            {/* Subtotal Master 2 */}
                                            {(status === "validate" || status === "done") && localStorage.getItem("level") === "1"?
                                                <td>
                                                    <Controller
                                                        as={
                                                            <NumberFormat
                                                                customInput={TextField}
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                prefix={"Rp "}
                                                                placeholder="Rp 0"
                                                                allowNegative={false}
                                                                className="restock-price right"
                                                                disabled={true}
                                                            />
                                                        }
                                                        name={`subtotal.${idx}`}
                                                        control={control}
                                                    />
                                                </td> : <></>
                                            }
                                        </tr>
                                    ))}
                                    {localStorage.getItem("level") === "1" && (status === "validate"
                                        || status === "done" )?
                                        <tr className="no-border">
                                            <td colSpan="6" className="footer">
                                                Total Harga Beli
                                            </td>
                                            <td className="footer">
                                                <NumberFormat
                                                    value={totalPrice}
                                                    displayType={"text"}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    prefix={"Rp "}
                                                />
                                            </td>
                                        </tr> : <></>
                                    }
                                </tbody>
                            </table>
                        </Grid>
                        </Grid>
                        {status === "pending" || status === "approve" || status === "validate"?
                            <Grid container>
                                <Grid item xs={12}>
                                    {status === "validate"?
                                        <></> :
                                        <Button
                                            variant="outlined"
                                            className="add-cancel-btn"
                                            onClick={() => setOpenDecline(true)}
                                        >Tolak</Button>
                                    }
                                    {status !== "validate" && status !== "done"?
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            style={{ float: "right", marginRight: "15px" }}
                                            className={`add-new-btn responsive-submit-btn`}
                                        >
                                            {status === "pending"?
                                                "Approve"
                                            : "Validasi"
                                            }
                                        </Button>
                                    : status === "validate" && localStorage.getItem("level") === "1"?
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            style={{ float: "right", marginRight: 0 }}
                                            className={`add-new-btn responsive-submit-btn`}
                                        >Done</Button>
                                    : <></>
                                    }
                                </Grid>
                            </Grid>
                        : <></>
                        }
                    </form>
                </Grid>
            </Grid>
        </div>
    )
}