import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar, TextField } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import FirstPageOutlinedIcon from '@material-ui/icons/FirstPageOutlined';
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import LastPageOutlinedIcon from '@material-ui/icons/LastPageOutlined';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { TextInput, NumberInput, PriceInput, ImageInput, DropdownInput } from "../../../logic/FormInput";
import {
    getCategories, getProduct, getProductsLog,
    getDiscounts, updateProduct,
} from "../../../logic/APIHandler";
import { formatPrice } from "../../../logic/Handler";
import { categs } from "../../../assets/data/Categories";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";
import moment from "moment";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailProduct(props) {

    const numberRegex = /^[0-9]*$/;
    let Barcode = require('react-barcode');

    // Form State
    const [code, setCode] = useState("0000");
    const [name, setName] = useState("");
    const [defaultPrice, setDefaultPrice] = useState(0);
    const [stock, setStock] = useState("");
    const [stockWarehouse, setStockWarehouse] = useState("");
    const [categories, setCategories] = useState([]);
    const [discounts, setDiscounts] = useState([]);
    const [updatedData, setUpdatedData] = useState("");
    const [log, setLog] = useState([]);
    const [logWR, setLogWR] = useState([]);
    
    // Pagination State - Store
    const [counter, setCounter] = useState(localStorage.getItem("tablePageLog")?
        parseInt(localStorage.getItem("tablePageLog")) : 1);
    const [page, setPage] = useState(localStorage.getItem("tablePageLog")?
        parseInt(localStorage.getItem("tablePageLog")) : 1);
    const [totalPage, setTotalPage] = useState(1);
    // Pagination State - Warehouse
    const [counterWR, setCounterWR] = useState(localStorage.getItem("tablePageLogWR")?
        parseInt(localStorage.getItem("tablePageLogWR")) : 1);
    const [pageWR, setPageWR] = useState(localStorage.getItem("tablePageLogWR")?
        parseInt(localStorage.getItem("tablePageLogWR")) : 1);
    const [totalPageWR, setTotalPageWR] = useState(1);
    
    // Page State
    const location = useLocation();
    const history = useHistory();
    const { register, handleSubmit, control, errors, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [idProduct, setIDProduct] = useState(null);
    const [productImg, setProductImg] = useState("");
    const [hide, setHide] = useState('hide');
    const [pageTitle, setPageTitle] = useState('Detail Produk');
    const [openPin, setOpenPin] = useState(false);
    const [openJWT, setOpenJWT] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
        
        // Set Indonesia as moment's location
        let idLocale = require('moment/locale/id'); 
        moment.locale('id', idLocale);
        
        getCategoryData();
        
        const idProduct = props.match.params.id;
        getDiscountList(idProduct);
        setIDProduct(idProduct);
        if ( idProduct ) {
            getData(idProduct);
            getLog(idProduct);
            getLogWarehouse(idProduct);
        }
        else {
            console.log("ID not found");
        }     
    }, [props.match.params.id]);

    // Get initial data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = await getProduct(id);
        if ( resp[0] && resp[0].status === 200 ) {
            const product = resp[0].data;
            setValue("name", product.name);
            setValue("product_code", product.product_code);
            setValue("selling_price", product.selling_price);
            setValue("purchase_price", product.purchase_price && product.purchase_price !== 0?
                product.purchase_price : "");
            setValue("shelf_location", product.shelf_location);
            if ( product.promo_id ) {
                setValue("promo_id", {
                    "label": product.promo_id.name,
                    "value": product.promo_id._id,
                });
            }

            let categs = [];
            product.categories.forEach(res => {
                categs.push({
                    "label": res.category_id.name, "value": res.category_id._id,
                });
            });
            setValue("category_id", categs);

            setDefaultPrice(product.selling_price);
            setStock(product.stock_store);
            setStockWarehouse(product.stock_warehouse);
            setCode(product.product_code);
            setName(product.name);
            setProductImg((product.product_image !== "undefined")? product.product_image : "");
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp[1]? resp[1].message : resp);
        }
        setIsLoading(false);
    }

    // Get product's log
    const getLog = async id => {
        setIsLoading(true);
        let resp = [];
        if ( localStorage.getItem("tablePageLog") ) {
            resp = await getProductsLog(id, 'store', localStorage.getItem("tablePageLog"), 10);
        }
        else {
            resp = await getProductsLog(id, 'store', 1, 10);
        }
        if ( resp[0] && resp[0].status === 200 ) {
            let totalPages = Math.ceil(resp[0].data.total / 10);
            setLog(resp[0].data.history);
            setTotalPage(totalPages);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
        }
        setIsLoading(false);
    }
    const getLogWarehouse = async id => {
        setIsLoading(true);
        let resp = [];
        if ( localStorage.getItem("tablePageLogWR") ) {
            resp = await getProductsLog(id, 'warehouse', localStorage.getItem("tablePageLogWR"), 10);
        }
        else {
            resp = await getProductsLog(id, 'warehouse', 1, 10);
        }
        if ( resp[0] && resp[0].status === 200 ) {
            let totalPages = Math.ceil(resp[0].data.total / 10);
            setLogWR(resp[0].data.history);
            setTotalPageWR(totalPages);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp);
        }
        setIsLoading(false);
    }

    // Get list of categories
    const getCategoryData = async () => {
        if ( localStorage.getItem("level") !== "1" && localStorage.getItem("level") !== "3" ) {
            setIsLoading(true);
            let resp = await getCategories();
            if ( resp[0] && resp[0].status === 200 ) {
                let temp = [];
                resp[0].data.sort((a,b) => (a.name > b.name? 1 : -1)).forEach(res => {
                    temp.push({
                        "label": res.name,
                        "value": res._id,
                    })
                });
                setCategories(temp);
            }
            else if ( resp[1] && resp[1].status === 401 ) {
                setIsLoading(false);
                setOpenJWT(true);
            }
            else {
                console.log(resp);
            }
            setIsLoading(false);
        }
    }
    // Get list of discounts
    const getDiscountList = async (id) => {
        if ( localStorage.getItem("level") !== "1" && localStorage.getItem("level") !== "3" ) {
            setIsLoading(true);
            let resp = await getDiscounts();
            if ( resp[0] && resp[0].status === 200 ) {
                let temp = [];
                let flatPercentDisc = resp[0].data.filter(res => res["type_promo"] !== "buyxgety");
                let buyxgetyDisc = resp[0].data.filter(res => res["type_promo"] === "buyxgety");
                buyxgetyDisc = buyxgetyDisc.filter(res => res["x"]["product_id"]["_id"] === id);
                const result = [...buyxgetyDisc, ...flatPercentDisc];
                result.forEach(res => {
                    temp.push({
                        "label": res.name,
                        "value": res._id,
                    })
                });
                setDiscounts(temp);
            }
            else if ( resp[0] && resp[0].status === 204 ) {
                // Do nothing
            }
            else if ( resp[1] && resp[1].status === 401 ) {
                setIsLoading(false);
                setOpenJWT(true);
            }
            else {
                console.log(resp);
            }
            setIsLoading(false);
        }
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Handle toggle edit button
    const onClickEdit = () => {
        if ( isDisabled ) {
            setPageTitle("Edit Produk")
            setHide("");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // CLose pin modal
    const handleClose = () => {
        setOpenPin(false);
    }

    const handlePagination = async (event, id, origin) => {
        setIsLoading(true);
        let resp = [];
        if ( id === "go-to-first" ) {
            resp = await getProductsLog(idProduct, origin, 1, 10);
            if ( resp[0] && resp[0].status === 200 ) {
                localStorage.setItem("path", location.pathname);
                if ( origin === "store" ) {
                    localStorage.setItem("tablePageLog", parseInt(1));
                    setLog(resp[0].data.history);
                    setPage(1);
                    setCounter(1);
                }
                else {
                    localStorage.setItem("tablePageLogWR", parseInt(1));
                    setLogWR(resp[0].data.history);
                    setPageWR(1);
                    setCounterWR(1);
                }
                setIsLoading(false);
            }
        }
        else if ( id === "go-to-last" ) {
            resp = await getProductsLog(idProduct, origin, origin === "store"? totalPage : totalPageWR, 10);
            if ( resp[0] && resp[0].status === 200 ) {
                localStorage.setItem("path", location.pathname);
                if ( origin === "store" ) {
                    localStorage.setItem("tablePageLog", parseInt(totalPage));
                    setLog(resp[0].data.history);
                    setPage(totalPage);
                    setCounter(totalPage);
                }
                else {
                    localStorage.setItem("tablePageLogWR", parseInt(totalPageWR));
                    setLogWR(resp[0].data.history);
                    setPageWR(totalPageWR);
                    setCounterWR(totalPageWR);
                }
                setIsLoading(false);
            }
        }
        else if ( id === "prev-page" ) {
            resp = await getProductsLog(idProduct, origin, origin === "store"? page-1 : pageWR-1, 10);
            if ( resp[0] && resp[0].status === 200 ) {
                localStorage.setItem("path", location.pathname);
                if ( origin === "store" ) {
                    localStorage.setItem("tablePageLog", parseInt(page - 1));
                    setLog(resp[0].data.history);
                    setPage(page-1);
                    setCounter(page-1);
                }
                else {
                    localStorage.setItem("tablePageLogWR", parseInt(pageWR - 1));
                    setLogWR(resp[0].data.history);
                    setPageWR(pageWR-1);
                    setCounterWR(pageWR-1);
                }
                setIsLoading(false);
            }
        }
        else if ( id === "next-page" ) {
            resp = await getProductsLog(idProduct, origin, origin === "store"? page+1 : pageWR+1, 10);
            if ( resp[0] && resp[0].status === 200 ) {
                localStorage.setItem("path", location.pathname);
                if ( origin === "store" ) {
                    localStorage.setItem("tablePageLog", parseInt(page + 1));
                    setLog(resp[0].data.history);
                    setPage(page+1);
                    setCounter(page+1);
                }
                else {
                    localStorage.setItem("tablePageLogWR", parseInt(pageWR + 1));
                    setLogWR(resp[0].data.history);
                    setPageWR(pageWR+1);
                    setCounterWR(pageWR+1);
                }
                setIsLoading(false);
            }
        }
        
        if ( resp[0] && resp[0].status === 200 ) {}
        else {
            setErrorText("Gagal memuat data. Cek kembali koneksi internet Anda & reload page ini.");
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    // Handle pagination if user input page from textfield 
    const handleSubmitFilter = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        let resp = [];
        let currPage = false;
        if ( counter < 1 ) {
            resp = await getProductsLog(idProduct, "store", 1, 10);
            currPage = 1;
        }
        else if ( counter >= totalPage ) {
            resp = await getProductsLog(idProduct, "store", totalPage, 10);
            currPage = totalPage;
        }
        else if ( counter >= 1 && counter < totalPage ) {
            resp = await getProductsLog(idProduct, "store", counter, 10);
        }
        if ( resp[0] && resp[0].status === 200 ) {
            localStorage.setItem("path", location.pathname);
            localStorage.setItem("tablePageLog", parseInt(currPage? currPage : counter));
            setLog(resp[0].data.history);
            setCounter(currPage? currPage : counter);
            setPage(currPage? currPage : counter);
            setIsLoading(false);
        }
        else {
            setLog([]);
            setCounter(1);
            setPage(1);
            setErrorText("Gagal memuat data. Cek kembali koneksi internet Anda & reload page ini.");
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }
    const handleSubmitFilterWR = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        let resp = [];
        let currPage = false;
        if ( counterWR < 1 ) {
            resp = await getProductsLog(idProduct, "warehouse", 1, 10);
            currPage = 1;
        }
        else if ( counterWR >= totalPageWR ) {
            resp = await getProductsLog(idProduct, "warehouse", totalPageWR, 10);
            currPage = totalPageWR;
        }
        else if ( counterWR >= 1 && counterWR < totalPageWR ) {
            resp = await getProductsLog(idProduct, "warehouse", counterWR, 10);
        }
        if ( resp[0] && resp[0].status === 200 ) {
            localStorage.setItem("path", location.pathname);
            localStorage.setItem("tablePageLogWR", parseInt(currPage? currPage : counterWR));
            setLogWR(resp[0].data.history);
            setCounterWR(currPage? currPage : counterWR);
            setPageWR(currPage? currPage : counterWR);
            setIsLoading(false);
        }
        else {
            setLogWR([]);
            setCounterWR(1);
            setPageWR(1);
            setErrorText("Gagal memuat data. Cek kembali koneksi internet Anda & reload page ini.");
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    // Handle onChange input number
    const onChangeInputNumber = (event) => {
        if ( numberRegex.test(event.target.value) ) {
            if ( event.target.name === "stock_store" ) {
                setStock(event.target.value);
            }
            else if ( event.target.name === "stock_warehouse" ) {
                setStockWarehouse(event.target.value);
            }
        }
    }

    // Download product's barcode as .png
    const downloadBarcode = () => {
        const image = document.querySelector('.barcode > img');
        let a = document.createElement("a"); //Create <a>
        a.href = image.src; //Image Base64 Goes here
        a.download = code + " - " + name + ".png"; //File name Here
        a.click(); //Downloaded file
    }

    // Send new data to server
    const onSubmit = async (data) => {
        let isUsePin = true;
        if ( localStorage.getItem("level") === "1" ) {
            data.product_code = code;
            isUsePin = false;
        }
        else if ( localStorage.getItem("level") === "2" ) {
            if ( defaultPrice === formatPrice(data.selling_price) ) {
                isUsePin = false;
            }
        }
        if ( isUsePin ) {
            setUpdatedData(data);
            setOpenPin(true);
        }
        else {
            setIsLoading(true);
            let resp = await updateProduct(idProduct, data, "");
            if ( resp[0] && resp[0].status === 200 ) {
                setIsLoading(false);
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.go(0);
            }
            else if ( resp[1] && resp[1].status === 400 ) {
                if ( resp[1].data ) setErrorText(resp[1].data[0].msg);
                else setErrorText(resp[1].message);
                setOpenErrorAlert(true);
                setIsLoading(false);
            }
            else {
                setErrorText("Gagal meng-update produk!");
                setOpenErrorAlert(true);
                setIsLoading(false);
            }
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""}
                    level={localStorage.getItem("level")} />

                <Modal
                    open={openPin}
                    onClose={handleClose}
                    onClickCancel={handleClose}
                    modalType="update-product"
                    id={idProduct}
                    color="primary"
                    modalTitle="Masukkan Pin Master"
                    updatedData={updatedData}
                    submitText="Submit"
                    cancelText="Cancel"
                />
                <Modal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Update Produk
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <h1 style={{ marginBottom: "2.2rem" }}>
                                        {pageTitle}
                                    </h1>
                                </Grid>
                                {localStorage.getItem("level") === "5"? // cashier
                                    <></>
                                : localStorage.getItem("level") === "3"?
                                    <Grid item xs={12} md={6}>
                                        <Button
                                            variant="contained"
                                            className="edit-btn"
                                            onClick={downloadBarcode}
                                        >Download Barcode</Button>
                                    </Grid>
                                : <Grid item xs={12} md={6}>
                                        <Button
                                            variant="contained"
                                            className={`edit-btn left-margin ${hide? "":"hide"}`}
                                            onClick={onClickEdit}
                                        >Edit</Button>
                                        <Button
                                            variant="contained"
                                            className="edit-btn"
                                            onClick={downloadBarcode}
                                        >Download Barcode</Button>
                                    </Grid>
                                }
                            </Grid>

                            <Grid container spacing={2} className="add-margin-bottom">
                                <Grid item xs={12} className="barcode hide">
                                    <Barcode
                                        value={code}
                                        renderer={"img"}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        label="Kode"
                                        name="product_code"
                                        placeholder="Kode Produk"
                                        className="full-width"
                                        validation={register({ required: "*kode produk harus diisi" })}
                                        errors={errors.product_code}
                                        errorClassName="text-required-half use-spacing"
                                        disabled={localStorage.getItem("level") === "2"? isDisabled : true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        label="Nama *"
                                        name="name"
                                        placeholder="Nama Produk"
                                        className="full-width"
                                        validation={register({ required: "*nama produk harus diisi" })}
                                        errors={errors.name}
                                        errorClassName="text-required-half use-spacing"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    <label className="selection-label">Kategori Produk *</label>
                                    <DropdownInput
                                        name="category_id"
                                        className={`${isDisabled? "disabled-field" : ""}
                                            selection-field`}
                                        control={control}
                                        options={categories}
                                        // defaultValue={defaultCategories}
                                        rules={{ required: "*pilih minimal 1 kategori" }}
                                        errors={errors.category_id}
                                        errorClassName="text-required use-spacing"
                                        isClearable={true}
                                        isMulti={true}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <PriceInput
                                        label="Harga Jual"
                                        name="selling_price"
                                        className="full-width price-field"
                                        control={control}
                                        rules={{ required: "*harga jual produk harus diisi" }}
                                        errors={errors.selling_price}
                                        errorClassName="text-required-half use-spacing"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {localStorage.getItem("level") === "1"?
                                        <PriceInput
                                            user="master"
                                            label="Harga Beli"
                                            name="purchase_price"
                                            className="full-width price-field"
                                            control={control}
                                            disabled={isDisabled}
                                        /> :      
                                        <TextInput
                                            label="Letak Rak"
                                            name="shelf_location"
                                            placeholder="Lokasi"
                                            className="full-width"
                                            validation={register}
                                            disabled={isDisabled}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberInput
                                        label="Stok Toko"
                                        name="stock_store"
                                        placeholder="x (piece)"
                                        className="full-width"
                                        value={stock}
                                        onChange={onChangeInputNumber}
                                        validation={register}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberInput
                                        label="Stok Gudang"
                                        name="stock_warehouse"
                                        placeholder="x (piece)"
                                        className="full-width"
                                        value={stockWarehouse}
                                        onChange={onChangeInputNumber}
                                        validation={register}
                                        disabled={true}
                                    />
                                </Grid>
                                {localStorage.getItem("level") === "1"?
                                    <Grid item xs={12}>
                                        <TextInput
                                            label="Letak Rak"
                                            name="shelf_location"
                                            placeholder="Lokasi"
                                            className="full-width"
                                            validation={register}
                                            disabled={isDisabled}
                                        />
                                    </Grid> : <></>
                                }
                                <Grid item xs={12} style={{ position: "relative" }}>
                                    <label className="selection-label">Diskon</label>
                                    <DropdownInput
                                        name="promo_id"
                                        className={`${isDisabled? "disabled-field" : ""}
                                            selection-field add-margin-bottom`}
                                        control={control}
                                        options={discounts}
                                        isClearable={true}
                                        placeholder="-"
                                    />
                                </Grid>
                                <Grid item xs={12} className="add-margin-top">
                                    <ImageInput
                                        name="product_image"
                                        label="Foto Produk"
                                        validation={register}
                                        isEdit={true}
                                        control={control}
                                        defaultImg={productImg}
                                        canDelete={true}
                                        overallClassName="full-input-image"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={onClickEdit}
                                        className={`add-cancel-btn ${hide}`}
                                    >Cancel</Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        style={{ float: "right", marginRight: "15px" }}
                                        className={`add-new-btn responsive-submit-btn ${hide}`}
                                    >Submit</Button>
                                </Grid>
                            </Grid>

                        </form>
                    </Grid>
                    <Grid item xs={12} >
                        <hr className="add-margin-top add-margin-bottom" />
                    </Grid>
                    <Grid item xs={12} >
                        <form onSubmit={handleSubmitFilter} className="add-form">
                            <Grid container>
                                <Grid item xs={12}>
                                    <h1 style={{ marginBottom: "2.2rem" }}>
                                        Log Produk - Toko
                                    </h1>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12} style={{ overflowX: 'auto' }}>
                                    <table className="manual-table">
                                        <thead>
                                            <tr>
                                                <th style={{width: "30px"}}>No</th>
                                                <th style={{width: "170px", textAlign: "left"}}>Tanggal</th>
                                                <th style={{textAlign: "left"}}>Kategori</th>
                                                {/* <th style={{textAlign: "left"}}>Asal</th> */}
                                                <th style={{width: "100px"}}>Jumlah Awal</th>
                                                <th style={{width: "100px"}}>Kuantitas</th>
                                                <th style={{width: "100px"}}>Jumlah Akhir</th>
                                                <th style={{width: "150px"}}>Detail</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {log.map((res, idx) => (
                                                <tr
                                                    key={`item-${idx}`}
                                                    className={(idx % 2) !== 0? "odd-cell" : "even-cell"}
                                                >
                                                    <td className="center-cell">
                                                        {idx+1+(10*(page-1))}
                                                    </td>
                                                    <td>
                                                        {moment(res.date).format("DD MMMM YYYY")}
                                                    </td>
                                                    <td>{categs[res.category]}</td>
                                                    {/* <td>{res.origin === "store"? "Toko" : "Gudang"}</td> */}
                                                    <td className="center-cell">{res.prev_stock}</td>
                                                    <td className={`center-cell text-bold ${
                                                        res.category === "buy" || res.category === "request-product"
                                                            || (res.category === "transition" && (res.curr_stock - res.prev_stock > 0))?
                                                            "text-green"
                                                        : res.category === "opname" || res.category === "adjustment"
                                                            || res.category === "refund" || res.category === "return-supplier"?
                                                            ""
                                                        : "text-red"
                                                    }`}>
                                                        {`
                                                            ${res.category === "buy"? "+"
                                                            : res.category === "opname"? "="
                                                            : res.category === "adjustment"? "="
                                                            : res.category === "refund"? ""
                                                            : res.category === "return-supplier"? ""
                                                            : res.category === "request-product"? "+"
                                                            : res.category === "transition" && (res.curr_stock - res.prev_stock > 0) ? "+"
                                                            // : res.category === "transition" && res.origin === "warehouse"? "-"
                                                            // : res.category === "losses"? "-"
                                                            : "-"}
                                                            ${res.quantity}
                                                        `}
                                                    </td>
                                                    <td className="center-cell">{res.curr_stock}</td>
                                                    <td className="center-cell">
                                                        <NavLink
                                                            to={res.category.includes("sell") && res.transaction_id?
                                                                `transaksi/${res.transaction_id._id}`
                                                                :res.category.includes("sell") && !res.transaction_id?
                                                                `/`
                                                                : location.pathname.includes("barang-retur")?
                                                                `/barang-retur/${res._id}`
                                                                : `/produk/riwayat-stok/${res._id}`
                                                            }
                                                            style={{ color: "black" }}
                                                        >Lihat Detail</NavLink>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: "2.5rem" }}>
                                <Grid item xs={12}>
                                    <div className="manual-pagination">

                                        <FirstPageOutlinedIcon
                                            id="go-to-first"
                                            style={{
                                                color: page === 1?
                                                    "#B0B0B0" : "black",
                                                cursor: page === 1?
                                                    "default" : "pointer",
                                                pointerEvents: page === 1?
                                                    "none" : "initial",
                                            }}
                                            onClick={(e) => handlePagination(e, "go-to-first", "store")}
                                        />
                                        <NavigateBeforeOutlinedIcon
                                            id="prev-page"
                                            style={{
                                                color: page === 1?
                                                    "#B0B0B0" : "black",
                                                cursor: page === 1?
                                                    "default" : "pointer",
                                                pointerEvents: page === 1?
                                                    "none" : "initial",
                                            }}
                                            onClick={(e) => handlePagination(e, "prev-page", "store")}
                                        />

                                        <TextField
                                            variant="outlined"
                                            placeholder="-"
                                            value={counter}
                                            onChange={(e) => {
                                                setCounter(e.target.value);
                                            }}
                                        />
                                        <span style={{ marginRight: "5px" }}>/</span>
                                        <span className="last-idx">{totalPage}</span>

                                        <NavigateNextOutlinedIcon
                                            id="next-page"
                                            style={{
                                                color: page === totalPage?
                                                    "#B0B0B0" : "black",
                                                cursor: page === totalPage?
                                                    "default" : "pointer",
                                                pointerEvents: page === totalPage?
                                                    "none" : "initial",
                                            }}
                                            onClick={(e) => handlePagination(e, "next-page", "store")}
                                        />
                                        <LastPageOutlinedIcon
                                            id="go-to-last"
                                            style={{
                                                color: page === totalPage?
                                                    "#B0B0B0" : "black",
                                                cursor: page === totalPage?
                                                    "default" : "pointer",
                                                pointerEvents: page === totalPage?
                                                    "none" : "initial",
                                            }}
                                            onClick={(e) => handlePagination(e, "go-to-last", "store")}
                                        />

                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid item xs={12} >
                        <hr className="add-margin-top add-margin-bottom" />
                    </Grid>
                    <Grid item xs={12} >
                        <form onSubmit={handleSubmitFilterWR} className="add-form">
                            <Grid container>
                                <Grid item xs={12}>
                                    <h1 style={{ marginBottom: "2.2rem" }}>
                                        Log Produk - Gudang
                                    </h1>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12} style={{ overflowX: 'auto' }}>
                                    <table className="manual-table">
                                        <thead>
                                            <tr>
                                                <th style={{width: "30px"}}>No</th>
                                                <th style={{width: "170px", textAlign: "left"}}>Tanggal</th>
                                                <th style={{textAlign: "left"}}>Kategori</th>
                                                {/* <th style={{textAlign: "left"}}>Asal</th> */}
                                                <th style={{width: "100px"}}>Jumlah Awal</th>
                                                <th style={{width: "100px"}}>Kuantitas</th>
                                                <th style={{width: "100px"}}>Jumlah Akhir</th>
                                                <th style={{width: "150px"}}>Detail</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {logWR.map((res, idx) => (
                                                <tr
                                                    key={`item-${idx}`}
                                                    className={(idx % 2) !== 0? "odd-cell" : "even-cell"}
                                                >
                                                    <td className="center-cell">
                                                        {idx+1+(10*(pageWR-1))}
                                                    </td>
                                                    <td>
                                                        {moment(res.date).format("DD MMMM YYYY")}
                                                    </td>
                                                    <td>{categs[res.category]}</td>
                                                    {/* <td>{res.origin === "store"? "Toko" : "Gudang"}</td> */}
                                                    <td className="center-cell">{res.prev_stock}</td>
                                                    <td className={`center-cell text-bold ${
                                                        res.category === "buy" || res.category === "request-product"
                                                            || (res.category === "transition" && (res.curr_stock - res.prev_stock > 0))?
                                                            "text-green"
                                                        : res.category === "opname" || res.category === "adjustment"
                                                            || res.category === "refund" || res.category === "return-supplier"?
                                                            ""
                                                        : "text-red"
                                                    }`}>
                                                        {`
                                                            ${res.category === "buy"? "+"
                                                            : res.category === "opname"? "="
                                                            : res.category === "adjustment"? "="
                                                            : res.category === "refund"? ""
                                                            : res.category === "return-supplier"? ""
                                                            : res.category === "request-product"? "+"
                                                            : res.category === "transition" && (res.curr_stock - res.prev_stock > 0) ? "+"
                                                            // : res.category === "transition" && res.origin === "warehouse"? "-"
                                                            // : res.category === "losses"? "-"
                                                            : "-"}
                                                            ${res.quantity}
                                                        `}
                                                    </td>
                                                    <td className="center-cell">{res.curr_stock}</td>
                                                    <td className="center-cell">
                                                        <NavLink
                                                            to={res.category.includes("sell") && res.transaction_id?
                                                                `transaksi/${res.transaction_id._id}`
                                                                :res.category.includes("sell") && !res.transaction_id?
                                                                `/`
                                                                : location.pathname.includes("barang-retur")?
                                                                `/barang-retur/${res._id}`
                                                                : `/produk/riwayat-stok/${res._id}`
                                                            }
                                                            style={{ color: "black" }}
                                                        >Lihat Detail</NavLink>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: "2.5rem" }}>
                                <Grid item xs={12}>
                                    <div className="manual-pagination">

                                        <FirstPageOutlinedIcon
                                            id="go-to-first"
                                            style={{
                                                color: pageWR === 1?
                                                    "#B0B0B0" : "black",
                                                cursor: pageWR === 1?
                                                    "default" : "pointer",
                                                pointerEvents: pageWR === 1?
                                                    "none" : "initial",
                                            }}
                                            onClick={(e) => handlePagination(e, "go-to-first", "warehouse")}
                                        />
                                        <NavigateBeforeOutlinedIcon
                                            id="prev-page"
                                            style={{
                                                color: pageWR === 1?
                                                    "#B0B0B0" : "black",
                                                cursor: pageWR === 1?
                                                    "default" : "pointer",
                                                pointerEvents: pageWR === 1?
                                                    "none" : "initial",
                                            }}
                                            onClick={(e) => handlePagination(e, "prev-page", "warehouse")}
                                        />

                                        <TextField
                                            variant="outlined"
                                            placeholder="-"
                                            value={counterWR}
                                            onChange={(e) => {
                                                setCounterWR(e.target.value);
                                            }}
                                        />
                                        <span style={{ marginRight: "5px" }}>/</span>
                                        <span className="last-idx">{totalPageWR}</span>

                                        <NavigateNextOutlinedIcon
                                            id="next-page"
                                            style={{
                                                color: pageWR === totalPageWR?
                                                    "#B0B0B0" : "black",
                                                cursor: pageWR === totalPageWR?
                                                    "default" : "pointer",
                                                pointerEvents: pageWR === totalPageWR?
                                                    "none" : "initial",
                                            }}
                                            onClick={(e) => handlePagination(e, "next-page", "warehouse")}
                                        />
                                        <LastPageOutlinedIcon
                                            id="go-to-last"
                                            style={{
                                                color: pageWR === totalPageWR?
                                                    "#B0B0B0" : "black",
                                                cursor: pageWR === totalPageWR?
                                                    "default" : "pointer",
                                                pointerEvents: pageWR === totalPageWR?
                                                    "none" : "initial",
                                            }}
                                            onClick={(e) => handlePagination(e, "go-to-last", "warehouse")}
                                        />

                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}