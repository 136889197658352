import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Button, Snackbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { TextInput, PhoneInput, EmailInput, } from "../../../logic/FormInput";
import { getBranch, updateBranch } from "../../../logic/APIHandler";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailBranch(props) {

    const numberRegex = /^[0-9]*$/;

    // Form State
    const [phoneNumber, setPhoneNumber] = useState("");
    
    // Page State
    const history = useHistory();
    const { register, handleSubmit, errors, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [idBranch, setIDBranch] = useState(null);
    const [hide, setHide] = useState('hide');
    const [pageTitle, setPageTitle] = useState('Detail Cabang');
    const [openJWT, setOpenJWT] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
        const idBranch = props.match.params.id;
        setIDBranch(idBranch);
        if ( idBranch ) {
            getData(idBranch);
        }
        else {
            console.log("ID not found");
        }     
    }, [props.match.params.id]);

    // Get initial data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = await getBranch(id);
        if ( resp[0] && resp[0].status === 200 ) {
            const branch = resp[0].data;
            setValue("name", branch.name);
            setValue("code", branch.code);
            setValue("npwp", branch.npwp);
            setValue("address", branch.address);
            setValue("kelurahan", branch.kelurahan);
            setValue("kecamatan", branch.kecamatan);
            setValue("kabupaten", branch.kabupaten);
            setValue("provinsi", branch.provinsi);
            setValue("kodepos", branch.kodepos);
            setValue("email", branch.email);
            setValue("pic", branch.pic);
            setPhoneNumber(branch.contact_pic);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp[1].message);
        }
        setIsLoading(false);
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Handle toggle edit button
    const onClickEdit = () => {
        if ( isDisabled ) {
            setPageTitle("Edit Cabang")
            setHide("");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Handle onChange phone number & prevent user to input characters
    const onChangePhoneNumber = (event) => {
        const value = event.target.value;
        if ( value ) {
            // allow + and number as first input
            if ( value.length === 1 ) {
                if ( value === '+' || numberRegex.test(value) ) {
                    setPhoneNumber(event.target.value);
                }
            }
            // only allow numbers after first input
            else if ( value.length <= 16 ) {
                if ( numberRegex.test(value.substring(1)) ) {
                    setPhoneNumber(event.target.value);
                }
            }
        }
        else {
            setPhoneNumber(event.target.value);
        }
    }

    // Send new data to server
    const onSubmit = async (data) => {
        setIsLoading(true);
        let resp = await updateBranch(idBranch, data);
        if (resp[0] && resp[0].status === 200) {
            // Update branches in localstorage
            let result = JSON.parse(localStorage.getItem("branch"));
            result = result.filter(res => res.value !== resp[0].data._id);
            result.push({
                label: resp[0].data.name,
                value: resp[0].data._id,
            })
            result = result.sort((a,b) => (a.label > b.label? 1 : -1));
            localStorage.setItem("branch", JSON.stringify(result));

            // Refresh page
            setIsLoading(false);
            setOpenSuccessAlert(true);
            await sleep(1000);
            history.go(0);
        }
        else if ( resp[1] && resp[1].status === 400 ) {
            setErrorText(resp[1].data[0].msg);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
        else {
            setErrorText(resp[1].message);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} level="1" />

                <Modal
                    open={openJWT}
                    modalType="handle-jwt"
                    modalTitle="Token Anda Sudah Expire"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={logo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Update Cabang
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            {errorText}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <h1 style={{ marginBottom: "2.2rem" }}>
                                        {pageTitle}
                                    </h1>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Button variant="contained" className={`edit-btn ${hide? "":"hide"}`}
                                        onClick={onClickEdit}>Edit</Button>
                                </Grid>
                            </Grid>

                            <Grid container className="add-margin-bottom">
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        label="Nama Cabang"
                                        name="name"
                                        placeholder="Nama Cabang"
                                        className="form-left-field add-margin-bottom"
                                        validation={register({ required: "*nama cabang harus diisi" })}
                                        errors={errors.name}
                                        errorClassName="text-required-half"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        label="Kode Cabang"
                                        name="code"
                                        placeholder="Kode"
                                        className="form-right-field add-margin-bottom"
                                        validation={register({ required: "*kode cabang harus diisi" })}
                                        errors={errors.code}
                                        errorClassName="text-required-half"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="NPWP"
                                        name="npwp"
                                        placeholder="NPWP"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*NPWP harus diisi" })}
                                        errors={errors.npwp}
                                        errorClassName="text-required"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Alamat Cabang"
                                        name="address"
                                        placeholder="Alamat"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*alamat cabang harus diisi" })}
                                        errors={errors.address}
                                        errorClassName="text-required"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Kelurahan"
                                        name="kelurahan"
                                        placeholder="Kelurahan"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Kecamatan"
                                        name="kecamatan"
                                        placeholder="Kecamatan"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label="Kabupaten"
                                        name="kabupaten"
                                        placeholder="Kabupaten"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        label="Provinsi"
                                        name="provinsi"
                                        placeholder="Provinsi"
                                        className="form-left-field add-margin-bottom"
                                        validation={register}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextInput
                                        label="Kode Pos"
                                        name="kodepos"
                                        placeholder="Kode Pos"
                                        className="form-right-field add-margin-bottom"
                                        validation={register}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <EmailInput
                                        label="Email"
                                        name="email"
                                        placeholder="Email"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                        required={false}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={6} className="add-margin-bottom">
                                    <TextInput
                                        label="Nama PIC"
                                        name="pic"
                                        placeholder="Nama PIC"
                                        className="form-left-field add-margin-bottom"
                                        validation={register({ required: "*nama PIC harus diisi" })}
                                        errors={errors.pic}
                                        errorClassName="text-required-half"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={6} className="add-margin-bottom">
                                    <PhoneInput
                                        label="Nomor Kontak PIC"
                                        name="contact_pic"
                                        placeholder="Nomor Kontak PIC"
                                        className="form-right-field add-margin-bottom"
                                        value={phoneNumber}
                                        onChange={onChangePhoneNumber}
                                        validation={register}
                                        requiredText="*nomor kontak PIC harus diisi"
                                        errors={errors.contact_pic}
                                        errorClassName="text-required-half"
                                        disabled={isDisabled}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={onClickEdit}
                                        className={`add-cancel-btn ${hide}`}
                                    >Cancel</Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        style={{ float: "right", marginRight: "15px" }}
                                        className={`add-new-btn responsive-submit-btn ${hide}`}
                                    >Submit</Button>
                                </Grid>
                            </Grid>

                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}