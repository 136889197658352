import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Grid, Button, Snackbar, TextField } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Sidebar from "../../../components/Sidebar_Com";
import Spinner from "../../../components/Loading_Com";
import Modal from "../../../components/Modal_Com";
import { DateInput, TextInput } from "../../../logic/FormInput";
import { downloadPickingOrderPDF } from "../../../logic/Handler";
import { getTransition } from "../../../logic/APIHandler";
import logo from "../../../assets/img/logo.png";
import "../../../styles/Form_Styles.css";
import moment from "moment";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailTransition(props) {
    
    // Form State
    const [id, setId] = useState("");
    const [items, setItems] = useState([]);
    const [defaultDate, setDefaultDate] = useState(moment());

    // Page State
    const { register, setValue, watch } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openJWT, setOpenJWT] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        window.scrollTo(0, 0);
        const idTransition = props.match.params.id;
        setId(idTransition);
        if ( idTransition ) {
            getData(idTransition);
        }
        else {
            console.log("ID not found");
        }     
    }, [props.match.params.id]);

    // Get initial data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = await getTransition(id);
        if ( resp[0] && resp[0].status === 200 ) {
            const result = resp[0].data;
            setDefaultDate(result.created_at.substring(0,19));
            setValue("notes", result.notes);
            setValue("invoice", result.invoice);
            const origin = result.origin;
            setValue("origin", origin === "store"? "Toko" : "Gudang");
            setValue("destination", origin === "store"? "Gudang" : "Toko");
            setItems(result.items);
            result.items.forEach((res, idx) => {
                setValue(("quantity." + idx), res.quantity);
            });
            setIsLoading(false);
        }
        else if ( resp[1] && resp[1].status === 401 ) {
            setIsLoading(false);
            setOpenJWT(true);
        }
        else {
            console.log(resp[1].message);
            setIsLoading(false);
        }
    }

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Download picking order PDF
    const downloadPickingOrder = () => {
        setIsLoading(true);
        let data = {
            "branch": items[0].product_id.branch_store_id.name,
            "invoice": watch("invoice"),
            "date": watch("date"),
            "notes": watch("notes"),
            "items": items,
        };
        downloadPickingOrderPDF(data);
        setIsLoading(false);
    };

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""}
                level={localStorage.getItem("level")} />

            <Modal
                open={openJWT}
                modalType="handle-jwt"
                modalTitle="Token Anda Sudah Expire"
            />
            
            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <img src={logo} alt="logo" className="header-logo" />
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openErrorAlert} autoHideDuration={3000} onClose={handleCloseError}>
                    <Alert severity="error">
                        {errorText}
                    </Alert>
                </Snackbar>
                <Grid item xs={12}>
                    <form className="add-form">
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <h1 style={{ marginBottom: "2.2rem" }}>
                                    Detail Transisi
                                </h1>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button
                                    variant="contained"
                                    className="edit-btn"
                                    onClick={downloadPickingOrder}
                                >
                                    <CloudDownloadIcon style={{ marginRight: "10px" }}/>
                                    Download Picking Order
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                <DateInput
                                    label="Tanggal Transisi"
                                    name="date"
                                    value={defaultDate}
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    label="No. Invoice"
                                    name="invoice"
                                    placeholder="-"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    label="Asal"
                                    name="origin"
                                    placeholder="-"
                                    className="form-left-field add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    label="Tujuan"
                                    name="destination"
                                    placeholder="-"
                                    className="form-right-field add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    multiline={true}
                                    rows={2}
                                    label="Keterangan"
                                    name="notes"
                                    placeholder="-"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={true}
                                />
                            </Grid>
                        <Grid item xs={12} className="add-margin-top transaction-table"
                            style={{ overflowX: 'auto' }}>
                            <table className="manual-table transaction restock">
                                <thead>
                                    <tr>
                                        <th style={{ width: "30px" }}>No</th>
                                        <th style={{ textAlign: "left" }}>Kode</th>
                                        <th style={{ textAlign: "left" }}>Nama Produk</th>
                                        <th>Kuantitas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((product, idx) => (
                                        <tr
                                            key={`trx-item-${idx}`}
                                            className={(idx % 2 !== 0)? "odd-cell" : "even-cell"}
                                        >
                                            <td className="center-cell">{idx+1}</td>
                                            <td>{product.product_id.product_code}</td>
                                            <td>{product.product_id.name}</td>
                                            <td className="center-cell">
                                                <TextField
                                                    name={`quantity.${idx}`}
                                                    className="input-stock"
                                                    placeholder="-"
                                                    inputRef={register}
                                                    disabled={true}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </div>
    )
}